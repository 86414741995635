import React, { useState } from "react";
import { Button, Modal, FormGroup, Label } from "reactstrap";
import { loggedInUser, encryptData } from "components/Common/Helpers.js";
import { toast } from 'react-toastify';
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import eventBus from "views/modules/Common/EventBus";
import { PostData } from "services/PostData";
import RenderActionButton from "views/modules/Common/ActionsButtons/RenderActionButton";
import { sendExceptionEmail } from "components/Common/Helpers";

const ReportOrderReviseDismissClientButton = ({ propReportRow, propButtonOptions }) => {

    const userData = loggedInUser();
    const [isProcess, setProcess] = useState(false);
    const [isOpenDismissReportOrderModal, setOpenDismissReportOrderModal] = useState(false);
    const [dismissReason, setDismissReason] = useState("");

    const handleActionClick = async (e, row) => {
        e.preventDefault();
        setOpenDismissReportOrderModal(true);
    };

    let handleToggleModal = (state) => {
        if (!isProcess) {
            state = !state;
            setOpenDismissReportOrderModal(state);
        }
    };

    let handleDismissOrderProcess = async (report_order_id) => {
        try {
            setProcess(true);
            let data = { report_order_id: report_order_id, user_id: userData.user_id, dismiss_reason: dismissReason }
            let encrypt = encryptData(data);
            const result = await PostData("client/orders/dismiss_report_order", { data: encrypt })

            let responseJson = result.data;
            setProcess(false);

            if (responseJson.status !== 200) {
                toast["error"](responseJson.message);
            } else {
                toast["success"](responseJson.message);
                handleToggleModal(isOpenDismissReportOrderModal);
            }
            eventBus.dispatch("report_order_status_updated", { client_id: propReportRow.company_id });
        } catch (error) {
            await sendExceptionEmail(error, { memberCode: 200, componentName: 'ReportOrderReviseDismissClientButton', functionName: 'handleDismissOrderProcess', data: {} });
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            setProcess(false);
        }
    };

    return (
        <>
            <RenderActionButton propButtonTitle="Dismiss" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />

            <Modal className="modal-dialog-centered" style={{ maxWidth: "500px" }} isOpen={isOpenDismissReportOrderModal} toggle={() => handleToggleModal(isOpenDismissReportOrderModal)}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-center w-100">
                        Dismiss
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenDismissReportOrderModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <AvForm onValidSubmit={(e) => handleDismissOrderProcess(propReportRow.id, false)} >
                        <AvGroup className="text-left">
                            <Label className="required">Reason</Label>
                            <AvField
                                name="dismiss_reason"
                                onChange={(e) => setDismissReason(e.target.value)}
                                className="form-control"
                                placeholder="Enter Reason"
                                type="textarea"
                                rows="6"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "This field is required."
                                    },
                                    maxLength: {
                                        value: 500,
                                        errorMessage: 'Reason must be less than 255 character'
                                    }
                                }}
                            />
                        </AvGroup>
                        <div className="text-right">
                            <FormGroup>
                                {isProcess ?
                                    <Button type="button" color="warning" size="sm">
                                        <div className="fa fa-spinner fa-spin"></div> Processing...
                                    </Button>
                                    :
                                    <>
                                        <Button size="sm" color="danger" outline onClick={() => handleToggleModal(isOpenDismissReportOrderModal)}>
                                            Close
                                        </Button>
                                        <Button size="sm" color="primary">
                                            Submit
                                        </Button>
                                    </>
                                }
                            </FormGroup>
                        </div>
                    </AvForm>
                </div>
            </Modal>
        </>
    );
};

export default ReportOrderReviseDismissClientButton;