import React from "react";
import RenderActionButton from "../RenderActionButton";
import { toast } from 'react-toastify';
import { PostData } from "services/PostData";
import { sendExceptionEmail } from "components/Common/Helpers";

const EpoCompletedOrderClientButton = ({ propRowData, propButtonOptions }) => {
    const handleActionClick = async (e, row) => {
        try {
            const orderDetailsData = await PostData("client/epo-orders/download-report", { order_token: propRowData.order_token });
            const pdfUrl = orderDetailsData.data.data.epoOrders.report_pdf_url
            if (pdfUrl) {
                const link = document.createElement('a');
                link.href = pdfUrl;
                link.setAttribute('download', pdfUrl);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                toast["error"]("PDF url not exists!");
            }
        } catch (errors) {
            await sendExceptionEmail(errors, { memberCode: 500, componentName: 'EpoCompletedOrderClientButton', functionName: 'handleActionClick', data: {} });
        }
    };

    return (
        <>
            <RenderActionButton propButtonTitle="Download Report" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />
        </>
    );
};

export default EpoCompletedOrderClientButton;