import React from 'react';
import { PopoverOnClick } from "components/PopoverOnHover/PopoverOnHover";
import { checkRoles } from "../../../components/Common/Helpers";

const OrderInfoDescription = ({ propertyCompInfo }) => {

    const updatedPropertyCompInfo = [
        { key: 'Comp ID', value: propertyCompInfo.find(item => item.key === 'Comp ID')?.value || 'Not Found' },
        { key: 'Discrepancy Task ID', value: propertyCompInfo.find(item => item.key === 'Discrepancy Task ID')?.value || 'Not Found' },
        { key: 'Report ID', value: propertyCompInfo.find(item => item.key === 'Report ID')?.value || 'N/A' },
        { key: 'Property ID', value: propertyCompInfo.find(item => item.key === 'Property ID')?.value || 'N/A' }
    ];

    const description = (
        <table className="table table-bordered">
            <tbody>
                {updatedPropertyCompInfo.map((item, index) => (
                    <tr key={index}>
                        <td>{item.key}</td>
                        <td>{item.value}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );

    return (
        <div>
            {checkRoles(['ADMINISTRATOR', 'TECH']) && (
                <PopoverOnClick placement="bottom" type="legacy" className="bg-light" description={description}>
                    <i className="fa fa-info-circle m-2 cursor-pointer" style={{ fontSize: '25px' }}></i>
                </PopoverOnClick>
            )}
        </div>
    );
}

export default OrderInfoDescription;


