import React, { useState, useEffect } from "react";
import { Button, Row, Col, CardFooter, CardBody } from "reactstrap";
import "./style.scss";
import QualityGradeComponent from "./QualityGradeComponent";
import { PostData } from "services/PostData";
import { toast } from "react-toastify";
import Spinner from "components/Common/Spinner.js";
import { loggedInUser } from "components/Common/Helpers.js"
import CompDiscrepancy from "../Common/CompDiscrepancy";
import { AvForm } from "availity-reactstrap-validation";
import axios from 'axios';
import { sendExceptionEmail } from "components/Common/Helpers";
import Slider from "../Common/DisputeOrder/Slider";
const userData = loggedInUser();
const SubjectPropertyCondition = ({ propData, onHandleSubjectPropertyConditionResponse }) => {
    const [isProcess, setProcess] = useState(false);
    let [qualityGrade, setQualityGrade] = useState("");
    let [selectedQualityGrade, setSelectedQualityGrade] = useState("");
    let [customError, setCustomError] = useState("");
    let [conditionId, setConditionId] = useState("");
    // let [qualityGradeIdArr] = useState([A_PLUS, A, A_MINUS, B_PLUS, B, B_MINUS, C_PLUS, C_OR_C_MINUS, D, E]);
    // let [qualityGradeArr] = useState([A_PLUS_GRADE, A_GRADE, A_MINUS_GRADE, B_PLUS_GRADE, B_GRADE, B_MINUS_GRADE, C_PLUS_GRADE, C_AMD_C_MINUS_GRADE, D_GRADE, E_GRADE]);

    useEffect(() => {
        if (propData.buildingQualityTemp) propData.subject_property.apex_quality_grade = propData.buildingQualityTemp.grade
        if (propData.subject_property && propData.subject_property.apex_quality_grade && propData.building_quality_apex_ratings) {
            let index = propData.building_quality_apex_ratings.findIndex(x => x.quality_rating === propData.subject_property.apex_quality_grade);

            if (["C", "C-"].includes(propData.subject_property.apex_quality_grade)) {
                index = propData.building_quality_apex_ratings.findIndex(x => x.quality_rating.includes(propData.subject_property.apex_quality_grade) && x.description.includes(propData.subject_property.apex_quality_grade));
            }
            if (index >= 0) {
                setQualityGrade(propData.building_quality_apex_ratings[index].quality_rating);
                setSelectedQualityGrade(parseInt(propData.building_quality_apex_ratings[index].apex_quality_grades));
                propData.grade = propData.building_quality_apex_ratings[index].quality_rating;
            }
        }

        if (propData.subject_property) {
            const isCondition = propData.conditionRatingsIds ?? propData.subject_property.condition_rating;
            setConditionId(parseFloat(isCondition));
            propData.condition_id = parseFloat(isCondition);
        }
        if (propData.building_quality_apex_ratings) {

            for (let i = 0; i < propData.building_quality_apex_ratings.length; i++) {
                if (parseInt(propData.building_quality_apex_ratings[i].apex_quality_grades) === selectedQualityGrade) {
                    setQualityGrade(propData.building_quality_apex_ratings[i].quality_rating);
                }
            }
        }
        propData.isFromSubjectPropertyCondition = true;

    }, [propData]);// eslint-disable-line react-hooks/exhaustive-deps  

    let handleQualityGradeResponse = (gradeID, grade) => {

        setQualityGrade(grade);
        setSelectedQualityGrade(gradeID);
        propData.buildingQualityTemp = { gradeID, grade };
        propData.grade = grade;
    }

    let handleConditionResponse = ({ rating }) => {
        setConditionId(rating);
        if (propData.conditionRatingsIds) propData.conditionRatingsIds = rating;
        propData.condition_id = rating;
    }

    // Handle Steps
    let onHandleSubjectPropertyCondition = (e) => {

        if (!qualityGrade || !conditionId) {
            setCustomError("Please select Quality and Condition");
            return false;
        }
        let data = {};
        data.report_order_id = propData.id;
        data.user_id = userData.user_id;
        data.company_id = userData.company_id;
        data.property_comp_id = propData.subject_property.id;
        data.condition_id = conditionId;
        data.quality_grade = qualityGrade;
        data.property_id = propData.property_id;
        setProcess(true);
        PostData("orders/save_subject_property_condition_and_quality", data).then(result => {
            if (parseFloat(propData.subject_property.condition_rating) !== parseFloat(conditionId)) {
                setProcess(false);
                onHandleSubjectPropertyConditionResponse(4, 'Budget Review');
                axios({
                    url: process.env.REACT_APP_PHP_ENDPOINT + "valuation/property_calculations/recalculateBtn/" + propData.property_id,
                    method: 'post',
                    data: data,
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then(response => {
                    toast["success"](result.data.message);
                }).catch(async (errors) => {
                    await sendExceptionEmail(errors, { memberCode: 401, componentName: 'SubjectPropertyCondition', functionName: 'onHandleSubjectPropertyCondition', data: {} });
                });
            } else {
                toast["success"](result.data.message);
                onHandleSubjectPropertyConditionResponse(4, 'Budget Review');
            }

        }).catch(errors => {
            setProcess(false);
            toast["error"](errors.message);
        });
    }

    return (
        <>
            <Spinner isShow={isProcess} />
            <div className="step-3">
                <AvForm onValidSubmit={(e) => onHandleSubjectPropertyCondition(e)}>
                    <CardBody className="full-view">
                        <p className="mb-4"><b>Please identify the current quality and condition of the subject property</b></p>
                        <Row className="full-view">
                            <Col xs={12} xl={6} className="text-left">
                                <Row className="condition-and-quality condition-quality-data">
                                    <Col xs={12} md={6} xl={12} className="mt-4">
                                        <div className="font-weight-bold mb-4">Quality<span className="w-350 underline-heading d-inline-block"></span></div>
                                        <div className="quality d-flex">

                                            {propData.building_quality_apex_ratings && propData.building_quality_apex_ratings.map(function (data, i) {

                                                return (
                                                    <div key={i}>
                                                        <QualityGradeComponent grade={data.quality_rating} gradeID={data.apex_quality_grades} tooltipId={i} onHandleQualityGrade={handleQualityGradeResponse} selected={selectedQualityGrade} propDescription={data.description} />
                                                    </div>
                                                );
                                            })}

                                        </div>
                                    </Col>

                                    <Col xs={12} md={6} xl={12} className="mt-4">
                                        <div className="font-weight-bold mb-4">Condition<span className="w-350 underline-heading d-inline-block"></span></div>
                                        <Slider id={1} conditionRating={conditionId ?? 1} propConditionRating={handleConditionResponse} />
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={12} xl={6} className="">
                                <div className="pdf-section shadow p-3 mb-5 bg-white rounded">
                                    {propData.inspection_file && (
                                        <>
                                            <iframe title="inspection" id="inspection" src={propData.inspection_file} width="100%" height="100%"></iframe>
                                        </>
                                    )}

                                    {
                                        !propData.inspection_file && propData.photos_files && propData.photos_files.map((item, i) => {
                                            return item.img ? (
                                                <img
                                                    key={i}
                                                    src={item.img}
                                                    alt={`photo_${i + 1}`}
                                                    className="photo-files"
                                                />
                                            ) : (
                                                <iframe key={i} title="inspection" id="inspection" src={item.pdf} width="100%" height="100%" ></iframe>
                                            );
                                        })
                                    }




                                    {!propData.inspection_file && !propData.photos_files.length && (
                                        <>
                                            <CompDiscrepancy propData={propData} />
                                        </>
                                    )}

                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col className="text-left">
                                <div className="form-error">{customError}</div>
                            </Col>

                            <Col className="text-right">
                                <Button color="danger" outline onClick={() => onHandleSubjectPropertyConditionResponse("cancel")}>
                                    Cancel Assignment
                                </Button>
                                <Button color="default" outline onClick={() => onHandleSubjectPropertyConditionResponse(2, 'Quality on Subject Street')}>
                                    Back
                                </Button>
                                <Button color="info">
                                    <span className="btn-inner--text" >{propData.budget_file && propData.renovation_budget_needed === 1 ? "Next" : "Analyze"}</span>
                                </Button>
                            </Col>
                        </Row>
                    </CardFooter>
                </AvForm>
            </div>
        </>
    );
};
export default SubjectPropertyCondition;
