import React from "react";
import { toast } from 'react-toastify';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { PostData } from "../../services/PostData";
import Spinner from "components/Common/Spinner.js";
import { Button, Card, CardBody, FormGroup, InputGroupAddon, InputGroupText, InputGroup, Col, Row } from "reactstrap";
import { encryptData, loggedInUser, sendExceptionEmail, updateLoginLogoutLocalStorage } from "components/Common/Helpers.js"

class Login extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			username: "",
			password: "",
			success: "success",
			error: "error",
			isProcess: false,
			type: "",
			isModal: false,
			userData: loggedInUser(),
			lockUnlock: "fa fa-lock",
			autoLoginRoute: ""
		};

		this.handleLogin = this.handleLogin.bind(this);
	};

	/*
	| Call before component render on the dom
	*/
	componentWillMount() { };

	/*
	| Call after component render on the dom
	*/
	async componentDidMount() {
		try {
			let param = this.props.location.search;

			let paramString = new URLSearchParams(param);
			if (param && paramString.get('token')) {

				let token = paramString.get('token');
				token = paramString.get('token').replace(/ /g, '+');

				let data = { string: token }

				this.setState({ isProcess: true });
				const result = await PostData("auth/decrypt", data)

				let responseJson = result.data;
				let autoLoginRoute = paramString.get('menu').replace(" ", "+");

				if (responseJson.status === 200) {
					this.setState({ username: responseJson.data.username, password: responseJson.data.password, type: responseJson.data.type, autoLoginRoute: autoLoginRoute })
					localStorage.removeItem("userData");
					localStorage.removeItem("OCT"); //Other client token
					this.handleLogin();
				} else {
					toast[this.state.error](responseJson.message);
					this.setState({ isProcess: false });
				}
			} else {
				if (this.state.userData) {
					const result = await PostData("auth/verify_token", { token: this.state.userData ? this.state.userData.token : null })
					let responseJson = result.data;
					if (responseJson.status === 200) {
						const lastVisitedRoute = localStorage.getItem('lastVisitedRoute')

						if (this.state.userData.report_order_company_id === this.state.userData.company_id) {
							this.props.history.push(lastVisitedRoute || '/admin/order-management');
						} else if (this.state.userData.oms_access === 1) {
							this.props.history.push(lastVisitedRoute || '/client/orders');
						} else {
							localStorage.removeItem("userData");
							localStorage.removeItem("OCT"); //Other client token
							toast[this.state.error]("Not Authorized to Login.");
						}
					} else {
						localStorage.removeItem("userData");
						localStorage.removeItem("OCT"); //Other client token
						this.props.history.push('/auth/login')
					}
				}
			}
		} catch (error) {
			await sendExceptionEmail(error, { memberCode: 502, componentName: 'Login', functionName: 'componentDidMount', data: {} });
			this.props.history.push('/auth/login')
		}
	};

	/*
	| Call before component delete/leave from dom
	*/
	componentWillUnmount() { };

	// Handle Input Data
	handleInput(e) {
		this.setState({ [e.target.name]: e.target.value });
	};

	// Handle refresh table
	handleRefresh = (state, isValue) => {
		if (isValue === true) {
			this.setState({ [state]: !this.state[state] });
			this.props.history.push('/admin/order-management');
			window.location.reload();
		}
	};

	// Handle Login Request
	async handleLogin() {
		try {
			this.setState({ lockUnlock: "fa fa-lock-open" });
			this.setState({ isProcess: true });
			let data = {
				username: this.state.username,
				password: this.state.password,
				type: this.state.type
			};

			let encrypt = encryptData(data);

			const result = await PostData("auth/login", { data: encrypt })

			let responseJson = result.data;

			if (responseJson.status === 200) {
				const userData = responseJson.data[0];

				this.setState({ isProcess: false });
				let sessionData = "";
				let lastVisitedRoute = localStorage.getItem('lastVisitedRoute');
				// If user launch from php, redirect redirect using that URL
				if (this.state.autoLoginRoute) {
					lastVisitedRoute = (userData?.is_RV_user ? '/admin/' : '/client/') + this.state.autoLoginRoute;
				}

				if (userData?.is_RV_user) {
					sessionData = JSON.stringify(userData);
					localStorage.setItem("userData", sessionData);
					this.props.history.push(lastVisitedRoute || '/admin/order-management');
					localStorage.removeItem('lastVisitedRoute')
					toast[this.state.success](responseJson.message);
				} else if (userData.oms_access === 1) {
					sessionData = JSON.stringify({ token: userData.token });
					localStorage.setItem("userData", sessionData);
					this.props.history.push(lastVisitedRoute || '/client/orders');
					localStorage.removeItem('lastVisitedRoute')
					toast[this.state.success](responseJson.message);
				} else {
					localStorage.removeItem("userData");
					localStorage.removeItem("OCT");
					toast[this.state.error]("Not Authorized to Login.");
					return false;
				}

				updateLoginLogoutLocalStorage(true);
				window.location.reload();
			} else {
				toast[this.state.error](responseJson.message);
				this.setState({ isProcess: false });
				this.setState({ lockUnlock: "fa fa-lock" });
			}
		}
		catch (error) {
			await sendExceptionEmail(error, { memberCode: 502, componentName: 'Login', functionName: 'handleLogin', data: {} });
			this.setState({ isProcess: false });
		}
	};

	handleModal = (state) => {
		this.setState({ [state]: !this.state[state] });
	};

	//Render Dom
	render() {
		return (
			<>
				<Col lg="5" md="7">
					{/* Loader Spinner */}
					<Spinner isShow={this.state.isProcess} />

					<Card className="bg-secondary shadow border-0">
						<CardBody className="px-lg-5 py-lg-5">
							<div className="text-center mb-4">
								<b>Login to your account</b>
							</div>
							<AvForm onValidSubmit={this.handleLogin} >
								<AvGroup >
									<InputGroup className="input-group-alternative">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className="ni ni-email-83" />
											</InputGroupText>
										</InputGroupAddon>
										<AvInput name="username"
											value={this.state.username}
											placeholder="Username or Email"
											type="text"
											autoComplete="new-email"
											onChange={(event) => this.handleInput(event)}
											required
										/>
										<AvFeedback>This field is invalid</AvFeedback>
									</InputGroup>
								</AvGroup>
								<AvGroup >
									<InputGroup className="input-group-alternative">
										<InputGroupAddon addonType="prepend">
											<InputGroupText>
												<i className={this.state.lockUnlock} />
											</InputGroupText>
										</InputGroupAddon>
										<AvInput name="password"
											value={this.state.password}
											placeholder="Password"
											type="password"
											autoComplete="new-password"
											onChange={(event) => this.handleInput(event)}
											required
										/>
										<AvFeedback>This field is invalid</AvFeedback>
									</InputGroup>
								</AvGroup>
								<div className="text-center">
									<FormGroup>
										<Button className="" color="info">
											Sign in
										</Button>
									</FormGroup>
								</div>
							</AvForm>
						</CardBody>
					</Card>
					<Row className="mt-3">
						<Col xs="6">
							<a className="text-light" href="forget-password">
								<small>Forgot password?</small>
							</a>
						</Col>
						<Col className="text-right" xs="6">
							<a className="text-light" href="retrieve-username">
								<small>Retrieve Username</small>
							</a>
						</Col>
					</Row>
				</Col>
			</>
		);
	};
};

export default Login;