const PROPERTY_TYPES = [
    { name: "Single-Family Residential (SFR) - Detached", slug: "sfr", units: 1 },
    { name: "Townhouse/Rowhouse (Single-Family Attached)", slug: "townhouse", units: 1 },
    { name: "Condominium", slug: "condo", units: 1 },
    { name: "Duplex (Both Units - Single Ownership)", slug: "duplex", units: 2 },
    { name: "Triplex", slug: "triplex", units: 3 },
    { name: "Quadruplex", slug: "quadruplex", units: 4 },
    { name: "Mobile", slug: "mobile", units: 1 },
    { name: "Multi Family", slug: "mfr", units: null },
];

const PROPERTY_TYPES_ABBREVIATIONS = {
    'Farm': 'FM',
    'Duplex': 'DP',
    'Condo': 'C',
    'Land': 'VL',
    'CRE': 'CRE',
    'Quadruplex': 'QP',
    'Mobile': 'MF',
    'Townhouse': 'TH',
    'SFR': 'SF',
    'Triplex': 'TP',
    '2-4 Unit': 'UKM',
    'MFR': 'MFR',
    'Mixed Use': 'MU',
    'Unknown': 'UK',

    // Below values are presents in PROD DB.
    'Manufactured': '',
    'Mobile Home': '',
    'RES': '',
    'Residential': '',
    'null': '',
    'TH': '',
};

const PROPERTY_CONDITIONS = {
    'NEW_BUILT': {
        slug: "new-construction",
        image: "new-build-purple.png",
        label: 'New Built',
        value: 8,
        description: "New construction in 2022 or 2021. This condition is equivalent to C1 condition."
    },
    'FULLY_REMODELED': {
        slug: "fully-remodeled",
        image: "full-remodel.png",
        label: 'Fully Remodeled',
        value: 7,
        description: "Fully remodeled to today's specs and standards, including kitchen, bathrooms, appliances, interior, exterior, etc. This condition is equivalent to C2 condition."
    },
    'PARTIALLY_REMODELED': {
        slug: "partially-remodeled",
        image: "partial-remodel.png",
        label: 'Partially Remodeled',
        value: 6,
        description: "Some recent upgrades and remodeling updates. This condition is equivalent to C3 condition."
    },
    'MAINTAINED': {
        slug: "maintained",
        image: "maintained.png",
        label: 'Maintained',
        value: 5,
        description: "Marketable condition, with good upkeep on maintenance repair items. This condition is equivalent to C3.5 condition."
    },
    'MODERATE': {
        slug: "moderate",
        image: "moderate.png",
        label: 'Moderate',
        value: 4,
        description: "Worn condition. May have disrepair items. This condition is equivalent to C4 condition."
    },
    'POOR': {
        slug: "poor",
        image: "poor.png",
        label: 'Poor',
        value: 3,
        description: "In definite disrepair, with significant work items to be financeable. This condition is equivalent to C5 condition."
    },
    'VERY_POOR': {
        slug: "very-poor",
        image: "very-poor.png",
        label: 'Very Poor',
        value: 2,
        description: "In need of major repair, possible 'total gut and remodel'. This condition is equivalent to C5.5 condition."
    },
    'UNSALVAGEABLE': {
        slug: "unsalvageable",
        image: "unsalvageable-grey.png",
        label: 'Unsalvageable',
        value: 1,
        description: "Suffering major issues which could prevent refurbish / remodel strategies of the existing structure. This condition is equivalent to C6 condition."
    }
};

const CONDITION = {
    UNSALVAGE_MIN: 1,
    UNSALVAGE_MAX: 1.64,
    VERY_POOR_MIN: 1.65,
    VERY_POOR_MAX: 2.64,
    POOR_MIN: 2.65,
    POOR_MAX: 3.64,
    MODERATE_MIN: 3.65,
    MODERATE_MAX: 4.64,
    MAINTAINED_MIN: 4.65,
    MAINTAINED_MAX: 5.64,
    PARTIAL_REMODEL_MIN: 5.65,
    PARTIAL_REMODEL_MAX: 6.64,
    FULLY_REMODEL_MIN: 6.65,
    FULLY_REMODEL_MAX: 7.49,
    NEW_BUILT_MIN: 7.5,
    NEW_BUILT_MAX: 8
};

const CLUSTER_MAP_CONTROLS = {
    'cluster_n/a': {
        home: require('../../../../assets/img/cluster-houses/cluster_na.png').default
    },
    'cluster_0': {
        home: require('../../../../assets/img/cluster-houses/cluster_na.png').default
    },
    'cluster_1': {
        home: require('../../../../assets/img/cluster-houses/cluster_1.png').default
    },
    'cluster_2': {
        home: require('../../../../assets/img/cluster-houses/cluster_2.png').default
    },
    'cluster_3': {
        home: require('../../../../assets/img/cluster-houses/cluster_3.png').default
    },
    'cluster_4': {
        home: require('../../../../assets/img/cluster-houses/cluster_4.png').default
    },
    'cluster_5': {
        home: require('../../../../assets/img/cluster-houses/cluster_5.png').default
    },
    'cluster_6': {
        home: require('../../../../assets/img/cluster-houses/cluster_6.png').default
    },
    'cluster_7': {
        home: require('../../../../assets/img/cluster-houses/cluster_7.png').default
    },
    'cluster_8': {
        home: require('../../../../assets/img/cluster-houses/cluster_8.png').default
    }
};

module.exports = {
    PROPERTY_TYPES,
    PROPERTY_TYPES_ABBREVIATIONS,
    PROPERTY_CONDITIONS,
    CONDITION,
    CLUSTER_MAP_CONTROLS
};