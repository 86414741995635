import React, { useState, useEffect } from "react";
import { AvForm } from 'availity-reactstrap-validation';
import { Button, Row, Col, UncontrolledTooltip } from "reactstrap";
import { PostData } from "../../../services/Api.service";
import Dropzone from 'react-dropzone';
import { confirmAlert } from 'react-confirm-alert';
import Spinner from "components/Common/Spinner.js";
import * as moment from "moment";
import { toast } from 'react-toastify';
import { loggedInUser, sendExceptionEmail, checkRoles, checkRolesWithLevel } from "components/Common/Helpers.js"
import { ROLE_ANALYST, ROLE_PROCESSOR, ROLE_ACCOUNT_REP, ROLE_REVIEWER } from "../Common/Constants";
import { MESSAGE_TYPES } from "../Common/Constants/Conversation.constant";
import FilesList from "../Common/File-Upload/FilesList";
import UserList from "../Common/UserList.js";

const SendMessageForm = ({ standaloneType, conversation, lastMessageData, onSendMessage, onMarkResolved, onEditSetMsg, onUpdateMessage }) => { // cTab
    let [isProcess, setProcess] = useState(false);
    let [message, setMessage] = useState([]);
    let [enable, setEnable] = useState(false);
    let [selectUser, setSelectUser] = useState(false);
    let [getUsers, setGetUsers] = useState([]);
    let [user, setUser] = useState(false);
    let [update, setUpdate] = useState(moment());
    let [selectedThreadFiles, setSelectedThreadFiles] = useState([]);
    let [recipients, setRecipients] = useState([]);
    let [threadFileUploadError, setThreadFileUploadError] = useState("");
    let [selectRecipientError, setSelectRecipientError] = useState("");
    let [selectUserError, setSelectUserError] = useState("");
    let [newConversationId, setNewConversationId] = useState([]);
    let [messageTypeValue, setMessageTypeValue] = useState(2);
    let [params] = useState({});
    let [toRole, setToRole] = useState([]);
    let [toUser, setToUser] = useState(false);
    let [toClient, setToClient] = useState(false);
    const userData = loggedInUser();

    useEffect(() => {
        if (messageTypeValue === MESSAGE_TYPES.CLIENT || messageTypeValue === MESSAGE_TYPES.INSPECTOR) {
            setSelectRecipientError("")
            setSelectUserError("")
        }
    }, [messageTypeValue, update]);
    useEffect(() => {
        lastMessageData && lastMessageData.forEach((recipient) => {
            if (parseInt(recipient) === ROLE_ANALYST) {
                toRole.push(ROLE_ANALYST);
            }
            if (parseInt(recipient) === ROLE_PROCESSOR) {
                toRole.push(ROLE_PROCESSOR);
            }
            if (parseInt(recipient) === ROLE_ACCOUNT_REP) {
                toRole.push(ROLE_ACCOUNT_REP);
            }
            if (parseInt(recipient) === ROLE_REVIEWER) {
                toRole.push(ROLE_REVIEWER);
            }
        });
        setToRole(toRole);
        recipients.push({ id: 'analyst', val: ROLE_ANALYST, text: 'Analyst' });
        recipients.push({ id: 'processor', val: ROLE_PROCESSOR, text: 'Processor' });
        recipients.push({ id: 'acct_rep', val: ROLE_ACCOUNT_REP, text: 'Account Rep' });
        recipients.push({ id: 'reviewer', val: ROLE_REVIEWER, text: 'Reviewer' });
        setRecipients(recipients)
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (onEditSetMsg) {
            message.senderId = userData.user_id;
            message.text = onEditSetMsg.message;
            setMessage(message);
            if (onEditSetMsg.file_name) {
                let temp = {
                    name: onEditSetMsg.file_name
                }
                selectedThreadFiles.push(temp);
                setSelectedThreadFiles(selectedThreadFiles);
            }
            if (onEditSetMsg.role_id !== undefined) {
                toRole.length = 0;
                let messageRecipients = onEditSetMsg.role_id.split(',');
                messageRecipients.forEach((e) => toRole.push(parseInt(e)))
            }
            if (onEditSetMsg.to && onEditSetMsg.to !== "0") {
                setTimeout(async function () {
                    await getUserInfo();
                    setSelectUser(onEditSetMsg.to);
                    setToUser((onEditSetMsg.to && onEditSetMsg.to !== "0") ? true : false);
                    setUser(true);
                }, 1)
            }
            setToClient((onEditSetMsg.to_client) ? true : false);
            setMessageTypeValue(onEditSetMsg.message_type ? onEditSetMsg.message_type : messageTypeValue)
            setToRole(toRole);
            setUpdate(moment());
        }

        return () => {
            setUser(false);
        }

    }, [onEditSetMsg]); // eslint-disable-line react-hooks/exhaustive-deps

    let getUserInfo = async () => {
        try {
            let userIds = onEditSetMsg.to;
            let responseUsers = await PostData("conversation/get-user-info/" + userIds);
            setGetUsers(responseUsers.data.userData);
        } catch (error) {
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error, { memberCode: 500, componentName: 'SendMessageForm', functionName: 'getUserInfo', data: {} });
        }
    }

    let handleUser = (response) => {
        if (response && response.ids.length) {
            selectUser = response.ids.join(',')
            setSelectUser(selectUser);
            setUser(true);
            setSelectUserError("")
        } else {
            setToUser(false);
            setUser(false);
        }
        setUpdate(moment());
    };

    let handleInput = (e) => {
        if (e.target.name === "msg") {
            message.senderId = userData.user_id;
            message.text = e.target.value;
        } else if (e.target.name === "role" || e.target.name === "client") {
            if (e.target.id === "user") {
                setToUser(e.target.checked ? true : false);
                setSelectUserError(e.target.checked ? selectUserError : '')
            } else if (e.target.id === "client_id") {
                setToClient(e.target.checked ? true : false);
            } else {
                if (e.target.checked) {
                    toRole.push(parseInt(e.target.value));
                } else {
                    let index = toRole.indexOf(parseInt(e.target.value));
                    if (index !== -1) {
                        toRole.splice(index, 1);
                    }
                }
                if (toUser === false) {
                    setUser(false);
                }
            }
            setToRole(toRole);
            setSelectRecipientError("")
        }
        setMessage(message);
        setUpdate(moment());
        if (((message.text && message.text.trim().length > 0) || (selectedThreadFiles.length)) && conversation.issue_resolved === '0') {
            setEnable(true);
        } else {
            setEnable(false);
        }
    }

    let handleResetForm = (e) => {
        setUser(false);
        message.text = '';
        setMessage(message);
        setEnable(false);
        setToRole([]);
        setToUser(false);
        setToClient(false);
        e.target.reset();
    }

    let handleMessageSubmit = async (e) => {
        try {
            if (!userData.is_RV_user)
                toRole.push(ROLE_PROCESSOR, ROLE_ACCOUNT_REP);
            let filterRole = [...new Set(toRole)];
            params.role_id = filterRole.join(',');
            params.to = (user === false) ? 0 : selectUser;
            params.client = toClient;
            e.preventDefault()
            if (userData.is_RV_user && messageTypeValue === MESSAGE_TYPES.INTERNAL) {
                if (!params.role_id && !params.to && !params.client) {
                    setSelectRecipientError("Please select recipient")
                    return false
                }
                if (toUser && user === false) {
                    setSelectUserError("Please select user")
                    return false
                }
            }
            const data = new FormData();
            if (selectedThreadFiles.length) {
                data.append(`uploaded_files`, selectedThreadFiles[0]);
            }
            if (standaloneType === "1") { // cTab === 'awaiting_inspection'
                params.inspection_order_id = conversation.inspection_order_id;
            } else {
                params.report_order_id = conversation.report_order_id;
            }
            params.conversation_id = conversation.id;
            params.from = message.senderId;
            params.message = message.text ? message.text : "";
            params.oldMessage = onEditSetMsg.message;
            params.messageType = (!userData.is_RV_user) ? MESSAGE_TYPES.CLIENT : messageTypeValue;
            if (onEditSetMsg.id) {
                params.messageTypeText = 'updateMessage';
            }
            for (let currentField in params) {
                data.append(currentField, params[currentField]);
            }
            setProcess(true);
            let routePath = "conversation/send-message";
            if (onEditSetMsg.id) {
                data.append('message_id', onEditSetMsg.id);
                routePath = "conversation/update-message/" + onEditSetMsg.id;
            }
            let conversationData = await PostData(routePath, data);
            newConversationId.push(params.conversation_id ? params.conversation_id : conversationData.data.convData.conversation_id);
            toast["success"](conversationData.message);
            onUpdateMessage();
            handleResetForm(e);
            setProcess(false);
        } catch (error) {
            setProcess(false);
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error, { memberCode: 500, componentName: 'SendMessageForm', functionName: 'handleMessageSubmit', data: {} });
        }
        setMessage([])
        setSelectedThreadFiles([]);
        setNewConversationId(newConversationId);
        onSendMessage(userData.is_RV_user ? [MESSAGE_TYPES.CLIENT, MESSAGE_TYPES.INTERNAL, MESSAGE_TYPES.INSPECTOR] : [MESSAGE_TYPES.CLIENT]);
    }

    let markResolved = async () => {
        confirmAlert({
            title: '',
            message: 'You are going to mark conversation as resolved. Are you sure ?',
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        setProcess(true);
                        try {
                            // Message sent before mark as resolved
                            const data = new FormData();
                            if (selectedThreadFiles.length) {
                                data.append(`uploaded_files`, selectedThreadFiles[0]);
                            }
                            if (standaloneType === "1") { // cTab === 'awaiting_inspection'
                                params.inspection_order_id = conversation.inspection_order_id;
                            } else {
                                params.report_order_id = conversation.report_order_id;
                            }
                            params.conversation_id = conversation.id;
                            params.from = message.senderId;
                            params.message = message.text ? message.text : "";
                            params.messageType = (!userData.is_RV_user) ? MESSAGE_TYPES.CLIENT : messageTypeValue;
                            params.messageTypeText = 'markResolvedMessage';
                            for (let currentField in params) {
                                data.append(currentField, params[currentField]);
                            }
                            await PostData("conversation/send-message", data);
                            // Message sent before mark as resolved

                            // Mark resolved process 
                            let result = await PostData("conversation/mark-resolved/" + conversation.id);
                            onMarkResolved(true, 1);
                            toast["success"](result.message);
                            // Mark resolved process
                            setProcess(false);
                        } catch (error) {
                            setProcess(false);
                            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message);
                            await sendExceptionEmail(error, { memberCode: 500, componentName: 'SendMessageForm', functionName: 'markResolved', data: {} });
                        }
                    },
                    className: "btn btn-primary btn-sm"
                }, {
                    label: 'Close',
                    onClick: () => { },
                    className: "btn btn-outline-danger btn-sm"
                }
            ]
        });
    };

    let onDropFiles = (files) => {
        let tempArr = [];
        tempArr = files;
        setSelectedThreadFiles(tempArr);
        setThreadFileUploadError("");
        setEnable(true);
        setUpdate(moment());
    };

    let onDropRejectedFiles = (rejectedFiles) => {
        if (rejectedFiles[0].errors[0].code === 'file-too-large') {
            rejectedFiles[0].errors[0].message = "File is larger than 100MB.";
        }
        if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
            rejectedFiles[0].errors[0].message = "Please upload a PDF, JPG, JPEG or PNG file only.";
        }
        const fileUploadError = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].errors[0].message;
        setThreadFileUploadError(fileUploadError);
        setUpdate(moment());
    };

    let deleteFile = (e, index) => {
        e.preventDefault();
        let selectedThreadFilesTemp = selectedThreadFiles;
        selectedThreadFilesTemp.splice(index, 1);
        setSelectedThreadFiles(selectedThreadFilesTemp);
        setUpdate(moment());
    };

    let threadFiles = selectedThreadFiles && selectedThreadFiles.map((file, i) => (
        <li key={file.name}>
            <i className="fa fa-trash text-danger" onClick={e => deleteFile(e, i)}></i> {file.name}
        </li>
    ));

    return (
        <>
            <Spinner isShow={isProcess} />
            <AvForm onValidSubmit={(e) => handleMessageSubmit(e)}>
                <Row>
                    <Col xs="12" sm="11" md="11" className="text-right">
                        <span className="accept-file-message">Accepted File PDF, PNG and JPG. Max File size 10MB.</span>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="1" md="1">
                        <i id={"resolved_notification"} style={{ color: (conversation.issue_resolved > 0) ? "green" : "grey" }} onClick={e => (conversation.issue_resolved > 0 || conversation.id === 0) ? '' : markResolved(e)} className="mt-1 fa fa-check-circle fa-2x cursor-pointer"></i>
                        <UncontrolledTooltip delay={0} placement="auto" target={"resolved_notification"} >Would you like to mark this issue as resolved?</UncontrolledTooltip>
                    </Col>
                    <Col xs="12" sm="10" md="10">
                        <textarea type="text" name="msg" rows={4} placeholder="Type your message" value={message.text} autoComplete="off" className={(conversation.issue_resolved > 0) ? 'disabled col-sm-12 rounded input-message-box' : 'col-sm-12 rounded input-message-box'} onChange={(e) => handleInput(e)} />
                    </Col>
                    <Col xs="12" sm="1" md="1">
                        <Row className="mt-2 pl-2">
                            <Dropzone
                                onDrop={onDropFiles}
                                onDropRejected={onDropRejectedFiles}
                                accept="application/pdf,image/jpg,image/jpeg,image/png"
                                minSize={0}
                                maxSize={1048576 * 100} //1048576 equal to 1 MB
                                maxFiles={1}
                            >
                                {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                                    return (
                                        <div {...getRootProps({})}>
                                            <input {...getInputProps()} />
                                            <i id={"file_attached"} className="fa fa-paperclip fa-2x"></i>
                                            <UncontrolledTooltip delay={0} placement="auto" target={"file_attached"} >Attach File</UncontrolledTooltip>
                                        </div>
                                    )
                                }}
                            </Dropzone>
                        </Row>
                        <Row className="mt-3">
                            <Button className={(enable) ? "" : "disabled"} size="sm" color="info">Send</Button>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="1" md="1"></Col>
                    <Col xs="12" sm="11" md="11">
                        <FilesList filesList={threadFiles} />
                        {threadFileUploadError && (
                            <div className="text-danger">
                                {threadFileUploadError}
                            </div>
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" sm="1" md="1"></Col>
                    <Col xs="12" sm="11" md="11">
                        <div className="custom-radio-btn inline">
                            {(checkRoles(['ADMINISTRATOR', 'ACCOUNT_REP', 'SALES_REP']) || checkRolesWithLevel(['REVIEWER'], ">=", 3) || userData.allowClientMessaging) &&
                                <>
                                    <input type="radio" checked={messageTypeValue && messageTypeValue === MESSAGE_TYPES.CLIENT} id="1" name="message_type_value" value={1} onChange={e => setMessageTypeValue(MESSAGE_TYPES.CLIENT)} />
                                    <label htmlFor="1">Client</label>
                                </>
                            }
                            <input type="radio" checked={messageTypeValue && messageTypeValue === MESSAGE_TYPES.INTERNAL} id="2" name="message_type_value" value={2} onChange={e => setMessageTypeValue(MESSAGE_TYPES.INTERNAL)} />
                            <label htmlFor="2">Internal</label>
                            {(checkRoles(['ADMINISTRATOR', 'ACCOUNT_REP', 'SALES_REP', 'PROCESSOR', 'DRV_ADMIN']) || checkRolesWithLevel(['REVIEWER'], ">=", 3)) &&
                                <>
                                    <input type="radio" checked={messageTypeValue && messageTypeValue === MESSAGE_TYPES.INSPECTOR} id="3" name="message_type_value" value={3} onChange={e => setMessageTypeValue(MESSAGE_TYPES.INSPECTOR)} />
                                    <label htmlFor="3">Inspector</label>
                                </>
                            }
                        </div>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col xs="12" sm="1" md="1">To</Col>
                    <Col xs="12" sm="11" md="11">
                        {selectRecipientError && (
                            <div className="text-danger mt-2">
                                {selectRecipientError}
                            </div>
                        )}
                        <li className="custom-control custom-checkbox mb-1" style={{ display: "grid" }} sm="2" >
                            {recipients && recipients.map((recipient, index) => {
                                return (
                                    <label key={index}>
                                        <input className="custom-control-input" type="checkbox" value={recipient.val}
                                            name={"role"}
                                            id={recipient.id}
                                            onChange={(e) => handleInput(e)}
                                            checked={(toRole && toRole.includes(recipient.val)) ? true : false}
                                        />
                                        <label className="custom-control-label mr-5" htmlFor={recipient.id}>{recipient.text}</label>
                                    </label>
                                )
                            })}
                        </li>

                    </Col>

                </Row>
                <Row>
                    <Col xs="12" sm="1" md="1"></Col>
                    <Col xs="12" sm="2" md="2">
                        <li className="custom-control custom-checkbox">
                            <input className="custom-control-input" type="checkbox" value={conversation.created_by}
                                name={"role"}
                                id={"user"}
                                onChange={(e) => handleInput(e)}
                                checked={(toUser) ? true : false}
                            />
                            <label className="custom-control-label" htmlFor={"user"}>User</label>
                        </li>
                    </Col>
                    <Col xs="12" sm="5" md="5">
                        {toUser &&
                            <UserList isRender={true} defaultSelection={Object.keys(onEditSetMsg).length > 0 && getUsers ? getUsers : []} isMultiSelect={true} onHandleUserSelect={handleUser} userQuery={{ company_id: userData.company_id }} />
                        }
                        {selectUserError && (
                            <div className="text-danger mt-2">
                                {selectUserError}
                            </div>
                        )}
                    </Col>
                </Row>
            </AvForm>
        </>
    )
}
export default SendMessageForm;