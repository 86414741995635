import React, { useEffect } from "react";
import { Button, Col, Label, Modal, Row } from "reactstrap";
import { useState } from "react";
import * as moment from "moment";
import { GetData as OrderIntakeGet, PutData as OrderIntakePut } from "services/OrderIntakeData";
import { toast } from "react-toastify";
import { AvForm } from "availity-reactstrap-validation";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import PriceFormatter from "components/Common/PriceFormatter";
import ReportOrderOriginalSource from "../../ReportOrderOriginalSource";
import PaymentForm from "../../PaymentForm";
import { loggedInUser, sendExceptionEmail, formatDateComponent } from "components/Common/Helpers";
import eventBus from "../../EventBus";
import RenderActionButton from "../RenderActionButton";
import DateSelectPicker from "components/Common/DateSelectPicker";
import { checkRoles } from "components/Common/Helpers";
const currentDate = moment(new Date()).format('YYYY-MM-DD');
moment.tz.setDefault("America/phoenix");
const AddRemoveRushOptionsButton = ({ propRowData, propButtonOptions }) => {

    const [userData] = useState(loggedInUser());
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [update, setUpdate] = useState(moment());
    const [isProcess, setProcess] = useState(false);
    const [fields, setFields] = useState([]);
    const [eligibleTATs, setEligibleTATs] = useState([]);
    let [tz] = useState(moment.tz.guess());
    const [isOpenPaymentSourceModal, setIsOpenPaymentSourceModal] = useState({ current: false });
    const [amtVariance, setAmtVariance] = useState();

    const [selectedPaymentSource, setSelectedPaymentSource] = useState(null);
    const [paymentError, setPaymentError] = useState(null);
    const [defaultSourceChecked, setDefaultSourceChecked] = useState(false);
    const [superRushMaxDate, setSuperRushMaxDate] = useState();
    const [rushMaxDate, setRushMaxDate] = useState();
    const [customError, setCustomError] = useState("");

    useEffect(() => { }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setFields({});
    }, [propRowData.order_token]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleActionClick = async (e, row) => {
        e.preventDefault();
        await getEligibleTAT();
        setCustomError("");
    };

    const handleToggleModal = (state) => {
        if (!isProcess) {
            setIsOpenModal(!state);
            setUpdate(moment());
        }
    };

    const handleConditions = (e, selectedTat) => {
        e.preventDefault();
        fields.selectedTat = selectedTat;
        setAmtVariance(selectedTat.fee - propRowData.currentRushFee)
        delete fields.due_date;

        if (selectedTat.slug === "super-rush") {
            fields['report_due_date'] = currentDate > selectedTat?.super_rush_due_date ? null : selectedTat?.super_rush_due_date;
        } else if (selectedTat.slug === "rush") {
            fields['report_due_date'] = currentDate > selectedTat?.rush_due_date ? null : selectedTat?.rush_due_date;
        }

        setFields(fields);
        setCustomError("");
        setUpdate(moment())
    }

    const getEligibleTAT = async (e) => {
        try {
            setProcess(true);
            const result = await OrderIntakeGet("tat", { company_token: "{company_token}", report_type: propRowData.report_type, order_token: propRowData.order_token }, propRowData.company_id)
            const response = result.data.tat;

            const superRushDate = response.find(item => item.slug === "super-rush");
            const rushDate = response.find(item => item.slug === "rush");

            setSuperRushMaxDate(superRushDate?.super_rush_due_date);
            setRushMaxDate(rushDate?.rush_due_date);
            setEligibleTATs(response);
            handleToggleModal(isOpenModal);
            setUpdate(moment())
            setProcess(false);
        } catch (error) {
            if (error.err_code === 'PAYMENT_ERROR' && selectedPaymentSource.isOriginalSource === true) {
                setPaymentError(error.message);
                setSelectedPaymentSource(null);
            } else {
                await sendExceptionEmail(error, { memberCode: 200, componentName: 'AddRemoveRushOptionsButton', functionName: 'getEligibleTAT', data: {} });
                toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            }
            setProcess(false);
        }
    };

    const submitProcess = async (e) => {
        try {
            setProcess(true);

            let params = {
                order_token: propRowData.order_token,
                new_rush_type: fields.selectedTat?.slug,

            };
            if (checkRoles(['ADMINISTRATOR', 'ACCOUNT_REP']) === true) {
                const tatSlug = fields.selectedTat?.slug;
                const dueDateConditions = {
                    "super-rush": fields.report_due_date,
                    "rush": fields.report_due_date
                };

                if (tatSlug in dueDateConditions) {
                    if ((!dueDateConditions[tatSlug] && !fields.due_date)) {
                        setCustomError("due_date");
                        setProcess(false);
                        setUpdate(moment());
                        return false;
                    }
                    params.due_date = fields.due_date ?? dueDateConditions[tatSlug];
                }
            }

            if (amtVariance > 0) {
                params.payment_source = selectedPaymentSource.payment_source;
            }

            const response = await OrderIntakePut("tat/add-remove-rush", params, propRowData.company_id);
            toast["success"](response.message);
            eventBus.dispatch("report_order_status_updated", { client_id: propRowData.company_id });
            handleToggleModal(isOpenModal);
            setProcess(false);
        } catch (error) {
            await sendExceptionEmail(error, { memberCode: 200, componentName: 'AddRemoveRushOptionsButton', functionName: 'submitProcess', data: {} });
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            setProcess(false);
        }
    };

    const openPaymentMethodSelector = () => {
        setSelectedPaymentSource(null);
        setIsOpenPaymentSourceModal({ current: true });
    };

    const handlePaymentSourceCb = (result) => {
        if (result) {
            setSelectedPaymentSource(result);
            setUpdate(moment());
        }
    };

    const onReportOrderOriginalSourceCB = (result) => {
        setSelectedPaymentSource(result);
        setDefaultSourceChecked(true);
        setUpdate(moment());
    };

    let handleDateSelect = (date) => {
        let name = 'due_date';
        if (date) {
            fields[name] = moment(date).tz(tz).format('YYYY-MM-DD');
            setCustomError("");
        } else {
            fields[name] = "";
            fields['report_due_date'] = null;
        }
        setFields(fields);
        setUpdate(moment());
    }


    return (
        <>
            <RenderActionButton propButtonTitle="Add/Edit Rush" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />

            {isOpenModal &&
                <Modal
                    className="modal-dialog-centered"
                    size="lg"
                    isOpen={isOpenModal}
                    toggle={() => handleToggleModal(isOpenModal)}
                >
                    <div className="modal-header modal-header-colored">
                        <h2 className="modal-title text-center w-100">
                            Add/Edit Rush
                        </h2>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenModal)}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <AvForm className="text-left" onValidSubmit={(e) => submitProcess(e)}>
                            <Row>
                                <Col lg="12">
                                    <Label className="required mb-2">Please Select Any Option</Label>
                                    <div className="table-responsive selectable-table">
                                        <table className="table conditions-table table-striped table-bordered table-condensed">
                                            <thead>
                                                <tr>
                                                    <th width="25%">Rush Type</th>
                                                    <th width="25%">Cost</th>
                                                    <th>Target Days</th>
                                                    {userData.is_RV_user === true &&
                                                        <th>Target Due Date</th>
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {eligibleTATs.map(function (tat, i) {
                                                    return (
                                                        <tr key={i} className={fields?.selectedTat?.slug === tat.slug ? "selected" : ""} onClick={e => handleConditions(e, tat)}>
                                                            <td>{tat.name}</td>
                                                            <td>
                                                                <PriceFormatter price={tat.fee - propRowData.currentRushFee} decimals={2} />
                                                            </td>
                                                            <td>{tat.turnaround_time_text}</td>
                                                            {userData.is_RV_user === true &&
                                                                <td>{formatDateComponent("due_date", tat, "MMM D", "Target Due Date", "MMM D, YY")}</td>
                                                            }
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>

                                <Col xs="12" sm="12" md="12" lg="12" className="mt-2">

                                    {amtVariance > 0 ?
                                        <>
                                            <label >
                                                Additional charges of <b>${amtVariance}</b> are required for the selected rush type.
                                            </label>
                                            <br />
                                            {!paymentError && <ReportOrderOriginalSource reportOrderId={propRowData.report_order_id} onReportOrderOriginalSourceCB={onReportOrderOriginalSourceCB} />}
                                            {paymentError &&
                                                <Row>
                                                    <span className="col-md-12 text-danger font-weight-bold">
                                                        The payment was failed because of following reason. <br />
                                                        <ul><li>{paymentError}</li></ul>
                                                    </span >
                                                </Row>
                                            }
                                            {(selectedPaymentSource && selectedPaymentSource.isOriginalSource === false) &&
                                                <Label><span className="text-success mr-2"><i className="ni ni-check-bold"></i> {selectedPaymentSource.selectionText}</span><i className="fa fa-pencil-alt text-danger" onClick={() => openPaymentMethodSelector()} aria-hidden="true"></i></Label>
                                            }

                                            {(!selectedPaymentSource && defaultSourceChecked) &&
                                                <Button size="sm" color="warning" outline onClick={() => openPaymentMethodSelector()}> <i className="fas fa-credit-card"></i> Select Payment Method </Button>
                                            }
                                        </>
                                        : ''
                                    }

                                    {amtVariance < 0 ?
                                        <>
                                            <label >
                                                <b>${Math.abs(amtVariance)}</b> will be refunded in your account soon.
                                            </label>
                                        </>
                                        : ''
                                    }
                                </Col>
                            </Row>
                            {checkRoles(['ADMINISTRATOR', 'ACCOUNT_REP']) === true &&
                                <Row>
                                    <Col md="4" lg="4">
                                        {fields.selectedTat?.slug === "super-rush" &&
                                            <>
                                                <Label className="required mt-3">Report Due Date</Label>
                                                <DateSelectPicker onHandleDateSelect={handleDateSelect} defaultValues={currentDate > superRushMaxDate ? null : superRushMaxDate} minDate={moment().toDate()} maxDate={moment(superRushMaxDate).toDate()} isClear={{ current: false }} showIcon={false} />
                                            </>
                                        }
                                        {fields.selectedTat?.slug === "rush" &&
                                            <>
                                                <Label className="required mt-3">Report Due Date</Label>
                                                <DateSelectPicker onHandleDateSelect={handleDateSelect} defaultValues={currentDate > rushMaxDate ? null : rushMaxDate} minDate={moment().toDate()} maxDate={moment(rushMaxDate).toDate()} isClear={{ current: false }} showIcon={false} />
                                            </>
                                        }
                                        {(customError === 'due_date') && <span className="form-error">This field is required.</span>}
                                    </Col>
                                </Row>
                            }
                            <Row >
                                <Col xs="12" sm="12" md="8" lg="8" className="mt-3">
                                    <small className="text-danger font-weight-bold mb-3">
                                        *Please Note:<br />
                                        - The delivery dates could be impacted by the upcoming holidays (If any).<br />
                                        - Removing rush option is not allowed after {userData.is_RV_user === true ? `3 Hours` : `1 Hour`} from the order submitted time.
                                    </small >
                                </Col>
                                <Col xs="12" sm="12" md="4" lg="4" className="mt-3 text-right">
                                    {isProcess ?
                                        <Button type="button" color="warning" size="sm">
                                            <div className="fa fa-spinner fa-spin"></div> Processing...
                                        </Button>
                                        :
                                        <>
                                            <Button color="danger" size="sm" outline onClick={() => handleToggleModal(isOpenModal)} >
                                                <i className="fa fa-times"></i> Cancel
                                            </Button>
                                            <Button color="primary" size="sm" disabled={amtVariance > 0 && !selectedPaymentSource} >
                                                <i className="fa fa-save"></i> Submit
                                            </Button>
                                        </>}
                                </Col>
                            </Row>
                        </AvForm>
                    </div>
                </Modal>
            }

            <PaymentForm onPay={isOpenPaymentSourceModal}
                paymentDetails={{
                    total_amount: amtVariance,
                    cc_surcharge: null,
                    paymentFor: 'Add/Edit Rush'
                }}
                onPaymentSourceCb={handlePaymentSourceCb} clientId={propRowData.company_id} />
        </>
    );
};

export default AddRemoveRushOptionsButton;