import React, { useState, useEffect } from "react";
import { Row, Button, Modal, Col } from "reactstrap";
import { toast } from 'react-toastify';
import { AvForm, AvGroup, AvField } from 'availity-reactstrap-validation';
import { PostData } from "../../../services/Api.service";
import * as moment from "moment";
import { sendExceptionEmail } from "components/Common/Helpers";

const AddEditTopic = ({ propHandleModal, onUpdateTopic, selectedRow }) => {
    const [isProcess, setProcess] = useState(false);
    const [update, setUpdate] = useState(moment());
    const [fields, setFields] = useState({});
    const [isOpenEditTopicModal, setIsOpenEditTopicModal] = useState(false);

    useEffect(() => {
        handleTopicModal(); //eslint-disable-next-line 
    }, [propHandleModal.current, selectedRow, update]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleTopicModal = () => {
        setIsOpenEditTopicModal(propHandleModal.current);
    };

    const closeModal = (state) => {
        if (!isProcess) {
            state = !state;
            setIsOpenEditTopicModal(state);
            propHandleModal.current = false;
            setUpdate(moment());
        }
    }

    // Handle Input Data
    const handleInput = (e) => {
        fields[e.target.name] = e.target.value;
        setFields(fields);
    }

    const handleEditTopicSubmit = async () => {
        try {
            setProcess(true);
            let routeVar = "conversation/add-topic";
            if (Object.keys(selectedRow).length > 0) {
                routeVar = "conversation/edit-topic/" + selectedRow.id;
            }
            const addEditDataResult = await PostData(routeVar, fields);
            if (addEditDataResult) {
                toast["success"](addEditDataResult.message);
                setIsOpenEditTopicModal(false);
                setProcess(false);
                onUpdateTopic(1);
            }
        } catch (error) {
            setProcess(false);
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message)
            await sendExceptionEmail(error, { memberCode: 500, componentName: 'AddEditTopic', functionName: 'handleEditTopicSubmit', data: {} });
        }
    }

    return (
        <>
            <Modal className="modal-dialog-centered" isOpen={isOpenEditTopicModal} toggle={() => closeModal(isOpenEditTopicModal)}>
                <div className="modal-header modal-header-colored">
                    <h4 className="modal-title text-center w-100">{(selectedRow.topic_name === undefined) ? "Add" : "Edit"} Topic</h4>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => closeModal(isOpenEditTopicModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <AvForm onValidSubmit={(e) => handleEditTopicSubmit(e)}>
                        <Row>
                            <Col xs="12" sm="4" md="4">
                                <label className="mt-2">
                                    Topic
                                </label>
                            </Col>
                            <Col xs="12" sm="8" md="8">
                                <AvGroup>
                                    <AvField
                                        name="topic_name"
                                        value={selectedRow.topic_name}
                                        onChange={(event) => handleInput(event)}
                                        className="form-control"
                                        placeholder="Topic"
                                        type="text"
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "This field is required."
                                            },
                                        }}
                                    />
                                </AvGroup>
                            </Col>

                            <Col xs="12" sm="4" md="4">
                                <label className="mt-2">
                                    Description
                                </label>
                            </Col>
                            <Col xs="12" sm="8" md="8">
                                <AvGroup>
                                    <AvField
                                        name="description"
                                        value={selectedRow.topic_description}
                                        onChange={(event) => handleInput(event)}
                                        className="form-control"
                                        placeholder="Description"
                                        type="text"
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: "This field is required."
                                            },
                                        }}
                                    />
                                </AvGroup>
                            </Col>
                            <Col md="12" className="text-right mt-2">
                                {isProcess ?
                                    <Button size="sm" type="button" color="warning" >
                                        <div className="fa fa-spinner fa-spin"></div> Processing...
                                    </Button>
                                    :
                                    <>
                                        <Button size="sm" color="danger" outline type="button" onClick={() => closeModal(isOpenEditTopicModal)}>
                                            Cancel
                                        </Button>
                                        <Button size="sm" color="info">
                                            Submit
                                        </Button>
                                    </>
                                }
                            </Col>
                        </Row>
                    </AvForm>
                </div>
            </Modal>
        </>
    )
}
export default AddEditTopic;