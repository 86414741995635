import { useEffect, useState } from "react";
import { generateRandomString } from "components/Common/Helpers";
import "./CustomToggleButton.scss";

const CustomToggleButton = ({ options = [], defaultSelectionKey = null, className, buttonSize = "md", buttonColor = "info", onToggleChange }) => {

	const [currentSelection, setCurrentSelection] = useState(defaultSelectionKey);

	useEffect(() => {
		setCurrentSelection(defaultSelectionKey);
	}, [defaultSelectionKey])

	/**
	  ** description: Handle selections
	  */
	const handleTimeFilter = (e) => {
		const { value } = e.target
		setCurrentSelection(value);
		if (onToggleChange) {
			onToggleChange(value);
		}
	}

	return (
		<>
			<div className={`custom-toggle-button ${className || ''}`} >
				<div className="btn-group" role="group" aria-label="Basic radio toggle button group">
					{options.map((item, index) => {
						const id = `filter_${index}_${generateRandomString(5)}`;

						return <span key={index}>
							<input type="radio" value={item.key} checked={currentSelection === item.key} className="btn-check" name={id} id={id} onChange={handleTimeFilter} />
							<label className={`btn btn-outline-${buttonColor} btn-${buttonSize}`} htmlFor={id}>{item.label}</label>
						</span>
					})}
				</div>
			</div>
		</>
	)
}

export default CustomToggleButton;