const TASK_SLUG = {
    LAND_USES_LAND_USES: 'land_uses_land_uses',
    NEIGHBORHOOD_STREET_VIEW_NEIGHBORHOOD_STREET_VIEW: 'neighborhood_street_view_neighborhood_street_view',
    SUBJECT_CONDITION_GOOGLE: 'subject_condition_google',
    SUBJECT_CONDITION_GOOGLE_OUTSIDE_LINKS: 'subject_condition_google_outside_links',
    SUBJECT_CONDITION_ZILLOW: 'subject_condition_zillow',
    SUBJECT_CONDITION_REDFIN: 'subject_condition_redfin',
    SUBJECT_CONDITION_REALTOR: 'subject_condition_realtor',
    BUDGET_SELECT_CATEGORY: 'budget_select_category',
    BUDGET_ALLOCATE_COSTS: 'budget_allocate_costs',
    BUDGET_VIEW_HIGHLIGHTED_BUDGET: 'budget_view_highlighted_budget',
    BUDGET_ADD_LINE_ITEM: 'budget_add_line_item',
    BUDGET_BYPASS_ALLOCATION: 'budget_bypass_allocation',
    BUDGET_ADJUST_ALLOCATION: 'budget_adjust_allocation',
    BUDGET_SELECT_TARGET_QUALITY: 'budget_select_target_quality',
    BUDGET_SUBMIT: 'budget_submit',
    SUBJECT_CONDITION_LISTING: 'subject_condition_listing',
    BUDGET_LISTING: 'budget_listing',
    BUDGET_SELECT_TARGET_CONDITION: 'budget_select_target_condition',
    OMS_REVIEWER_FLAG_OPEN_POPUP: 'oms_reviewer_flag_open_popup',
    OMS_REVIEWER_FLAG_TOGGLE_RATING: 'oms_reviewer_flag_toggle_rating',
    OMS_REVIEWER_FLAG_TOGGLE_SEVERITY: 'oms_reviewer_flag_toggle_severity',
    OMS_REVIEWER_FLAG_DELETE_FLAG: 'oms_reviewer_flag_delete_flag',
    OMS_REVIEWER_FLAG_SAVE: 'oms_reviewer_flag_save',
    OMS_VALUATION_TOGGLE_RATING: 'oms_valuation_toggle_rating',
    OMS_VALUATION_TOGGLE_SEVERITY: 'oms_valuation_toggle_severity',
    OMS_VALUATION_DELETE_FLAG: 'oms_valuation_delete_flag',
    OMS_VALUATION_EDIT_COMMENT_OPEN_POPUP: 'oms_valuation_edit_comment_open_popup',
    OMS_VALUATION_EDIT_COMMENT_SAVE: 'oms_valuation_edit_comment_save',
    OMS_VALUATION_ADD_FLAG_OPEN_POPUP: 'oms_valuation_add_flag_open_popup',
    OMS_VALUATION_ADD_FLAG_SAVE: 'oms_valuation_add_flag_save'
};

module.exports = {
    TASK_SLUG
};

