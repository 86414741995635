import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Button, Row, Col, CardFooter, CardBody, Label, UncontrolledTooltip, Modal } from "reactstrap";
import "./style.scss";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import { PostData } from "services/PostData";
import { GetData } from "services/Api.service";
import { PostData as ApiPostData } from "services/Api.service";
import eventBus from "views/modules/Common/EventBus";
import { toast } from 'react-toastify';
import { loggedInUser, number_format, priceNumber, sendExceptionEmail } from "components/Common/Helpers.js"
import RenderBootstrapTable from "components/Common/RenderBootstrapTable";
import * as moment from "moment";
import BudgetAnalysis from "./BudgetAnalysisModal";
import BudgetAddNewLineItem from "./BudgetAddNewLineItemModal";
import BudgetLineItemEditModal from "./BudgetLineItemEdit";
import { Typeahead } from 'react-bootstrap-typeahead';
import { useDispatch, useSelector } from 'react-redux';
import { TASK_SLUG } from "../Common/Constants/TimeTracking.constant";
import { periodObservance } from "components/Common/TimeTrackingHelpers";
import { showSpinner } from "../../../../src/ShowHideSpinner.reducer";
import { formatNumber } from "components/Common/Helpers";
import DocumentsUploadModal from "../Common/DocumentsUploadModal";
import ReviewerFlags from "../Common/ReviewerFlags/ReviewerFlags";
import { confirmAlert } from "react-confirm-alert";
const userData = loggedInUser();

const BudgetReview = ({ propData: propDataParams, onHandleBudgetReviewResponse }) => {

    const dispatch = useDispatch();
    let [data, setData] = useState({});
    let [update, setUpdate] = useState(moment());
    let [excludeIds, setExcludeIds] = useState([]);
    let [categoryIds, setCategoryIds] = useState([]);
    let [customError, setCustomError] = useState([]);
    let [analyzeLineItem, setAnalyzeLineItem] = useState([]);
    let [budgetData, setBudgetData] = useState([]);
    let [isBudgetData, setIsBudgetData] = useState(false);
    let [updatedBudgetData, setUpdatedBudgetData] = useState([]);
    let [total_budget, setTotalBudget] = useState();
    let [isBudgetAnalysisModal, setIsBudgetAnalysisModal] = useState(false);
    let [isAddNewLineItemModal, setIsAddNewLineItemModal] = useState(false);
    let [dataForEdit, setDataForEdit] = useState({});
    let [isEditLineItemModal, setIsEditLineItemModal] = useState(false);
    let [checkBoxStateByID, setCheckBoxStateByID] = useState([]);
    let [particularData, setParticularData] = useState({});
    let [category, setCategory] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    let [deferredMaintenance, setDeferredMaintenance] = useState(0);
    let [heavyRepairs, setHeavyRepairs] = useState(0);
    let [reconfigurationAdditions, setReconfigurationAdditions] = useState(0);
    let [softCosts, setSoftCosts] = useState(0);
    let [upgrades, setUpgrades] = useState(0);
    let [deferredMaintenanceTotal, setDeferredMaintenanceTotal] = useState(0);
    let [heavyRepairsTotal, setHeavyRepairsTotal] = useState(0);
    let [reconfigurationAdditionsTotal, setReconfigurationAdditionsTotal] = useState(0);
    let [softCostsTotal, setSoftCostsTotal] = useState(0);
    let [upgradesTotal, setUpgradesTotal] = useState(0);
    let [newCategoryInput, setNewCategoryInput] = useState(" ");
    let [fromReviseTab, setFromReviseTab] = useState(false);
    let [fromSupplementalInfo, setFromSupplementalInfo] = useState(false);
    let [useLineItem, setUseLineItem] = useState(true);
    const [isDropdownOpen, setIsDropdownOpen] = useState([]);
    const [key, setKey] = useState(0);
    const taskData = useSelector((state) => state.taskIdsList.data);
    let [uploadedFiles, setUploadedFiles] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);
    let [selectedBudgetFilesData, setSelectedBudgetFilesData] = useState([]);
    let [statedTotal, setStatedTotal] = useState(0);
    let [capturedTotal, setCapturedTotal] = useState(0);
    let [ocrTotal, setOCRTotal] = useState(0);
    const [loading, setLoading] = useState(false);
    const [showData, setShowData] = useState(false);
    const [QACompleted, setQACompleted] = useState(false);
    const [retriggeredBudgetId, setRetriggeredBudgetId] = useState([]);
    const [selectedAllCategory, setSelectedAllCategory] = useState('');
    let [targetQualityGrade, setTargetQualityGrade] = useState("");
    const [showMessage, setShowMessage] = useState(null);
    const [isModal, setIsModal] = useState(false);
    let [propData] = useState(propDataParams);

    const memoizedPropData = useMemo(() => ({
        location: "oms_budget_analysis_popup",
        propertyId: propData.isFromSupplementalTab ? propData.property.property_id : propData.property_id,
        reportId: propData.id,
        userId: userData.user_id,
        orderStatus: propData.order_status,
    }), [propData]);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };
    let unsetBeforeLoad = () => {
        setData({});
        setExcludeIds([]);
        setCategoryIds([]);
        setCustomError([]);
        setAnalyzeLineItem([]);
        setBudgetData([]);
        setIsBudgetData(false);
        setUpdatedBudgetData([]);
        setTotalBudget();
        setIsBudgetAnalysisModal(false);
        setIsAddNewLineItemModal(false);
        setDataForEdit({});
        setIsEditLineItemModal(false);
        setCheckBoxStateByID([]);
        setParticularData({});
        setCategory([]);
        setCategoryData([]);
        setDeferredMaintenance(0);
        setHeavyRepairs(0);
        setReconfigurationAdditions(0);
        setSoftCosts(0);
        setUpgrades(0);
        setDeferredMaintenanceTotal(0);
        setHeavyRepairsTotal(0);
        setReconfigurationAdditionsTotal(0);
        setSoftCostsTotal(0);
        setUpgradesTotal(0);
        setNewCategoryInput(" ");
        setFromReviseTab(false);
        setFromSupplementalInfo(false);
        setUseLineItem(true);
        setIsDropdownOpen([]);
        setKey(0);
        setUploadedFiles([]);
        setIsExpanded(false);
        setSelectedBudgetFilesData([]);
        setStatedTotal(0);
        setCapturedTotal(0);
        setOCRTotal(0);
        setLoading(false);
        setShowData(false);
        setQACompleted(false);
        setRetriggeredBudgetId([]);
        setSelectedAllCategory('');
        setUpdate(moment());
    };

    useEffect(() => {
        async function fetchInitialData() {
            try {
                console.log("This is called - fetchInitialData")
                unsetBeforeLoad();
                dispatch(showSpinner(true));
                let isFromSupplementalTab = propData.isFromSupplementalTab ? true : false;
                if (!isFromSupplementalTab) {
                    await getLandUses();
                    await get_valuation_commentary_instruction();
                }
                if (propData && Object.keys(propData).length) {
                    let targetQuality = propData.subject_property?.target_quality ? propData.subject_property?.target_quality : "";
                    setTargetQualityGrade(targetQuality);
                    setFromReviseTab(propData.isFromReviseTab && propData.isFromReviseTab ? true : false);
                    if (!categoryData.length) {
                        let result = await GetData("orders/get-budget-categories");
                        const budgetCategoriesData = result.data.budgetCategoriesData;
                        setCategoryData(budgetCategoriesData);
                        if (budgetCategoriesData) {
                            setCategory(budgetCategoriesData.map((res) => res.name));
                            let result = await getIntItBudgetData();
                            await callInitBudget(result);
                        }
                    }

                }
                dispatch(showSpinner(false));
            } catch (errors) {
                dispatch(showSpinner(false));
                toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message)
                await sendExceptionEmail(errors, { memberCode: 401, componentName: 'BudgetReview', functionName: 'fetchInitialData', data: {} });
            }
            setUpdate(moment());
        }
        fetchInitialData();
    }, [propDataParams?.id]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

    }, [update]);

    const checkRetriggeredStatus = useCallback(async (eventResponse) => {
        try {
            const property_file_id = eventResponse.detail?.payload?.budget_queue?.property_file_id;
            if (parseInt(eventResponse.detail.payload.property_id) === parseInt(propData.property_id)) {
                setSelectedBudgetFilesData([]);
                const response = await ApiPostData('orders/get-retriggered-budget-data', { property_file_id })
                const budgetResponse = response.data;
                if (budgetResponse && budgetResponse.status !== "Pending" && budgetResponse.status !== "Inprogress") {
                    await getIntItBudgetData(true, false);
                    let budgetExtraction = budgetResponse.budget_extraction.length ? budgetResponse.budget_extraction : [];
                    setBudgetData(budgetExtraction);
                    set_totals_and_data(budgetExtraction);
                    setUpdate(moment());
                }
            }
        } catch (errors) {
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message);
            await sendExceptionEmail(errors, { memberCode: 401, componentName: 'BudgetReview', functionName: 'checkRetriggeredStatus', data: {} });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        eventBus.on("budget_extraction_order_updated", checkRetriggeredStatus);
        return () => {
            eventBus.remove("budget_extraction_order_updated", checkRetriggeredStatus);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkRetriggeredStatus]);

    let callInitBudget = async (result) => {
        try {
            const budgetData = result?.data?.data || [];
            if (budgetData.length > 0) {
                if (budgetData[0]) {
                    let lineItemUserId = [];
                    budgetData.forEach((budget) => {
                        if (budget.line_item_name !== "total") {
                            if (budget.user_id !== userData.user_id) {
                                lineItemUserId.push(budget.user_id);
                            }
                        }
                    });
                    lineItemUserId = getUniqueList(lineItemUserId);
                    var filteredUserId = lineItemUserId.filter(function (el) {
                        return el != null;
                    });

                    if (filteredUserId[0]) {
                        let user_role = {};
                        user_role.user_id = filteredUserId[0];
                        let result = await PostData("orders/get-user-role", user_role)
                        let userAnalyst = result.data.data.roles.findIndex(x => x === 'Analyst');
                        if (userAnalyst !== -1) {
                            toast["info"]("This budget has been already analyzed by Analyst.");
                        }

                    }
                }

            }

            if (propData?.budget_analysis) {
                updateBudgetAnalysis(propData.budget_analysis);
            }
        } catch (errors) {
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message);
            await sendExceptionEmail(errors, { memberCode: 401, componentName: 'BudgetReview', functionName: 'callInitBudget', data: {} });
        }
    }

    const updateBudgetAnalysis = async (budgetAnalysis) => {
        try {
            let tempData = {};
            tempData.deferred_maintenance = budgetAnalysis.deferred_maintenance;
            setDeferredMaintenance(budgetAnalysis.deferred_maintenance);
            setDeferredMaintenanceTotal(budgetAnalysis.deferred_maintenance);

            tempData.heavy_repairs = budgetAnalysis.heavy_repairs;
            setHeavyRepairs(budgetAnalysis.heavy_repairs);
            setHeavyRepairsTotal(budgetAnalysis.heavy_repairs);

            tempData.reconfiguration_additions = budgetAnalysis.reconfiguration_additions;
            setReconfigurationAdditions(budgetAnalysis.reconfiguration_additions);
            setReconfigurationAdditionsTotal(budgetAnalysis.reconfiguration_additions);

            tempData.soft_costs = budgetAnalysis.soft_costs;
            setSoftCosts(budgetAnalysis.soft_costs);
            setSoftCostsTotal(budgetAnalysis.soft_costs);

            tempData.upgrades = budgetAnalysis.upgrades;
            setUpgrades(budgetAnalysis.upgrades);
            setUpgradesTotal(budgetAnalysis.upgrades);

            tempData.total_budget = budgetAnalysis.total_budget;
            setTotalBudget(budgetAnalysis.total_budget);

            tempData.target_quality = propData.grade;
            tempData.target_condition = propData.arv_user_value.BorrowerTargetCondition;

            setData(tempData);
            const tempTotalBudget = ['heavy_repairs', 'deferred_maintenance', 'upgrades', 'reconfiguration_additions']
                .reduce((total, key) => total + parseFloat(tempData[key] || 0), 0);
            setTotalBudget(tempTotalBudget);
        } catch (errors) {
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message);
            await sendExceptionEmail(errors, { memberCode: 401, componentName: 'BudgetReview', functionName: 'updateBudgetAnalysis', data: {} });
        }

    };

    const get_valuation_commentary_instruction = async () => {
        try {
            const report_order_id = propData?.report_order_id || propData.id;
            const { data } = await GetData("orders/get-valuation-commentary-instruction", { report_order_id });
            setShowMessage(data.valuation_commentary_or_instruction)
        } catch (errors) {
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message);
            await sendExceptionEmail(errors, { memberCode: 401, componentName: 'BudgetReview', functionName: 'get_valuation_commentary_instruction', data: {} });
        }
    }

    let getLandUses = async () => {
        try {
            const report_order_id = propData?.report_order_id || propData.id;

            let data = {
                report_order_id: report_order_id,
                property_id: propData.property_id,
                user_id: userData.user_id,
                company_id: userData.company_id
            }
            let result = await ApiPostData("orders/get_land_uses", data);
            if (result) {
                let responseJson = result.data;
                propData.subject_property = responseJson.subject_property;
                propData.budget_file = responseJson.budget_files ? responseJson.budget_files.location : "";
                propData.building_quality_apex_ratings = responseJson.building_quality_apex_ratings;
                propData.arv_user_value = responseJson.current_arv_user_value;
                propData.budget_analysis = responseJson.current_budget_analysis;
            }
        } catch (errors) {
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message);
            await sendExceptionEmail(errors, { memberCode: 401, componentName: 'BudgetReview', functionName: 'getLandUses', data: {} });
        }
    }

    const handleSubmit = (state, e) => {
        if (state && showMessage) {
            setIsModal(true)
        } else handleFinalData(e)
    }

    let getIntItBudgetData = async (isFromAddLineItem = false, showProgress = true) => {
        try {
            let isFromSupplementalTab = propData.isFromSupplementalTab ? true : false;
            data.budget_file = propData?.budget_file;
            data.property_id = propData?.property_id;
            data.report_order_id = propData?.report_order_id;
            if (isFromSupplementalTab) {
                setFromSupplementalInfo(isFromSupplementalTab);
                data.property_id = propData?.property?.property_id ? propData?.property?.property_id : propData.inspection_property.property_id;
                data.report_order_id = propData.id;
                data.is_from_supplemental_info = true;
                data.is_inspection_order = propData.isInspectionOrder;
                setUseLineItem(true);
                setUpdate(moment());
            } else if (parseInt(propData?.budget_analysis?.bypass_line_item) === 0) {
                setUseLineItem(true);
            } else if (parseInt(propData?.budget_analysis?.bypass_line_item) === 1) {
                setUseLineItem(false);
            }
            if (data && data?.property_id) {
                showProgress && dispatch(showSpinner(true));
                let initResult = await PostData("orders/budget-ocr-data", data)
                showProgress && dispatch(showSpinner(false));
                let result = initResult;
                if (result.data.data.budget_data.length) {
                    result.data.data = result.data.data.budget_data;
                    if (isFromSupplementalTab) {
                        propData.budget_file = result.data.data[0].location;
                    }
                    await retrievePropertyFiles(result.data.data, isFromAddLineItem, showProgress);
                }
                return result;
            }

        } catch (error) {
            dispatch(showSpinner(false));
            toast["error"](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error, { memberCode: 401, componentName: 'BudgetReview', functionName: 'getIntItBudgetData', data: {} });
        }
    }

    let retrievePropertyFiles = async (result, isFromAddLineItem = false, showProgress = true) => {
        try {
            showProgress && setLoading(true);
            const response = result;
            let uploadedFilesTemp = [];
            let tempBudgetData = [];
            let selectedFiles = selectedBudgetFilesData;
            let QACompletedTemp = QACompleted;
            if (!isFromAddLineItem) {
                response.forEach(fileRow => {
                    if (fileRow.qc_verified) {
                        selectedFiles.push(fileRow);
                    }
                });
            }

            response.forEach(fileRow => {
                let splittedArr = fileRow.location.split(".");
                let ext = splittedArr[splittedArr.length - 1];
                fileRow.ext = ext;

                if (selectedFiles.length === 0 || selectedFiles.some(row => row.id === fileRow.id)) {
                    const index = selectedFiles.findIndex(row => row.id === fileRow.id);

                    if (index > -1) {
                        if (selectedFiles[index].budget_extractions.length < fileRow.budget_extractions.length) {
                            const newRecords = fileRow.budget_extractions.slice(selectedFiles[index].budget_extractions.length);
                            selectedFiles[index].budget_extractions.push(...newRecords);
                            selectedFiles[index].qc_verified = true;
                            fileRow.qc_verified = true;
                        }
                    } else {
                        fileRow.qc_verified = selectedFiles.length === 0 || propData.isFromSupplementalTab;
                        selectedFiles.push(fileRow);
                    }
                } else {
                    fileRow.qc_verified = false;
                }

                let className, color;
                if (['xls', 'xlsx'].includes(fileRow.ext)) {
                    className = 'fa fa-file-excel-o text-success fa-2x';
                    color = 'success';
                } else if (['pdf'].includes(fileRow.ext)) {
                    className = 'fa fa-file-pdf-o text-danger fa-2x';
                    color = 'danger';
                } else {
                    className = 'fa fa-file fa-2x';
                    color = 'default';
                }

                fileRow.iClassName = className;
                fileRow.disabled = false;
                uploadedFilesTemp.push({ ...fileRow, color });
            });

            let statedTotalCost = 0;
            for (const budget of selectedFiles) {
                tempBudgetData = [...tempBudgetData, ...budget.budget_extractions];
                if (!QACompletedTemp) {
                    QACompletedTemp = parseInt(budget.use_our_budget) === 1 && !QACompletedTemp ? true : false;
                }
                statedTotalCost = statedTotalCost + parseFloat(budget.stated_total);
            }

            setStatedTotal(statedTotalCost);
            setQACompleted(QACompletedTemp);
            setSelectedBudgetFilesData(selectedFiles);
            setBudgetData(tempBudgetData);
            setIsBudgetData(true);
            set_totals_and_data(tempBudgetData);
            setUploadedFiles(uploadedFilesTemp);
            showProgress && setLoading(false);
            setShowData(true);
            setUpdate(moment());
        } catch (error) {
            toast["error"](error.errors && error.errors.length ? error.errors[0].message : error.message);
            dispatch(showSpinner(false));
        }
    };

    let openInNewTab = (url) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    let retriggerBudget = async (property_file_id) => {
        try {
            dispatch(showSpinner(true));
            let indexFileRow = uploadedFiles.findIndex((a) => a.id === property_file_id);
            let tempData = { property_file_id };
            let result = await PostData("orders/retrigger-budget", tempData)
            uploadedFiles[indexFileRow].budget_status = 'Inprogress';
            uploadedFiles[indexFileRow].disabled = true;
            uploadedFiles[indexFileRow].budget_extractions = [];
            setBudgetData([]);
            toast['success'](result.data.message)
            setUploadedFiles(uploadedFiles);
            retriggeredBudgetId.push(property_file_id);
            setRetriggeredBudgetId(retriggeredBudgetId);
            dispatch(showSpinner(false));
            setUpdate(moment());
            set_totals_and_data([]);
        } catch (errors) {
            dispatch(showSpinner(false));
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message)
            await sendExceptionEmail(errors, { memberCode: 401, componentName: 'BudgetReview', functionName: 'retriggerBudget', data: {} });
        }
    };

    let setEachAnalyzeLineItem = (id) => {
        analyzeLineItem = [...analyzeLineItem, id];
        analyzeLineItem = getUniqueList(analyzeLineItem);
        setAnalyzeLineItem(analyzeLineItem);
        setUpdate(moment());
    };

    const updateBudgetValues = async (values) => {
        try {
            setHeavyRepairs(parseFloat(values.heavy_repairs));
            setHeavyRepairsTotal(parseFloat(values.heavy_repairs));
            setDeferredMaintenance(parseFloat(values.deferred_maintenance));
            setDeferredMaintenanceTotal(parseFloat(values.deferred_maintenance));
            setUpgrades(parseFloat(values.upgrades));
            setUpgradesTotal(parseFloat(values.upgrades));
            setReconfigurationAdditions(parseFloat(values.reconfiguration_additions));
            setReconfigurationAdditionsTotal(parseFloat(values.reconfiguration_additions));
            setSoftCosts(parseFloat(values.soft_costs));
            setSoftCostsTotal(parseFloat(values.soft_costs));

            const totalBudget = Object.values(values).reduce((acc, value) => acc + parseFloat(value), 0);
            setTotalBudget(totalBudget);
        } catch (errors) {
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message)
            await sendExceptionEmail(errors, { memberCode: 401, componentName: 'BudgetReview', functionName: 'updateBudgetValues', data: {} });
        }
    };

    let set_totals_and_data = (result, manualStates = {}) => {
        dispatch(showSpinner(true));
        if (Object.keys(manualStates).length) {
            if ("useLineItem" in manualStates) {
                useLineItem = manualStates.useLineItem;
            }
        }
        setLoading(true);
        let tempData = {}
        tempData.deferred_maintenance = 0;
        tempData.heavy_repairs = 0;
        tempData.reconfiguration_additions = 0;
        tempData.upgrades = 0;
        tempData.soft_costs = 0;

        let deferred_maintenance = 0;
        let heavy_repairs = 0;
        let reconfiguration_additions = 0;
        let upgrades = 0;
        let soft_costs = 0;
        let capturedTotalCost = 0;
        let ocrTotalCost = 0;

        total_budget = 0;
        let budgetDataTemp;
        if (result === null) {
            budgetDataTemp = budgetData;
        } else {
            budgetDataTemp = result;
        }
        let excludeIdsTemp = excludeIds;
        let categoryIdsTemp = [];
        let tempBudgetData = [];
        let tempUpdateBudgetData = [];
        for (let i = 0; i < budgetDataTemp.length; i++) {
            tempUpdateBudgetData.push(budgetDataTemp[i].id);
            if (budgetDataTemp[i].line_item_name !== "total") {
                tempBudgetData.push(budgetDataTemp[i]);
                let tempTotalPercent = 100;
                if (budgetDataTemp[i].exclude === 1) {
                    checkBoxStateByID = [...checkBoxStateByID, "excludeBudget_" + budgetDataTemp[i].id];
                    excludeIdsTemp = [...excludeIdsTemp, budgetDataTemp[i].id];
                    excludeIdsTemp = getUniqueList(excludeIdsTemp);
                    setExcludeIds(excludeIdsTemp);
                } else {
                    capturedTotalCost = capturedTotalCost + parseFloat(budgetDataTemp[i].cost_amount);
                    if (budgetDataTemp[i].manual_entry === '0') {
                        ocrTotalCost = ocrTotalCost + parseFloat(budgetDataTemp[i].cost_amount);
                    }
                    if (budgetDataTemp[i].exclude !== 1 && budgetDataTemp[i].category && budgetDataTemp[i].category.length !== 0) {
                        categoryIdsTemp = [...categoryIdsTemp, budgetDataTemp[i].id];
                        categoryIdsTemp = getUniqueList(categoryIdsTemp);
                        setCategoryIds(categoryIdsTemp);
                    }
                    budgetDataTemp[i].split_amount_heavy_repairs = parseFloat(budgetDataTemp[i].split_amount_heavy_repairs);
                    budgetDataTemp[i].split_amount_deferred_maintenance = parseFloat(budgetDataTemp[i].split_amount_deferred_maintenance);
                    budgetDataTemp[i].split_amount_upgrades = parseFloat(budgetDataTemp[i].split_amount_upgrades);
                    budgetDataTemp[i].split_amount_reconfiguration_value_add = parseFloat(budgetDataTemp[i].split_amount_reconfiguration_value_add);
                    budgetDataTemp[i].split_amount_soft_costs = parseFloat(budgetDataTemp[i].split_amount_soft_costs);
                    if (budgetDataTemp[i].split_amount_heavy_repairs <= 0 && budgetDataTemp[i].split_amount_deferred_maintenance <= 0 && budgetDataTemp[i].split_amount_upgrades <= 0 && budgetDataTemp[i].split_amount_reconfiguration_value_add <= 0 && budgetDataTemp[i].split_amount_soft_costs <= 0) {
                        if (budgetDataTemp[i].category.length === 1) {
                            // let categoryIndex = categoryData.findIndex((x) => x.name.toLowerCase() === budgetDataTemp[i].category[0].toLowerCase())                            
                        } else if (budgetDataTemp[i].category.length) {
                            let tempSplit_amount_heavy_repairs = 0;
                            let tempSplit_amount_deferred_maintenance = 0;
                            let tempSplit_amount_upgrades = 0;
                            let tempSplit_amount_reconfiguration_value_add = 0;
                            let tempSplit_amount_soft_costs = 0;
                            for (let cat = 0; cat < budgetDataTemp[i].category.length; cat++) {
                                let categoryIndex = categoryData.findIndex((x) => x.name.toLowerCase() === budgetDataTemp[i].category[cat].toLowerCase())
                                if (categoryIndex !== -1) {
                                    tempSplit_amount_heavy_repairs += parseFloat(categoryData[categoryIndex].allocation_hr);
                                    tempSplit_amount_deferred_maintenance += parseFloat(categoryData[categoryIndex].allocation_dm);
                                    tempSplit_amount_upgrades += parseFloat(categoryData[categoryIndex].allocation_up);
                                    tempSplit_amount_reconfiguration_value_add += parseFloat(categoryData[categoryIndex].allocation_rc);
                                    tempSplit_amount_soft_costs += parseFloat(categoryData[categoryIndex].allocation_sc);
                                }
                            }
                            if (budgetDataTemp[i].category.length) {
                                let totalPercent = 100;
                                budgetDataTemp[i].split_amount_heavy_repairs = parseFloat(tempSplit_amount_heavy_repairs / budgetDataTemp[i].category.length);
                                totalPercent = totalPercent - budgetDataTemp[i].split_amount_heavy_repairs;
                                budgetDataTemp[i].split_amount_deferred_maintenance = parseFloat(tempSplit_amount_deferred_maintenance / budgetDataTemp[i].category.length);
                                totalPercent = totalPercent - budgetDataTemp[i].split_amount_deferred_maintenance;
                                if (parseFloat(tempSplit_amount_upgrades / budgetDataTemp[i].category.length) <= totalPercent) {
                                    if (totalPercent - 1 === parseFloat(tempSplit_amount_upgrades / budgetDataTemp[i].category.length)) {
                                        budgetDataTemp[i].split_amount_upgrades = totalPercent;
                                    } else {
                                        budgetDataTemp[i].split_amount_upgrades = parseFloat(tempSplit_amount_upgrades / budgetDataTemp[i].category.length);
                                    }
                                    totalPercent = totalPercent - budgetDataTemp[i].split_amount_upgrades;
                                }
                                if (totalPercent && parseFloat(tempSplit_amount_reconfiguration_value_add / budgetDataTemp[i].category.length) <= totalPercent) {
                                    if (totalPercent - 1 === parseFloat(tempSplit_amount_reconfiguration_value_add / budgetDataTemp[i].category.length) || totalPercent - 2 === parseFloat(tempSplit_amount_reconfiguration_value_add / budgetDataTemp[i].category.length)) {
                                        budgetDataTemp[i].split_amount_reconfiguration_value_add = totalPercent;
                                    } else {
                                        budgetDataTemp[i].split_amount_reconfiguration_value_add = parseInt(tempSplit_amount_reconfiguration_value_add / budgetDataTemp[i].category.length);
                                        totalPercent = totalPercent - budgetDataTemp[i].split_amount_reconfiguration_value_add;
                                    }
                                }
                                if (totalPercent && parseInt(tempSplit_amount_soft_costs / budgetDataTemp[i].category.length) <= totalPercent) {
                                    if (totalPercent - 1 === parseInt(tempSplit_amount_soft_costs / budgetDataTemp[i].category.length) || totalPercent - 2 === parseInt(tempSplit_amount_soft_costs / budgetDataTemp[i].category.length)) {
                                        budgetDataTemp[i].split_amount_soft_costs = totalPercent;
                                    } else {
                                        budgetDataTemp[i].split_amount_soft_costs = parseInt(tempSplit_amount_soft_costs / budgetDataTemp[i].category.length);
                                        totalPercent = totalPercent - budgetDataTemp[i].split_amount_soft_costs;
                                    }
                                }
                            }
                        } else {
                            budgetDataTemp[i].split_amount_heavy_repairs = 0;
                            budgetDataTemp[i].split_amount_deferred_maintenance = 0;
                            budgetDataTemp[i].split_amount_upgrades = 0;
                            budgetDataTemp[i].split_amount_reconfiguration_value_add = 0;
                            budgetDataTemp[i].split_amount_soft_costs = 0;
                        }
                    }
                    if (budgetDataTemp[i].split_amount_heavy_repairs > 0) {
                        checkBoxStateByID = [...checkBoxStateByID, "heavyRepairsBudget_" + budgetDataTemp[i].id];
                        if (excludeIdsTemp.findIndex(x => x === budgetDataTemp[i].id) === -1) {
                            setEachAnalyzeLineItem(budgetDataTemp[i].id);
                            if (tempTotalPercent - budgetDataTemp[i].split_amount_heavy_repairs >= 0 && tempTotalPercent - budgetDataTemp[i].split_amount_heavy_repairs !== 1) {
                                tempTotalPercent = tempTotalPercent - budgetDataTemp[i].split_amount_heavy_repairs;
                                tempData.heavy_repairs = tempData.heavy_repairs + budgetDataTemp[i].split_amount_heavy_repairs;
                                heavy_repairs = heavy_repairs + (budgetDataTemp[i].split_amount_heavy_repairs * budgetDataTemp[i].cost_amount) / 100;
                            } else {
                                tempData.heavy_repairs = tempData.heavy_repairs + tempTotalPercent;
                                heavy_repairs = heavy_repairs + (tempTotalPercent * budgetDataTemp[i].cost_amount) / 100;
                            }
                        }
                    }
                    if (budgetDataTemp[i].split_amount_deferred_maintenance > 0) {
                        checkBoxStateByID = [...checkBoxStateByID, "deferredMaintenanceBudget_" + budgetDataTemp[i].id];
                        if (excludeIdsTemp.findIndex(x => x === budgetDataTemp[i].id) === -1) {
                            setEachAnalyzeLineItem(budgetDataTemp[i].id);
                            if (tempTotalPercent - budgetDataTemp[i].split_amount_deferred_maintenance >= 0 && tempTotalPercent - budgetDataTemp[i].split_amount_deferred_maintenance !== 0) {
                                tempTotalPercent = tempTotalPercent - budgetDataTemp[i].split_amount_deferred_maintenance;
                                tempData.deferred_maintenance = tempData.deferred_maintenance + budgetDataTemp[i].split_amount_deferred_maintenance;
                                deferred_maintenance = deferred_maintenance + (budgetDataTemp[i].split_amount_deferred_maintenance * budgetDataTemp[i].cost_amount) / 100;
                            }
                            else {
                                tempData.deferred_maintenance = tempData.deferred_maintenance + tempTotalPercent;
                                deferred_maintenance = deferred_maintenance + (tempTotalPercent * budgetDataTemp[i].cost_amount) / 100;
                            }
                        }
                    }
                    if (budgetDataTemp[i].split_amount_upgrades > 0) {
                        checkBoxStateByID = [...checkBoxStateByID, "upgradesBudget_" + budgetDataTemp[i].id];
                        if (excludeIdsTemp.findIndex(x => x === budgetDataTemp[i].id) === -1) {
                            setEachAnalyzeLineItem(budgetDataTemp[i].id);
                            if (tempTotalPercent - budgetDataTemp[i].split_amount_upgrades >= 0 && tempTotalPercent - budgetDataTemp[i].split_amount_upgrades !== 1) {
                                tempTotalPercent = tempTotalPercent - budgetDataTemp[i].split_amount_upgrades;
                                tempData.upgrades = tempData.upgrades + budgetDataTemp[i].split_amount_upgrades;
                                upgrades = upgrades + (budgetDataTemp[i].split_amount_upgrades * budgetDataTemp[i].cost_amount) / 100;
                            } else {
                                tempData.upgrades = Math.round(tempData.upgrades) + tempTotalPercent;
                                upgrades = upgrades + Math.round((Math.round(tempTotalPercent) * budgetDataTemp[i].cost_amount) / 100);
                            }
                        }
                    }
                    if (budgetDataTemp[i].split_amount_reconfiguration_value_add > 0) {
                        checkBoxStateByID = [...checkBoxStateByID, "valueAddBudget_" + budgetDataTemp[i].id];
                        if (excludeIdsTemp.findIndex(x => x === budgetDataTemp[i].id) === -1) {
                            setEachAnalyzeLineItem(budgetDataTemp[i].id);

                            if (tempTotalPercent - budgetDataTemp[i].split_amount_reconfiguration_value_add >= 0 && tempTotalPercent - Math.round(budgetDataTemp[i].split_amount_reconfiguration_value_add) !== 1) {
                                tempTotalPercent = tempTotalPercent - budgetDataTemp[i].split_amount_reconfiguration_value_add;
                                tempData.reconfiguration_additions = tempData.reconfiguration_additions + budgetDataTemp[i].split_amount_reconfiguration_value_add;
                                reconfiguration_additions = reconfiguration_additions + (budgetDataTemp[i].split_amount_reconfiguration_value_add * budgetDataTemp[i].cost_amount) / 100;
                            } else {
                                tempData.reconfiguration_additions = tempData.reconfiguration_additions + tempTotalPercent;
                                reconfiguration_additions = reconfiguration_additions + (tempTotalPercent * budgetDataTemp[i].cost_amount) / 100;
                            }
                        }
                    }
                    if (budgetDataTemp[i].split_amount_soft_costs > 0) {
                        checkBoxStateByID = [...checkBoxStateByID, "softCostsBudget_" + budgetDataTemp[i].id];
                        if (excludeIdsTemp.findIndex(x => x === budgetDataTemp[i].id) === -1) {
                            setEachAnalyzeLineItem(budgetDataTemp[i].id);

                            if (tempTotalPercent - budgetDataTemp[i].split_amount_soft_costs >= 0 && tempTotalPercent - budgetDataTemp[i].split_amount_soft_costs !== 1) {
                                tempTotalPercent = tempTotalPercent - budgetDataTemp[i].split_amount_soft_costs;
                                tempData.soft_costs = tempData.soft_costs + budgetDataTemp[i].split_amount_soft_costs;
                                soft_costs = soft_costs + (budgetDataTemp[i].split_amount_soft_costs * budgetDataTemp[i].cost_amount) / 100;
                            } else {
                                tempData.soft_costs = tempData.soft_costs + tempTotalPercent;
                                soft_costs = soft_costs + (tempTotalPercent * budgetDataTemp[i].cost_amount) / 100;
                            }
                        }
                    }

                }
            }
        }
        setUpdatedBudgetData(tempUpdateBudgetData);
        setCapturedTotal(capturedTotalCost);
        setOCRTotal(ocrTotalCost);
        checkBoxStateByID = getUniqueList(checkBoxStateByID);
        setCheckBoxStateByID(checkBoxStateByID);
        setBudgetData(tempBudgetData);
        setData(tempData);
        setIsBudgetData(false);

        if (useLineItem) {
            updateBudgetValues({ heavy_repairs, deferred_maintenance, upgrades, reconfiguration_additions, soft_costs });
        } else if (propData.budget_analysis) {
            const { budget_analysis, grade, arv_user_value } = propData;
            updateBudgetValues(budget_analysis);
            setData({
                ...budget_analysis,
                target_quality: grade,
                target_condition: arv_user_value.BorrowerTargetCondition
            });
        } else {
            updateBudgetValues({
                heavy_repairs: heavy_repairs !== 0 ? heavy_repairs : 0,
                deferred_maintenance: deferred_maintenance !== 0 ? deferred_maintenance : 0,
                upgrades: upgrades !== 0 ? upgrades : 0,
                reconfiguration_additions: reconfiguration_additions !== 0 ? reconfiguration_additions : 0,
                soft_costs: soft_costs !== 0 ? soft_costs : 0
            });
        }

        dispatch(showSpinner(false));
        setLoading(false);
        setUpdate(moment());
    };


    // Handle Steps
    let handleStep = (step) => {
        onHandleBudgetReviewResponse(step);
    }

    let handleInput = async (e) => {

        let name = e.target.name;
        let value = e.target.value;
        if (name === 'target_quality') {
            setTargetQualityGrade(value);
            await periodObservance({ taskData, slug: TASK_SLUG.BUDGET_SELECT_TARGET_QUALITY, orderData: propData, closingTask: 0, newTabCheck: 0 });
        } else if (name === 'target_condition') {
            await periodObservance({ taskData, slug: TASK_SLUG.BUDGET_SELECT_TARGET_CONDITION, orderData: propData, closingTask: 0, newTabCheck: 0 });
        }

        if (value.indexOf("+") === 0) {
            let errors = customError;
            if (!errors.includes(name)) {
                errors = [...errors, name];
                setCustomError(errors);

            }
        } else if (value.indexOf("+") !== -1) {
            let sumData = value.split("+");
            let total = sumData.reduce((a, b) => parseInt(a) + parseInt(b), 0);
            if (total) {
                if (name === "deferred_maintenance") {
                    setDeferredMaintenanceTotal(parseFloat(total));
                } else if (name === "heavy_repairs") {
                    setHeavyRepairsTotal(parseFloat(total));
                } else if (name === "upgrades") {
                    setUpgradesTotal(parseFloat(total));
                } else if (name === "reconfiguration_additions") {
                    setReconfigurationAdditionsTotal(parseFloat(total));
                } else if (name === "soft_costs") {
                    setSoftCostsTotal(parseFloat(total));
                }
                data[name] = total;
            }
            setCustomError("");
        } else if (value.indexOf("+") === -1) {
            if (name === "deferred_maintenance") {
                setDeferredMaintenanceTotal(parseFloat(value) > 0 ? value : 0);
            } else if (name === "heavy_repairs") {
                setHeavyRepairsTotal(parseFloat(value) > 0 ? value : 0);
            } else if (name === "upgrades") {
                setUpgradesTotal(parseFloat(value) > 0 ? value : 0);
            } else if (name === "reconfiguration_additions") {
                setReconfigurationAdditionsTotal(parseFloat(value) > 0 ? value : 0);
            } else if (name === "soft_costs") {
                setSoftCostsTotal(parseFloat(value) > 0 ? value : 0);
            }
            data[name] = value;
            setCustomError("");
        }
        setData(data);
        setUpdate(moment());
    }
    let handleFinalData = async (e) => {
        try {
            if (!fromSupplementalInfo) {
                data.is_budget_file = false;
                if (propData.budget_file) {
                    data.is_budget_file = true;
                }
                data.report_order_id = propData.id;
                data.property_id = propData.property_id;
                data.user_id = userData.user_id;
                data.company_id = userData.company_id;
                data.property_comp_id = propData.subject_property ? propData.subject_property.id : null;
                if (budgetData && budgetData.length > 0) {
                    data.deferred_maintenance = deferredMaintenanceTotal;
                    data.heavy_repairs = heavyRepairsTotal;
                    data.reconfiguration_additions = reconfigurationAdditionsTotal;
                    data.upgrades = upgradesTotal;
                    data.soft_costs = softCostsTotal;
                }
                data.property_files_id = [];
                data.qc_remove_property_files_id = [];
                for (const propertyFileData of uploadedFiles) {
                    if (propertyFileData.qc_verified) {
                        data.property_files_id.push(propertyFileData.id);
                    } else {
                        data.qc_remove_property_files_id.push(propertyFileData.id);
                    }
                }
                data.bypass_line_item = useLineItem ? 0 : 1;
                data.target_quality = targetQualityGrade;
                dispatch(showSpinner(true));
                let result = await PostData("orders/save_report_budget_analysis", data);
                data = [];
                budgetData && budgetData.forEach(budget => {
                    if (updatedBudgetData && updatedBudgetData.includes(budget.id)) {
                        let count = 0;
                        let split_heavy_repairs = parseFloat(budget.split_amount_heavy_repairs);
                        let split_deferred_maintenance = parseFloat(budget.split_amount_deferred_maintenance);
                        let split_upgrades = parseFloat(budget.split_amount_upgrades);
                        let split_reconfiguration_value_add = parseFloat(budget.split_amount_reconfiguration_value_add);
                        let split_soft_costs = parseFloat(budget.split_amount_soft_costs);
                        let total_amount = Math.round(split_heavy_repairs) + Math.round(split_deferred_maintenance) + Math.round(split_upgrades) + Math.round(split_reconfiguration_value_add) + Math.round(split_soft_costs);
                        count = count + (split_heavy_repairs !== 0 ? 1 : 0);
                        count = count + (split_deferred_maintenance !== 0 ? 1 : 0);
                        count = count + (split_upgrades !== 0 ? 1 : 0);
                        count = count + (split_reconfiguration_value_add !== 0 ? 1 : 0);
                        count = count + (split_soft_costs !== 0 ? 1 : 0);
                        if (count === 1) {
                            split_heavy_repairs = split_heavy_repairs === total_amount ? 100 : 0;
                            split_deferred_maintenance = split_deferred_maintenance === total_amount ? 100 : 0;
                            split_upgrades = split_upgrades === total_amount ? 100 : 0;
                            split_reconfiguration_value_add = split_reconfiguration_value_add === total_amount ? 100 : 0;
                            split_soft_costs = split_soft_costs === total_amount ? 100 : 0;
                        }
                        data.push({ category: budget.category, user_id: userData.user_id, budget_extraction_id: budget.id, budget_classes_name: { split_amount_heavy_repairs: split_heavy_repairs, split_amount_deferred_maintenance: split_deferred_maintenance, split_amount_upgrades: split_upgrades, split_amount_reconfiguration_value_add: split_reconfiguration_value_add, split_amount_soft_costs: split_soft_costs } });
                    }
                });
                await PostData("orders/update-single-budget-class", { budget_extraction_data: data });
                toast["success"](result.data.message);
                onHandleBudgetReviewResponse("done");
                dispatch(showSpinner(false));
            }
            setIsModal(false)
        } catch (errors) {
            dispatch(showSpinner(false));
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message)
            await sendExceptionEmail(errors, { memberCode: 401, componentName: 'BudgetReview', functionName: 'handleFinalData', data: {} });
            setIsModal(false)
        }
        await periodObservance({ taskData, slug: TASK_SLUG.BUDGET_SUBMIT, orderData: propData, closingTask: 0, newTabCheck: 0 }); // time  tracking - budget submit task
        setIsModal(false)
    }

    let handleBudgetFinalData = async (e) => {
        try {
            dispatch(showSpinner(true));
            let dataTemp = {};
            dataTemp.property_file_id = propData.property_file_id;
            dataTemp.qc_verified = '1';

            let result = await PostData("orders/finalize-budget-supplemental-data", dataTemp);
            toast["success"](result.data.message);
            onHandleBudgetReviewResponse("done");
            dispatch(showSpinner(false));
        } catch (errors) {
            dispatch(showSpinner(false));
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message)
            await sendExceptionEmail(errors, { memberCode: 401, componentName: 'BudgetReview', functionName: 'handleBudgetFinalData', data: {} });
        }

    }

    let excludeCheckbox = function (cell, row, field) {
        const excludeBudgetId = `${field}_${row.id}`;
        return (<>
            <Button type="button" id={excludeBudgetId} outline onClick={async (e) => await onButtonClick(e, row, field)} className="btn btn-sm" color={(checkBoxStateByID && checkBoxStateByID.find(o => o === excludeBudgetId)) ? "danger" : "success"}>
                <i className={(checkBoxStateByID && checkBoxStateByID.find(o => o === excludeBudgetId)) ? "fa fa-times red" : "fa fa-check green"} color={(checkBoxStateByID && checkBoxStateByID.find(o => o !== excludeBudgetId)) ? "danger" : "success"}></i>
            </Button >
        </>)
    };

    let checkbox = function (cell, row, field) {
        const budgetId = `${field}_${row.id}`;
        const toolTipData = field === "heavyRepairsBudget" ? row.split_amount_heavy_repairs : field === "deferredMaintenanceBudget" ? row.split_amount_deferred_maintenance : field === "upgradesBudget" ? row.split_amount_upgrades : field === "valueAddBudget" ? row.split_amount_reconfiguration_value_add : field === "softCostsBudget" ? row.split_amount_soft_costs : 0;
        return (
            <>
                <div className="custom-checkbox text-center" name={budgetId}>
                    <input type="checkbox" id={budgetId} disabled={row.exclude !== 0 ? true : false} onChange={(e) => onCheckBoxChange(e, row, field)} checked={row.exclude !== 1 && (checkBoxStateByID && checkBoxStateByID.find(o => o === budgetId)) ? true : false} className="custom-checkbox" align="left" />
                    <UncontrolledTooltip delay={0} placement="auto" target={budgetId} >{parseInt(toolTipData) !== "" && parseInt(toolTipData) > 0 ? parseInt(toolTipData) : 0}%</UncontrolledTooltip>
                </div>
            </>
        );
    };

    let checkboxAll = function (field) {
        const budgetId = `${field}`;

        let selectedAllCategoryName = '';
        if ((budgetData.length - 1) === checkBoxStateByID.length && checkBoxStateByID.length > 0) {
            let filterredData = checkBoxStateByID.filter(obj => obj.startsWith('heavy'));
            if (filterredData.length === checkBoxStateByID.length) {
                selectedAllCategoryName = 'H';
            }
            filterredData = checkBoxStateByID.filter(obj => obj.startsWith('deferred'));
            if (filterredData.length === checkBoxStateByID.length) {
                selectedAllCategoryName = 'D';
            }
            filterredData = checkBoxStateByID.filter(obj => obj.startsWith('upgrades'));
            if (filterredData.length === checkBoxStateByID.length) {
                selectedAllCategoryName = 'U';
            }
            filterredData = checkBoxStateByID.filter(obj => obj.startsWith('value'));
            if (filterredData.length === checkBoxStateByID.length) {
                selectedAllCategoryName = 'VA';
            }
            filterredData = checkBoxStateByID.filter(obj => obj.startsWith('soft'));
            if (filterredData.length === checkBoxStateByID.length) {
                selectedAllCategoryName = 'SC';
            }
        }
        if (selectedAllCategoryName === '') {
            selectedAllCategoryName = selectedAllCategory;
        }
        return (
            <>
                <div className="custom-checkbox text-center" name={budgetId}>
                    {field} <input type="checkbox" id={`${budgetId}_all`} onChange={(e) => onCheckBoxChangeAll(e, field)} checked={selectedAllCategoryName === field ? true : false} className="custom-checkbox" align="left" />
                    <UncontrolledTooltip delay={0} placement="bottom" target={`${budgetId}_all`} >Select All</UncontrolledTooltip>
                </div>
            </>
        );
    };

    let onEditButtonClick = function (e, row) {
        setIsEditLineItemModal(true);
        setDataForEdit({ id: row.id, line_item_name: row.line_item_name, description: row.description, category: row.category, quantity: row.quantity, price: row.price, cost_amount: row.cost_amount });
    };

    let editButton = function (cell, row) {
        let buttonId = `edit_line_item_${row.id}`;
        return (<>
            <button type="button" id={buttonId} className="btn btn-primary btn-sm" onClick={(e) => onEditButtonClick(e, row)}>
                <i className="fa fa-pencil-alt" aria-hidden="true"></i>
            </button>
            <UncontrolledTooltip delay={0} placement="auto" target={buttonId} >Edit</UncontrolledTooltip>
        </>)
    };

    let checkMultiples = function (budget_extraction_id) {
        let selected_class = [];
        checkBoxStateByID.forEach(id => {
            try {
                if (!id.startsWith("excludeBudget")) {
                    if (id.endsWith(`_${budget_extraction_id}`)) {
                        selected_class = [...selected_class, id];
                    }
                }
            } catch { }
        });
        selected_class = getUniqueList(selected_class);
        return { count: selected_class.length, selected_class: selected_class };
    };

    let getUniqueList = function (items) {
        const uniqueTags = [];
        items.map((item) => (uniqueTags.indexOf(item) === -1 ? uniqueTags.push(item) : ''));
        return uniqueTags;
    };
    let uncheckPreparePayload = function (id, cost_amount) {
        let payloadData = [];
        let checkBoxStateByIDtemp = checkMultiples(id);
        checkBoxStateByIDtemp = checkBoxStateByIDtemp.selected_class;
        checkBoxStateByIDtemp.forEach(selectedId => {
            if (selectedId.includes(id) && selectedId.includes("heavy")) {
                payloadData.push(selectedId.includes("heavy") ? { budget_class_name: 'split_amount_heavy_repairs', cost_amount: parseFloat(cost_amount / checkBoxStateByIDtemp.length).toFixed(0) } : { budget_class_name: 'split_amount_heavy_repairs', cost_amount: 0 })
            }
            if (selectedId.includes(id) && selectedId.includes("deferred")) {
                payloadData.push(selectedId.includes("deferred") ? { budget_class_name: 'split_amount_deferred_maintenance', cost_amount: parseFloat(cost_amount / checkBoxStateByIDtemp.length).toFixed(0) } : { budget_class_name: 'split_amount_deferred_maintenance', cost_amount: 0 })
            }
            if (selectedId.includes(id) && selectedId.includes("upgrades")) {
                payloadData.push(selectedId.includes("upgrades") ? { budget_class_name: 'split_amount_upgrades', cost_amount: parseFloat(cost_amount / checkBoxStateByIDtemp.length).toFixed(0) } : { budget_class_name: 'split_amount_upgrades', cost_amount: 0 })
            }
            if (selectedId.includes(id) && selectedId.includes("value")) {
                payloadData.push(selectedId.includes("value") ? { budget_class_name: 'split_amount_reconfiguration_value_add', cost_amount: parseFloat(cost_amount / checkBoxStateByIDtemp.length).toFixed(0) } : { budget_class_name: 'split_amount_reconfiguration_value_add', cost_amount: 0 })
            }
            if (selectedId.includes(id) && selectedId.includes("Costs")) {
                payloadData.push(selectedId.includes("Costs") ? { budget_class_name: 'split_amount_soft_costs', cost_amount: parseFloat(cost_amount / checkBoxStateByIDtemp.length).toFixed(0) } : { budget_class_name: 'split_amount_soft_costs', cost_amount: 0 })
            }
        });
        return payloadData;
    };

    let give_budget_class_name = function (class_name, status, cost_amount, take_cost_amount = false) {
        let budget_class_name;
        let tempData = {}
        tempData = data;

        if (class_name && class_name.includes("heavy")) {
            budget_class_name = "split_amount_heavy_repairs";
            if (status === "less_amount") {
                if (!take_cost_amount) {
                    cost_amount = heavyRepairs;
                }
                tempData.heavy_repairs = parseFloat(heavyRepairs) - parseFloat(cost_amount);
            } else {
                tempData.heavy_repairs = parseFloat(heavyRepairs) + parseFloat(cost_amount);
            }
        }
        if (class_name.includes("deferred")) {
            budget_class_name = "split_amount_deferred_maintenance";
            if (status === "less_amount") {
                if (!take_cost_amount) {
                    cost_amount = deferredMaintenance;
                }
                tempData.deferred_maintenance = parseFloat(deferredMaintenance) - parseFloat(cost_amount);
            } else {
                tempData.deferred_maintenance = parseFloat(deferredMaintenance) + parseFloat(cost_amount);
            }
        }
        if (class_name.includes("upgrades")) {
            budget_class_name = "split_amount_upgrades";
            if (status === "less_amount") {
                if (!take_cost_amount) {
                    cost_amount = upgrades;
                }
                tempData.upgrades = parseFloat(upgrades) - parseFloat(cost_amount);
            } else {
                tempData.upgrades = parseFloat(upgrades) + parseFloat(cost_amount);
            }
        }
        if (class_name.includes("value")) {
            budget_class_name = "split_amount_reconfiguration_value_add";
            if (status === "less_amount") {
                if (!take_cost_amount) {
                    cost_amount = reconfigurationAdditions;
                }
                tempData.reconfiguration_additions = parseFloat(reconfigurationAdditions) - parseFloat(cost_amount);
            } else {
                tempData.reconfiguration_additions = parseFloat(reconfigurationAdditions) + parseFloat(cost_amount);
            }
        }
        if (class_name.includes("soft")) {
            budget_class_name = "split_amount_soft_costs";
            if (status === "less_amount") {
                if (!take_cost_amount) {
                    cost_amount = softCosts;
                }
                tempData.soft_costs = parseFloat(softCosts) - parseFloat(cost_amount);
            } else {
                tempData.soft_costs = parseFloat(softCosts) + parseFloat(cost_amount);
            }
        }
        setData(tempData);
        return budget_class_name;
    }

    let onButtonClick = async (e, row, class_name) => {
        try {
            let index = checkBoxStateByID.findIndex(x => x === `${class_name}_${row.id}`);
            let excludeState;
            if (index === -1) {
                checkBoxStateByID = [...checkBoxStateByID, `${class_name}_${row.id}`];
                setCheckBoxStateByID(checkBoxStateByID);
                excludeState = 1;
                let tempAnalyzeLineItemIndex = analyzeLineItem.findIndex(x => x === row.id);
                analyzeLineItem.splice(tempAnalyzeLineItemIndex, 1);
                setAnalyzeLineItem(analyzeLineItem);
                let tempCategoryIds = categoryIds.findIndex(x => x === row.id);
                categoryIds.splice(tempCategoryIds, 1);
                setCategoryIds(categoryIds);
            } else {
                let tempAnalyzeLineItem = [...analyzeLineItem, row.id];
                setAnalyzeLineItem(tempAnalyzeLineItem);
                excludeState = 0;
                checkBoxStateByID.splice(index, 1);
                setCheckBoxStateByID(checkBoxStateByID);
                if (row.category !== "" && row.category !== "Select category") {
                    let categoryIdsTemp = categoryIds;
                    categoryIdsTemp = [...categoryIdsTemp, row.id];
                    categoryIdsTemp = getUniqueList(categoryIdsTemp);
                    setCategoryIds(categoryIdsTemp);
                }
            }
            let excludeIndex = excludeIds.findIndex(x => x === row.id);
            let excludeIdsTemp = excludeIds;
            if (excludeIndex === -1) {
                excludeIdsTemp = [...excludeIdsTemp, row.id];
                excludeIdsTemp = getUniqueList(excludeIdsTemp);
                setExcludeIds(excludeIdsTemp);

            } else {
                excludeIds.splice(excludeIndex, 1);
                excludeIdsTemp = getUniqueList(excludeIds);
                setExcludeIds(excludeIdsTemp);
            }
            let budgetDatatemp = budgetData;
            for (let i = 0; i < budgetDatatemp.length; i++) {
                if (budgetDatatemp[i].id === row.id) {
                    budgetDatatemp[i].exclude = excludeState;
                    if (excludeState === 0 && (budgetDatatemp[i].split_amount_upgrades > 0 || budgetDatatemp[i].split_amount_deferred_maintenance > 0 || budgetDatatemp[i].split_amount_heavy_repairs > 0 || budgetDatatemp[i].split_amount_reconfiguration_value_add > 0 || budgetDatatemp[i].split_amount_soft_costs > 0)) {
                        setEachAnalyzeLineItem(row.id);
                    } else {
                        setBudgetData(budgetDatatemp)
                    }
                }
            }
            set_totals_and_data(null);
            if (class_name === "excludeBudget") {
                data = { budget_extraction_id: row.id }
                dispatch(showSpinner(true));
                await PostData("orders/update-budget-analysis", data);
                dispatch(showSpinner(false));
            }
            setUpdate(moment());
        } catch (errors) {
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message)
            await sendExceptionEmail(errors, { memberCode: 401, componentName: 'BudgetReview', functionName: 'onButtonClick', data: {} });
            dispatch(showSpinner(false));
        }
    };

    let onCheckBoxChange = async (e, row, class_name) => {
        let index = checkBoxStateByID.findIndex(x => x === `${class_name}_${row.id}`);
        let updatedBudgettempData = updatedBudgetData;

        if (e.target.checked) {
            try {
                updatedBudgettempData = [...updatedBudgettempData, row.id];
            } catch {
                updatedBudgettempData = [row.id];
            }
            updatedBudgettempData = getUniqueList(updatedBudgettempData);
            setUpdatedBudgetData(updatedBudgettempData);
            checkBoxStateByID = [...checkBoxStateByID, `${class_name}_${row.id}`];
            checkBoxStateByID = getUniqueList(checkBoxStateByID);
            setCheckBoxStateByID(checkBoxStateByID);
            if (class_name !== "excludeBudget") {

                let response = checkMultiples(row.id);
                let count = response.count;
                if (count >= 2) {
                    const particularData = { budget_extraction_id: row.id, line_item_name: row.line_item_name, description: row.description, category: row.category, cost_amount: row.cost_amount, selected_class: response.selected_class };
                    setParticularData(particularData);
                    setIsBudgetAnalysisModal(true);
                    await periodObservance({ taskData, slug: TASK_SLUG.BUDGET_ALLOCATE_COSTS, orderData: propData, closingTask: 0, newTabCheck: 1 }); // time  tracking - allocate cost task opening task
                }
                if (count === 1) {
                    let budget_class_name = give_budget_class_name(class_name, "increase_amount", row.cost_amount);
                    data = { budget_extraction_id: row.id, budget_classes_name: [{ budget_class_name: budget_class_name, cost_amount: row.cost_amount }] };
                    let tempBudgetdata = budgetData;
                    for (let i = 0; i < tempBudgetdata.length; i++) {
                        if (tempBudgetdata[i].id === row.id) {
                            tempBudgetdata[i][budget_class_name] = 100;
                        }
                    }
                    setBudgetData(tempBudgetdata);
                    set_totals_and_data(tempBudgetdata);
                    setIsBudgetAnalysisModal(false);
                }
            }
            if ((updatedBudgettempData.length - 1) === analyzeLineItem.length) {
                let filteredData = checkBoxStateByID.filter(x => x.startsWith(class_name));
                if (filteredData.length === (analyzeLineItem.length - 1)) {
                    if (class_name === 'heavyRepairsBudget') { setSelectedAllCategory('H') };
                    if (class_name === 'deferredMaintenanceBudget') { setSelectedAllCategory('D') };
                    if (class_name === 'upgradesBudget') { setSelectedAllCategory('U') };
                    if (class_name === 'valueAddBudget') { setSelectedAllCategory('VA') };
                    if (class_name === 'softCostsBudget') { setSelectedAllCategory('SC') };
                    setUpdate(moment());
                }
            }
        } else {
            let response = checkMultiples(row.id);
            if (response.count === 1) {
                let analyzeIndex = analyzeLineItem.findIndex(x => x === row.id);
                analyzeLineItem.splice(analyzeIndex, 1);
                setAnalyzeLineItem(analyzeLineItem);
            }
            try {
                updatedBudgetData = [...updatedBudgetData, row.id];
            } catch {
                updatedBudgetData = [row.id];
            }
            updatedBudgetData = getUniqueList(updatedBudgetData);
            setUpdatedBudgetData(updatedBudgetData);
            checkBoxStateByID.splice(index, 1);
            setCheckBoxStateByID(checkBoxStateByID);
            if (class_name !== "excludeBudget") {
                let budget_class_name = give_budget_class_name(class_name, "less_amount", 0);
                let tempdata = { budget_class_name: budget_class_name, cost_amount: 0 };

                data = uncheckPreparePayload(row.id, row.cost_amount);
                data.push(tempdata);
                data = { budget_extraction_id: row.id, budget_classes_name: data };
                let tempBudgetdata = budgetData;
                for (let i = 0; i < tempBudgetdata.length; i++) {
                    if (tempBudgetdata[i].id === row.id) {
                        let lengthOfClasses = data.budget_classes_name.length;
                        let cnt = 0;
                        data.budget_classes_name.forEach(rec => {
                            cnt = cnt + 1;
                            if (cnt === 3 && (lengthOfClasses - 1) === 3) {
                                tempBudgetdata[i][rec.budget_class_name] = 34
                            } else {
                                tempBudgetdata[i][rec.budget_class_name] = lengthOfClasses > 1 && rec.cost_amount !== 0 ? parseInt(100 / (lengthOfClasses - 1)) : 0;
                            }
                        });
                    }
                }
                setBudgetData(tempBudgetdata);
                set_totals_and_data(tempBudgetdata);
            }
            setIsBudgetAnalysisModal(false);
            if ((analyzeLineItem.length - 1) !== analyzeLineItem.length) {
                setSelectedAllCategory('');
            }
        }
    }
    let getBudgetClassName = (field) => {
        const allBudgetClassNames = [
            "split_amount_heavy_repairs",
            "split_amount_deferred_maintenance",
            "split_amount_upgrades",
            "split_amount_reconfiguration_value_add",
            "split_amount_soft_costs"
        ];
        let budgetClassName;
        if (field && field.includes("heavy")) {
            budgetClassName = "split_amount_heavy_repairs";
        }
        if (field.includes("deferred")) {
            budgetClassName = "split_amount_deferred_maintenance";
        }
        if (field.includes("upgrades")) {
            budgetClassName = "split_amount_upgrades";
        }
        if (field.includes("value")) {
            budgetClassName = "split_amount_reconfiguration_value_add";
        }
        if (field.includes("soft")) {
            budgetClassName = "split_amount_soft_costs";
        }
        const otherBudgetClassNames = allBudgetClassNames.filter(className => className !== budgetClassName);

        return { currentBudgetClass: budgetClassName, otherBudgetClasses: otherBudgetClassNames };
    }
    let onCheckBoxChangeAll = (e, field) => {
        setSelectedAllCategory(field);
        let currentFieldName = field === 'H' ? 'heavy' : field === 'D' ? 'deferred' : field === 'U' ? 'upgrades' : field === 'VA' ? 'value' : field === 'SC' ? 'soft' : '';
        if (!e.target.checked) {
            currentFieldName = '';
        }
        let { currentBudgetClass, otherBudgetClasses } = getBudgetClassName(currentFieldName);
        let tempBudgetdata = budgetData;

        let updatedBudgettempData = updatedBudgetData;
        for (let i = 0; i < tempBudgetdata.length; i++) {
            if (tempBudgetdata[i].id) {
                updatedBudgettempData = [...updatedBudgettempData, tempBudgetdata[i].id];
                let filterredData = checkBoxStateByID.filter(obj => !obj.startsWith('exclude') && obj.includes(tempBudgetdata[i].id));
                let tempcheckBoxStateByID = checkBoxStateByID;
                filterredData.forEach(element => {
                    let checboxIndex = tempcheckBoxStateByID.findIndex(x => x === element);
                    tempcheckBoxStateByID.splice(checboxIndex, 1);
                    setCheckBoxStateByID(tempcheckBoxStateByID);
                });
                if (e.target.checked) {
                    tempBudgetdata[i][currentBudgetClass] = 100;
                    tempBudgetdata[i][otherBudgetClasses[0]] = 0;
                    tempBudgetdata[i][otherBudgetClasses[1]] = 0;
                    tempBudgetdata[i][otherBudgetClasses[2]] = 0;
                    tempBudgetdata[i][otherBudgetClasses[3]] = 0;
                } else {
                    let analyzeIndex = analyzeLineItem.findIndex(x => x === tempBudgetdata[i].id);
                    analyzeLineItem.splice(analyzeIndex, 1);
                    setAnalyzeLineItem(analyzeLineItem);
                    let index = checkBoxStateByID.findIndex(x => x.endsWith(tempBudgetdata[i].id));
                    checkBoxStateByID.splice(index, 1);
                    setCheckBoxStateByID(checkBoxStateByID);
                    tempBudgetdata[i][otherBudgetClasses[0]] = 0;
                    tempBudgetdata[i][otherBudgetClasses[1]] = 0;
                    tempBudgetdata[i][otherBudgetClasses[2]] = 0;
                    tempBudgetdata[i][otherBudgetClasses[3]] = 0;
                    tempBudgetdata[i][otherBudgetClasses[4]] = 0;
                }
            }
        }
        if (!e.target.checked) {
            setCategoryIds([]);
            setSelectedAllCategory('');
            setUpdate(moment());
        }
        setBudgetData(tempBudgetdata);
        setUpdatedBudgetData(updatedBudgettempData);
        set_totals_and_data(tempBudgetdata);
        setIsBudgetAnalysisModal(false);
    }

    let selectBudgetFiles = (e, row) => {
        let tempBudgetData = [];
        let tempUploadedFiles = [...uploadedFiles];
        let tempUploadedFilesIndex = tempUploadedFiles.findIndex(x => x.location === row.location);
        tempUploadedFiles[tempUploadedFilesIndex].qc_verified = e.target.checked;

        let tempData;
        if (e.target.checked) {
            tempData = [...selectedBudgetFilesData, row];
            for (const budget of tempData) {
                tempBudgetData = [...tempBudgetData, ...budget.budget_extractions];
            }
        } else {
            tempData = selectedBudgetFilesData.filter(x => x.location !== row.location);
            for (const budget of tempData) {
                tempBudgetData = [...tempBudgetData, ...budget.budget_extractions];
            }

            row.budget_extractions.forEach(budget => {
                let analyzeIndex = analyzeLineItem.indexOf(budget.id);
                if (analyzeIndex !== -1) {
                    analyzeLineItem.splice(analyzeIndex, 1);
                }
            });

            setCategoryIds([]);
            setCheckBoxStateByID([]);
            setExcludeIds([]);
            if (tempData.length === 0) {
                tempBudgetData = [];
            }
        }

        const statedTotalCost = tempData.reduce((total, budget) => {
            const statedTotal = budget.hasOwnProperty('stated_total') ? parseFloat(budget.stated_total) : 0;
            return total + statedTotal;
        }, 0);

        setStatedTotal(statedTotalCost);
        setUploadedFiles(tempUploadedFiles);
        setSelectedBudgetFilesData(tempData);
        setBudgetData(tempBudgetData);
        setAnalyzeLineItem([...analyzeLineItem]);
        set_totals_and_data(tempBudgetData);
        setUpdate(moment());
    };

    let removeCheckBoxState = (className, budget_extraction_id) => {
        let index = checkBoxStateByID.findIndex(x => x === `${className}${budget_extraction_id}`);
        checkBoxStateByID.splice(index, 1);
        setCheckBoxStateByID(checkBoxStateByID);
    };

    let handleCheckboxResponse = async (data) => {
        let tempBudgetdata = budgetData;
        for (let i = 0; i < tempBudgetdata.length; i++) {
            if (tempBudgetdata[i].id === data.budget_extraction_id) {
                data.budget_classes_name.forEach(rec => {
                    tempBudgetdata[i][rec.budget_class_name] = rec.cost_amount;
                    if (rec.cost_amount === 0) {
                        let className = "";
                        if (rec.budget_class_name.includes("heavy")) {
                            className = "heavyRepairsBudget_"
                        }
                        if (rec.budget_class_name.includes("deferred")) {
                            className = "deferredMaintenanceBudget_"
                        }
                        if (rec.budget_class_name.includes("upgrades")) {
                            className = "upgradesBudget_"
                        }
                        if (rec.budget_class_name.includes("value")) {
                            className = "valueAddBudget_"
                        }
                        if (rec.budget_class_name.includes("soft")) {
                            className = "softCostsBudget_"
                        }
                        removeCheckBoxState(className, data.budget_extraction_id);
                    }
                });
            }
        }
        setBudgetData(tempBudgetdata);
        setUpdate(moment());
        set_totals_and_data(null);
        setUpdate(moment());
        await periodObservance({ taskData, slug: TASK_SLUG.BUDGET_ALLOCATE_COSTS, orderData: propData, closingTask: 1, newTabCheck: 0 }); // time  tracking - allocate cost task closing it when saved button is clicked from allocate cost popup
    };

    let addLineItem = async (e) => {
        if (!useLineItem) {
            confirmAlert({
                title: 'Error',
                message: `Enable the "Use Line Item" button to add a line item`,
                closeOnClickOutside: false,
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => { },
                        className: "btn btn-danger btn-sm"
                    }
                ]
            });
        } else {
            dispatch(showSpinner(false));
            setIsAddNewLineItemModal(true);
            setUpdate(moment());
            await periodObservance({ taskData, slug: TASK_SLUG.BUDGET_ADD_LINE_ITEM, orderData: propData, closingTask: 0, newTabCheck: 0 }); // time  tracking - add line item task
        }
    };

    let handleAddNewLineItemResponseData = async (lineItemData) => {
        try {
            let result;
            let line_item_data = [];
            dispatch(showSpinner(true));
            lineItemData.forEach(newLineItemData => {
                newLineItemData.report_id = propData.id;
                newLineItemData.user_id = userData.user_id;
                newLineItemData.property_file_id = selectedBudgetFilesData.length ? selectedBudgetFilesData[0]?.id : propData?.property_file_id;
                newLineItemData.is_from_supplemental_info = fromSupplementalInfo;
                newLineItemData.is_inspection_order = ('isInspectionOrder' in propData) ? propData.isInspectionOrder : true;
                newLineItemData.property_id = propData.property_id;
                line_item_data.push(newLineItemData);
            });
            result = await ApiPostData("orders/create-budget-line-item", line_item_data);
            if (result.success) {
                await getIntItBudgetData(true);
                setIsAddNewLineItemModal(false);
                setUpdate(moment());
                toast["success"]("Data saved successfully.");
            }
            dispatch(showSpinner(false));
        } catch (errors) {
            setIsAddNewLineItemModal(true);
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message)
            await sendExceptionEmail(errors, { memberCode: 401, componentName: 'BudgetReview', functionName: 'handleAddNewLineItemResponseData', data: {} });
            dispatch(showSpinner(false));
        }

    };

    let handleAddNewLineItemPopupStateData = (flag) => {
        setIsAddNewLineItemModal(flag);
        setUpdate(moment());
    }

    let handleEditLineItemResponseData = (updatedLineItemData) => {
        let tempBudgetdata = budgetData;
        for (const budget of tempBudgetdata) {
            if (budget.id === updatedLineItemData.id) {
                budget.line_item_name = updatedLineItemData.line_item_name;
                budget.description = updatedLineItemData.description;
                budget.category = updatedLineItemData.category;
                budget.quantity = updatedLineItemData.quantity;
                budget.price = updatedLineItemData.price;
                budget.cost_amount = updatedLineItemData.cost_amount;
            }
        }
        setBudgetData(tempBudgetdata);
        set_totals_and_data(tempBudgetdata);
        setIsEditLineItemModal(false);
    };

    let onDropDownChangeHandle = async (e, row) => {
        try {
            if (e.length <= 3) {
                let index = categoryIds.findIndex(x => x === row.id);
                if (index === -1) {
                    let tempCat = categoryIds;
                    let tempCategoryIds = [...tempCat, row.id]
                    tempCategoryIds = getUniqueList(tempCategoryIds);
                    setCategoryIds(tempCategoryIds);
                }
                if (e.length === 0) {
                    categoryIds.splice(index, 1);
                    categoryIds = getUniqueList(categoryIds);
                    setCategoryIds(categoryIds);
                }
                let selectedBudgetLine = budgetData.find(record => record.id === row.id);
                if (selectedBudgetLine) {
                    dispatch(showSpinner(true));
                    let postLineData = {
                        user_id: userData.user_id,
                        id: selectedBudgetLine.id,
                        category: e
                    }
                    selectedBudgetLine.category = e;
                    await PostData("orders/update-budget-line-item-category", postLineData)
                    dispatch(showSpinner(false));
                }

                setUpdate(moment());
                handleFocusOut();
                await periodObservance({ taskData, slug: TASK_SLUG.BUDGET_SELECT_CATEGORY, orderData: propData, closingTask: 0, newTabCheck: 0 }); // time  tracking - select category task
            } else toast["error"]("Max 3 categories allowed!")
        } catch (errors) {
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message)
            await sendExceptionEmail(errors, { memberCode: 401, componentName: 'BudgetReview', functionName: 'onDropDownChangeHandle', data: {} });
            dispatch(showSpinner(false));
        }
    };

    let onKeyDownAddNewCategory = async (e, row) => {
        if ((e.key).toLowerCase() === "tab") {
            if ((row.category).length < 3) {
                let categoryFlag = category.includes(x => x !== newCategoryInput);
                if (categoryFlag === false) {
                    let tmpCategory = [...row.category, (newCategoryInput).trim()]
                    row.category = tmpCategory;
                    let categoryTemp = [...category, newCategoryInput.trim()]
                    setCategory(categoryTemp);
                    await onDropDownChangeHandle(tmpCategory, row);
                    setKey(key + 1);
                    setUpdate(moment());
                }
            }
        }
    };
    let onInputChangeAddNewCategory = (e, row) => {
        let index = category.findIndex(x => x === e);
        if (index === -1) {
            setNewCategoryInput(e);
        }
    };

    const handleFocus = (e, row) => {
        let openDropDownRow = [row.id];
        setIsDropdownOpen(openDropDownRow);
        setUpdate(moment());
    };

    const handleFocusOut = () => {
        setIsDropdownOpen([]);
        setUpdate(moment());
    };

    let dropDown = (cell, row) => {
        let dropDownSelectedCategory = row.category;
        return (
            <><Row>
                <Col className="text-left">
                    <div className="my-typeahead">
                        <Typeahead
                            multiple={true}
                            id="typeahead"
                            key={key}
                            options={category}
                            value={newCategoryInput}
                            inputText={newCategoryInput}
                            selected={dropDownSelectedCategory}
                            placeholder="Select Categories"
                            onChange={async (e) => await onDropDownChangeHandle(e, row)}
                            onKeyDown={async (e) => await onKeyDownAddNewCategory(e, row)}
                            onInputChange={(e) => onInputChangeAddNewCategory(e, row)}
                            onFocus={(e) => handleFocus(e, row)}
                            onBlur={handleFocusOut}
                            open={isDropdownOpen.length > 0 && isDropdownOpen[0] === row.id ? true : false}
                            emptyLabel={< div style={{ fontSize: 14, whiteSpace: "normal" }}>Type new category and hit tab to add new category.</div>}
                        />
                    </div>
                </Col>
            </Row>
            </>
        );
    };

    const getSubmittedData = (response) => {
        setIsBudgetAnalysisModal(response.submitted ? false : true);
    }

    const trstyle = { border: "blue 2px solid", backgroundClip: "border-box", borderRadius: "50px 50px" };
    let setTrStyle = (cell) => {
        try {
            if (!fromSupplementalInfo) {
                let categoryIndex = (categoryIds.findIndex(x => x === cell.id))
                if ((!([...excludeIds, ...analyzeLineItem].includes(cell.id))) || (categoryIndex === -1)) {
                    if ((categoryIndex === -1)) {
                        if (excludeIds.findIndex(x => x === cell.id) === -1) {
                            return trstyle;
                        } else {
                            return {}
                        }
                    } else {
                        return trstyle;
                    }
                } else {
                    return {};
                }
            }
        } catch (error) { }
    };

    let capitalize = (cell, row) => {
        let str = row.line_item_name;
        if (str) {
            return str
                .toLowerCase()
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        }
        return null;
    };

    let onLineItemUsageChange = async (e) => {
        if (useLineItem === false) {
            setUseLineItem(!useLineItem);
            set_totals_and_data(null, { useLineItem: !useLineItem });
            await periodObservance({ taskData, slug: TASK_SLUG.BUDGET_ADJUST_ALLOCATION, orderData: propData, closingTask: 0, newTabCheck: 0 });
        } else {
            setUseLineItem(!useLineItem);
            await periodObservance({ taskData, slug: TASK_SLUG.BUDGET_BYPASS_ALLOCATION, orderData: propData, closingTask: 0, newTabCheck: 0 });
            if (propData && propData.budget_analysis) {
                propData.budget_analysis.bypass_line_item = 0;
                setDeferredMaintenance(propData.budget_analysis.deferred_maintenance);
                setDeferredMaintenanceTotal(propData.budget_analysis.deferred_maintenance);

                setHeavyRepairs(propData.budget_analysis.heavy_repairs);
                setHeavyRepairsTotal(propData.budget_analysis.heavy_repairs);

                setReconfigurationAdditions(propData.budget_analysis.reconfiguration_additions);
                setReconfigurationAdditionsTotal(propData.budget_analysis.reconfiguration_additions);

                setUpgrades(propData.budget_analysis.upgrades);
                setUpgradesTotal(propData.budget_analysis.upgrades);

                setSoftCosts(propData.budget_analysis.soft_costs);
                setSoftCostsTotal(propData.budget_analysis.soft_costs);

                setTotalBudget(propData.budget_analysis.total_budget);
            }
        }
        setUpdate(moment());
    };

    total_budget = parseFloat(heavyRepairsTotal !== 0 && heavyRepairsTotal !== null ? heavyRepairsTotal : 0) + parseFloat(deferredMaintenanceTotal !== 0 && deferredMaintenanceTotal !== null ? deferredMaintenanceTotal : 0) + parseFloat(upgradesTotal !== 0 && upgradesTotal !== null ? upgradesTotal : 0) + parseFloat(reconfigurationAdditionsTotal !== 0 && reconfigurationAdditionsTotal !== null ? reconfigurationAdditionsTotal : 0) + parseFloat(softCostsTotal ? softCostsTotal : 0);

    let columns = [
        { title: "I/E", width: "5%", dataFormat: (cell, row) => excludeCheckbox(cell, row, "excludeBudget"), hasTooltip: true, tooltipText: "Include / Exclude" },
        { title: "Line Item", width: "18%", dataFormat: (cell, row) => capitalize(cell, row), align: "right" },
        { title: "Description", width: "17%", dataField: "description", align: "right" },
        { title: "Category", width: "21%", dataFormat: (cell, row) => dropDown(cell, row) },
        { title: "Quantity", width: "7%", dataField: "quantity", align: "right" },
        { title: "Price", width: "7%", isKey: true, dataField: "price", align: "right" },
        { title: "Cost", width: "8%", dataFormat: (cell, row) => priceNumber(row.cost_amount, 0), align: "right" }
    ];
    if (!fromSupplementalInfo) {
        columns.push(
            { title: checkboxAll("H"), width: "3%", dataFormat: (cell, row) => checkbox(cell, row, "heavyRepairsBudget"), align: "center", hasTooltip: true, tooltipText: "Heavy Repairs" },
            { title: checkboxAll("D"), width: "3%", dataFormat: (cell, row) => checkbox(cell, row, "deferredMaintenanceBudget"), align: "center", hasTooltip: true, tooltipText: "Deferred Mainenance" },
            { title: checkboxAll("U"), width: "3%", dataFormat: (cell, row) => checkbox(cell, row, "upgradesBudget"), align: "center", hasTooltip: true, tooltipText: "Upgrades" },
            { title: checkboxAll("VA"), width: "3%", dataFormat: (cell, row) => checkbox(cell, row, "valueAddBudget"), align: "center", hasTooltip: true, tooltipText: "Value Add / Reconfigurations" },
            { title: checkboxAll("SC"), width: "3%", dataFormat: (cell, row) => checkbox(cell, row, "softCostsBudget"), align: "center", hasTooltip: true, tooltipText: "Soft Costs" }
        )
    }
    columns.push({ title: `Edit`, width: "5%", dataFormat: (cell, row) => editButton(cell, row) })
    const filterTableData = (data) => {
        return data.filter(row => row.line_item_name !== 'total');
    };

    const tableParams = {
        tableData: filterTableData(budgetData),
        columns,
        trStyle: (cell) => setTrStyle(cell),
        extraClassName: "budget-data"
    };


    const isAnalyzeButtonDisabled = () => {
        if (budgetData.length !== analyzeLineItem.length + excludeIds.length) {
            return true;
        }
        const countEmptyCategories = budgetData.filter(item => item.category.length === 0 && item.exclude === 0).length;
        return countEmptyCategories ? true : false;
    };

    const getUploadStatus = async (data) => {
        if (data.success) {
            await getIntItBudgetData(true, false);
        }
    }


    return (
        <>
            {/* <Spinner isShow={isProcess} /> */}
            <div className="p-1 budget-section">
                <AvForm>
                    <div>
                        <CardBody className="budget-line-data">
                            {!propData.budget_file &&
                                <h2 className="text-center w-100">No Budget File Available</h2>
                            }
                            <Row>
                                {!fromSupplementalInfo &&
                                    <Col className="col-md-4 text-left">
                                        <>
                                            <table className="subject-street table table-striped text-center">
                                                <thead >
                                                    <tr>
                                                        <th className="text-center"><b>Bypass Line Item</b></th>
                                                        <th className="text-center">
                                                            <div className="map-toggle" style={{ width: "30px" }}>
                                                                <label className="toggle-switch" id="display_comps" style={{ width: "30px" }}>
                                                                    <input type="checkbox" checked={useLineItem} onChange={e => onLineItemUsageChange(e)} />
                                                                    <span className="switch" style={{ width: "50px" }} />
                                                                </label>
                                                            </div>
                                                        </th>
                                                        <th className="text-left"><b>Use Line Item</b></th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </>
                                        <div className="budget-review-scrollbar">
                                            <div className={`uploaded-docs-col ${isExpanded ? 'expanded' : ''}`}>
                                                <div className="upload-files-budget-review">
                                                    <Row className="mr-0">
                                                        <div className="col-md-12">
                                                            <div className={`col-md-8 float-left toggle-button ${isExpanded ? 'expanded' : ''}`} onClick={toggleExpand}>
                                                                <b>Select Budget File </b><i className={`fa ${isExpanded ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                                                            </div>
                                                            <div className="col-md-4 document-upload-div">
                                                                <DocumentsUploadModal propData={{ property_id: propData.property_id, report_id: propData.id, user_id: userData.user_id, order_status: propData.order_status, documents: "budgets" }} uploadCallback={getUploadStatus} className="text-right" />
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </div>

                                                <div className="content" style={{ height: isExpanded ? '0px' : '150px', overflowY: 'scroll' }}>
                                                    {uploadedFiles.length ? (
                                                        uploadedFiles.map((fileRow, i) => (
                                                            <Col key={i} lg="12" className="pl-3 p-1">
                                                                <div className="custom-checkbox text-left" name={i}>
                                                                    <input type="checkbox" id={i} onChange={(e) => selectBudgetFiles(e, fileRow)} className="custom-checkbox" align="left" checked={fileRow.qc_verified} />
                                                                    <i className={fileRow.iClassName + " pl-2"}></i>
                                                                    <div className="cursor-pointer" style={{ display: 'inline-block', marginLeft: '10px', verticalAlign: 'super' }} onClick={(e) => {
                                                                        e.stopPropagation(); // Stop event propagation
                                                                        openInNewTab(fileRow.location);
                                                                    }}>
                                                                        {moment(fileRow.uploaded).format("MMM DD, YYYY")} &nbsp;
                                                                    </div>
                                                                    <a className="font-weight-bolder" style={{ verticalAlign: 'super' }} href={fileRow.highlighted_property_files[0]?.highlighted_location} target="_blank" rel="noreferrer"
                                                                        onClick={async () => await periodObservance({
                                                                            taskData,
                                                                            slug: TASK_SLUG.BUDGET_VIEW_HIGHLIGHTED_BUDGET,
                                                                            orderData: propData,
                                                                            userData,
                                                                            closingTask: 0,
                                                                            newTabCheck: 0
                                                                        })}>View Highlighted Budget</a>
                                                                    {fileRow.qc_verified &&
                                                                        <>
                                                                            <div id={`percent_captured_${fileRow.id}`} style={{ display: 'inline-block', marginLeft: '10px', verticalAlign: 'super' }}>
                                                                                <Button color="primary" outline className="btn-sm p-1 ml-1" style={{ verticalAlign: 'super' }} onClick={async (e) => await retriggerBudget(fileRow.id)} disabled={fileRow.disabled || (fileRow.percent_captured && (fileRow.percent_captured > 90 || (fileRow.budget_status !== 'Completed' && fileRow.budget_status !== 'Cancelled By System'))) ? true : false}>
                                                                                    <span className="btn-inner--text">{fileRow.budget_status === 'Completed' ? 'Retrigger' : (fileRow.budget_status === 'Inprogress' || fileRow.budget_status === 'Pending' ? 'Inprogress' : (fileRow.budget_status === 'Cancelled By System' ? 'Failed - Retrigger' : 'Retrigger'))}</span>
                                                                                </Button>
                                                                            </div>
                                                                            <UncontrolledTooltip delay={0} placement="auto" target={`percent_captured_${fileRow.id}`} >Percent Captured : {`${fileRow.percent_captured ? fileRow.percent_captured : 0}`}%</UncontrolledTooltip>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </Col>
                                                        ))
                                                    ) : (
                                                        <Col lg="12 pl-3 p-1" className="text-center text-muted"> No budget files available!</Col>
                                                    )}
                                                </div>
                                            </div>
                                            <>
                                                <p><b>Please review the budget and enter the values below</b></p>
                                                <table className="subject-street table table-striped text-center">
                                                    <thead >
                                                        <tr>

                                                            <th>Proposed Budget Amt</th>
                                                            <th >Enter Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <Label>Heavy Repairs</Label>
                                                            </td>
                                                            <td>
                                                                <AvGroup>
                                                                    <AvField
                                                                        name="heavy_repairs"
                                                                        onChange={(event) => handleInput(event)}
                                                                        className="form-control-alternative form-control"
                                                                        placeholder="Enter Amount"
                                                                        type="text"
                                                                        value={parseFloat(heavyRepairs) > 0 ? parseFloat(parseFloat(heavyRepairs).toFixed(2)) : "0"}
                                                                        disabled={useLineItem === true ? true : false}
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: "This field is required."
                                                                            },
                                                                            pattern: {
                                                                                value: /^[0-9]+(\.[0-9]+)?(\+[0-9]+(\.[0-9]+)?)*$/,
                                                                                errorMessage: "Invalid format. Only numbers, decimals, and '+' signs between them are allowed."
                                                                            },
                                                                        }}
                                                                    />
                                                                    {customError.includes('heavy_repairs') &&
                                                                        <div className="form-error">Invalid format.</div>
                                                                    }
                                                                </AvGroup>
                                                            </td>
                                                            <td className="total-budget-td"><span>=</span></td>
                                                            <td className="total-budget-td"><b>{formatNumber(heavyRepairsTotal)}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Label>Deferred Maintenance </Label>
                                                            </td>
                                                            <td>
                                                                <AvGroup>
                                                                    <AvField
                                                                        name="deferred_maintenance"
                                                                        onChange={(event) => handleInput(event)}
                                                                        className="form-control-alternative form-control"
                                                                        placeholder="Enter Amount"
                                                                        type="text"
                                                                        disabled={useLineItem === true ? true : false}
                                                                        value={parseFloat(deferredMaintenance) > 0 ? parseFloat(parseFloat(deferredMaintenance).toFixed(2)) : "0"}
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: "This field is required."
                                                                            },
                                                                            pattern: {
                                                                                value: /^[0-9]+(\.[0-9]+)?(\+[0-9]+(\.[0-9]+)?)*$/,
                                                                                errorMessage: "Invalid format. Only numbers, decimals, and '+' signs between them are allowed."
                                                                            },
                                                                        }}
                                                                    />
                                                                    {customError.includes('deferred_maintenance') &&
                                                                        <div className="form-error">Invalid format.</div>
                                                                    }
                                                                </AvGroup>
                                                            </td>
                                                            <td className="total-budget-td"><span>=</span></td>
                                                            <td className="total-budget-td"><b>{formatNumber(deferredMaintenanceTotal)}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Label>Upgrades </Label>
                                                            </td>
                                                            <td>
                                                                <AvGroup>
                                                                    <AvField
                                                                        name="upgrades"
                                                                        onChange={(event) => handleInput(event)}
                                                                        className="form-control-alternative form-control"
                                                                        placeholder="Enter Amount"
                                                                        type="text"
                                                                        disabled={useLineItem === true ? true : false}
                                                                        value={parseFloat(upgrades) > 0 ? parseFloat(parseFloat(upgrades).toFixed(2)) : "0"}
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: "This field is required."
                                                                            },
                                                                            pattern: {
                                                                                value: /^[0-9]+(\.[0-9]+)?(\+[0-9]+(\.[0-9]+)?)*$/,
                                                                                errorMessage: "Invalid format. Only numbers, decimals, and '+' signs between them are allowed."
                                                                            },
                                                                        }}
                                                                    />
                                                                    {customError.includes('upgrades') &&
                                                                        <div className="form-error">Invalid format.</div>
                                                                    }
                                                                </AvGroup>
                                                            </td>
                                                            <td className="total-budget-td"><span>=</span></td>
                                                            <td className="total-budget-td"><b>{formatNumber(upgradesTotal)}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Label>Reconfiguration / Additions</Label>
                                                            </td>
                                                            <td>
                                                                <AvGroup>
                                                                    <AvField
                                                                        name="reconfiguration_additions"
                                                                        onChange={(event) => handleInput(event)}
                                                                        className="form-control-alternative form-control"
                                                                        placeholder="Enter Amount"
                                                                        type="text"
                                                                        disabled={useLineItem === true ? true : false}
                                                                        value={parseFloat(reconfigurationAdditions) > 0 ? parseFloat(parseFloat(reconfigurationAdditions).toFixed(2)) : "0"}
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: "This field is required."
                                                                            },
                                                                            pattern: {
                                                                                value: /^[0-9]+(\.[0-9]+)?(\+[0-9]+(\.[0-9]+)?)*$/,
                                                                                errorMessage: "Invalid format. Only numbers, decimals, and '+' signs between them are allowed."
                                                                            },
                                                                        }}
                                                                    />
                                                                    {customError.includes('reconfiguration_additions') &&
                                                                        <div className="form-error">Invalid format.</div>
                                                                    }
                                                                </AvGroup>
                                                            </td>
                                                            <td className="total-budget-td"><span>=</span></td>
                                                            <td className="total-budget-td"><b>{formatNumber(reconfigurationAdditionsTotal)}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Label>Soft Costs</Label>
                                                            </td>
                                                            <td>
                                                                <AvGroup>
                                                                    <AvField
                                                                        name="soft_costs"
                                                                        onChange={(event) => handleInput(event)}
                                                                        className="form-control-alternative form-control"
                                                                        placeholder="Enter Amount"
                                                                        type="text"
                                                                        disabled={useLineItem === true ? true : false}
                                                                        value={parseFloat(softCosts) > 0 ? parseFloat(parseFloat(softCosts).toFixed(2)) : "0"}
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: "This field is required."
                                                                            },
                                                                            pattern: {
                                                                                value: /^[0-9]+(\.[0-9]+)?(\+[0-9]+(\.[0-9]+)?)*$/,
                                                                                errorMessage: "Invalid format. Only numbers, decimals, and '+' signs between them are allowed."
                                                                            },
                                                                        }}
                                                                    />
                                                                    {customError.includes('soft_costs') &&
                                                                        <div className="form-error">Invalid format.</div>
                                                                    }
                                                                </AvGroup>
                                                            </td>
                                                            <td className="total-budget-td"><span>=</span></td>
                                                            <td className="total-budget-td"><b>{formatNumber(softCostsTotal)}</b></td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Label>Total Budget</Label>
                                                            </td>
                                                            <td>
                                                                <AvGroup>
                                                                    <AvField
                                                                        disabled
                                                                        name="total_budget"
                                                                        className="form-control-alternative form-control"
                                                                        type="text"
                                                                        value={priceNumber(total_budget)}
                                                                    />
                                                                    <span>&nbsp;</span>
                                                                </AvGroup>
                                                            </td>
                                                            <td><span>&nbsp;</span></td>
                                                            <td><span>&nbsp;</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Label>Target Condition</Label>
                                                            </td>
                                                            <td>
                                                                <AvGroup>
                                                                    <AvField
                                                                        type="select"
                                                                        name="target_condition"
                                                                        onChange={(event) => handleInput(event)}
                                                                        value={propData.arv_user_value ? propData.arv_user_value.BorrowerTargetCondition : ""}
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: "This field is required."
                                                                            }
                                                                        }}>
                                                                        <option value="">Select Target Condition</option>
                                                                        <option value="1">As Is</option>
                                                                        {parseInt(propData.condition_id) === 1 ?
                                                                            <>
                                                                                <option value="2">Low</option>
                                                                                <option value="3">Med</option>
                                                                                <option value="4">High</option>
                                                                            </>
                                                                            : <>
                                                                                <option value="2">Min</option>
                                                                                <option value="3">Partial</option>
                                                                                <option value="4">Full</option>
                                                                                <option value="5">Value Add</option>
                                                                            </>
                                                                        }
                                                                    </AvField>
                                                                </AvGroup>
                                                            </td>
                                                            <td><span>&nbsp;</span></td>
                                                            <td><span>&nbsp;</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <Label>Target Quality</Label>
                                                            </td>
                                                            <td>
                                                                <AvGroup>
                                                                    <AvField type="select" name="target_quality" onChange={(event) => handleInput(event)} value={targetQualityGrade}
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                                errorMessage: "This field is required."
                                                                            }
                                                                        }}>
                                                                        <option value="">Unknown</option>
                                                                        {propData.building_quality_apex_ratings && propData.building_quality_apex_ratings.map(function (data, i) {
                                                                            return (
                                                                                <option key={i} value={data.quality_rating}>{data.apex_quality_grades + ' ' + data.description}</option>

                                                                            );
                                                                        })}
                                                                    </AvField>
                                                                </AvGroup>
                                                            </td>
                                                            <td><span>&nbsp;</span></td>
                                                            <td><span>&nbsp;</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2"> &nbsp;</td>
                                                        </tr>
                                                        <tr className="arv_data">
                                                            <td>
                                                                <Label>Min Rehab</Label>
                                                            </td>
                                                            <td>
                                                                {parseInt(propData?.subject_property?.condition_rating) !== propData?.condition_id ? <span>TBD</span> : <span>${number_format(propData?.subject_property ? propData?.subject_property?.min_rehab : 0)}</span>}
                                                            </td>
                                                            <td><span>&nbsp;</span></td>
                                                            <td><span>&nbsp;</span></td>
                                                        </tr>
                                                        <tr className="arv_data">
                                                            <td>
                                                                <Label>Partial Rehab</Label>
                                                            </td>
                                                            <td>
                                                                {parseInt(propData?.subject_property?.condition_rating) !== propData?.condition_id ? <span>TBD</span> : <span>${number_format(propData?.subject_property ? propData?.subject_property?.partial_rehab : 0)}</span>}
                                                            </td>
                                                            <td><span>&nbsp;</span></td>
                                                            <td><span>&nbsp;</span></td>
                                                        </tr>
                                                        <tr className="arv_data">
                                                            <td>
                                                                <Label>Full Rehab</Label>
                                                            </td>
                                                            <td>
                                                                {parseInt(propData?.subject_property?.condition_rating) !== propData?.condition_id ? <span>TBD</span> : <span>${number_format(propData?.subject_property ? propData?.subject_property.full_rehab : 0)}</span>}
                                                            </td>
                                                            <td><span>&nbsp;</span></td>
                                                            <td><span>&nbsp;</span></td>
                                                        </tr>
                                                        <tr className="arv_data">
                                                            <td>
                                                                <Label>Value Add Rehab</Label>
                                                            </td>
                                                            <td>
                                                                {parseInt(propData?.subject_property?.condition_rating) !== propData?.condition_id ? <span>TBD</span> : <span>${number_format(propData?.subject_property ? propData?.subject_property?.valueadd_rehab : 0)}</span>}
                                                            </td>
                                                            <td><span>&nbsp;</span></td>
                                                            <td><span>&nbsp;</span></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </>

                                        </div>
                                    </Col>
                                }

                                <Col className={`col-md-${!fromSupplementalInfo ? 8 : 12} text-${!fromSupplementalInfo ? 'right' : 'center'}`}>
                                    {useLineItem ?
                                        <>
                                            <Row className={`mb-${fromSupplementalInfo ? 2 : 0}`}>
                                                <Col className="col-md-3 text-center">
                                                    <span><b>Stated Total : {priceNumber(statedTotal)}</b></span>
                                                </Col>

                                                <Col className="col-md-3 text-right">
                                                    <span><b>OCR Total : {priceNumber(ocrTotal)}</b></span>
                                                </Col>

                                                <Col className="col-md-3 text-right">
                                                    <span><b>Total Captured : {priceNumber(capturedTotal)}</b></span>
                                                </Col>
                                                {QACompleted && (!fromSupplementalInfo) &&
                                                    <>
                                                        <Col className="col-md-3 text-center budget-exhibit">
                                                            <Button type="button" id="random_id_here" className="btn btn-sm" color={(!QACompleted) ? "danger" : "success"}>
                                                                <i className={(!QACompleted) ? "fa fa-times red" : "fa fa-check green"} color={(!QACompleted) ? "danger" : "success"}></i>
                                                            </Button > <b>QA</b>
                                                        </Col>
                                                    </>
                                                }
                                                {!QACompleted &&
                                                    <>
                                                        <Col className="col-md-3 text-center budget-exhibit">
                                                            <Button type="button" id="random_id_here" outline className="btn btn-sm" color={(statedTotal !== capturedTotal) ? "danger" : "success"}>
                                                                <i className={(statedTotal !== capturedTotal) ? "fa fa-times red" : "fa fa-check green"} color={(statedTotal !== capturedTotal) ? "danger" : "success"}></i>
                                                            </Button > <b>Total Matched</b>
                                                        </Col>
                                                    </>
                                                }
                                                {!fromSupplementalInfo &&
                                                    <>
                                                        <div className="budget-exhibit">
                                                            <div className="text-left m-2" height="0px">
                                                                <input height="0" type="checkbox" readOnly={true} checked={useLineItem} /><span className="height-0 !important m-2"><b>Use our line items for the Budget exhibit in the report</b></span>
                                                            </div>
                                                        </div>
                                                    </>
                                                }
                                            </Row>
                                            {loading && <p>Your data is being loaded. Please wait...</p>}
                                            {showData ?
                                                <div className='budget-review-scrollbar'>
                                                    <div>
                                                        {(!fromSupplementalInfo) && isBudgetData !== true && selectedBudgetFilesData && selectedBudgetFilesData.length === 0 &&
                                                            <div className="text-center m-2" height="0px">
                                                                <h2>Please select budget file(s) for analysis.</h2>
                                                            </div>
                                                        }
                                                        {propData.budget_file &&
                                                            <>
                                                                {(!fromSupplementalInfo) && isBudgetData !== true && selectedBudgetFilesData && selectedBudgetFilesData.length > 0 &&
                                                                    <>
                                                                        <RenderBootstrapTable tableParams={tableParams} pagination={false} />
                                                                        <br /><br />
                                                                    </>
                                                                }
                                                                {fromSupplementalInfo &&
                                                                    <>
                                                                        <RenderBootstrapTable tableParams={tableParams} pagination={false} />
                                                                        <br /><br />
                                                                        <Row>
                                                                            <Col className={`col-md-7 text-right} `}>
                                                                                <a className="font-weight-bolder" href={propData.budget_file} target="_blank" rel="noreferrer">View Original Budget</a>
                                                                            </Col>
                                                                            {selectedBudgetFilesData[0]?.highlighted_property_files[0]?.highlighted_location &&
                                                                                <Col className={`col-md-3 text-left`} >
                                                                                    <a className="font-weight-bolder" href={selectedBudgetFilesData[0]?.highlighted_property_files[0]?.highlighted_location} target="_blank" rel="noreferrer">View Highlighted Budget</a>
                                                                                </Col>
                                                                            }
                                                                        </Row>
                                                                    </>
                                                                }
                                                                {isBudgetData !== false && propData.budget_file.includes(".pdf") &&
                                                                    < iframe title="budget" id="budget" src={propData.budget_file} width="100%" height="500px"></iframe>
                                                                }
                                                                {isBudgetData !== false && propData.budget_file && propData.budget_file.includes(".xls") &&
                                                                    < th className="col-md-1 text-center" colSpan="12">
                                                                        <br /><br />
                                                                        <div className="text-center pr-4">
                                                                            <a className="font-weight-bolder" href={propData.budget_file} target="_blank" rel="noreferrer">View Original Budget</a>
                                                                        </div>
                                                                    </th>
                                                                }
                                                                {budgetData && isBudgetAnalysisModal === true &&
                                                                    <>
                                                                        {/* <Spinner isShow={isProcess} /> */}
                                                                        <BudgetAnalysis propData={particularData} isOpenModel={isBudgetAnalysisModal} handleCheckbox={handleCheckboxResponse} submitCallback={getSubmittedData} />
                                                                    </>
                                                                }
                                                                {budgetData && isAddNewLineItemModal === true &&
                                                                    <>
                                                                        {/* <Spinner isShow={isProcess} /> */}
                                                                        <BudgetAddNewLineItem propData={propData} handleResponseData={handleAddNewLineItemResponseData} handleAddNewLineItemPopupState={handleAddNewLineItemPopupStateData} isOpenAddNewLineItemModal={isAddNewLineItemModal} />
                                                                    </>
                                                                }
                                                                {budgetData && isEditLineItemModal === true &&
                                                                    <>
                                                                        <BudgetLineItemEditModal propData={dataForEdit} handleEditLineItem={handleEditLineItemResponseData} isOpenEditLineItemModal={isEditLineItemModal} />
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                                </div> : null
                                            }
                                        </>
                                        :
                                        <>
                                            {!fromSupplementalInfo &&
                                                <div className="text-center m-2" height="0px">
                                                    <h2>Please select <b>USE LINE ITEM</b> option to see the line item(s) data.</h2>
                                                </div>
                                            }
                                        </>
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                    </div>
                    <CardFooter>
                        <Row>
                            {selectedBudgetFilesData && selectedBudgetFilesData.length && (!fromSupplementalInfo) ?
                                <Col className="text-left">
                                    <Button color="info" onClick={async (e) => addLineItem(e)}>
                                        <span className="btn-inner--text">+ Add Line Item</span>
                                    </Button>
                                </Col> :
                                <>
                                    {fromSupplementalInfo === true &&
                                        < Col className="text-left">
                                            <Button color="info" onClick={async (e) => addLineItem(e)}>
                                                <span className="btn-inner--text">+ Add Line Item</span>
                                            </Button>
                                        </Col>
                                    }
                                </>
                            }
                            <Col className="text-right justify-content-between">
                                <ReviewerFlags propData={memoizedPropData} />
                                <Button color="danger" outline onClick={() => handleStep("cancel")}>
                                    Cancel Assignment
                                </Button>
                                {fromReviseTab === false && fromSupplementalInfo === false &&
                                    <>
                                        <Button color="default" outline onClick={() => onHandleBudgetReviewResponse(3, "Subject Property Condition")}>
                                            Back
                                        </Button>
                                    </>
                                }
                                {!fromSupplementalInfo &&
                                    <>
                                        {budgetData && budgetData.length > 0 && useLineItem === true &&
                                            <Button type="button" color="info" disabled={isAnalyzeButtonDisabled()} onClick={(e) => handleSubmit(true, e)}>
                                                <span className="btn-inner--text">Analyze</span>
                                            </Button>
                                        }
                                        {isBudgetData !== false &&
                                            <Button type="button" color="info"><span className="btn-inner--text" onClick={(e) => handleSubmit(true, e)}>Analyze</span></Button>
                                        }
                                        {useLineItem === false && isBudgetData === false &&
                                            <Button type="button" color="info"><span className="btn-inner--text" onClick={(e) => handleSubmit(true, e)}>Analyze</span></Button>
                                        }
                                    </>
                                }
                                {fromSupplementalInfo &&
                                    <Button color="info" type="button"><span className="btn-inner--text" onClick={(e) => handleBudgetFinalData(e)}>Finalize</span></Button>
                                }
                            </Col>
                        </Row>
                        <Row>
                            {useLineItem === true && budgetData && (((budgetData.length)) !== (analyzeLineItem.length + excludeIds.length) ? true : budgetData.length !== (categoryIds.length + excludeIds.length) ? true : false) && (!fromSupplementalInfo) &&
                                < Col className="text-right">
                                    <div className="form-error text-right">*To activate Analyze button, please select at least one budget category per line item AND select at least one class per line item (Heavy, Deferred Maintenance, Update, Value Add.). Alternatively, you may exclude an erroneous line item which you do not wish to include in the budget analysis.</div>
                                </Col>
                            }
                        </Row>
                    </CardFooter>
                </AvForm>
                <Modal className="modal-dialog-centered modal-xl compensation-modal" style={{ maxWidth: "500px" }} isOpen={isModal} toggle={() => setIsModal(!isModal)}>
                    <CardBody className="pb-0">
                        <Row className="mb-2">
                            <Col className="">
                                <span><b>Valuation Comments or Instructions</b>: {showMessage}</span>
                            </Col>
                        </Row>

                    </CardBody>
                    <CardFooter className="py-4">
                        <Button color="primary" onClick={() => setIsModal(false)}>
                            Go Back
                        </Button>
                        <Button onClick={(e) => handleSubmit(false, e)}>
                            Continue
                        </Button>
                    </CardFooter>
                </Modal>
            </div>
        </>
    );
};
export default BudgetReview;