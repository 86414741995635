import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row, Progress } from "reactstrap";
import SubCategoriesChart from "./SubCategoriesChart";
import SubjectPropertyStatistics from "./SubjectPropertyStatistics";
import RecipientFlagsTable from "../Common/ReviewerFlags/recipientFlagsTable";
import LatitudeLongitude from "./LatitudeLongitude";
import SubjectCondition from "./SubjectCondition";
import CompConditions from "./CompConditions";
import TargetCondition from "./TargetCondition";
import { useParams } from 'react-router';
import { PostData } from "../../../services/PostData";
import Spinner from "components/Common/Spinner.js";
import Discrepancies from "./Discrepancies";
import { getLabelName, getColorCode } from "./AnalysisComparisonHelper";
import CompSelection from "./CompSelection";
import Values from "./Values";
import RehabCurve from "./RehabCurve";
import { formatDate } from "components/Common/Helpers";
import Commentary from "./Commentary";
import { Link } from "react-router-dom";

const Overview = ({ onData }) => {
    const [isProcess, setProcess] = useState(false);
    const { report_id, amts_id } = useParams();
    const [isEmptyOverview, setIsEmptyOverview] = useState(false);
    const [ratingRecords, setRatingRecords] = useState([]);
    const [performanceCategories, setPerformanceCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [subCategoryData, setSubCategoryData] = useState({});
    const [totalGradeSection, setTotalGradeSection] = useState({});
    const [qualityRatings, setQualityRatings] = useState([]);
    const [otherData, setOtherData] = useState([]);

    useEffect(() => {
        if (onData) {
            handleResponseData(onData);
            getSelectedCategory({ slug: 'subject-property-stats' })
        }
        //eslint-disable-next-line   
    }, [onData]);

    let handleResponseData = (responseData) => {
        if (responseData) {
            let final_data = responseData.final_data;
            let submitted_data = responseData.submitted_data;
            let rating_records = responseData.condition_ratings;
            let performance_categories = responseData.performance_categories;
            let total_grade = parseFloat(responseData.total_grade);
            let qualityRatings = responseData.qualityRatings;
            let ratingData = responseData.comps_ratings;
            let discrepancy_values = responseData.discrepancy_values;
            let report_order_detail = responseData.report_order_detail ? responseData.report_order_detail[0] : null;
            let reportAmount = responseData.reportAmount;
            let deliver_date = report_order_detail.deliver_date;
            let propertyData = report_order_detail.property;
            let full_address = propertyData.address;
            let recipientFlags = responseData.recipientFlags || [];
            if (propertyData.address_line_2) {
                full_address += `, ${propertyData.address_line_2}`;
            }
            let propertyAddress = `${full_address}, ${propertyData.city}, ${propertyData.state}, ${propertyData.postal_code}`;
            let reportTypeData = report_order_detail.report_type;
            let submitted_date = submitted_data.created_date ? formatDate('', submitted_data.created_date, 'MMM D, YY') : "-";
            deliver_date = deliver_date ? formatDate('', deliver_date, 'MMM D, YY') : "-";
            let property_files = responseData.property_files;

            if (rating_records) {
                setRatingRecords(rating_records);
            }
            if (performance_categories) {
                setPerformanceCategories(performance_categories);
            }
            if (report_order_detail) {
                let baseUrl = process.env.REACT_APP_PHP_ENDPOINT;
                let productUrl = `${baseUrl}valuation/deals/details/${report_order_detail.property_id}`
                let analystName = `${submitted_data.submitted_by.first_name} ${submitted_data.submitted_by.last_name}`;
                let reviewerName = `${final_data.submitted_by.first_name} ${final_data.submitted_by.last_name}`;
                let submit_email = `${submitted_data.submitted_by.email}`;

                setOtherData({
                    property_id: report_order_detail.property_id,
                    company_id: report_order_detail?.company_id,
                    comps_ratings: ratingData,
                    condition_ratings: rating_records,
                    discrepancy_values,
                    reportAmount,
                    propertyAddress,
                    report_type: reportTypeData.nickname,
                    deliver_date,
                    submitted_date,
                    report_type_id: report_order_detail.report_type_id,
                    productUrl,
                    analystName,
                    reviewerName,
                    property_files,
                    final_custom_analysis_ids: final_data.custom_analysis_ids,
                    submitted_custom_analysis_ids: submitted_data.custom_analysis_ids,
                    submit_email,
                    recipientFlags
                })
            }
            setQualityRatings(qualityRatings);
            setGradeState(total_grade, qualityRatings);

            if (!submitted_data && !final_data) {
                setIsEmptyOverview(true);
            }
        }

    };

    let getSelectedCategory = (data) => {
        setSelectedCategory(data.slug);
        getSubCategoryData(data);
    }

    let getSubCategoryData = (data) => {
        let sendData = {
            category_id: data.id,
            report_id,
            amts_id,
            slug: data.slug
        };
        setProcess(true);
        PostData("performance-rating/getSubCategoryData", sendData).then(result => {
            let responseJson = result.data.data;
            if (sendData.slug === "values") {
                setSubCategoryData({ type: data.slug, data: responseJson })
            } else {
                setSubCategoryData({ type: data.slug, data: responseJson })
            }

            setProcess(false);
        });
    }
    let getTotalGrade = (data) => {
        if (data) {
            setGradeState(data.total_grade, qualityRatings);
            if (data.performance_categories) {
                setPerformanceCategories(data.performance_categories);
            }
        }
    }

    let setGradeState = (total_grade, qualityRatings) => {
        let total_grade_label = getLabelName(parseFloat(total_grade), qualityRatings);
        let colorCode = getColorCode(qualityRatings, total_grade_label);
        let gradeLabelNew = total_grade_label;
        if (gradeLabelNew.slice(gradeLabelNew.length - 1) === '+') {
            gradeLabelNew = gradeLabelNew.replace('+', '-plus');
        } else if (gradeLabelNew.slice(gradeLabelNew.length - 1) === '-') {
            gradeLabelNew = gradeLabelNew.replace('-', '-minus');
        }
        setTotalGradeSection({ total_grade_label, colorCode, clsName: gradeLabelNew.toLowerCase(), total_grade });
    }

    let handlePropertyFiles = () => {
        let output;
        if (otherData.property_files) {
            output = otherData.property_files.map(function (file, index) {
                let displayName = `${file.property_file.property_file_type.file_type_name}`;
                return (
                    <p key={index} className="mb-0">
                        <label>
                            <Link className="text-primary custom-link" to={{ pathname: file.property_file.location }} target="_blank" >
                                &nbsp;{displayName} - {formatDate('', file.property_file.report_date, 'MMM D, YY')}
                            </Link>
                        </label>
                    </p>
                )
            })
        }
        return output;
    }


    const classes = `progress-bar progress-bar-${totalGradeSection.clsName}`;
    let subCategoryChart = selectedCategory === 'values' ? 'col-lg-5 col-xl-5' : 'col-lg-5 col-xl-5';
    let subCategoryContent = selectedCategory === 'values' ? 'col-lg-7 col-xl-7' : 'col-lg-7 col-xl-7';

    return (
        <>
            <Spinner isShow={isProcess} />
            <Col lg="12" xl="12" className="pr-0 pl-0 property-detail">
                <Card className="card-stats ">
                    <CardBody className="pr-0 pl-3">
                        <Row>
                            <Col lg="6">
                                <p className="mb-0"><span className="h3 heading-label">Property Address:</span>
                                    <label>
                                        <Link className="text-primary custom-link" to={{ pathname: otherData.productUrl }} target="_blank" >
                                            &nbsp;{otherData.propertyAddress}
                                        </Link>
                                    </label>
                                </p>
                                <p className="mb-0"><span className="h3 heading-label">Analyst Name:</span> <label>{otherData.analystName}</label></p>
                                <p className="mb-0"><span className="h3 heading-label">My Analysis date:</span> <label> {otherData.submitted_date}</label></p>
                                {handlePropertyFiles()}
                                {Array.isArray(otherData.recipientFlags) && otherData.recipientFlags.length > 0 && (
                                    <>
                                        <p className="mb-0"><span className="h3 heading-label">Reviewer Flags:</span></p>
                                        <RecipientFlagsTable flagsData={otherData.recipientFlags} />
                                    </>
                                )}
                            </Col>
                            <Col lg="6">
                                <p className="mb-0"><span className="h3 heading-label">Report Type:</span> <label>{otherData.report_type}</label></p>
                                <p className="mb-0"><span className="h3 heading-label">Reviewer Name:</span> <label>{otherData.reviewerName}</label></p>
                                <p className="mb-0"><span className="h3 heading-label">Delivered date:</span> <label>{otherData.deliver_date}</label></p>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <Col lg="12" xl="12" className="pr-0 pl-0">
                {!isEmptyOverview ? (
                    <Card className="card-stats ">
                        <CardBody className="pr-0 pl-3">
                            <Row className="overview-row">
                                <Col className={subCategoryChart} style={{ paddingLeft: '4px' }} >
                                    <div style={{ width: '90%' }}>
                                        <p className="h3">Total Grade: {totalGradeSection.total_grade_label}</p>
                                        <Progress value={totalGradeSection.total_grade} color="success" className={classes} >
                                            <span>{totalGradeSection.total_grade}</span>
                                        </Progress>
                                    </div>

                                    <SubCategoriesChart onData={performanceCategories} propCategoryData={getSelectedCategory} qualityRatings={qualityRatings} />
                                </Col>
                                <Col className={subCategoryContent} >
                                    {
                                        (() => {
                                            if (selectedCategory === "subject-property-stats") {
                                                return (
                                                    <SubjectPropertyStatistics onData={subCategoryData} otherData={otherData} propWaivedResult={getTotalGrade} />
                                                )
                                            } else if (selectedCategory === "lat-long") {
                                                return (
                                                    <LatitudeLongitude onData={subCategoryData} otherData={otherData} propWaivedResult={getTotalGrade} />
                                                )
                                            } else if (selectedCategory === "subject-condition") {
                                                return (
                                                    <SubjectCondition onData={subCategoryData} ratingRecords={ratingRecords} otherData={otherData} propWaivedResult={getTotalGrade} />
                                                )
                                            } else if (selectedCategory === "condition-ratings") {
                                                return (
                                                    <CompConditions onData={subCategoryData} otherData={otherData} propWaivedResult={getTotalGrade} />
                                                )
                                            } else if (selectedCategory === "discrepancies") {
                                                return (
                                                    <Discrepancies onData={subCategoryData} otherData={otherData} propWaivedResult={getTotalGrade} />
                                                )
                                            } else if (selectedCategory === "target-condition") {
                                                return (
                                                    <TargetCondition onData={subCategoryData} ratingRecords={ratingRecords} otherData={otherData} propWaivedResult={getTotalGrade} />
                                                )
                                            } else if (selectedCategory === "comp-selection") {
                                                return (
                                                    <CompSelection onData={subCategoryData} otherData={otherData} propWaivedResult={getTotalGrade} />
                                                )
                                            } else if (selectedCategory === "values") {
                                                return (
                                                    <Values onData={subCategoryData} otherData={otherData} propWaivedResult={getTotalGrade} />
                                                )
                                            } else if (selectedCategory === "rehab-curve") {
                                                return (
                                                    <RehabCurve onData={subCategoryData} otherData={otherData} propWaivedResult={getTotalGrade} />
                                                )
                                            } else if (selectedCategory === "commentary") {
                                                return (
                                                    <Commentary onData={subCategoryData} otherData={otherData} propWaivedResult={getTotalGrade} />
                                                )
                                            } else {
                                                return (
                                                    <SubjectPropertyStatistics onData={subCategoryData} otherData={otherData} propWaivedResult={getTotalGrade} />
                                                )
                                            }
                                        })()
                                    }
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                ) : (
                    <h2 className="not-authorized-msg">Insufficient data</h2>
                )}
            </Col>
        </>
    );
};

export default Overview;