import React, { useState, useEffect } from "react";
import { Modal, Row, Col, CardBody, Button, Label } from "reactstrap";
import "./style.scss";
import axios from "axios";
import { sendExceptionEmail } from "components/Common/Helpers";
import Spinner from "components/Common/Spinner";

const Recalculate = ({ handleRecalculate, propertyId, onHandleResponse }) => {

    const [isRecalculateModal, setOpenRecalculateModal] = useState(false);
    const [isProcess, setProcess] = useState(false);

    useEffect(() => {
        if (handleRecalculate.current) {
            setOpenRecalculateModal(true);
        }
    }, [handleRecalculate.current, propertyId, onHandleResponse]);// eslint-disable-line react-hooks/exhaustive-deps

    let handleToggleModal = (state) => {
        state = !state;
        setOpenRecalculateModal(state);
        handleRecalculate.current = false;
    };

    const recalculateProperty = async () => {
        try {
            setProcess(true);
            await axios({
                url: process.env.REACT_APP_PHP_ENDPOINT + "valuation/property_calculations/recalculateBtn/" + propertyId,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            onHandleResponse(false)
            setOpenRecalculateModal(false);
            handleRecalculate.current = false;
            setProcess(false);
        } catch (error) {
            await sendExceptionEmail(error, { memberCode: 502, componentName: 'RecalculateModal', functionName: 'recalculateProperty', data: {} });
            setProcess(false);
        }
    };

    return (
        <>
            <Modal className="modal-dialog-centered resume-select-option-modal" style={{ maxWidth: "500px" }} isOpen={handleRecalculate.current} toggle={() => handleToggleModal(isRecalculateModal)}>
                <Spinner isShow={isProcess} />
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-center w-100">
                        Action required!
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isRecalculateModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <CardBody className="pb-0">
                    <Row className="mb-2">
                        <Label>
                            Please recalculate this property to continue working on this order.
                        </Label>
                        <Col className="text-center m-3">
                            <Button size="sm" color="info" title="Recalculate" outline onClick={(e) => recalculateProperty(e)}>Recalculate Now</Button>
                        </Col>
                    </Row>
                </CardBody>
            </Modal>
        </>
    );
};

export default Recalculate;