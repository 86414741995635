import { createSlice } from '@reduxjs/toolkit'

export const TrainingReducer = createSlice({
    name: 'training',
    initialState: {
        imageViewed: [],
        isCompViewed: false,
        selectedFilters: [],
        isGradeCalculated: false,
        isContinue: false,
        syncCompList: 0
    },
    reducers: {
        setImageViewed: (state, action) => {
            state.imageViewed = action.payload;
        },
        setCompViewed: (state, action) => {
            state.isCompViewed = action.payload;
        },
        setSelectedFilters: (state, action) => {
            state.selectedFilters = action.payload;
        },
        setGradeCalculated: (state, action) => {
            state.isGradeCalculated = action.payload;
        },
        setContinue: (state, action) => {
            state.isContinue = action.payload;
        },
        setSyncCompList: (state, action) => {
            state.syncCompList = Date.now();
        }

    },
})

// Action creators are generated for each case reducer function
export const { setImageViewed, setCompViewed, setSelectedFilters, setGradeCalculated, setContinue, setSyncCompList } = TrainingReducer.actions

export default TrainingReducer.reducer;