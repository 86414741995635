import { createSlice } from '@reduxjs/toolkit'

const initialStates = {
	initialDataRendered: false,

	subjectPropertyComp: {},
	compsOriginalArray: [],

	systemValuesErr: false,
	systemValuesFetching: true,
	systemValues: [],

	propertyComparisonDataErr: false,
	propertyComparisonDataFetching: true,
	propertyComparisonData: {},

	compListingHistoryErr: false,
	compListingHistoryFetching: true,
	compListingHistory: [],
};

export const ValDetailsMasterReducer = createSlice({
	name: 'ValDetails',
	initialState: { ...initialStates },
	reducers: {
		setValDetailsMasterData: (state, data) => {
			data?.payload?.forEach(element => {
				state[element.key] = element.value
			});
		},
		resetForm: (state) => {
			for (const key in { ...initialStates }) {
				if (initialStates.hasOwnProperty(key)) {
					state[key] = initialStates[key]
				}
			}
		},
	},
})

// Action creators are generated for each case reducer function
export const {
	setValDetailsMasterData,
	resetForm
} = ValDetailsMasterReducer.actions

export default ValDetailsMasterReducer.reducer