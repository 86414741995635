import React, { useState, useEffect } from "react";
import { Col, Table, Modal, Row, UncontrolledTooltip } from "reactstrap";
import { GoogleApiWrapper } from 'google-maps-react';
import { PostData } from "../../../services/PostData";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'react-tabs/style/react-tabs.css';
import { Carousel } from 'react-responsive-carousel';
import { loggedInUser } from "components/Common/Helpers.js";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getConditionRatingName } from "components/Common/Helpers";
import CompList from "../Common/CompLinks/CompList";
const userData = loggedInUser();

const ConditionRatings = (onData) => {
    let [myRatingData, setMyRatingData] = useState([]);
    let [finalRatingData, setFinalRatingData] = useState([]);
    const [isOpenDiscModal, setIsOpenDiscModal] = useState(false);
    let [carouselSlider, setCarouselSlider] = useState(null);
    let [propertyComps, setPropertyComps] = useState([]);
    let [otherSite, setOtherSite] = useState(null);
    let [google_prop, setGoogleProps] = useState(null);
    const [isEmptyRatingData, setIsEmptyRatingData] = useState(false);
    let [mapData, setMapData] = useState({ activeMarker: {}, selectedPlace: {}, showingInfoWindow: false });
    let [isLoadMap, setIsLoadMap] = useState(false);
    const [ratingRecords, setRatingRecords] = useState([]);

    let [mapStyles] = useState({ width: '100%', height: '400px' });
    let propertyCompData = {};


    useEffect(() => {
        if (onData) {
            handleConditionRating(onData);
        }
    }, [onData]);

    let handleConditionRating = (allData) => {
        let ratingData = allData.onData?.comps_ratings;
        if (ratingData && Object.keys(ratingData).length !== 0) {
            setIsEmptyRatingData(false);
            let my_comps = ratingData?.my_comps_rating ?? [];
            if (allData.onData?.comp_type === "selected") {
                let selected_comps = allData.compConditionData.selectedDifferentComps;
                if (selected_comps.length === 0) {
                    setIsEmptyRatingData(true);
                }
                let filterMyComps = []
                for (const myComp of my_comps) {
                    selected_comps.filter(function (val) {
                        if (parseInt(val) === parseInt(myComp.property_comp_id)) {
                            filterMyComps.push(myComp);
                        }
                        return filterMyComps
                    });
                }
                my_comps = filterMyComps;
            }

            if (my_comps) {
                let address = '';
                my_comps.forEach((element, key) => {
                    address = `${element.property_comp.address}, ${element.property_comp.city}, ${element.property_comp.state}, ${element.property_comp.postal_code}`;
                    my_comps[key].address = address;
                });
                setMyRatingData(my_comps);
            }
            let final_comps = ratingData?.final_comps_rating ?? [];
            if (final_comps) {
                let address = '';
                final_comps.forEach((element, key) => {
                    address = `${element.property_comp.address}, ${element.property_comp.city}, ${element.property_comp.state}, ${element.property_comp.postal_code}`;
                    final_comps[key].address = address;
                });
                setFinalRatingData(final_comps);
            }
            if (!my_comps && !final_comps) {
                setIsEmptyRatingData(true);
                return false;
            }

            if ((my_comps && my_comps.length === 0) && (final_comps && final_comps.length === 0)) {
                setIsEmptyRatingData(true);
                return false;
            }

            let rating_records = allData.onData.condition_ratings;
            if (rating_records) {
                setRatingRecords(rating_records);
            }

        } else {
            setIsEmptyRatingData(true);
        }
        setGoogleProps(allData.google);
    }

    let tableRow = myRatingData.map((val, i) => (
        <tr key={i}>
            <td onClick={(e) => toggleDiscrepancyModal(e, val)}><span role="button">{val.address}</span></td>
            <td onClick={(e) => toggleDiscrepancyModal(e, val)}>
                <span role="button" id={"myRating_" + val.id} dangerouslySetInnerHTML={{ __html: getConditionRatingName(val.condition_rating, ratingRecords) }}>
                </span>
                <UncontrolledTooltip delay={0} placement="auto" target={"myRating_" + val.id} >{getConditionRatingName(val.condition_rating, ratingRecords, false)}</UncontrolledTooltip>
            </td>
            <td onClick={(e) => toggleDiscrepancyModal(e, val)}>
                <span role="button" id={"newRating_" + val.id} dangerouslySetInnerHTML={{ __html: getConditionRatingName(finalRatingData[i]?.condition_rating ? finalRatingData[i]?.condition_rating : 0, ratingRecords) }}></span>
                <UncontrolledTooltip delay={0} placement="auto" target={"newRating_" + val.id} >{getConditionRatingName(finalRatingData[i]?.condition_rating ? finalRatingData[i]?.condition_rating : 0, ratingRecords, false)}</UncontrolledTooltip>
            </td>
        </tr>
    ));

    /*
    | Handle Modal
    */
    let toggleDiscrepancyModal = (e, row) => {
        let newState = !isOpenDiscModal;
        if (newState) {
            let postData = { property_comp_id: row.property_comp_id };
            PostData("analysis-comparison/getCompDetails", postData).then(result => {
                let responseJson = result.data.data;
                propertyCompData.latitude = responseJson.latitude;
                propertyCompData.longitude = responseJson.longitude;
                propertyCompData.public_remarks = responseJson.public_remarks;
                propertyCompData.address = responseJson.address;
                propertyCompData.city = responseJson.city;
                propertyCompData.state = responseJson.state;
                propertyCompData.postal_code = responseJson.postal_code;
                propertyCompData.image_urls = responseJson.image_urls;
                let propertyImgs = responseJson.image_urls;
                if (propertyImgs) {
                    propertyImgs = propertyImgs.replace("[", "");
                    propertyImgs = propertyImgs.replace("]", "");
                    let isQoute = propertyImgs.indexOf('"');
                    if (isQoute !== -1) {
                        propertyImgs = JSON.parse("[" + propertyImgs + "]");
                    } else {
                        propertyImgs = propertyImgs.split(",");
                    }
                    propertyImgs = propertyImgs.filter(element => element !== "\"\"" && element !== "");

                    carouselSlider = (
                        <>
                            {propertyImgs.length > 0 ?
                                <Carousel >
                                    {propertyImgs.map((imageUrl, index) =>
                                        <div key={index}>
                                            <img src={imageUrl} alt={"img_" + index} loading="lazy" />
                                        </div>
                                    )}
                                </Carousel>
                                : <h2 className="not-authorized-msg">NO IMAGE DATA.</h2>}
                            {responseJson.public_remarks ? <p className="text-left">{responseJson.public_remarks}</p> : null}
                        </>
                    )
                } else {
                    carouselSlider = (
                        <>
                            <h2 className="not-authorized-msg">NO IMAGE DATA.</h2>
                            {responseJson.public_remarks ? <p className="text-left">{responseJson.public_remarks}</p> : null}
                        </>
                    );
                }

                if (propertyCompData.address) {
                    let zlinkData = propertyCompData.address + " " + propertyCompData.city + " " + propertyCompData.state + " " + propertyCompData.postal_code;
                    zlinkData = zlinkData.replace(/ /g, '-');
                    let zlink = "https://www.zillow.com/homes/" + zlinkData + "_rb/";
                    let redfin = "https://www.redfin.com/";
                    let realtor = "https://www.realtor.com/";
                    otherSite = (
                        <>
                            <div className="others-site">
                                <Row>
                                    <Col md="12">
                                        <a href={zlink} target="_blank" rel="noreferrer"> <img
                                            alt="Zillow"
                                            src={
                                                require("../../../assets/img/marketplace/zillow.png")
                                                    .default
                                            }
                                        /></a>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <CopyToClipboard text={propertyCompData.address + ", " + propertyCompData.city + ", " + propertyCompData.state + ", " + propertyCompData.postal_code}>
                                            <a href={realtor} target="_blank" rel="noreferrer"> <img
                                                alt="realtor"
                                                src={
                                                    require("../../../assets/img/marketplace/realtor.png")
                                                        .default
                                                }
                                            /></a>
                                        </CopyToClipboard>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                        <CopyToClipboard text={propertyCompData.address + " " + propertyCompData.city + " " + propertyCompData.state + " " + propertyCompData.postal_code}>
                                            <a href={redfin} target="_blank" rel="noreferrer"> <img
                                                alt="realtor"
                                                src={
                                                    require("../../../assets/img/marketplace/redfin.png")
                                                        .default
                                                }
                                            /></a>
                                        </CopyToClipboard>
                                    </Col>
                                </Row>
                            </div>
                        </>
                    )
                    setOtherSite(otherSite);
                }
                setCarouselSlider(carouselSlider);
                setPropertyComps(propertyCompData);
                setIsLoadMap(true);
            });
        }
        setIsOpenDiscModal(newState);
    };

    // Mapbox
    let onMarkerClick = (props, marker, e) => {
        setMapData({
            selectedPlace: props,
            activeMarker: marker,
            showingInfoWindow: true
        });
    };

    //  Map box close
    let onClose = props => {
        if (mapData.showingInfoWindow) {
            setMapData({
                showingInfoWindow: false,
                activeMarker: null
            });
        }
    };

    let propDataForCompList = {
        propertyImgs: propertyComps.image_urls,
        image_urls: propertyComps.image_urls,
        public_remarks: propertyComps.public_remarks,
        address: propertyComps.address,
        city: propertyComps.city,
        state: propertyComps.state,
        postal_code: propertyComps.postal_code,
        customTabListStyle: { padding: "6px 26px" },
        contextMenu: true,
        google: google_prop,
        zoom: 20,
        mapStyles,
        latitude: propertyComps.latitude,
        longitude: propertyComps.longitude,
        onMarkerClick: onMarkerClick,
        markerName: propertyComps.address + ", " + propertyComps.city + ", " + propertyComps.state + ", " + propertyComps.postal_code,
        infoWindowMarker: mapData.activeMarker,
        infoWindowVisible: mapData.showingInfoWindow,
        infoWindowOnClose: onClose,
        selectedPlace: mapData.selectedPlace,
        openModel: isLoadMap
    }

    return (
        <>
            <Col lg="12" xl="12">
                {isEmptyRatingData === false ? (
                    <Table striped className="analysis-table">
                        <thead>
                            <tr>
                                <th>&nbsp;</th>
                                <th>My Rating</th>
                                <th>New Rating</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableRow}
                        </tbody>
                    </Table>
                ) : (
                    <h2 className="not-authorized-msg">No changes in condition rating found</h2>
                )}
            </Col>
            <Modal className="modal-dialog-centered modal-lg condition-rating-modal" isOpen={isOpenDiscModal} toggle={() => toggleDiscrepancyModal(isOpenDiscModal)}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-left w-100" id="discrepancy_comp">
                        Comp Photos: {propertyComps.address + ", " + propertyComps.city + ", " + propertyComps.state + ", " + propertyComps.postal_code}
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => toggleDiscrepancyModal(isOpenDiscModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body text-center">
                    <div className="col-md-12">
                        <div className="others-column-body">
                            {isLoadMap ? (
                                <CompList props={propDataForCompList} />
                            ) : (
                                null
                            )}
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    );
};

export default GoogleApiWrapper({
    apiKey: userData.google_geocoding_api_key
})(ConditionRatings);