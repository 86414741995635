import React from "react";
import { Button, Col, Label, Modal, Row } from "reactstrap";
import { useState } from "react";

import * as moment from "moment";
import { useEffect } from "react";

import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";

import { confirmAlert } from "react-confirm-alert";
import { loggedInUser } from "components/Common/Helpers";
import { PostData } from "services/PostData";
import { toast } from "react-toastify";

import { IO_CANCELLED, IO_COMPLETED, IO_ON_HOLD, IO_RECEIVED } from "../../Constants";
import eventBus from "../../EventBus";
import RenderActionButton from "../RenderActionButton";
import { sendExceptionEmail } from "components/Common/Helpers";
import { encryptData } from "components/Common/Helpers";

const ReportOrderPlaceHoldClientButton = ({ propRowData, propButtonOptions }) => {

    const [isOpenPlaceHoldModal, setIsOpenModal] = useState(false);

    const userData = loggedInUser();
    const [isProcess, setProcess] = useState(false);

    const [fields, setFields] = useState({});
    const [requiredDocuments, setRequiredDocuments] = useState([]);
    const [update, setUpdate] = useState(moment());
    const [customErrors, setCustomErrors] = useState([]);

    useEffect(() => { }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleActionClick = (e, row) => {
        e.preventDefault();
        setIsOpenModal(true);
    };

    const handleToggleModal = (state) => {
        if (!isProcess) {
            state = !state;
            setIsOpenModal(state);
            // setCancellationReason("");
            setFields({});
            setRequiredDocuments([]);
            setUpdate(moment());
        }
    };

    const handleSubmit = () => {

        let customErrorsTemp = [];

        if (propRowData.inspection_status && [IO_CANCELLED, IO_COMPLETED, IO_RECEIVED, IO_ON_HOLD].includes(propRowData.inspection_status) === false) {
            if (!fields.place_inspection_hold) {
                customErrorsTemp.push('place_inspection_hold');
            }
        }

        setCustomErrors(customErrorsTemp);
        setUpdate(moment());

        if (customErrorsTemp.length) {
            return;
        }

        confirmAlert({
            title: '',
            message: "Are you sure you want to place the hold ?",
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Yes, Place Hold',
                    onClick: () => placeHold(),
                    className: "btn btn-primary btn-sm"
                }, {
                    label: 'Close',
                    onClick: () => { },
                    className: "btn btn-outline-danger btn-sm"
                }
            ]
        });
    };

    const placeHold = async () => {
        try {
            let row = propRowData;

            let params = fields;

            setProcess(true);

            let tempDocs = requiredDocuments;
            tempDocs.push("client_request");

            setRequiredDocuments(tempDocs);

            params.report_order_id = row.id;
            params.required_documents = requiredDocuments;
            params.user_id = userData.user_id;

            const encrypt = encryptData(params);

            const result = await PostData("client/orders/place_hold", { data: encrypt })
            const responseJson = result.data;
            setProcess(false);
            if (responseJson.status === 200) {
                toast["success"](responseJson.message);
                handleToggleModal(isOpenPlaceHoldModal)
            } else {
                toast["error"](responseJson.message);
            }

            eventBus.dispatch("report_order_status_updated", { client_id: propRowData.company_id });
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error, { memberCode: 200, componentName: 'ReportOrderPlaceHoldClientButton', functionName: 'placeHold', data: {} });
            setProcess(false);
        };

    };

    let handleInput = (e) => {
        let tempFields = fields;
        tempFields[e.target.name] = e.target.value

        setFields(tempFields);
    };

    return (
        <>
            <RenderActionButton propButtonTitle="Place Hold" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />

            {/* Cancel report order Modal */}
            <Modal className="modal-dialog-centered modal-md" isOpen={isOpenPlaceHoldModal} toggle={() => handleToggleModal(isOpenPlaceHoldModal)}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-center w-100">
                        Place this file on hold
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenPlaceHoldModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <div className="text-left">
                        <AvForm onValidSubmit={(e) => handleSubmit()} >
                            <Row>
                                <Col xs="12" sm="12" md="12" lg="12">
                                    <Label className="required">Reason</Label>
                                    <AvField
                                        name="hold_reason"
                                        onChange={(e) => handleInput(e)}
                                        className="form-control"
                                        placeholder="Enter reason to place a hold"
                                        type="textarea"
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: 'This field is required'
                                            },
                                            maxLength: {
                                                value: 300,
                                                errorMessage: 'Reason must be less or equal to 300 character'
                                            }
                                        }}
                                    />
                                </Col>

                                {(propRowData.inspection_status && [IO_CANCELLED, IO_COMPLETED, IO_RECEIVED, IO_ON_HOLD].includes(propRowData.inspection_status) === false) &&
                                    <Col sm="12" md="12" lg="12">
                                        <Label className="required">Do you want to place hold on inspection as well ?</Label>
                                        <div className="custom-radio-btn inline">
                                            <div>
                                                <input
                                                    type="radio"
                                                    id='place_inspection_hold_yes'
                                                    name="place_inspection_hold"
                                                    value='1'
                                                    onChange={(e) => handleInput(e)}
                                                />
                                                <label htmlFor='place_inspection_hold_yes'>Yes</label>
                                            </div>
                                            <div>
                                                <input
                                                    type="radio"
                                                    id='place_inspection_hold_no'
                                                    name="place_inspection_hold"
                                                    value='0'
                                                    onChange={(e) => handleInput(e)}
                                                />
                                                <label htmlFor='place_inspection_hold_no'>No</label>
                                            </div>
                                        </div>
                                        {(customErrors.includes('place_inspection_hold')) && <span className="form-error">This field is required.</span>}
                                    </Col>
                                }

                                <Col xs="12" sm="12" md="12" lg="12">
                                    <div className="text-right">
                                        <AvGroup>
                                            {isProcess ?
                                                <Button type="button" color="warning" size="sm">
                                                    <div className="fa fa-spinner fa-spin"></div> Processing...
                                                </Button>
                                                :
                                                <>
                                                    <Button size="sm" color="danger" outline onClick={() => handleToggleModal(isOpenPlaceHoldModal)}>
                                                        Cancel
                                                    </Button>
                                                    <Button size="sm" color="primary">
                                                        Submit
                                                    </Button>
                                                </>
                                            }
                                        </AvGroup>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ReportOrderPlaceHoldClientButton;