
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Label, Modal, Row } from "reactstrap";
import RenderActionButton from "views/modules/Common/ActionsButtons/RenderActionButton";
import GoogleAutoSearchTextInput from "../GoogleAutoSearchTextInput";
import { sendExceptionEmail } from "components/Common/Helpers";
import { toast } from "react-toastify";
import eventBus from "../EventBus";
import { PutData } from "services/OrderIntakeData";

const InspectionOrderEditAddressButton = ({ currentRow, propButtonOptions }) => {

    const [isProcess, setProcess] = useState(false);
    const [isOpenModal, setOpenModal] = useState(false);
    const [fields, setFields] = useState({});
    const [googleAddress, setGoogleAddress] = useState("");
    const [isUpdateAddress, setIsUpdateAddress] = useState(true);

    useEffect(() => {
        setGoogleAddress(currentRow.address);
    }, [currentRow]);

    const handleActionClick = (e, row) => {
        handleToggleModal(isOpenModal)
    }

    const handleToggleModal = (state) => {
        if (!isProcess) {
            state = !state;
            setOpenModal(state);
            setFields({});
            setIsUpdateAddress(true);
        }
    };

    const handleSubmit = async () => {
        try {
            setProcess(true);

            const response = await PutData(`inspection/${currentRow.inspection_order_token}/modify-address`, fields, currentRow.company_id)

            handleToggleModal(isOpenModal);
            toast["success"](response.message);
            setProcess(false);
            eventBus.dispatch("inspection_order_status_updated", { client_id: currentRow.company_id });
        } catch (error) {
            toast["error"](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error, { memberCode: 502, componentName: 'InspectionOrderEditAddressButton', functionName: 'handleSubmit', data: {} });
            setProcess(false)
        }
    }

    const getAddressObject = async (data) => {
        try {
            const addressObj = data.address;
            let updatedAddress = { city: addressObj.city, state: addressObj.state, property_address: addressObj.address_1, postal_code: addressObj.postal_code, place_id: addressObj.place_id, latitude: addressObj.google_latitude, longitude: addressObj.google_longitude, unit_number: currentRow.unit_number };
            setFields(updatedAddress);
            setIsUpdateAddress(false);
        } catch (error) {
            toast["error"](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error, { memberCode: 502, componentName: 'InspectionOrderEditAddressButton', functionName: 'getAddressObject', data: {} });
            setProcess(false);
        }
    }

    const handleInput = (e) => {
        let tempFields = fields;
        tempFields[e.target.name] = e.target.value
        setFields(tempFields);
    };

    return (
        <>
            <RenderActionButton propButtonTitle="Edit Address" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />
            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={isOpenModal}
                toggle={() => handleToggleModal(isOpenModal)}
            >
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-center w-100">
                        Edit inspection property address
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardBody className="text-left">
                            <AvForm onValidSubmit={(e) => handleSubmit()} >
                                <Row className="mb-2">
                                    <Col xs="12" sm="12" md="12" lg="12">
                                        <Label>Address</Label>
                                        <GoogleAutoSearchTextInput googleAddress={googleAddress} propAddressObject={(data) => getAddressObject(data)} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs="12" sm="12" md="4" lg="4">
                                        <Label>Address Line</Label>
                                        <AvField
                                            name="property_address"
                                            placeholder="Address"
                                            value={fields?.property_address}
                                            onChange={e => handleInput(e)}
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "This field is required."
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col xs="12" sm="12" md="4" lg="4">
                                        <Label>City</Label>
                                        <AvField
                                            name="city"
                                            placeholder="city"
                                            value={fields?.city}
                                            onChange={e => handleInput(e)}
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "This field is required."
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col xs="12" sm="12" md="4" lg="4">
                                        <Label>State</Label>
                                        <AvField
                                            name="state"
                                            placeholder="state"
                                            value={fields?.state}
                                            onChange={e => handleInput(e)}
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "This field is required."
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col xs="12" sm="12" md="4" lg="4">
                                        <Label>Postal code</Label>
                                        <AvField
                                            name="postal_code"
                                            placeholder="postal_code"
                                            value={fields?.postal_code}
                                            onChange={e => handleInput(e)}
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "This field is required."
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col xs="12" sm="12" md="4" lg="4">
                                        <Label>Unit Number</Label>
                                        <AvField
                                            value={fields?.unit_number}
                                            name="unit_number"
                                            placeholder="Unit"
                                            onChange={e => handleInput(e)}
                                            validate={{
                                                pattern: { value: /^[a-zA-Z0-9-]*$/, errorMessage: "Invalid format." },
                                                maxLength: { value: 8, errorMessage: "Max length must be 8." }
                                            }}
                                        />
                                    </Col>
                                    <Col xs="12" sm="12" md="12" lg="12">
                                        <div className="text-right">
                                            <AvGroup>
                                                {isProcess ?
                                                    <Button type="button" color="warning" size="sm">
                                                        <div className="fa fa-spinner fa-spin"></div> Processing...
                                                    </Button>
                                                    :
                                                    <>
                                                        <Button size="sm" color="danger" outline onClick={() => handleToggleModal(isOpenModal)}>
                                                            Cancel
                                                        </Button>
                                                        <Button size="sm" color="primary" disabled={isUpdateAddress}>
                                                            Submit
                                                        </Button>
                                                    </>
                                                }
                                            </AvGroup>
                                        </div>
                                    </Col>
                                </Row>
                            </AvForm>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        </>
    )
}

export default InspectionOrderEditAddressButton;