import React, { useEffect, useState, memo } from "react";
import { GetData, PostData } from "services/Api.service";
import FlagIcons from "./FlagIcons.js";
import { toast } from 'react-toastify';
import { sendExceptionEmail, checkRoles } from "components/Common/Helpers.js"
import { RO_REPORT_CANCELLED } from "../Constants";

const ReviewerFlags = memo(({ propData }) => {
    const [flagData, setFlagData] = useState([]);
    const [flagLocation, setFlagLocation] = useState('');
    const [flagLocationData, setFlagLocationData] = useState([]);

    useEffect(() => {
        async function fetchInitialData() {
            try {
                if (propData && Object.keys(propData).length) {
                    setFlagLocation(propData.location);
                    let reviewerFlags = await PostData("reviewer-flags/get-reviewer-flags", { propertyId: propData?.propertyId, reportId: propData?.reportId, userId: propData?.userId });
                    let FlagLocationData = await GetData("reviewer-flags/get-reviewer-locations");
                    setFlagData(reviewerFlags.data.data);
                    setFlagLocationData(FlagLocationData.data.data);
                }

            } catch (errors) {
                toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message)
                await sendExceptionEmail(errors, { memberCode: 403, componentName: 'ReviewerFlags', functionName: 'fetchInitialData', data: {} });
            }
        }
        fetchInitialData();
    }, [propData?.location]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {(propData && checkRoles(['TRAINING_ADMINISTRATOR', 'ANALYST', 'ADMINISTRATOR', 'REVIEWER', 'MANAGER']) && propData?.reportId && ![RO_REPORT_CANCELLED].includes(propData?.orderStatus) &&
                <FlagIcons propData={{ reviewerFlagData: flagData, reviewerFlagAllLocationData: flagLocationData, flagLocation, propertyId: propData?.propertyId, reportId: propData?.reportId, userId: propData?.userId, orderStatus: propData?.orderStatus }} />
            )}
        </>
    );
});
export default ReviewerFlags;
