
/*
### Example:

Import : import CommonModal from "components/Common/CommonModal.js";

### Component:

<CommonModal
    isModal={boolean}
    title={Head}
    text={Body Text}
    link={url}
    onHandleToggle={Event Function 1},
    onButton={EVent Function 2}
    modalWidth={800px}
    isShowModalBtn={true}
/>

*/

import { Button, Modal, } from "reactstrap";
const CommonModal = ({ isModal, title, text, link, onHandleToggle, onButton, modalWidth = "auto", isShowModalBtn = true, children }) => {

    return (
        <>
            <Modal className="modal-dialog-centered" style={{ maxWidth: modalWidth }} isOpen={isModal} toggle={() => onHandleToggle("isConfirmModal")}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title w-100 text-center" >
                        {title}
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => onHandleToggle("isConfirmModal")}            >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                {text && !children && <div className="modal-body text-center" dangerouslySetInnerHTML={{ __html: text }}></div>}
                {children && !text && <div>{children}</div>}
                <div className="modal-footer">
                    {isShowModalBtn ? (
                        <Button size="sm" color="primary" type="button" onClick={() => onButton("isConfirmModal", true, link)} outline>
                            Continue
                        </Button>) : null}
                </div>
            </Modal>
        </>
    )
}

export default CommonModal;