import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { PostData } from "services/Api.service";
import * as moment from "moment";
import { sendExceptionEmail } from "components/Common/Helpers";
import eventBus from "views/modules/Common/EventBus";
import { Button, Col, FormGroup, Label, Modal, Row } from "reactstrap";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import RenderActionButton from "views/modules/Common/ActionsButtons/RenderActionButton";

const InspectionOrderReopenClientButton = ({ propRowData, propButtonOptions }) => {

    let [isOpenModal, setIsOpenModal] = useState(false);
    let [update, setUpdate] = useState(moment());
    let [isProcess, setProcess] = useState(false);
    const [note, setNote] = useState('');
    useEffect(() => { }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let unmounted = false;

        if (!unmounted) { }

        return () => {
            unmounted = true;
        };
    }, []);// eslint-disable-line react-hooks/exhaustive-deps   

    const handleActionClick = () => {
        setIsOpenModal(true);
    };

    let cancelOrReplaceInspectionOrder = async (row) => {
        try {
            setProcess(true);
            let params = {
                inspection_order_id: row.inspection_order_id,
                notes: note
            };
            let result = await PostData("client/inspection/re-open-inspection", params);
            toast["success"](result.message);
            eventBus.dispatch("inspection_order_status_updated", { client_id: row.company_id });
            setIsOpenModal(false);
            setProcess(false);
        } catch (errors) {
            setProcess(false);
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message)
            await sendExceptionEmail(errors, { memberCode: 200, componentName: 'InspectionOrderReopenClientButton', functionName: 'cancelOrReplaceInspectionOrder', data: {} });
        }
        setUpdate(moment());
    };

    let handleToggleModal = (state) => {
        if (!isProcess) {
            setIsOpenModal(!state);
            setUpdate(moment());
        }
    };

    return (<>
        {propRowData.inspection_order_id &&
            <RenderActionButton propButtonTitle="Reopen Inspection" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />
        }

        {isOpenModal &&
            <Modal
                className="modal-dialog-centered"
                isOpen={isOpenModal}
                toggle={() => handleToggleModal(isOpenModal)}
            >
                <div className="modal-header modal-header-colored">
                    <h3 className="modal-title text-center w-100">
                        Are you sure about to reopen the inspection order?
                    </h3>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <AvForm>
                        <Row>
                            <Col xl="12" >
                                <AvGroup>
                                    <Label>*Notes : </Label>
                                    <AvField
                                        name={"Notes"}
                                        onChange={(e) => {
                                            const cleanedNote = e.target.value.replace(/ +/g, ' ').trim();
                                            setNote(cleanedNote);
                                        }}
                                        className="form-control"
                                        placeholder="Enter Notes"
                                        type="textarea"
                                        validate={{
                                            minLength: {
                                                value: 15,
                                                errorMessage: 'Please enter minimum 15 character.'
                                            }
                                        }}
                                    />

                                </AvGroup>
                            </Col>
                            <Col xl="12" className="text-right">
                                <FormGroup>
                                    {isProcess ?
                                        <Button type="button" color="warning" size="sm">
                                            <div className="fa fa-spinner fa-spin"></div> Processing...
                                        </Button>
                                        :
                                        <>
                                            <Button size="sm" outline color="danger" onClick={() => handleToggleModal(isOpenModal)}>
                                                Close
                                            </Button>
                                            <Button size="sm" color="primary" onClick={(e) => cancelOrReplaceInspectionOrder(propRowData)}>
                                                Reopen
                                            </Button>
                                        </>
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                    </AvForm>
                </div>
            </Modal>
        }
    </>);
};

export default InspectionOrderReopenClientButton;