import { PostData } from "services/Api.service";

const { GetData } = require("services/Api.service");

export const defaultValues = () => {
	return {
		compFilterParams: {
			compType: 'smallestAdjustments',
			compMLSStatus: 'sold',
			mapsDisplayType: 'sp',
			showHiddenComps: false,
			mapDrawings: [],
			mapShapeFilteredComps: [],
			fields: {
				above: {},
				below: {},
				total: {},
				dist: {},
				coe: {},
				year: {},
				stories: {},
				beds: {},
				baths: {},
				acres: {},
				score: {},
				units: {},
				prop_types: {},
				water_front: {},
				golf_course: {},
				pool: {}
			},
		},
		adjustmentFactorFields: [
			{ label: "Condition", sliderKey: 'condition', dbKey: 'condition' },
			{ label: "Above Grade", sliderKey: 'aboveGrade', dbKey: 'above_grade' },
			{ label: "Below Grade", sliderKey: 'belowGrade', dbKey: 'below_grade' },
			{ label: "Beds", sliderKey: 'beds', dbKey: 'beds' },
			{ label: "Baths", sliderKey: 'baths', dbKey: 'baths' },
			{ label: "Year Built", sliderKey: 'yearBuilt', dbKey: 'year' },
			{ label: "Lot Size", sliderKey: 'lot', dbKey: 'lot' },
			{ label: "Location", sliderKey: 'location', dbKey: 'location' },
			{ label: "COE", sliderKey: 'coe', dbKey: 'coe' },
		],
		adjustmentFactorSliderValue: {
			condition: 0,
			aboveGrade: 0,
			belowGrade: 0,
			beds: 0,
			baths: 0,
			location: 0,
			coe: 0,
			lot: 0,
			yearBuilt: 0,
		},
	}
};

export const retrievePageData = async (params) => {
	try {
		const resp = await GetData("api/v1/deals/page-data", params);
		return resp?.data;
	} catch (error) {
		throw error;
	}
};

export const retrieveReportOrderData = async (params) => {
	try {
		const resp = await GetData("api/v1/deals/report-order-data", params);
		return resp?.data;
	} catch (error) {
		throw error;
	}
};

export const retrieveCompData = async (params) => {
	try {
		const resp = await GetData("api/v1/deals/comp-data", params);
		return resp?.data;
	} catch (error) {
		throw error;
	}
};

export const retrieveImpairmentData = async (params) => {
	try {
		const resp = await GetData("api/v1/deals/impairments", params);
		return resp?.data;
	} catch (error) {
		throw error;
	}
};

export const retrieveConfidenceScoreData = async (params) => {
	try {
		const resp = await GetData("api/v1/deals/confidence-score-data", params);
		let confidenceScoreData = resp?.data?.confidenceScoreData;

		confidenceScoreData.r2_blended = parseFloat(confidenceScoreData?.r2_blended * 100).toFixed(2);
		confidenceScoreData.precision_score = parseFloat(confidenceScoreData?.precision_score * 100).toFixed(2);
		confidenceScoreData.percent_high_quality = parseFloat(confidenceScoreData?.percent_high_quality * 100).toFixed(1);
		confidenceScoreData.influence_core = parseFloat(confidenceScoreData?.influence_core * 100).toFixed(1);
		confidenceScoreData.confidence_score = parseFloat(confidenceScoreData?.confidence_score * 100).toFixed(0);

		return { confidenceScoreData };
	} catch (error) {
		throw error;
	}
};

export const retrieveMarketDemandData = async (params) => {
	try {
		const resp = await GetData("api/v1/deals/market-demand-data", params);
		return resp?.data;
	} catch (error) {
		throw error;
	}
};

export const retrievePropertyComparisonData = async (params) => {
	try {
		const resp = await GetData("api/v1/deals/property-comparison-data", params);
		const propertyComparisonData = resp?.data?.propertyComparisonData;

		return { propertyComparisonData };
	} catch (error) {
		throw error;
	}
};

export const retrieveCompLocationHistory = async (params) => {
	try {
		const resp = await GetData("api/v1/comps/location-history", params);
		const compLocationHistory = resp?.data?.compLocationHistory;

		return { compLocationHistory }
	} catch (error) {
		throw error;
	}
};

export const retrievePropertyFiles = async (propertyId) => {
	try {
		const resp = await GetData(`property/${propertyId}/property-files`);
		const propertyFiles = resp?.data?.propertyFiles;

		return { propertyFiles }
	} catch (error) {
		throw error;
	}
};

export const saveAIVData = async (valDetailsData) => {
	try {
		console.log(valDetailsData);

		const soldComps = valDetailsData?.displayComps?.sold;
		const allSoldComps = [...soldComps?.compArr];

		const filteredCompIds = allSoldComps?.filter(e => !e.isFilterExcluded).map(e => {
			return e.id
		});

		const filteredComps = allSoldComps?.filter(e => !e.isFilterExcluded).map(e => {
			return { id: e.id, excluded: e.excluded }
		});

		const analysisData = {
			compFilters: {
				// fields: Object.fromEntries(
				// 	Object.entries(valDetailsData?.compFilterParams?.fields).filter(([key, value]) => Object.keys(value).length > 0)
				// ),
				fields: valDetailsData?.compFilterParams?.fields,
				mapDrawings: valDetailsData?.compFilterParams?.mapDrawings,
			},
			adjustmentFactors: {
				sliderValue: valDetailsData?.adjustmentFactors?.sliderValue,
				mutedFields: valDetailsData?.adjustmentFactors?.mutedFields,
			},
			compIdArray: filteredCompIds,
			filteredComps,
			propertyCondition: valDetailsData?.subjectPropertyComp?.condition_id
		};

		await PostData(`api/v1/deals/submit-custom-analysis`, {
			propertyId: valDetailsData?.propertyId,
			analysisData
		});

		const data = [];
		return data;
	} catch (error) {
		throw error;
	}
};

export const saveCompScoreUserValues = async (comps) => {
	const data = [];
	for (const comp of [...comps?.sold?.compArr]) {
		const compAdjustmentDetailsDefault = comp?.comp_adjustments_detail_default;
		const compAdjustmentDetails = comp?.comp_adjustments_detail;

		let isModified = false;

		if (compAdjustmentDetails?.above_grade !== compAdjustmentDetailsDefault?.above_grade) {
			isModified = true;
		} else if (compAdjustmentDetails?.baths !== compAdjustmentDetailsDefault?.baths) {
			isModified = true;
		} else if (compAdjustmentDetails?.beds !== compAdjustmentDetailsDefault?.beds) {
			isModified = true;
		} else if (compAdjustmentDetails?.below_grade !== compAdjustmentDetailsDefault?.below_grade) {
			isModified = true;
		} else if (compAdjustmentDetails?.coe !== compAdjustmentDetailsDefault?.coe) {
			isModified = true;
		} else if (compAdjustmentDetails?.condition !== compAdjustmentDetailsDefault?.condition) {
			isModified = true;
		} else if (compAdjustmentDetails?.location !== compAdjustmentDetailsDefault?.location) {
			isModified = true;
		} else if (compAdjustmentDetails?.lot !== compAdjustmentDetailsDefault?.lot) {
			isModified = true;
		} else if (compAdjustmentDetails?.year !== compAdjustmentDetailsDefault?.year) {
			isModified = true;
		}

		if (isModified) {
			data.push(compAdjustmentDetails);
		}
	}

	if (data?.length) {
		await PostData("api/v1/comps/save-comp-score-user-values", { adjustmentDetails: data });
	}
};