import React, { useState, useEffect } from "react";
import { Map, InfoWindow, Marker } from 'google-maps-react';
import { TASK_SLUG } from "../Constants/TimeTracking.constant";
import { periodObservance } from 'components/Common/TimeTrackingHelpers';
import { Row } from "reactstrap";

const CompList = (props) => {
    let [data, setData] = useState([]);
    useEffect(() => {
        setData(props.props)
    }, [props]);

    const handleInfoWindowOpen = () => {
        const infoWindowLink = document.getElementById("info-window-link");
        if (infoWindowLink) {
            infoWindowLink.addEventListener("click", handleLinkClick);
        }
    };

    const handleLinkClick = async () => {
        await periodObservance({ taskData: data.taskData, slug: TASK_SLUG.SUBJECT_CONDITION_GOOGLE_OUTSIDE_LINKS, orderData: data.orderData, closingTask: 0, newTabCheck: 0 });
    };

    return (
        <>
            {data && data.selectedPlace &&

                //  <GoogleMapComponent
                //         zoomLevel={data.zoom}
                //         mapCenterCoordinates={{
                //             lat: data.latitude,
                //             lng: data.longitude
                //         }}
                //         mapType={"map"}
                //         mapHeights={'400px'}
                //         markers={[{
                //             position: { lat: data.latitude, lng: data.longitude },
                //             label: "",
                //             icon: null,
                //             dataObj: { ...data, id: 1, address: data.markerName },
                //             openInfoWindow: true,
                //             infoPopupTemplate: "compAddressLink"
                //         }]}
                //         onInfoWindowOpenFn={handleLinkClick}
                //     />

                <Row className="row">
                    <Map
                        className="custom-map-controls"
                        contextMenu={data.contextMenu}
                        google={data.google}
                        zoom={data.zoom}
                        style={data.mapStyles}
                        initialCenter={{
                            lat: data.latitude,
                            lng: data.longitude
                        }}
                        zoomControlOptions={{
                            position: data.google.maps.ControlPosition.BOTTOM_RIGHT
                        }}
                    >
                        <Marker
                            onClick={data.onMarkerClick}
                            name={data.markerName}
                        />
                        <InfoWindow
                            marker={data.infoWindowMarker}
                            visible={data.infoWindowVisible}
                            onClose={data.infoWindowOnClose}
                            onOpen={data.isFromSubjectPropertyCondition ? handleInfoWindowOpen : undefined}
                        >
                            <div className="map-link">
                                <a id="info-window-link" href={"https://www.google.com/maps/place/" + data.selectedPlace.name + "/@" + data.latitude + "," + data.longitude + ",20z"} target="_blank" rel="noreferrer"><h2>{data.selectedPlace.name}</h2></a>
                            </div>
                        </InfoWindow>
                    </Map>
                </Row>
            }
        </>
    );
};

export default CompList;
