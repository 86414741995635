import React, { useState, useEffect } from "react";
import { Row, Col, UncontrolledTooltip, Modal, Button, FormGroup } from "reactstrap";
import * as moment from "moment";
import { truncate } from "components/Common/Helpers.js"
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";
import { toast } from "react-toastify";
import eventBus from "../../EventBus";
import RenderActionButton from "../RenderActionButton";
import { sendExceptionEmail } from "components/Common/Helpers";
import { GetData, PostData } from "services/Api.service";
import TableSkeleton from "components/SkeletonUI/TableSkeleton";
import RenderBootstrapTable from "components/Common/RenderBootstrapTable";
import { formatDateComponent } from "components/Common/Helpers";

const ReportOrderQuestionAnswerButton = ({ propButtonOptions, onQuestionAnswerData, hideButtons = false, handleModalObj }) => {

    const [isProcess, setProcess] = useState(false);
    const [update, setUpdate] = useState(moment());

    const [isReportCheckbox, setReportCheckbox] = useState(true);
    const [isInspectionCheckbox, setInspectionCheckbox] = useState(true);

    const [isOpenQuestionAnswerModal, setIsOpenQuestionAnswerModal] = useState(false);
    const [isOpenAnswerModal, setIsOpenAnswerModal] = useState(false);
    const [questionId, setQuestionId] = useState(false);
    const [answer, setAnswer] = useState(false);

    const [sortFieldName, setSortFieldName] = useState('date_added');
    const [sortOrder, setSortOrder] = useState('desc');
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [totalRecordSize, setTotalRecordSize] = useState(0);
    const [tableData, setTableData] = useState([]);

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (handleModalObj?.current) {
            handleActionClick();
            // handleModalObj.current = false
        }
    }, [handleModalObj?.current]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isLoaded) {
            handleQuestionAnswer();
        }
        setIsLoaded(true)
    }, [currentPage, recordsPerPage, sortFieldName, sortOrder]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleActionClick = () => {
        setReportCheckbox(true);
        setCurrentPage(1);
        setTableData([]);
        setInspectionCheckbox(true);
        setIsOpenQuestionAnswerModal(false);
        setIsOpenAnswerModal(false);
        handleQuestionAnswer();
    };

    useEffect(() => { }, [update]);

    useEffect(() => {
        if (isOpenQuestionAnswerModal) {
            setCurrentPage(1);
            setTableData([]);
            handleQuestionAnswer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReportCheckbox, isInspectionCheckbox]);

    // Handle Error Modal
    let handleModal = (state) => {
        if (state === "isOpenQuestionAnswerModal") {
            state = !state;
            setIsOpenQuestionAnswerModal(state);
            setCurrentPage(1);
        } else if (state === "isOpenAnswerModal") {
            state = !isOpenAnswerModal;
            setIsOpenAnswerModal(state);
        }
        setUpdate(moment());
    };

    // Get History Reports
    const handleQuestionAnswer = async () => {
        const offset = (currentPage - 1) * recordsPerPage;

        const data = {
            orderToken: onQuestionAnswerData.order_token,
            offset, limit: recordsPerPage, sortFieldName, sortOrder,
        };

        try {
            if (onQuestionAnswerData) {

                let tableDataTemp = [];
                setProcess(true);
                const result = await GetData("orders/question-answers", data)

                let responseJson = result.data;
                let reportOrderData = {};
                reportOrderData.id = onQuestionAnswerData.id;
                reportOrderData.address = onQuestionAnswerData.address;

                for (const log of responseJson.rows) {
                    let historyDataObj = { ...log };
                    historyDataObj.question_user = historyDataObj.question_user ? historyDataObj.question_user.first_name + ' ' + historyDataObj.question_user.last_name : '-';
                    historyDataObj.answer_user = historyDataObj.answer_user ? historyDataObj.answer_user.first_name + ' ' + historyDataObj.answer_user.last_name : '-';
                    tableDataTemp.push(historyDataObj);
                }

                setTotalRecordSize(responseJson.count)
                setTableData(tableDataTemp);
                setIsOpenQuestionAnswerModal(true);
                setProcess(false);
            }
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error, { memberCode: 200, componentName: 'ReportOrderQuestionAnswerButton', functionName: 'handleQuestionAnswer', data: data });
            setProcess(false);
        };
    };

    // Report section
    const historyActionBtn = (cell, row) => {
        return (
            <>
                {row.resolved_status === 0 ? (
                    <>
                        <Button id={"answer_" + row.id} color="warning" size="sm" type="button" onClick={e => handleOrderStatus(e, row, 'answer')}>
                            <i className="fa fa-reply" aria-hidden="true"></i>
                        </Button>
                        <UncontrolledTooltip delay={0} placement="auto" target={"answer_" + row.id} >Answer</UncontrolledTooltip>
                    </>
                ) : "-"}
            </>
        );
    };

    const questionData = (cell, row) => {
        const question_id = `question_text_${row.id}`;
        return (
            <div>
                <span id={question_id}>{truncate(row.question, 20)}</span>
                <UncontrolledTooltip
                    delay={0}
                    placement="auto"
                    target={question_id}
                >
                    {row.question}
                </UncontrolledTooltip>
            </div>

        );
    };

    const answerData = (cell, row) => {
        const answer_id = `answer_text_${row.id}`;
        return (
            <div>
                <span id={answer_id}>{truncate(row.answer, 20)}</span>
                <UncontrolledTooltip
                    delay={0}
                    placement="auto"
                    target={answer_id}
                >
                    {row.answer}
                </UncontrolledTooltip>
            </div>

        );
    };

    const handleOrderStatus = (e, row, type) => {
        setQuestionId(row.id);
        handleModal("isOpenAnswerModal");
    };

    const handleAnswerProcess = async (question_id) => {
        const data = { orderToken: onQuestionAnswerData.order_token, questionId: question_id, answer: answer }
        try {
            setProcess(true);
            const result = await PostData("orders/submit-answer", data);
            setProcess(false);

            toast["success"](result.message);
            handleModal("isOpenAnswerModal");
            handleModal("isOpenQuestionAnswerModal");

            eventBus.dispatch("report_order_status_updated", { client_id: onQuestionAnswerData.company_id });
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error, { memberCode: 200, componentName: 'ReportOrderQuestionAnswerButton', functionName: 'handleAnswerProcess', data: data });
            setProcess(false);
        };
    };

    // TABLE EVENTS
    // Handle  page change show record in table
    const onPageChangeEvent = (currentPageTemp, recordsPerPageTemp) => {
        setCurrentPage(currentPageTemp > 0 ? currentPageTemp : 1);
        setRecordsPerPage(recordsPerPageTemp);
    };

    // On sorting filter
    const onSortChangeEvent = (fieldName, order) => {
        setSortFieldName(fieldName);
        setSortOrder(order);
    };

    const columns = [
        { width: "15%", title: 'Question User', dataField: 'question_user', isKey: true },
        { width: "20%", title: 'Question', dataFormat: questionData },
        { width: "20%", title: 'Answer', dataFormat: answerData },
        { width: "15%", title: 'Answer User', dataField: 'answer_user' },
        { width: "10%", title: 'Que Date', dataFormat: (cell, row) => formatDateComponent("date_added", row, "MMM D h:mm a") },
        { width: "10%", title: 'Ans Date', dataFormat: (cell, row) => formatDateComponent("date_answered", row, "MMM D h:mm a") },
        { width: "20%", title: 'Action', dataFormat: historyActionBtn },
    ];

    const tableParams = {
        currentPage,
        recordsPerPage,
        onPageChangeEvent,
        onSortChangeEvent,
        tableData,
        totalRecordSize,
        columns,
    };

    return (
        <>
            {!hideButtons &&
                <RenderActionButton propButtonTitle="Question/Answer" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />
            }

            <Modal className="modal-dialog-centered modal-xl" isOpen={isOpenQuestionAnswerModal} toggle={() => handleModal("isOpenQuestionAnswerModal")}>
                <div className="modal-header modal-header-colored">
                    <h4 className=" modal-title text-center w-100">Report Question/Answer</h4>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() =>
                        handleModal("isOpenQuestionAnswerModal")}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <Col>
                            {isProcess ?
                                <TableSkeleton rows={10} cols={7} /> :
                                <RenderBootstrapTable tableParams={tableParams} />
                            }
                        </Col>
                    </Row>
                </div>
            </Modal>


            <Modal className="modal-dialog-centered" isOpen={isOpenAnswerModal} toggle={() => handleModal("isOpenAnswerModal")}>
                <div className="modal-header modal-header-colored">
                    <h4 className="modal-title w-100 text-center" id="reassignModalLabel">
                        Answer
                    </h4>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleModal("isOpenAnswerModal")}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <AvForm onValidSubmit={(e) => handleAnswerProcess(questionId, false)} >
                        <AvGroup className="text-left">
                            <AvField
                                name="cancellation_reason"
                                onChange={(e) => setAnswer(e.target.value)}
                                className="form-control"
                                placeholder="Enter Answer"
                                type="textarea"
                                rows={5}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "This field is required."
                                    },
                                    maxLength: {
                                        value: 700,
                                        errorMessage: 'Answer must be less than 700 character'
                                    }
                                }}
                            />
                        </AvGroup>
                        <div className="text-right">
                            <FormGroup>
                                {isProcess ?
                                    <Button type="button" color="warning" size="sm">
                                        <div className="fa fa-spinner fa-spin"></div> Processing...
                                    </Button>
                                    :
                                    <>
                                        <Button size="sm" color="danger" outline onClick={() => handleModal("isOpenAnswerModal")}>
                                            Close
                                        </Button>
                                        <Button size="sm" color="primary">
                                            Submit
                                        </Button>
                                    </>
                                }
                            </FormGroup>
                        </div>
                    </AvForm>
                </div>
            </Modal>
        </>
    );
};

export default ReportOrderQuestionAnswerButton;