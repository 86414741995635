import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import * as moment from "moment";
import { useDispatch } from "react-redux";
import { showSpinner } from './../../../../ShowHideSpinner.reducer'
import {
    GetData as OrderIntakeGet,
    PostData as OrderIntakePost
} from "services/OrderIntakeData";
import { sendExceptionEmail } from "components/Common/Helpers";
import PaymentForm from "../PaymentForm";
import RenderActionButton from "./RenderActionButton";

const InspectionOrderPaymentButton = ({ propRowData, propButtonOptions, paymentCb = null }) => {

    const dispatch = useDispatch();
    let [update, setUpdate] = useState(moment());
    let isOpenPaymentFormModal = React.useRef(false);
    let [pricingDetails, setPricingDetails] = useState({})

    useEffect(() => { }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

    let handleActionClick = async (e) => {
        e.preventDefault();

        try {
            dispatch(showSpinner(true));
            if (propRowData?.sourcePage === 'inspectionOrderPublicPaymentPage') {
                const isValidBorrower = await validateBorrower();
                if (!isValidBorrower) {
                    return false
                };
            }

            let response = await OrderIntakeGet(`public/${propRowData.intake_token}/inspection-pricing-details`, {});
            let pricingDataTemp = response.data.pricing_data;

            setPricingDetails(pricingDataTemp)
            dispatch(showSpinner(false));
            isOpenPaymentFormModal.current = true;
            setUpdate(moment())

        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error, { memberCode: 200, componentName: 'InspectionOrderPaymentButton', functionName: 'handleActionClick', data: {} });
            setUpdate(moment());
        };
    };

    let validateBorrower = async (e) => {

        try {

            if (!propRowData?.policyAgreementUserData?.name || !propRowData?.policyAgreementUserData?.email || !propRowData?.policyAgreementUserData?.phone_number) {
                toast['error']("Please enter all fields value!")
                return false;
            }

            let response = await OrderIntakeGet(`public/${propRowData.intake_token}/inspection-borrower-sent-email-row`, {})

            if (response.data.emailData) {
                let emailData = response.data.emailData;

                let toEmails = emailData.to_emails ? JSON.parse(emailData.to_emails) : [];
                let ccEmails = emailData.cc_emails ? JSON.parse(emailData.cc_emails) : [];
                let order_intake_id = emailData.report_intake_id ? JSON.parse(emailData.report_intake_id) : [];

                let email_matched = false;
                let valid_form_data = false;

                propRowData.policyAgreementUserData.toEmails = JSON.stringify(toEmails)
                propRowData.policyAgreementUserData.ccEmails = JSON.stringify(ccEmails)
                propRowData.policyAgreementUserData.order_intake_id = order_intake_id
                if (toEmails.length > 0 || ccEmails.length > 0) {
                    if (toEmails.length > 0) {
                        for (var i = 0; i < toEmails.length; i++) {
                            if (propRowData?.policyAgreementUserData?.email?.toLowerCase() === toEmails[i].toLowerCase()) {
                                email_matched = true;
                                break;
                            }
                        }
                    }

                    if (!email_matched) {
                        if (ccEmails.length > 0 && !email_matched) {
                            for (var j = 0; j < ccEmails.length; j++) {
                                if (propRowData?.policyAgreementUserData?.email?.toLowerCase() === ccEmails[j].toLowerCase()) {
                                    email_matched = true;
                                    break;
                                }
                            }
                        }
                    }
                } else {
                    toast["error"]('To and CC emails both can not be empty.');
                    return false;
                }

                if (email_matched) {
                    valid_form_data = true;
                } else {
                    valid_form_data = false;
                    toast["error"]('Please use the same email address on which you get the payment link.');
                    return false;
                }

                return valid_form_data;

            } else {
                toast["error"]("System doesn't found any link sent to borrower for this order.");
                return false;
            }
        } catch (errors) {
            confirmAlert({
                title: 'Error',
                message: (errors.errors.length && errors.errors[0].message) ? errors.errors[0].message : errors.message,
                closeOnClickOutside: false,
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => { },
                        className: "btn btn-danger btn-sm"
                    }
                ]
            });
            dispatch(showSpinner(false));
            await sendExceptionEmail(errors, { memberCode: 200, componentName: 'InspectionOrderPaymentButton', functionName: 'validateBorrower', data: {} });
            setUpdate(moment());
        };

    };

    let handlePaymentSourceCb = async (paymentSourceCb) => {
        try {
            const paymentSource = paymentSourceCb.payment_source;

            let requestData = { payment_source: paymentSource };

            if (propRowData?.policyAgreementUserData !== null) {
                requestData.policy_agreement_user_data = propRowData?.policyAgreementUserData;
            }

            dispatch(showSpinner(true));

            let response = await OrderIntakePost(`public/${propRowData.intake_token}/inspection-payment`, requestData, propRowData.company_id)
            toast["success"](response.message);
            dispatch(showSpinner(false));
            if (paymentCb) {
                paymentCb({
                    success: true
                })
            }
        } catch (errors) {
            await sendExceptionEmail(errors, { memberCode: 200, componentName: 'InspectionOrderPaymentButton', functionName: 'handlePaymentSourceCb', data: {} });
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message);
            dispatch(showSpinner(false));
        }
    };

    return (
        <>
            <RenderActionButton propButtonTitle="Pay" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />

            {isOpenPaymentFormModal.current && <PaymentForm onPay={isOpenPaymentFormModal} onPaymentSourceCb={handlePaymentSourceCb}
                paymentDetails={{
                    total_amount: pricingDetails?.total_amounts?.total_price,
                    cc_surcharge: pricingDetails?.total_amounts?.cc_surcharge,
                    paymentFor: 'New Inspection Order'
                }}
                clientId={propRowData.company_id}
                sourcePage={propRowData.sourcePage}
            />}
        </>
    );
};

export default InspectionOrderPaymentButton;