import React from "react";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { showSpinner } from '../../../../../ShowHideSpinner.reducer'

import eventBus from "../../EventBus";
import { PostData } from "services/Api.service";
import { sendExceptionEmail } from "components/Common/Helpers";
import RenderActionButton from "../RenderActionButton";

const ReportOrderRetryPropertyUploadButton = ({ propRowData, propButtonOptions }) => {

    const dispatch = useDispatch();

    let handleActionClick = (e) => {
        const message = propRowData?.propertyUploadType === 'batch' ? "This report was ordered within a batch portfolio. Would you like to retrigger this property only, or would you like to retrigger all failed properties in the portfolio?" : "Are you sure you want to retrigger the property upload?";

        const buttons = [];

        if (propRowData?.propertyUploadType === 'batch') {
            buttons.push({
                label: 'Single Property',
                onClick: async () => {
                    await handleProcess({ retriggerType: 'singleProperty' });
                },
                className: "btn btn-primary btn-sm"
            })
            buttons.push({
                label: 'All failed properties in portfolio',
                onClick: async () => {
                    await handleProcess({ retriggerType: 'portfolio' });
                },
                className: "btn btn-primary btn-sm"
            })
        } else {
            buttons.push({
                label: 'Yes, Retrigger',
                onClick: async () => {
                    await handleProcess({ retriggerType: 'singleProperty' });
                },
                className: "btn btn-primary btn-sm"
            })
        }

        buttons.push({
            label: 'Close',
            onClick: () => { },
            className: "btn btn-outline-danger btn-sm"
        });


        e.preventDefault();
        confirmAlert({
            title: 'Confirmation',
            message: message,
            closeOnClickOutside: false,
            buttons: buttons
        });
    };

    let handleProcess = async ({ retriggerType }) => {

        try {
            dispatch(showSpinner(true));
            const response = await PostData("orders/retry-property-upload", { reportOrderId: propRowData.report_order_id, retriggerType });
            toast["success"](response.message);
            dispatch(showSpinner(false));
            eventBus.dispatch("report_order_status_updated", { client_id: propRowData.company_id });
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            eventBus.dispatch("report_order_status_updated", { client_id: propRowData.company_id });
            await sendExceptionEmail(error, { memberCode: 500, componentName: 'ReportOrderRetryPropertyUploadButton', functionName: 'handleProcess', data: {} });
            dispatch(showSpinner(false));
        }
    };

    return (
        <>
            <RenderActionButton propButtonTitle="Retry Property Upload" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />
        </>
    );
};

export default ReportOrderRetryPropertyUploadButton;