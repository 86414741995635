import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";

import "./ActionButtonsStyle.scss";

import EditReportOrderIntakeFormButton from "./EditReportOrderIntakeFormButton";
import DismissReportOrderIntakeButton from "./DismissReportOrderIntakeButton";
import ReactivateReportOrderIntakeButton from "./ReactivateReportOrderIntakeButton";
import ReportOrderPaymentButton from "./ReportOrderPaymentButton";
import ReportOrderSendBorrowerPaymentLinkButton from "./ReportOrderSendBorrowerPaymentLinkButton";
import OrderNewInspectionButton from "./OrderNewInspectionButton";
import DismissInspectionOrderIntakeButton from "./DismissInspectionOrderIntakeButton";
import ReactivateInspectionOrderIntakeButton from "./ReactivateInspectionOrderIntakeButton";
import InspectionOrderPaymentButton from "./InspectionOrderPaymentButton";
import EditInspectionOrderIntakeFormButton from "./EditInspectionOrderIntakeFormButton";
import InspectionOrderSendBorrowerPaymentLinkButton from "./InspectionOrderSendBorrowerPaymentLinkButton";
import ReopenInspectionOrderButton from "./ReopenInspectionOrderButton";
import InspectionOrderPlaceHoldButton from "./InspectionOrderPlaceHoldButton";
import InspectionOrderReleaseHoldButton from "./InspectionOrderReleaseHoldButton";
import InspectionOrderDownloadPDFButton from "./InspectionOrderDownloadPDFButton";
import SendInspectionCompletedEmail from "./SendInspectionCompletedEmail";
import EpoEditReportOrderIntakeFormButton from "./EpoEditReportOrderIntakeFormButton";
import EpoDownloadReportButton from "./EpoDownloadReportButton";
import EpoRegenerateReportButton from "./EpoRegenerateReportButton";

import ReportOrderReleaseSnagButton from "./Staffs/ReportOrderReleaseSnagButton";
import ReportOrderRetryPropertyUploadButton from "./Staffs/ReportOrderRetryPropertyUploadButton";
import PropertyCompDiscrepancyButton from "./Staffs/PropertyCompDiscrepancyButton";
import ReportOrderCancelButton from "./Staffs/ReportOrderCancelButton";
import InspectionBudgetStatusButton from "./InspectionBudgetStatusButton";
import ReportOrderReopenButton from "./Staffs/ReportOrderReopenButton";
import InspectionOrderCancelOrReplaceButton from "./Staffs/InspectionOrderCancelOrReplaceButton";
import PropertyAnalysisButton from "./Staffs/PropertyAnalysisButton";
import AnalysisReviewButton from "./Staffs/AnalysisReviewButton";
import ReportOrderPlaceHoldButton from "./Staffs/ReportOrderPlaceHoldButton";
import ChangeReportTypeButton from "./Staffs/ChangeReportTypeButton";

import AddRemoveRushOptionsButton from "./Clients/AddRemoveRushOptionsButton";
import ReportOrderCancelClientButton from "./Clients/ReportOrderCancelClientButton";
import OrderNewInspectionButtonClient from "./Clients/OrderNewInspectionButtonClient";
import ChangeReportTypeClientButton from "./Clients/ChangeReportTypeClientButton";
import MarkReferralFeeAsPaidButton from "views/modules/IntegrationPartners/MarkReferralFeeAsPaidButton";
import ExportReferralFees from "views/modules/IntegrationPartners/ExportReferralFees";
import ReportOrderPlaceHoldClientButton from "./Clients/ReportOrderPlaceHoldClientButton";
import InspectionOrderCancelOrReplaceClientButton from "./Clients/InspectionOrderCancelOrReplaceClientButton";
import ReportOrderReleaseHoldClientButton from "./Clients/ReportOrderReleaseHoldClientButton";
import ReportOrderReviseDismissClientButton from "./Clients/ReportOrderReviseDismissClientButton";
import ReopenFilesClientButton from "./Clients/ReopenFilesClientButton";
import CancelledReopenClientButton from "./Clients/CancelledReopenClientButton";
import HandlePdfReportClientButton from "./Clients/HandlePdfReportClientButton";
import InspectionOrderReopenClientButton from "./Clients/InspectionOrderReopenClientButton";
import InspectionHistoryModal from "../InspectionHistoryModal";
import DismissInspectionOrderButton from "./DismissInspectionOrderButton";
import InspectionOrderDismissRevisionButton from "./InspectionOrderDismissRevisionButton";
import BudgetReviewButton from "./Staffs/BudgetReviewButton";
import HandleAssignOrder from "../HandleAssignOrder";
import HandlePdfReport from "./Staffs/HandlePdfReport";
import DisputeOrderButton from "../DisputeOrder/DisputeOrderButton";
import ReportOrderDismissRevisionButton from "./Staffs/ReportOrderDismissRevisionButton";
import ReleaseHoldButton from "./Staffs/ReleaseHoldButton";
import ReportOrderQuestionAnswerButton from "./Staffs/ReportOrderQuestionAnswerButton";
import ReportOrderRevisionAcceptRelinquishButton from "./Staffs/ReportOrderRevisionAcceptRelinquishButton";
import ClientResponse from "../ClientResponse";


import EpoCompletedOrderClientButton from "./Clients/EpoCompletedOrderClientButton";
import EpoConvertToEvalClientButton from "./Clients/EpoConvertToEvalClientButton";
import PreviewEmail from "views/modules/EmailLogs/PreviewEmail";
import RequeueEmail from "views/modules/EmailLogs/RequeueEmail";
import PropertyCompListingDetailButton from "./PropertyCompListingDetailButton";
import InspectionOrderEditAddressButton from "./InspectionOrderEditAddressButton";


const ActionButtons = ({ propRowData, propButtonsToDisplay, propCb }) => {

    const [buttonsToDisplay, setButtonsToDisplay] = useState([]);

    useEffect(() => {
        setButtonsToDisplay(propButtonsToDisplay ? propButtonsToDisplay : [])
    }, [propButtonsToDisplay]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            {buttonsToDisplay?.length ?
                <div className="action-buttons">
                    <div className="dropdown mr-2">
                        <Button size="" color="secondary"><i className="fas fa-ellipsis-v" /></Button>
                        <div className="dropdown-content">

                            {buttonsToDisplay?.includes('EDIT_REPORT_ORDER_INTAKE_FORM') &&
                                <EditReportOrderIntakeFormButton
                                    propRowData={{
                                        report_intake_batch_id: propRowData?.report_intake_batch_id
                                    }} propButtonOptions={{
                                        faClass: "fa fa-pencil-alt",
                                        color: "primary",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('REPORT_ORDER_PAYMENT') &&
                                <ReportOrderPaymentButton
                                    propRowData={{
                                        intake_token: propRowData?.intake_token,
                                        company_id: propRowData?.company_id,
                                    }} propButtonOptions={{
                                        faClass: "fa fa-dollar",
                                        color: "warning",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('REPORT_ORDER_BORROWER_PAYMENT_LINK') &&
                                <ReportOrderSendBorrowerPaymentLinkButton
                                    propRowData={{
                                        intake_token: propRowData?.intake_token,
                                        company_id: propRowData?.company_id,
                                    }} propButtonOptions={{
                                        faClass: "fa fa-paper-plane",
                                        color: "success",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('DISMISS_REPORT_ORDER_INTAKE') &&
                                <DismissReportOrderIntakeButton
                                    propRowData={{
                                        intake_token: propRowData?.intake_token,
                                        incomplete: propRowData?.incomplete,
                                        company_id: propRowData?.company_id,
                                    }} propButtonOptions={{
                                        faClass: "fa fa-times",
                                        color: "danger",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('REACTIVATE_REPORT_ORDER_INTAKE') &&
                                <ReactivateReportOrderIntakeButton
                                    propRowData={{
                                        intake_token: propRowData?.intake_token,
                                        company_id: propRowData?.company_id,
                                    }} propButtonOptions={{
                                        faClass: "fa fa-check",
                                        color: "success",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('REPORT_ORDER_RELEASE_SNAG') &&
                                <ReportOrderReleaseSnagButton
                                    propRowData={{
                                        report_order_id: propRowData.report_order_id,
                                        company_id: propRowData.company_id
                                    }} propButtonOptions={{
                                        faClass: "fa fa-play",
                                        color: "primary",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('REPORT_ORDER_RETRY_PROPERTY_UPLOAD') &&
                                <ReportOrderRetryPropertyUploadButton
                                    propRowData={{
                                        report_order_id: propRowData.report_order_id,
                                        company_id: propRowData.company_id,
                                        propertyUploadType: propRowData.propertyUploadType
                                    }} propButtonOptions={{
                                        faClass: "fa fa-refresh",
                                        color: "warning",
                                    }} />
                            }


                            {buttonsToDisplay?.includes('BUDGET_REVIEW') &&
                                <BudgetReviewButton propRowData={{
                                    id: propRowData.id,
                                    property_id: propRowData.property_id,
                                    company_id: propRowData.company_id,
                                    order_status: propRowData.order_status,
                                    address: propRowData.address,
                                }} propButtonOptions={{
                                    type: 'review',
                                    faClass: "fa fa-hammer",
                                    color: "success",
                                }} />
                            }

                            {buttonsToDisplay?.includes('REPORT_ORDER_REVISION_ACCEPT') &&
                                <ReportOrderRevisionAcceptRelinquishButton
                                    propRowData={{
                                        id: propRowData.id,
                                        status: 'assign',
                                        company_id: propRowData.company_id,
                                    }}
                                    propButtonOptions={{
                                        type: 'review',
                                        faClass: "fa fa-search",
                                        color: "accept",
                                    }}
                                />
                            }

                            {buttonsToDisplay?.includes('REPORT_ORDER_REVISION_REASSIGN') &&
                                <ReportOrderRevisionAcceptRelinquishButton
                                    propRowData={{
                                        id: propRowData.id,
                                        status: 'reassign',
                                        company_id: propRowData.company_id,
                                    }}
                                    propButtonOptions={{
                                        type: 'review',
                                        faClass: "fa fa-tasks",
                                        color: "warning",
                                    }}
                                />
                            }

                            {buttonsToDisplay?.includes('PROPERTY_ANALYSIS') &&
                                <PropertyAnalysisButton
                                    propRowData={{
                                        neighborhood_metrics: propRowData.neighborhood_metrics,
                                        property_id: propRowData.property_id,
                                        report_order_id: propRowData.report_order_id,
                                        company_id: propRowData.company_id,
                                        order_status: propRowData.order_status,
                                    }}
                                    propButtonOptions={{
                                        type: 'analyze',
                                        faClass: "fa fa-check",
                                        color: "accept",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('PROPERTY_ANALYSIS_RELINQUISH') &&
                                <PropertyAnalysisButton
                                    propRowData={{
                                        neighborhood_metrics: propRowData.neighborhood_metrics,
                                        property_id: propRowData.property_id,
                                        report_order_id: propRowData.report_order_id,
                                        company_id: propRowData.company_id,
                                        order_status: propRowData.order_status,
                                    }}
                                    propButtonOptions={{
                                        type: 'relinquish',
                                        faClass: "fa fa-tasks",
                                        color: "warning",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('ANALYSIS_REVIEW') &&
                                <AnalysisReviewButton
                                    propRowData={{
                                        neighborhood_metrics: propRowData.neighborhood_metrics,
                                        property_id: propRowData.property_id,
                                        report_order_id: propRowData.report_order_id,
                                        company_id: propRowData.company_id,
                                        order_status: propRowData.order_status,
                                    }}
                                    propButtonOptions={{
                                        type: 'review',
                                        faClass: "fa fa-search",
                                        color: "accept",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('ANALYSIS_REVIEW_RELINQUISH') &&
                                <AnalysisReviewButton
                                    propRowData={{
                                        neighborhood_metrics: propRowData.neighborhood_metrics,
                                        property_id: propRowData.property_id,
                                        report_order_id: propRowData.report_order_id,
                                        company_id: propRowData.company_id,
                                        order_status: propRowData.order_status,
                                    }}
                                    propButtonOptions={{
                                        type: 'reassign',
                                        faClass: "fa fa-list",
                                        color: "warning",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('ORDER_NEW_INSPECTION') &&
                                <OrderNewInspectionButton
                                    propRowData={{
                                        property_access_contacts: propRowData?.property_access_contacts ? propRowData?.property_access_contacts : [],
                                        company_id: propRowData?.company_id,
                                        inspection_order: propRowData?.inspection_order,
                                        isStandalone: propRowData?.isStandalone,
                                        report_type_id: propRowData?.report_type_id,
                                        report_order_id: propRowData?.id,
                                        inspection_vendor_id: propRowData?.inspection_order?.inspection_vendor?.id,
                                    }} />
                            }

                            {buttonsToDisplay?.includes('REPORT_ORDER_CANCEL') &&
                                <ReportOrderCancelButton
                                    propRowData={{
                                        report_order_id: propRowData.report_order_id,
                                        company_id: propRowData.company_id
                                    }} propButtonOptions={{
                                        faClass: "fa fa-times",
                                        color: "danger",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('PROPERTY_COMP_DISCREPANCY') &&
                                <PropertyCompDiscrepancyButton
                                    propRowData={{
                                        task_id: propRowData?.id,
                                        action_type: propRowData.action_type,
                                        property_comp_id: propRowData?.property_comp_id,
                                    }} />
                            }

                            {buttonsToDisplay?.includes('PROPERTY_COMP_LISTING_DETAIL') &&
                                <PropertyCompListingDetailButton
                                    propRowData={{
                                        task_status: propRowData?.task_status,
                                        action_type: propRowData.action_type,
                                        id: propRowData?.property_comp_id,
                                        drv_id: propRowData?.drv_id,
                                        showMyValues: propRowData?.showMyValues
                                    }} />
                            }

                            {buttonsToDisplay?.includes('REPORT_ORDER_REOPEN') &&
                                <ReportOrderReopenButton
                                    propRowData={{
                                        property_access_contacts: propRowData.property_access_contacts,
                                        id: propRowData.id,
                                        isStandalone: propRowData.isStandalone,
                                        property_id: propRowData.property_id,
                                        report_type_id: propRowData.report_type_id,
                                        company_id: propRowData.company_id,
                                        property_type_normalized: propRowData.property_type_normalized,
                                        inspection_order_id: propRowData.inspection_order_id,
                                        inspection_order: propRowData.inspection_order,
                                        order_status: propRowData.order_status,
                                        renovation_budget_needed: propRowData.renovation_budget_needed,
                                        state: propRowData.state,
                                        postal_code: propRowData.postal_code,
                                        slug: propRowData.slug,
                                    }} propButtonOptions={{
                                        faClass: "fa fa-folder-open",
                                        color: "success",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('REPORT_ORDER_PLACE_HOLD') &&
                                <ReportOrderPlaceHoldButton
                                    propRowData={{
                                        id: propRowData?.id,
                                        company_id: propRowData?.company_id,
                                        inspection_status: propRowData?.inspection_status_id,
                                    }} propButtonOptions={{
                                        faClass: "fa fa-pause",
                                        color: "yellow",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('REPORT_ORDER_RELEASE_HOLD') &&
                                <ReleaseHoldButton propReportRow={{
                                    missing_docs_arr: propRowData.missing_docs_arr,
                                    company_id: propRowData.company_id,
                                    id: propRowData.id,
                                    property_id: propRowData.property_id,
                                    report_hold: propRowData.report_hold,
                                    property_address: propRowData.address,
                                    report_type: propRowData.nickname,
                                }}
                                    propButtonOptions={{
                                        faClass: "fa fa-play",
                                        color: "primary",
                                        // outline: 'false'
                                    }}
                                />
                            }

                            {buttonsToDisplay?.includes('REPORT_ORDER_QUESTION_ANSWER') &&
                                <ReportOrderQuestionAnswerButton
                                    onQuestionAnswerData={{
                                        id: propRowData.id,
                                        order_token: propRowData.order_token,
                                        // address: propRowData.address,
                                        company_id: propRowData.company_id,
                                    }}
                                    propButtonOptions={{
                                        faClass: "fa fa-question",
                                        color: "danger",
                                        // outline: 'false'
                                    }}
                                />
                            }

                            {buttonsToDisplay?.includes('INSPECTION_LOG_ACTION') &&
                                <InspectionHistoryModal
                                    onReportData={{
                                        address: propRowData.address,
                                        company_id: propRowData.company_id,
                                        trip_charge: propRowData.trip_charge ? propRowData.trip_charge : 0,
                                        inspection_order_id: propRowData.inspection_order_id,
                                        report_order_id: propRowData.report_order_id,
                                        isStandalone: propRowData.isStandalone,
                                    }}
                                    propButtonOptions={{
                                        faClass: "fa fa-search",
                                        color: "primary",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('CHANGE_REPORT_TYPE') &&
                                <ChangeReportTypeButton
                                    propRowData={{
                                        report_order_id: propRowData?.report_order_id,
                                        company_id: propRowData?.company_id,
                                        base_fee: propRowData?.base_fee,
                                        report_type_id: propRowData?.report_type_id
                                    }}
                                    propButtonOptions={{
                                        faClass: "fa fa-pencil-alt",
                                        color: "primary",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('ADD_REMOVE_RUSH') &&
                                <AddRemoveRushOptionsButton
                                    propRowData={{
                                        report_order_id: propRowData.report_order_id,
                                        order_token: propRowData?.order_token,
                                        company_id: propRowData?.company_id,
                                        currentRushFee: propRowData?.rush_fee,
                                        report_type: propRowData?.report_type_slug
                                    }} propButtonOptions={{
                                        faClass: "fa fa-rocket",
                                        color: "warning"
                                    }} />
                            }

                            {buttonsToDisplay?.includes('EDIT_INSPECTION_ORDER_INTAKE_FORM') &&
                                <EditInspectionOrderIntakeFormButton
                                    propRowData={{
                                        intake_token: propRowData?.inspection_intake_batch?.token,
                                        company_id: propRowData?.company_id,
                                    }} propButtonOptions={{
                                        faClass: "fa fa-pencil-alt",
                                        color: "primary",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('INSPECTION_BUDGET_STATUS') &&
                                <InspectionBudgetStatusButton
                                    propRowData={{
                                        intake_token: propRowData?.inspection_intake_batch?.token,
                                        budget_status: propRowData?.budget_status,
                                    }} />
                            }

                            {buttonsToDisplay?.includes('INSPECTION_ORDER_PAYMENT') &&
                                <InspectionOrderPaymentButton
                                    propRowData={{
                                        intake_token: propRowData?.inspection_intake_batch?.token,
                                        company_id: propRowData?.company_id,
                                    }} propButtonOptions={{
                                        faClass: "fa fa-dollar",
                                        color: "warning",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('INSPECTION_ORDER_BORROWER_PAYMENT_LINK') &&
                                <InspectionOrderSendBorrowerPaymentLinkButton
                                    propRowData={{
                                        intake_token: propRowData?.inspection_intake_batch?.token,
                                        company_id: propRowData?.company_id,
                                    }} propButtonOptions={{
                                        faClass: "fa fa-paper-plane",
                                        color: "success",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('DISMISS_INSPECTION_ORDER_INTAKE') &&
                                <DismissInspectionOrderIntakeButton
                                    propRowData={{
                                        intake_token: propRowData?.inspection_intake_batch?.token,
                                        // incomplete: propRowData?.incomplete,
                                        company_id: propRowData?.company_id,
                                    }} propButtonOptions={{
                                        faClass: "fa fa-times",
                                        color: "danger",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('REACTIVATE_INSPECTION_ORDER_INTAKE') &&
                                <ReactivateInspectionOrderIntakeButton
                                    propRowData={{
                                        intake_token: propRowData?.inspection_intake_batch?.token,
                                        company_id: propRowData?.company_id,
                                    }} propButtonOptions={{
                                        faClass: "fa fa-check",
                                        color: "success",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('INSPECTION_ORDER_REOPEN') &&
                                <ReopenInspectionOrderButton
                                    propRowData={{
                                        inspection_order_token: propRowData?.inspection_order_token,
                                        company_id: propRowData?.company_id
                                    }} propButtonOptions={{
                                        faClass: "fa fa-folder-open",
                                        color: "success",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('INSPECTION_ORDER_CANCEL_OR_REPLACE') &&
                                <InspectionOrderCancelOrReplaceButton
                                    propRowData={{
                                        property_access_contacts: propRowData.property_access_contacts,
                                        company_id: propRowData.company_id,
                                        inspection_order_id: propRowData.inspection_order_id,
                                        report_order_id: propRowData.report_order_id,
                                        report_type_id: propRowData.report_type_id,
                                        isStandalone: propRowData.isStandalone,
                                    }} propButtonOptions={{
                                        faClass: "fa fa-times",
                                        color: "danger",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('REFERRAL_FEE_MARK_AS_PAID') &&
                                <MarkReferralFeeAsPaidButton
                                    propRowData={{
                                        referral_fee_ids: propRowData.referral_fee_ids,
                                        company_token: propRowData.company_token,
                                        month: propRowData.month,
                                        payment_status: propRowData?.payment_status,
                                    }} propButtonOptions={{
                                        faClass: "fa fa-check",
                                        color: "primary",
                                    }} propCb={propCb} />
                            }

                            {buttonsToDisplay?.includes('EXPORT_REFERRAL_FEES_EXCEL') &&
                                <ExportReferralFees
                                    propRowData={{
                                        referral_fee_ids: propRowData.referral_fee_ids,
                                        month: propRowData.month,
                                        company_token: propRowData?.company_token,
                                        payment_status: propRowData?.payment_status,
                                    }} propButtonOptions={{
                                        faClass: "fa fa-file-excel",
                                        color: "success",
                                    }} propCb={propCb} />
                            }

                            {buttonsToDisplay?.includes('ASSIGN_ORDER') &&
                                <HandleAssignOrder orderData={propRowData} />
                            }

                            {buttonsToDisplay?.includes('EPO_EDIT_REPORT_ORDER_INTAKE_FORM') &&
                                <EpoEditReportOrderIntakeFormButton propRowData={{
                                    intake_token: propRowData?.intake_token,
                                    company_id: propRowData?.company_id,
                                }} propButtonOptions={{
                                    faClass: "fa fa-pencil-alt",
                                    color: "primary",
                                }} />
                            }

                            {buttonsToDisplay?.includes('EPO_DOWNLOAD_REPORT') &&
                                <EpoDownloadReportButton propRowData={{
                                    report_pdf_url: propRowData?.report_pdf_url,
                                }} propButtonOptions={{
                                    faClass: "fa fa-download",
                                    color: "warning",
                                }} />
                            }

                            {buttonsToDisplay?.includes('EPO_REGENERATE_REPORT') &&
                                <EpoRegenerateReportButton propRowData={{
                                    property_id: propRowData?.property_id
                                }} propButtonOptions={{
                                    faClass: "fa fa-repeat",
                                    color: "primary",
                                }} propCb={propCb} />
                            }


                            {buttonsToDisplay?.includes('PDF_BUTTONS') &&
                                <HandlePdfReport
                                    propReportRow={propRowData}
                                    onHandlePDF={propRowData?.pdfButtonCb || (() => { console.log('Unhandled PDF Callback'); })}
                                    propButtons={propRowData?.pdfButtons} />
                            }

                            {buttonsToDisplay?.includes('CLIENT_RESPONSE') &&
                                <ClientResponse propReportRow={propRowData} />
                            }

                            {buttonsToDisplay?.includes('DISPUTE_ORDER') &&
                                <DisputeOrderButton propRowData={{
                                    order_id: propRowData.id,
                                    company_id: propRowData.company_id,
                                    property_id: propRowData.property_id,
                                    address: propRowData.address,
                                }} propButtonOptions={{
                                    faClass: "fa fa-search",
                                    color: "accept",
                                    // outline: 'false'
                                }} />
                            }

                            {buttonsToDisplay?.includes('REPORT_ORDER_DISMISS_REVISION') &&
                                <ReportOrderDismissRevisionButton
                                    propReportRow={propRowData}
                                    propButtonOptions={{
                                        faClass: "fa fa-ban",
                                        color: "danger",
                                        // outline: 'false'
                                    }}
                                />
                            }


                            {/* Client Actions */}
                            {buttonsToDisplay?.includes('REPORT_ORDER_CANCEL_CLIENT') &&
                                <ReportOrderCancelClientButton
                                    propRowData={{
                                        report_order_id: propRowData?.report_order_id,
                                        company_id: propRowData?.company_id,
                                    }} propButtonOptions={{
                                        faClass: "fa fa-times",
                                        color: "danger",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('REPORT_ORDER_PLACE_HOLD_CLIENT') &&
                                <ReportOrderPlaceHoldClientButton
                                    propRowData={{
                                        id: propRowData?.id,
                                        company_id: propRowData?.company_id,
                                        inspection_status: propRowData?.inspection_status_id,
                                    }} propButtonOptions={{
                                        faClass: "fa fa-pause",
                                        color: "yellow",
                                        // outline: 'false',
                                    }} />
                            }

                            {buttonsToDisplay?.includes('INSPECTION_ORDER_CANCEL_OR_REPLACE_CLIENT') &&
                                <InspectionOrderCancelOrReplaceClientButton
                                    propReportRow={{
                                        property_access_contacts: propRowData?.property_access_contacts,
                                        company_id: propRowData?.company_id,
                                        inspection_order_id: propRowData?.inspection_order_id,
                                        report_order_id: propRowData?.report_order_id,
                                        report_type_id: propRowData?.report_type_id,
                                        isStandalone: propRowData?.standalone,
                                    }}
                                    propButtonOptions={{
                                        faClass: "fa fa-times",
                                        color: "danger",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('ORDER_NEW_INSPECTION_CLIENT') &&
                                <OrderNewInspectionButtonClient
                                    propRowData={{
                                        property_access_contacts: propRowData?.property_access_contacts ? propRowData?.property_access_contacts : [],
                                        company_id: propRowData?.company_id,
                                        inspection_order: propRowData?.inspection_order,
                                        isStandalone: propRowData?.isStandalone,
                                        report_type_id: propRowData?.report_type_id,
                                        report_order_id: propRowData?.id,
                                        inspection_vendor_id: propRowData?.inspection_order?.inspection_vendor?.id,
                                    }} propButtonOptions={{
                                        faClass: "fa fa-times",
                                        color: "info",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('CHANGE_REPORT_TYPE_CLIENT') &&
                                <ChangeReportTypeClientButton
                                    propRowData={{
                                        report_order_id: propRowData?.report_order_id,
                                        company_id: propRowData?.company_id,
                                        base_fee: propRowData?.base_fee,
                                        report_type_id: propRowData?.report_type_id
                                    }}
                                    propButtonOptions={{
                                        faClass: "fa fa-pencil-alt",
                                        color: "primary",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('REPORT_ORDER_RELEASE_HOLD_CLIENT') &&
                                <ReportOrderReleaseHoldClientButton
                                    propReportRow={{
                                        missing_docs_arr: propRowData.missing_docs_arr,
                                        company_id: propRowData.company_id,
                                        id: propRowData.id,
                                        property_id: propRowData.property_id,
                                        report_hold: propRowData.report_hold,
                                        property_address: propRowData.address,
                                        report_type: propRowData.nickname,
                                    }} propButtonOptions={{
                                        faClass: "fa fa-play",
                                        color: "primary",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('REPORT_ORDER_REVISE_DISMISS_CLIENT') &&
                                <ReportOrderReviseDismissClientButton
                                    propReportRow={{
                                        id: propRowData?.id,
                                        company_id: propRowData?.company_id,
                                    }}
                                    propButtonOptions={{
                                        faClass: "fa fa-ban",
                                        color: "danger",
                                        // outline: 'false',
                                    }}
                                />
                            }

                            {buttonsToDisplay?.includes('PDF_BUTTONS_CLIENT') &&
                                <HandlePdfReportClientButton
                                    propReportRow={propRowData}
                                    onHandlePDF={propRowData?.pdfButtonCb || (() => { console.log('Unhandled PDF Callback'); })}
                                    propButtons={propRowData?.pdfButtons} />
                            }

                            {buttonsToDisplay?.includes('REPORT_ORDER_REOPEN_CLIENT') &&
                                <ReopenFilesClientButton
                                    propReportRow={propRowData}
                                    propButtonOptions={{
                                        faClass: "fa fa-folder-open",
                                        color: "success",
                                        // outline: 'false',
                                    }} />
                            }

                            {buttonsToDisplay?.includes('REPORT_ORDER_CANCELLED_REOPEN_CLIENT') &&
                                <CancelledReopenClientButton
                                    propReportRow={{
                                        property_access_contacts: propRowData.property_access_contacts,
                                        property_id: propRowData.property_id,
                                        report_type_id: propRowData.report_type_id,
                                        company_id: propRowData.company_id,
                                        property_type_normalized: propRowData.property_type_normalized,
                                        order_status: propRowData.order_status,
                                        inspection_order: propRowData.inspection_order,
                                        id: propRowData.id,
                                        isStandalone: propRowData.inspection_order && propRowData.inspection_order.standalone ? propRowData.inspection_order.standalone : '0'
                                    }}
                                    propButtonOptions={{
                                        faClass: "fa fa-folder-open",
                                        color: "success",
                                        // outline: 'false',
                                    }} />
                            }

                            {buttonsToDisplay?.includes('INSPECTION_ORDER_REOPEN_CLIENT') &&
                                <InspectionOrderReopenClientButton
                                    propRowData={{
                                        company_id: propRowData.company_id,
                                        inspection_order_id: propRowData.id
                                    }}
                                    propButtonOptions={{
                                        faClass: "fa fa-folder-open",
                                        color: "success",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('INSPECTION_ORDER_DOWNLOAD_PDF') &&
                                <InspectionOrderDownloadPDFButton
                                    propRowData={{
                                        downloadUrl: propRowData.download_url
                                    }}
                                    propButtonOptions={{
                                        faClass: "fa fa-download",
                                        color: "warning",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('INSPECTION_ORDER_SEND_COMPLETED_PDF') &&
                                <SendInspectionCompletedEmail rowData={{
                                    fulfillment_action_id: propRowData.fulfillment_action_id
                                }} />
                            }

                            {buttonsToDisplay?.includes('INSPECTION_ORDER_PLACE_HOLD') &&
                                <InspectionOrderPlaceHoldButton
                                    currentRow={{
                                        inspection_order_id: propRowData.id,
                                        inspection_order_token: propRowData.inspection_order_token,
                                        company_id: propRowData.company_id
                                    }}
                                    propButtonOptions={{
                                        faClass: "fa fa-pause",
                                        color: "yellow",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('INSPECTION_ORDER_EDIT_ADDRESS') &&
                                <InspectionOrderEditAddressButton
                                    currentRow={{
                                        address: propRowData.address,
                                        inspection_order_token: propRowData.inspection_order_token,
                                        unit_number: propRowData.unit_number,
                                        company_id: propRowData.company_id
                                    }}
                                    propButtonOptions={{
                                        faClass: "fa fa-edit",
                                        color: "warning",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('INSPECTION_ORDER_RELEASE_HOLD') &&
                                <InspectionOrderReleaseHoldButton
                                    currentRow={{
                                        inspection_order_id: propRowData.id,
                                        inspection_order_token: propRowData.inspection_order_token,
                                        company_id: propRowData.company_id
                                    }}
                                    propButtonOptions={{
                                        faClass: "fa fa-play",
                                        color: "warning",
                                    }} />
                            }

                            {buttonsToDisplay?.includes('INSPECTION_ORDER_DISMISS') &&
                                <DismissInspectionOrderButton
                                    propRowData={{
                                        inspection_order_id: propRowData.inspection_order_id,
                                        company_id: propRowData.company_id
                                    }}
                                    propButtonOptions={{
                                        faClass: "fa fa-ban",
                                        color: "danger",
                                        outline: 'false'
                                    }} />
                            }

                            {buttonsToDisplay?.includes('INSPECTION_ORDER_DISMISS_REVISION') &&
                                <InspectionOrderDismissRevisionButton propRowData={{
                                    inspection_order_id: propRowData.inspection_order_id,
                                    company_id: propRowData.company_id,
                                    report_files: propRowData.download_url
                                }} propButtonOptions={{
                                    faClass: "fa fa-ban",
                                    color: "danger",
                                    outline: 'false'
                                }} />
                            }
                            {buttonsToDisplay?.includes('EPO_COMPLETED_ORDER_CLIENT_BUTTON') &&
                                <EpoCompletedOrderClientButton propRowData={{
                                    order_token: propRowData?.order_token
                                }} propButtonOptions={{
                                    faClass: "fa fa-download",
                                    color: "warning",
                                }} />
                            }

                            {buttonsToDisplay?.includes('EPO_CONVERT_TO_EVAL_CLIENT_BUTTON') &&
                                <EpoConvertToEvalClientButton propRowData={{
                                    report_intake_batch_id: propRowData?.report_intake_batch_id
                                }} propButtonOptions={{
                                    faClass: "fa fa-random",
                                    color: "success",
                                }} />
                            }

                            {buttonsToDisplay?.includes('PREVIEW_EMAIL') &&
                                <PreviewEmail propRowData={{
                                    id: propRowData.id,
                                    activeTab: propRowData?.activeTab
                                }} />
                            }

                            {buttonsToDisplay?.includes('REQUEUE_EMAIL') &&
                                <RequeueEmail propRowData={{
                                    id: propRowData.id,
                                    activeTab: propRowData?.activeTab
                                }} callback={propRowData.callback} />
                            }
                        </div>
                    </div>
                </div>
                : null
            }
        </>
    );
};

export default ActionButtons;