import React, { useState, useEffect } from "react";
import { Button, Modal, Col, Row, Label } from "reactstrap";
import { AvForm, AvGroup, AvField } from "availity-reactstrap-validation";

import { PostData } from "../../../../../services/PostData";
import { loggedInUser } from "components/Common/Helpers.js"
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; // Import
import * as moment from "moment";
import { checkRoles } from "components/Common/Helpers.js"
import eventBus from "../../EventBus";
import DragAndDropOrUpload from "../../File-Upload/DragAndDropOrUpload";
import RenderActionButton from "../RenderActionButton";
import { sendExceptionEmail } from "components/Common/Helpers";
import AutoLoginDecrypt from "components/Common/AutoLoginDecrypt.js";
const phpUrl = process.env.REACT_APP_PHP_ENDPOINT;

const ReleaseHoldButton = ({ propButtonOptions, propReportRow }) => {

    const userData = loggedInUser();
    const [isProcess, setProcess] = useState(false);
    const [isOpenReleaseHoldModal, setOpenReleaseHoldModal] = useState(false);
    const [selectedBudgetFiles, setSelectedBudgetFiles] = useState([]);
    const [selectedPlansFiles, setSelectedPlansFiles] = useState([]);
    const [selectedPhotoFiles, setSelectedPhotoFiles] = useState([]);
    const [selectedInspectionFiles, setSelectedInspectionFiles] = useState([]);

    const [fields, setFields] = useState({});
    const [update, setUpdate] = useState(moment());

    useEffect(() => {
        if (propReportRow && propReportRow.missing_docs_arr) {
            propReportRow.requiredDocCount = propReportRow.missing_docs_arr.length;
        };

    }, [propReportRow, update]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleActionClick = () => {
        setOpenReleaseHoldModal(true);
    };

    const handleToggleModal = (state) => {
        if (!isProcess) {
            state = !state;
            setOpenReleaseHoldModal(state);
            setFields({});
            setUpdate(moment());
        }
        setShowAll(false);
        setRequestData({ would_you_like_to_upload_additional_files: '0' });
    };

    const onDroPlans = (files) => {
        setSelectedPlansFiles(files?.selectedFiles);
        setUpdate(moment());
    };

    const onDropBudget = (files) => {
        setSelectedBudgetFiles(files?.selectedFiles);
        setUpdate(moment());
    };

    const onDropPhoto = (files) => {
        setSelectedPhotoFiles(files?.selectedFiles);
        setUpdate(moment());
    };

    const onDropInspection = (files) => {
        setSelectedInspectionFiles(files?.selectedFiles);
        setUpdate(moment());
    };

    const handleSubmit = () => {

        let uploadDocTypeCount = 0;

        if (selectedPhotoFiles.length) { uploadDocTypeCount++; };
        if (selectedInspectionFiles.length) { uploadDocTypeCount++; };
        if (selectedBudgetFiles.length) { uploadDocTypeCount++; };
        if (selectedPlansFiles.length) { uploadDocTypeCount++; };
        if (fields.property_info) { uploadDocTypeCount++; };
        if (fields.zoning_info) { uploadDocTypeCount++; };
        if (fields.property_type_confirmation) { uploadDocTypeCount++; };
        if (fields.subject_property_mapping_issue) { uploadDocTypeCount++; };
        if (fields.processing_or_calculation_issue) { uploadDocTypeCount++; };
        if (fields.client_request) { uploadDocTypeCount++; };

        if (!checkRoles(['ADMINISTRATOR', 'SALES_REP', 'REVIEWER', 'ACCOUNT_REP']) && uploadDocTypeCount === 0) {
            toast["error"]("Please provide at least one document/information.");
            return false;
        };

        let confirmation_text = "You are not uploading all missing document(s) / information. Do you want to release the hold ?";
        let save_document_btn_text = "No, Save document/details only";

        if (!checkRoles(['ADMINISTRATOR', 'SALES_REP', 'REVIEWER', 'ACCOUNT_REP']) && (propReportRow.requiredDocCount !== uploadDocTypeCount)) {
            confirmation_text = "You are not uploading all missing document(s) / information. Do you want to upload document(s) / information only ?";
            save_document_btn_text = "Yes, Save document/details only";
        };

        if (propReportRow.requiredDocCount !== uploadDocTypeCount) {

            let buttons = [];

            if (checkRoles(['ADMINISTRATOR', 'SALES_REP', 'REVIEWER', 'ACCOUNT_REP']) || (propReportRow.requiredDocCount === uploadDocTypeCount)) {
                buttons.push({
                    label: 'Yes, Release Hold',
                    onClick: () => releaseHold('Y'),
                    className: "btn btn-primary btn-sm"
                });
            }

            if (uploadDocTypeCount > 0) {
                buttons.push({
                    label: save_document_btn_text,
                    onClick: () => releaseHold('N'),
                    className: "btn btn-warning btn-sm"
                });
            }


            buttons.push({
                label: 'Close',
                onClick: () => { },
                className: "btn btn-outline-danger btn-sm"
            });

            confirmAlert({
                title: '',
                message: confirmation_text,
                closeOnClickOutside: false,
                buttons: buttons
            });

        } else {
            confirmAlert({
                title: '',
                message: "Are you sure you want to release the hold ?",
                closeOnClickOutside: false,
                buttons: [
                    {
                        label: 'Yes, Release Hold',
                        onClick: () => releaseHold('Y'),
                        className: "btn btn-primary btn-sm"
                    }, {
                        label: 'Close',
                        onClick: () => { },
                        className: "btn btn-outline-danger btn-sm"
                    }
                ]
            });
        }
    };

    const releaseHold = async (isReleaseHold) => {
        try {

            let row = propReportRow;

            let params = fields;

            setProcess(true);

            params.report_id = row.id;
            params.property_id = row.property_id;
            params.user_id = userData.user_id;
            params.hold_id = row.report_hold.id;
            params.release_hold = isReleaseHold;

            const data = new FormData()

            for (let i = 0; i < selectedPlansFiles.length; i++) {
                data.append(`plan_files`, selectedPlansFiles[i])
            }

            for (let i = 0; i < selectedBudgetFiles.length; i++) {
                data.append(`budget_files`, selectedBudgetFiles[i])
            }

            for (let i = 0; i < selectedInspectionFiles.length; i++) {
                data.append(`inspection_files`, selectedInspectionFiles[i])
            }

            for (let i = 0; i < selectedPhotoFiles.length; i++) {
                data.append(`photo_files`, selectedPhotoFiles[i])
            }

            for (var key in params) {
                data.append(key, params[key]);
            }

            const result = await PostData("orders/release_hold_file", data)
            let responseJson = result.data;
            if (responseJson.status === 200) {
                toast["success"](responseJson.message);
                handleToggleModal(isOpenReleaseHoldModal)
            } else {
                toast["error"](responseJson.message);
            }

            eventBus.dispatch("report_order_status_updated", { client_id: propReportRow.company_id });
            setProcess(false);
        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error, { memberCode: 401, componentName: 'ReleaseHoldButton', functionName: 'releaseHold', data: {} });
            setProcess(false);
        };
    };

    const AllHoldDocuments = ({ propReportRow, }) => {
        return (
            <>
                {
                    propReportRow?.missing_docs_arr?.includes('plans') &&
                    <Col xs="12" sm="12" md="6" lg="6">
                        <DragAndDropOrUpload label="Plans" accept="pdf" fileUploadCB={(files) => onDroPlans(files)} maxFileSize={10} maxFiles={2} selectedFiles={selectedPlansFiles} />
                    </Col>
                }

                {
                    propReportRow?.missing_docs_arr?.includes('budget') &&
                    <Col xs="12" sm="12" md="6" lg="6">
                        <DragAndDropOrUpload label="Budget" accept="pdf,excel" fileUploadCB={(files) => onDropBudget(files)} maxFileSize={2} maxFiles={2} selectedFiles={selectedBudgetFiles} />
                    </Col>
                }

                {
                    propReportRow?.missing_docs_arr?.includes('photo') &&
                    <Col xs="12" sm="12" md="6" lg="6">
                        <DragAndDropOrUpload label="Photos" accept="pdf,jpg,png" fileUploadCB={(files) => onDropPhoto(files)} maxFileSize={10} maxFiles={5} selectedFiles={selectedPhotoFiles} />
                    </Col>
                }

                {
                    propReportRow?.missing_docs_arr?.includes('inspection') &&
                    <Col xs="12" sm="12" md="6" lg="6">
                        <DragAndDropOrUpload label="Inspection" accept="pdf" fileUploadCB={(files) => onDropInspection(files)} maxFileSize={10} maxFiles={5} selectedFiles={selectedInspectionFiles} />
                    </Col>
                }

                {
                    propReportRow?.missing_docs_arr?.includes('property_info') &&
                    <Col xs="12" sm="12" md="6" lg="6">
                        <Label>Property Info</Label>
                        <AvField
                            name="property_info"
                            onChange={(e) => handleInput(e)}
                            className="form-control"
                            placeholder="Enter Property Info"
                            type="textarea"
                            validate={{
                                maxLength: {
                                    value: 255,
                                    errorMessage: 'Property must be less than 255 character'
                                }
                            }}
                        />
                    </Col>
                }

                {
                    propReportRow?.missing_docs_arr?.includes('zoning_info') &&
                    <Col xs="12" sm="12" md="6" lg="6">
                        <Label>Zoning Info</Label>
                        <AvField
                            name="zoning_info"
                            onChange={(e) => handleInput(e)}
                            className="form-control"
                            placeholder="Enter Zoning Info"
                            type="textarea"
                            validate={{
                                maxLength: {
                                    value: 255,
                                    errorMessage: 'Zoning info must be less than 255 character'
                                }
                            }}
                        />
                    </Col>
                }

                {
                    propReportRow?.missing_docs_arr?.includes('property_type_confirmation') &&
                    <Col xs="12" sm="12" md="6" lg="6">
                        <Label>Property Type Confirmation</Label>
                        <AvField
                            name="property_type_confirmation"
                            onChange={(e) => handleInput(e)}
                            className="form-control"
                            placeholder="Enter Property Type Confirmation Details"
                            type="textarea"
                            validate={{
                                maxLength: {
                                    value: 255,
                                    errorMessage: 'Property type confirmation must be less than 255 character'
                                }
                            }}
                        />
                    </Col>
                }

                {
                    propReportRow?.missing_docs_arr?.includes('subject_property_mapping_issue') &&
                    <Col xs="12" sm="12" md="6" lg="6">
                        <Label>Subject Property Mapping Issue</Label>
                        <AvField
                            name="subject_property_mapping_issue"
                            onChange={(e) => handleInput(e)}
                            className="form-control"
                            placeholder="Enter Subject Property Mapping Issue Details"
                            type="textarea"
                            validate={{
                                maxLength: {
                                    value: 255,
                                    errorMessage: 'Subject property mapping issue must be less than 255 character'
                                }
                            }}
                        />
                    </Col>
                }

                {
                    propReportRow?.missing_docs_arr?.includes('processing_or_calculation_issue') &&
                    <Col xs="12" sm="12" md="6" lg="6">
                        <Label>Processing Or Calculation Issue</Label>
                        <AvField
                            name="processing_or_calculation_issue"
                            onChange={(e) => handleInput(e)}
                            className="form-control"
                            placeholder="Enter Processing Or Calculation Issue Details"
                            type="textarea"
                            validate={{
                                maxLength: {
                                    value: 255,
                                    errorMessage: 'Processing or calculation issue must be less than 255 character'
                                }
                            }}
                        />
                    </Col>
                }

                {
                    propReportRow?.missing_docs_arr?.includes('client_request') &&
                    <Col xs="12" sm="12" md="6" lg="6">
                        <Label>Client Request</Label>
                        <AvField
                            name="client_request"
                            onChange={(e) => handleInput(e)}
                            className="form-control"
                            placeholder="Enter Client Request Details"
                            type="textarea"
                            validate={{
                                maxLength: {
                                    value: 255,
                                    errorMessage: 'Client request must be less than 255 character'
                                }
                            }}
                        />
                    </Col>
                }
            </>
        )

    }

    let handleInput = (e) => {
        let tempFields = fields;
        tempFields[e.target.name] = e.target.value
        setFields(tempFields);
    };

    const [showAll, setShowAll] = useState(false);
    const [requestData, setRequestData] = useState({ would_you_like_to_upload_additional_files: '0' });

    const onHandleChange = (e) => {
        // Logic to update state based on the selected option
        const { name, value } = e.target;
        setRequestData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        if (name === 'would_you_like_to_upload_additional_files' && value === '1') {
            setShowAll(true);
        } else if (name === 'would_you_like_to_upload_additional_files' && value === '0') {
            setShowAll(false);
        }
    };

    const allPropReportRowDocuments = {
        ...propReportRow, missing_docs_arr: [
            "budget",
            "photo",
            "inspection",
            "property_info",
            "zoning_info",
            "property_type_confirmation",
            "subject_property_mapping_issue",
            "processing_or_calculation_issue",
            "client_request",
            "plans"
        ]
    }

    allPropReportRowDocuments.missing_docs_arr = allPropReportRowDocuments.missing_docs_arr.filter(
        (item) => !propReportRow.missing_docs_arr?.includes(item)
    );

    return (
        <>
            <RenderActionButton propButtonTitle="Release Hold" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />

            <Modal className="modal-dialog-centered modal-lg release-hold-modal" style={{ maxWidth: "800px" }} isOpen={isOpenReleaseHoldModal} toggle={() => handleToggleModal(isOpenReleaseHoldModal)}>
                <div className="modal-header modal-header-colored d-flex align-items-center">
                    <h4 className="modal-title flex-grow-1 text-left">
                        Release Hold: {checkRoles(['ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP', 'REVIEWER', 'ANALYST', 'DRV_ADMIN']) ? (
                            <AutoLoginDecrypt
                                data={{
                                    label: propReportRow.property_address,
                                    url: `${phpUrl}valuation/deals/details/${propReportRow.property_id}`,
                                }}
                            />
                        ) : (
                            <span>{propReportRow.property_address}</span>
                        )}
                    </h4>
                    <h4 className="modal-title flex-grow-1 text-right">
                        {propReportRow.report_type}
                    </h4>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenReleaseHoldModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body py-2">
                    <div className="text-left">
                        <AvForm onValidSubmit={(e) => handleSubmit()} >
                            <Row>
                                <AllHoldDocuments propReportRow={propReportRow} />

                                <Col xs="12" sm="12" md="12" lg="12">
                                    <Label className="required">Would you like to upload additional files?</Label>
                                    <div className={"custom-radio-btn inline"}>
                                        <div>
                                            <input
                                                type="radio"
                                                id="would_you_like_to_upload_additional_files_yes"
                                                name="would_you_like_to_upload_additional_files"
                                                value={'1'}
                                                onChange={(e) => onHandleChange(e)}
                                                checked={requestData.would_you_like_to_upload_additional_files === '1'}
                                            />
                                            <label htmlFor="would_you_like_to_upload_additional_files_yes">Yes</label>
                                        </div>
                                        <div>
                                            <input
                                                type="radio"
                                                id="would_you_like_to_upload_additional_files_no"
                                                name="would_you_like_to_upload_additional_files"
                                                value={'0'}
                                                onChange={(e) => onHandleChange(e)}
                                                checked={
                                                    requestData.would_you_like_to_upload_additional_files === undefined ||
                                                    requestData.would_you_like_to_upload_additional_files === '0'
                                                }
                                            />
                                            <label htmlFor="would_you_like_to_upload_additional_files_no">No</label>
                                        </div>
                                    </div>
                                </Col>

                                {showAll && <AllHoldDocuments propReportRow={allPropReportRowDocuments} />}

                                <Col xs="12" sm="12" md="12" lg="12">
                                    <div className="text-right">
                                        <AvGroup>
                                            {isProcess ?
                                                <Button type="button" color="warning" size="sm">
                                                    <div className="fa fa-spinner fa-spin"></div> Processing...
                                                </Button>
                                                :
                                                <>
                                                    <Button size="sm" color="danger" outline onClick={() => handleToggleModal(isOpenReleaseHoldModal)}>
                                                        Cancel
                                                    </Button>
                                                    <Button size="sm" color="primary">
                                                        Submit
                                                    </Button>
                                                </>
                                            }
                                        </AvGroup>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </div>
                </div>
            </Modal>

        </>
    );
};

export default ReleaseHoldButton;