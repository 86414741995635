import React, { useState, useEffect } from "react";
import { Button, Modal, FormGroup, Col, Row, Label } from "reactstrap";
import { PostData } from "../../../../../services/Api.service.js";
import { GetData as OrderIntakeGet } from "services/OrderIntakeData";
import { GetData } from "services/Api.service";
import { loggedInUser, formatDate, encryptData } from "components/Common/Helpers.js"
import { toast } from 'react-toastify';
import { AvForm, AvGroup, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import eventBus from "../../EventBus.js";
import * as moment from "moment";
import { IO_CANCELLED, IO_COMPLETED, RO_REPORT_CANCELLED } from "../../Constants.js";
import PaymentForm from "../../PaymentForm.js";
import ReportOrderOriginalSource from "../../ReportOrderOriginalSource.js";
import { sendExceptionEmail } from "components/Common/Helpers";
import { checkRoles } from "components/Common/Helpers";
import RenderActionButton from "views/modules/Common/ActionsButtons/RenderActionButton";

const CancelledReopenClientButton = ({ propReportRow, propButtonOptions }) => {

    const userData = loggedInUser();
    let [isProcess, setProcess] = useState(false);
    let [isOpenReopenFilesModal, setOpenReopenFilesModal] = useState(false);
    let [questionComment, setQuestionComment] = useState("");
    let [update, setUpdate] = useState(moment());
    let [showStripeMsg, setShowStripeMsg] = useState(false);
    let [showRefundMsg, setShowRefundMsg] = useState(false);
    let [isReopenInspection, setReopenInspection] = useState("");
    let [inspectionPriceData, setInspectionPriceData] = useState([]);
    let [inspectionOptionType, setInspectionOptionType] = useState(null);
    let [inspectionType, setInspectionType] = useState(null);
    let [eligibleInspectionTypes, setEligibleInspectionTypes] = useState([]);
    let [isContactInfoRequired, setContactInfoRequired] = useState(false);

    let [fields, setFields] = useState({});
    let defaultValues = {
        vendor_id: null,
        inspection_type: null,
        contact_name: propReportRow.property_access_contacts.length ? propReportRow.property_access_contacts[0].contact_name : '',
        contact_email: propReportRow.property_access_contacts.length ? propReportRow.property_access_contacts[0].contact_email : '',
        contact_phone: propReportRow.property_access_contacts.length ? propReportRow.property_access_contacts[0].contact_phone : '',
    };

    let [isOpenPaymentSourceModal, setIsOpenPaymentSourceModal] = useState({ current: false });
    let [clientId, setClientId] = useState();
    let [selectedPaymentSource, setSelectedPaymentSource] = useState(null);
    let [paymentError, setPaymentError] = useState(null);
    let [defaultSourceChecked, setDefaultSourceChecked] = useState(false);
    let [doPaymentProcess, setDoPaymentProcess] = useState(null);
    let [glaAndLicensingSettings, setGlaAndLicensingSettings] = useState([]);

    useEffect(() => {
        setSelectedPaymentSource(null);
        setClientId(propReportRow.company_id);
        setPaymentError(null);
        setDefaultSourceChecked(false);
    }, [propReportRow]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { }, [update]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        let asyncFuncs = async () => {
            if (inspectionType) {
                await getInspectionsFees(inspectionType);
            }
        }
        asyncFuncs()
    }, [inspectionType, fields?.gla_include, fields?.licensing_required]); // eslint-disable-line react-hooks/exhaustive-deps

    let handleToggleModal = (state) => {
        if (!isProcess) {
            state = !state;
            setOpenReopenFilesModal(state);
            setShowStripeMsg(false);
            setShowRefundMsg(false);
            setPaymentError(null);
            defaultValues.inspection_type = '0';
            setDoPaymentProcess(null);
            setSelectedPaymentSource(null)
            setUpdate(moment());
        }
    };

    let handleOrderStatus = async (e, type, data) => {
        setOpenReopenFilesModal(true);

        let fieldsTemp = {
            contact_name: propReportRow && propReportRow.property_access_contacts.length ? propReportRow.property_access_contacts[0].contact_name : '',
            contact_email: propReportRow && propReportRow.property_access_contacts.length ? propReportRow.property_access_contacts[0].contact_email : '',
            contact_phone: propReportRow && propReportRow.property_access_contacts.length ? propReportRow.property_access_contacts[0].contact_phone : '',
        }
        fieldsTemp = { ...fieldsTemp, ...await retrieveGLAAndLicensingSettings() }
        fieldsTemp = { ...fieldsTemp }

        setFields(fieldsTemp);
        setUpdate(moment());
    }

    let handleReopenFilesModal = async (e) => {

        e.preventDefault();

        if (([RO_REPORT_CANCELLED].includes(propReportRow.order_status)) && propReportRow.id) {

            try {
                let params = {};

                let current_inspection_type = null;
                if (propReportRow.inspection_order) {
                    current_inspection_type = propReportRow.inspection_order.inspection_type?.slug;
                }

                params.id = propReportRow.id;
                params.property_id = propReportRow.property_id;
                params.user_id = userData.user_id;
                params.question_comment = questionComment;
                params.inspection_type = inspectionType ? inspectionType : current_inspection_type;
                params.cancel_reopen_inspection_option_type = isReopenInspection;
                if (isReopenInspection === 'choose_different' || inspectionOptionType === 'updated_inspection') {
                    params.inspection_type = inspectionType ? inspectionType : current_inspection_type;
                }

                if (inspectionOptionType === 'updated_inspection') {
                    params.order_new_inspection_amt = inspectionPriceData?.totalVariance;
                    params.order_new_inspection = 'true';
                } else {
                    params.order_new_inspection = 'false';
                }

                params.inspections_status = propReportRow.inspection_order.inspection_status;
                params.inspection_order_id = propReportRow.inspection_order.id;

                let fieldsTemp = { ...fields }

                fieldsTemp.gla_include = fieldsTemp.gla_applicable === '1' ? fieldsTemp.gla_include : '0'
                fieldsTemp.licensing_required = fieldsTemp.licensing_applicable === '1' ? fieldsTemp.licensing_required : '0'

                params.other_fields = fieldsTemp;

                if (selectedPaymentSource && selectedPaymentSource.payment_source) {
                    params.payment_source = selectedPaymentSource.payment_source;
                }

                params.do_payment_process = doPaymentProcess;

                let encrypt = encryptData(params);

                setProcess(true);
                let result = await PostData("client/orders/cancelled_reopen_files", { data: encrypt })
                setProcess(false);
                toast["success"](result.message);
                eventBus.dispatch("report_order_status_updated", { client_id: propReportRow.company_id });
                setOpenReopenFilesModal(false);
            } catch (error) {
                if (error?.errors?.err_code === 'PAYMENT_ERROR') {
                    setPaymentError(error.message);
                    setSelectedPaymentSource(null);
                    setUpdate(moment());
                } else {
                    await sendExceptionEmail(error, { memberCode: 200, componentName: 'CancelledReopenClientButton', functionName: 'handleReopenFilesModal', data: {} });
                    toast["error"]("Something went wrong.");
                }
                setProcess(false);
            }
        } else {
            setProcess(false);
            toast["error"]("Something went wrong.");
        }
    };

    let handleUpdateInspection = (updateInspectionType, ven_id) => {
        setReopenInspection(updateInspectionType);
        if (updateInspectionType === "choose_different") {
            onHandleVendorChange(ven_id);
        } else if (updateInspectionType === "same_inspection") {
            setShowStripeMsg(false);
            setShowRefundMsg(false);
            setInspectionPriceData([]);
            setEligibleInspectionTypes([]);
            defaultValues.inspection_type = '0';
        } else {
            setEligibleInspectionTypes([]);
        }
        setUpdate(moment());
    }

    let onHandleVendorChange = async (vendor_id) => {
        setShowStripeMsg(false);
        setShowRefundMsg(false);
        setInspectionPriceData([]);
        setEligibleInspectionTypes([]);
        defaultValues.inspection_type = '0';
        setContactInfoRequired(false);
        if (vendor_id !== 0) {
            setProcess(true);

            try {
                let params = {
                    vendor_id: vendor_id,
                    company_id: propReportRow.company_id
                };

                if (propReportRow?.isStandalone === '1') {
                    params.inspection_type = 'standalone-inspection'
                } else {
                    params.inspection_type = 'report-inspection'
                    params.report_type_id = propReportRow.report_type_id
                }

                const result = await GetData("inspection/vendor-eligible-inspection-types", params)

                setEligibleInspectionTypes(result.data.inspectionTypes);
                setProcess(false);
            } catch (error) {
                setProcess(false);
                toast["error"]("Something went wrong.");
            }
        }
    };

    let onInspectionTypeChanged = async (val) => {


        let { slug, contact_info_required, gla_applicable, licensing_applicable } = val

        let fieldsTemp = fields

        fieldsTemp.gla_applicable = gla_applicable
        fieldsTemp.licensing_applicable = licensing_applicable

        setFields(fieldsTemp);

        setInspectionType(slug);
        setContactInfoRequired(contact_info_required === '1' ? true : false);
        setUpdate(moment());
    };

    let retrieveGLAAndLicensingSettings = async () => {
        let requestDataTemp = {};
        try {
            const glaAndLicensingSettingsResp = await OrderIntakeGet("company/gla-licensing-settings", { company_token: '{company_token}', }, propReportRow.company_id);
            setGlaAndLicensingSettings(glaAndLicensingSettingsResp.data)

            if (!requestDataTemp['gla_include'] && (glaAndLicensingSettingsResp.data?.glaAndLicensingSettings?.gla_default === '1' || glaAndLicensingSettingsResp.data?.glaAndLicensingSettings?.gla_required === '1')) {
                requestDataTemp['gla_include'] = '1'
            }

            if (!requestDataTemp['licensing_required'] && (glaAndLicensingSettingsResp.data?.glaAndLicensingSettings?.licensed_default === '1' || glaAndLicensingSettingsResp.data?.glaAndLicensingSettings?.licensed_required === '1')) {
                requestDataTemp['licensing_required'] = '1'
            }

            return requestDataTemp;
        } catch (error) {
            toast["error"](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error, { memberCode: 200, componentName: 'CancelledReopenClientButton', functionName: 'retrieveGLAAndLicensingSettings', data: {} });

        }
    };

    let onDataChange = (e) => {

        let { name, value, checked } = e.target;

        if (['gla_include', 'licensing_required'].includes(name)) {
            value = checked ? '1' : '0';
        }

        let fieldsTemp = { ...fields };
        fieldsTemp[name] = value

        setFields(fieldsTemp);
        setUpdate(moment());
    };

    let getInspectionsFees = async (inspection_type) => {
        try {
            let params = {
                inspection_order_type: "report-inspection",
                report_order_id: propReportRow.id,
                new_inspection_type: inspection_type,
                gla_include: fields?.gla_applicable === '1' ? fields.gla_include : '0',
                licensing_required: fields?.licensing_applicable === '1' ? fields.licensing_required : '0',
            };

            setShowStripeMsg(false);
            setShowRefundMsg(false);
            setProcess(true);
            setSelectedPaymentSource(null);

            const result = await PostData("inspection/price-comparison", params)
            let inspectionFeesData = result.data

            let doPaymentProcessTemp = null;
            if (parseFloat(inspectionFeesData.totalVariance) > 0) {
                setShowStripeMsg(true);
                doPaymentProcessTemp = 'yes';
            } else if (parseFloat(inspectionFeesData.totalVariance) < 0) {
                setShowRefundMsg(true);
                doPaymentProcessTemp = 'no';
            }

            setDoPaymentProcess(doPaymentProcessTemp)
            setInspectionPriceData(inspectionFeesData);
            setProcess(false);
            setUpdate(moment())
        } catch (errors) {
            setProcess(false);
            await sendExceptionEmail(errors, { memberCode: 200, componentName: 'CancelledReopenClientButton', functionName: 'getInspectionsFees', data: {} });
            toast["error"](errors?.errors?.length ? errors.errors[0].message : errors.message);
        };
    };

    let openPaymentMethodSelector = () => {
        setSelectedPaymentSource(null);
        setIsOpenPaymentSourceModal({ current: true });
    };

    let handlePaymentSourceCb = (result) => {
        if (result) {
            setSelectedPaymentSource(result);
            setUpdate(moment());
        }
    };

    let onReportOrderOriginalSourceCB = (result) => {
        setSelectedPaymentSource(result);
        setDefaultSourceChecked(true);
        setUpdate(moment());
    };

    let onInspectionOptionChange = (e) => {
        const { value } = e.target;

        setInspectionOptionType(value);

        if (value === 'updated_inspection') {
            onHandleVendorChange(propReportRow.inspection_order.inspection_vendor.id);
        } else {
            onHandleVendorChange(0);
        }
    }

    return (
        <>
            <RenderActionButton propButtonTitle="Reopen File" propButtonOptions={propButtonOptions} propOnClickEvent={e => handleOrderStatus(e, 'reopen_files', propReportRow)} />

            {/* Reopen Files Modal */}
            <Modal className="modal-dialog-centered reopen-files modal-lg" isOpen={isOpenReopenFilesModal} toggle={() => handleToggleModal("isOpenReopenFilesModal")}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title w-100 text-center" id="reassignModalLabel">
                        Reopen Files
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal("isOpenReopenFilesModal")}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body text-left">
                    <h2 className="text-center">Purpose of Reopening this file:</h2>
                    <AvForm onValidSubmit={(e) => handleReopenFilesModal(e)} >
                        {(parseInt(propReportRow.inspection_order.inspection_status) === IO_CANCELLED && [RO_REPORT_CANCELLED].includes(propReportRow.order_status) === true) &&
                            <>
                                <Row>
                                    <Col className="custom-av-radio-btn inline">
                                        <p>The previous order included <b>{propReportRow.inspection_order.inspection_type.name}</b> inspection. Would you like to reopen the inspection ? </p>
                                        <AvRadioGroup name="cancel_inspection" required onChange={e => handleUpdateInspection(e.target.value, propReportRow.inspection_order.inspection_vendor.id)} >
                                            <AvRadio label='Yes, the same inspection' value='same_inspection' />
                                            <AvRadio label='No, choose a different inspection' value='choose_different' />
                                            <AvRadio label="Don't order an inspection" value='none' />
                                        </AvRadioGroup>
                                    </Col>
                                </Row>
                            </>
                        }
                        {(parseInt(propReportRow.inspection_order.inspection_status) === IO_COMPLETED && [RO_REPORT_CANCELLED].includes(propReportRow.order_status) === true) &&
                            <>
                                <Row>
                                    <Col className="custom-av-radio-btn inline">
                                        <p>The previous <b>{propReportRow.inspection_order.inspection_type.name}</b> completed on <b>{formatDate(null, propReportRow.inspection_order.last_update_date, "MMM D, YY h:mm a")}</b>. Would you like to order updated inspection OR keep existing inspection ? </p>
                                        <AvRadioGroup name="completed_inspection" required  >
                                            <AvRadio label="Updated Inspection (Additional Charge)" value='updated_inspection' onChange={(e) => onInspectionOptionChange(e)} />
                                            <AvRadio label='Keep existing ($0)' value='keep_existing' onChange={(e) => onInspectionOptionChange(e)} />
                                        </AvRadioGroup>
                                    </Col>
                                </Row>
                            </>
                        }

                        {eligibleInspectionTypes.length > 0 &&
                            <>
                                <Row>
                                    <Col >
                                        <Label className="required label-font">Inspection Type</Label>
                                        <div className="custom-av-radio-btn inline">

                                            <AvRadioGroup name="inspection_type" required >
                                                {eligibleInspectionTypes.map(function (inspectionEligibility, i) {
                                                    return (
                                                        inspectionEligibility &&
                                                        <AvRadio key={i} label={inspectionEligibility.name} value={inspectionEligibility.slug} onClick={(e) => onInspectionTypeChanged(inspectionEligibility)} />
                                                    );
                                                })}
                                            </AvRadioGroup>
                                        </div>
                                    </Col>
                                </Row>

                                {fields?.gla_applicable === '1' &&
                                    <Row>
                                        <Col className="my-2">
                                            <div color="info" className="custom-control custom-checkbox" sm="2">
                                                <input
                                                    className="custom-control-input"
                                                    name="gla_include"
                                                    id="gla_include"
                                                    type="checkbox"
                                                    value='1'
                                                    onChange={(e) => onDataChange(e)}
                                                    disabled={glaAndLicensingSettings?.glaAndLicensingSettings?.gla_required === '1'}
                                                    checked={(fields?.gla_include === '1')}
                                                />
                                                <label className="custom-control-label" htmlFor="gla_include">
                                                    Include GLA Measurement and Floorplan (${glaAndLicensingSettings?.glaAndLicensingCharges?.gla_surcharge})
                                                </label>
                                            </div>
                                        </Col>
                                    </Row>
                                }

                                {fields?.licensing_applicable === '1' &&
                                    <Row>
                                        <Col className="my-2">
                                            <div color="info" className="custom-control custom-checkbox" sm="2">
                                                <input
                                                    className="custom-control-input"
                                                    name="licensing_required"
                                                    id="licensing_required"
                                                    type="checkbox"
                                                    value='1'
                                                    onChange={(e) => onDataChange(e)}
                                                    disabled={glaAndLicensingSettings?.glaAndLicensingSettings?.licensed_required === '1'}
                                                    checked={fields?.licensing_required === '1'}
                                                />
                                                <label className="custom-control-label" htmlFor="licensing_required">
                                                    Require licensed agents only (${glaAndLicensingSettings?.glaAndLicensingCharges?.licensing_surcharge})
                                                </label>
                                            </div>
                                        </Col>
                                    </Row>
                                }

                                {isContactInfoRequired &&
                                    <Row className="form-control-table-narrow">
                                        <Col lg="12">
                                            <h2>Property Access Contact Information</h2>
                                        </Col>
                                        <Col md="4" >
                                            <AvGroup>
                                                <AvField
                                                    name={"contact_name"}
                                                    onChange={(event) => setFields(prevState => ({
                                                        ...prevState,
                                                        contact_name: event.target.value,
                                                    }))}
                                                    className="form-control"
                                                    placeholder="Contact Name"
                                                    type="text"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "This field is required."
                                                        },
                                                        maxLength: {
                                                            value: 50,
                                                            errorMessage: 'Max length is 50'
                                                        }
                                                    }}
                                                />
                                            </AvGroup>
                                        </Col>
                                        <Col md="4" >
                                            <AvGroup>
                                                <AvField
                                                    name={"contact_email"}
                                                    onChange={(event) => setFields(prevState => ({
                                                        ...prevState,
                                                        contact_email: event.target.value,
                                                    }))}
                                                    className="form-control"
                                                    placeholder="Contact Email"
                                                    type="email"
                                                    validate={{
                                                        pattern: {
                                                            value: '/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$/',
                                                            errorMessage: 'Invalid Email'
                                                        },
                                                        maxLength: {
                                                            value: 50,
                                                            errorMessage: 'Max length is 50'
                                                        }
                                                    }}
                                                />
                                            </AvGroup>
                                        </Col>
                                        <Col md="4" >
                                            <AvGroup>
                                                <AvField
                                                    name={"contact_phone"}
                                                    onChange={(event) => setFields(prevState => ({
                                                        ...prevState,
                                                        contact_phone: event.target.value,
                                                    }))}
                                                    className="form-control"
                                                    placeholder="Contact Phone"
                                                    type="text"
                                                    validate={{
                                                        pattern: {
                                                            value: '/^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})(?: *x(\\d+))?\\s*$/',
                                                            errorMessage: 'Invalid phone number'
                                                        }
                                                    }}
                                                />
                                            </AvGroup>
                                        </Col>
                                    </Row>
                                }
                            </>
                        }

                        {(showStripeMsg || showRefundMsg) &&
                            <>
                                {checkRoles(["ADMINISTRATOR", "SALES_REP", "ACCOUNT_REP"]) ?
                                    <Row>
                                        <Col xs="12" sm="12" md="12" lg="12" className="my-2">
                                            {showStripeMsg &&
                                                <Label className="required"><b> Fee Increase:</b> The new inspection has a fee of ${inspectionPriceData.totalNew} and the previous inspection was charged at ${inspectionPriceData.totalCurrent}.Would you like to charge the client in the amount of ${Math.abs(inspectionPriceData.totalVariance)} ? </Label>
                                            }
                                            {showRefundMsg &&
                                                <Label className="required"><b>Possible Refund:</b> The previous inspection was charged at ${inspectionPriceData.totalCurrent} and the new inspection has a fee of ${inspectionPriceData.totalNew}.  Would you like to provide a refund to the client of ${Math.abs(inspectionPriceData.totalVariance)} ? </Label>
                                            }
                                            <div className="custom-radio-btn inline">
                                                <div >
                                                    <input type="radio" id='do_payment_process_yes' name="do_payment_process" value='yes'
                                                        onChange={(event) => {
                                                            setSelectedPaymentSource(null);
                                                            setDoPaymentProcess(event.target.value)
                                                        }
                                                        }
                                                        checked={'yes' === doPaymentProcess}
                                                    />
                                                    <label htmlFor='do_payment_process_yes'>Yes </label>
                                                </div>
                                                <div >
                                                    <input type="radio" id='do_payment_process_no' name="do_payment_process" value='no'
                                                        onChange={(event) => {
                                                            setSelectedPaymentSource(null);
                                                            setDoPaymentProcess(event.target.value)
                                                        }}
                                                        checked={'no' === doPaymentProcess}
                                                    />
                                                    <label htmlFor='do_payment_process_no'>No</label>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row> :
                                    <>
                                        {(showStripeMsg) &&
                                            <Row>
                                                <Col className="mb-3">
                                                    <Label>New inspection order required the additional charge of <b> ${inspectionPriceData.totalVariance}</b>. </Label>
                                                </Col>
                                            </Row>
                                        }
                                    </>
                                }
                            </>
                        }

                        {(showStripeMsg && doPaymentProcess === 'yes') &&
                            <Row>
                                <Col className="mb-3">

                                    {!paymentError && <ReportOrderOriginalSource reportOrderId={propReportRow.report_order_id} inspectionOrderId={propReportRow.inspection_order_id} onReportOrderOriginalSourceCB={onReportOrderOriginalSourceCB} />}
                                    {paymentError &&
                                        <Row>
                                            <span className="col-md-12 text-danger font-weight-bold">
                                                The payment was failed because of following reason. <br />
                                                <ul><li>{paymentError}</li></ul>
                                            </span >
                                        </Row>
                                    }
                                    {(selectedPaymentSource && selectedPaymentSource?.isOriginalSource === false) &&
                                        <Label><span className="text-success mr-2"><i className="ni ni-check-bold"></i> {selectedPaymentSource.selectionText}</span><i className="fa fa-pencil-alt text-danger" onClick={() => openPaymentMethodSelector()} aria-hidden="true"></i></Label>
                                    }

                                    {(!selectedPaymentSource && defaultSourceChecked) &&
                                        <Button size="sm" color="warning" outline onClick={() => openPaymentMethodSelector()}> <i className="fas fa-credit-card"></i> Select Payment Method </Button>
                                    }
                                </Col>
                            </Row>
                        }

                        <Row>
                            <Col>
                                <AvGroup>
                                    <Label>Reason / Request <span className="required">*</span></Label>
                                    <AvField maxLength="1000" type="textarea" onChange={(e) => setQuestionComment(e.target.value)} cols="100" name="question_comment" id="question_comment" placeholder="Type here..." required />
                                </AvGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12" className="text-right mt-2">
                                <FormGroup>
                                    {isProcess ?
                                        <Button type="button" color="warning" size="sm">
                                            <div className="fa fa-spinner fa-spin"></div> Processing...
                                        </Button>
                                        :
                                        <>
                                            <Button size="sm" color="danger" outline onClick={() => handleToggleModal("isOpenCancelReportOrderModal")}>
                                                Close
                                            </Button>
                                            <Button size="sm" color="primary" className={(showStripeMsg && doPaymentProcess === 'yes' && !selectedPaymentSource) ? "disabled" : ""}> Submit </Button>
                                        </>
                                    }
                                </FormGroup>
                            </Col>
                        </Row>
                    </AvForm >
                </div >
            </Modal >

            <PaymentForm onPay={isOpenPaymentSourceModal}
                paymentDetails={{
                    total_amount: inspectionPriceData?.totalVariance,
                    cc_surcharge: null,
                    paymentFor: 'Inspection Order'
                }}
                onPaymentSourceCb={handlePaymentSourceCb} clientId={clientId} />
        </>
    );
};

export default CancelledReopenClientButton;