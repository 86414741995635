import React, { useEffect, useState } from "react";
import { Button, Col, Label, Modal, Row } from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { AvField, AvForm } from "availity-reactstrap-validation";
import * as moment from "moment";
import {
    GetData as OrderIntakeGet,
    PostData as OrderIntakePost,
} from "services/OrderIntakeData";
import { sendExceptionEmail } from "components/Common/Helpers";
import ToEmailsTag from "components/Common/ToEmailsTag";
import CcEmailsTag from "components/Common/CcEmailsTag";
import RenderActionButton from "./RenderActionButton";

const InspectionOrderSendBorrowerPaymentLinkButton = ({ propRowData, propButtonOptions, onHandleData }) => {

    const [isOpenSendToBorrowerModal, setIsOpenSendToBorrowerModal] = useState(false);
    const [update, setUpdate] = useState(moment());
    const [toEmails, setToEmails] = useState([]);
    const [toCc, setToCC] = useState([]);
    const [borrowerComment, setBorrowerComment] = useState("");
    const [customError, setCustomError] = useState("");
    const [defaultTo, setDefaultTo] = useState([]);
    const [defaultCC, setDefaultCC] = useState([]);
    const [defaultComment, setDefaultComment] = useState('');
    const [isProcessing, setProcessing] = useState(true);

    useEffect(() => { }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

    let handleActionClick = async (e, row) => {
        e.preventDefault();
        await fetchBorrowerEmailRow();
        setIsOpenSendToBorrowerModal(true);
        setUpdate(moment())
    };

    let handleToggleModal = (state) => {
        state = !state;
        setIsOpenSendToBorrowerModal(state);
        setDefaultTo([]);
        setDefaultCC([]);
        setDefaultComment('');
        setCustomError("");
        setUpdate(moment());
    };

    let fetchBorrowerEmailRow = async (e) => {

        setProcessing(true);
        try {
            let response = await OrderIntakeGet(`inspection/${propRowData.intake_token}/borrower-sent-email-row`, {}, propRowData.company_id)

            if (response.data.emailData) {
                let emailData = response.data.emailData;

                let defaultTo = emailData.to_emails ? JSON.parse(emailData.to_emails) : [];
                setDefaultTo(defaultTo);
                setToEmails(defaultTo);

                let defaultCC = emailData.cc_emails ? JSON.parse(emailData.cc_emails) : [];
                setDefaultCC(defaultCC);
                setToCC(defaultCC);

                let defaultComment = emailData.comment ? emailData.comment : '';

                setDefaultComment(defaultComment);
                setBorrowerComment(defaultComment);

                setUpdate(moment());
            } else {
                setDefaultTo([]);
                setToEmails([]);
                setDefaultCC([]);
                setToCC([]);
                setDefaultComment('');
                setBorrowerComment('');
                setUpdate(moment());
            }

            setProcessing(false);
        } catch (error) {
            await sendExceptionEmail(error, { memberCode: 504, componentName: 'InspectionOrderSendBorrowerPaymentLinkButton', functionName: 'fetchBorrowerEmailRow', data: {} });
            confirmAlert({
                title: 'Error',
                message: (error.errors.length && error.errors[0].message) ? error.errors[0].message : error.message,
                closeOnClickOutside: false,
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => { },
                        className: "btn btn-danger btn-sm"
                    }
                ]
            });
            setProcessing(false);
        }
    };

    let sendToBorrower = async (e) => {

        if (toEmails.length > 0) {
            let params = {
                to: toEmails,
                cc: toCc
            }
            if (borrowerComment) {
                params.comment = borrowerComment;
            }
            try {
                setProcessing(true);
                let response = await OrderIntakePost(`inspection/${propRowData.intake_token}/send-payment-link-to-borrower`, params, propRowData.company_id)
                setIsOpenSendToBorrowerModal(false);
                setToEmails([]);
                setToCC([]);
                setProcessing(false);
                toast["success"](response.message);

                if (onHandleData) {
                    onHandleData(response)
                }
            } catch (error) {
                await sendExceptionEmail(error, { memberCode: 504, componentName: 'InspectionOrderSendBorrowerPaymentLinkButton', functionName: 'sendToBorrower', data: {} });
                confirmAlert({
                    title: 'Error',
                    message: error?.errors?.length ? error.errors[0].message : error.message,
                    closeOnClickOutside: false,
                    buttons: [
                        {
                            label: 'Ok',
                            onClick: () => { },
                            className: "btn btn-danger btn-sm"
                        }
                    ]
                });
                setIsOpenSendToBorrowerModal(false);
                setProcessing(false);
            }
        } else {
            setCustomError("Please enter valid email.");
        }
    };

    let handleToEmail = (response) => {
        setCustomError("");
        setToEmails(response);
        setDefaultTo(response);
    };

    let handleCcEmail = (response) => {
        setCustomError("");
        setToCC(response);
        setDefaultCC(response);
    };

    return (
        <>
            <RenderActionButton propButtonTitle="Send/Resend payment link to borrower" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />

            {isOpenSendToBorrowerModal &&
                <Modal className="modal-dialog-centered modal-lg" isOpen={isOpenSendToBorrowerModal} toggle={() => handleToggleModal(isOpenSendToBorrowerModal)}>
                    <div className="modal-header modal-header-colored">
                        <h2 className="modal-title w-100 text-center" id="reassignModalLabel">
                            Send Payment Link to Borrower
                        </h2>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenSendToBorrowerModal)}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body mt-3 mb-3">
                        {/* <hr /> */}
                        <AvForm onValidSubmit={(e) => sendToBorrower(e)} className="text-left">
                            <Row>
                                <small className="col-md-12 text-danger font-weight-bold mb-3">
                                    Orders will begin only after payment is made. This order will sit in your payment queue, and once the payment link is used and payment is made, we will commence work on your order. Thank you for your understanding and cooperation!
                                </small >
                            </Row>
                            <Row>
                                <Col className="col-md-6">
                                    <ToEmailsTag onHandleEmail={handleToEmail} defaultTO={defaultTo} />
                                    <div className="form-error">{customError}</div>
                                </Col>
                                <Col className="col-md-6">
                                    <CcEmailsTag onHandleCcEmail={handleCcEmail} defaultCC={defaultCC} />
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col>
                                    <Label className=""><b>Comment</b></Label>
                                    <AvField value={defaultComment} maxLength="1000" type="textarea" rows="5" cols="100" name="question_comment" id="question_comment" placeholder="Type here..." onChange={e => setBorrowerComment(e.target.value)} validate={{
                                        maxLength: { value: 500, errorMessage: "Max length must be 1000." }

                                    }} />
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                <Col className="col-md-6"></Col>
                                <Col className="col-md-6 text-right">
                                    {isProcessing ?
                                        <Button type="button" color="warning" size="sm">
                                            <div className="fa fa-spinner fa-spin"></div> Processing...
                                        </Button>
                                        :
                                        <>
                                            <Button color="danger" outline size="sm" onClick={() => handleToggleModal(isOpenSendToBorrowerModal)} >
                                                Cancel
                                            </Button>
                                            <Button color="primary" size="sm" >
                                                Send
                                            </Button>
                                        </>
                                    }
                                </Col>
                            </Row>

                        </AvForm>
                    </div>
                </Modal>
            }
        </>
    );
};

export default InspectionOrderSendBorrowerPaymentLinkButton;