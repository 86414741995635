import { createSlice } from '@reduxjs/toolkit'
import { defaultValues } from './ValDetailsAPIHelper';

const initialStates = {
	initialDataRendered: false,

	bestAIVComps: {},
	conditionLevels: {},
	aivArvVAType: 'aiv',

	compoListErr: false,
	compoListFetching: true,

	propertyFilesFetchingErr: false,
	propertyFilesFetchingFetching: true,
	propertyFilesFetching: [],

	confidenceScoreDataErr: false,
	confidenceScoreDataFetching: true,
	confidenceScoreData: {},

	marketDemandDataFetching: true,
	marketDemandData: {},

	compSortingParams: {
		// smallestAdjmtsComps: { sortingField: 'default_list_number', sortingDirection: 'asc' },
		// similarConditionComps: { sortingField: 'default_list_number', sortingDirection: 'asc' },
		compArr: { sortingField: 'default_list_number', sortingDirection: 'asc' },
	},
	expandedRows: [],
	compFilterParams: { ...defaultValues().compFilterParams },
	displayComps: {},
	noOfFilteredComps: { active: 0, pending: 0, sold: 0, },
	noOfFilterApplied: 0,
	noOfTotalComps: { active: 0, pending: 0, sold: 0, },

	adjustmentFactors: {
		mutedFields: [],
		sliderValue: defaultValues().adjustmentFactorSliderValue,
		averageAdjustmentValues: {}
	}
};

export const ValDetailsReducer = createSlice({
	name: 'ValDetails',
	initialState: { ...initialStates },
	reducers: {
		setValDetailsData: (state, data) => {
			data?.payload?.forEach(element => {
				state[element.key] = element.value
			});
		},
		setValDetailsFilterParams: (state, data) => {
			data?.payload?.forEach(element => {
				state.compFilterParams[element.key] = element.value
			});
		},
		setCompList: (state, data) => {
			data?.payload?.forEach(element => {
				state.displayComps[element.key] = element.value
			});
		},
		setNoOfFilteredComps: (state, data) => {
			data?.payload?.forEach(element => {
				state.noOfFilteredComps[element.key] = element.value
			});
		},
		setValDetailsAdjustmentFactorValues: (state, data) => {
			data?.payload?.forEach(element => {
				state.adjustmentFactors[element.key] = element.value
			});
		},
		setBestAIVCompValues: (state, data) => {
			data?.payload?.forEach(element => {
				state.bestAIVComps[element.key] = element.value
			});
		},
		setConditionLevelValues: (state, data) => {
			data?.payload?.forEach(element => {
				state.conditionLevels[element.key] = element.value
			});
		},
		resetForm: (state) => {
			for (const key in { ...initialStates }) {
				if (initialStates.hasOwnProperty(key)) {
					state[key] = initialStates[key]
				}
			}
		},
	},
})

// Action creators are generated for each case reducer function
export const {
	setValDetailsData,
	setValDetailsFilterParams,
	setCompList,
	setNoOfFilteredComps,
	setValDetailsAdjustmentFactorValues,
	setBestAIVCompValues,
	setConditionLevelValues,
	resetForm
} = ValDetailsReducer.actions

export default ValDetailsReducer.reducer