import { useEffect, useState } from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { loggedInUser } from "components/Common/Helpers";
import { GoogleApiWrapper } from 'google-maps-react';
import Geocode from "react-geocode";
import { GOOGLE_MAP_API_URL } from "./Constants";
Geocode.setApiKey(process.env.REACT_APP_GOOGLE_ADDRESS_KEY);
const userData = loggedInUser();

const GoogleAutoSearchTextInput = ({ googleAddress, propAddressObject, google }) => {
    const [inputValue, setInputValue] = useState('');
    const [customError, setCustomError] = useState('');

    useEffect(() => {
        if (googleAddress && googleAddress.length) {
            let containsClear = googleAddress.includes("clear");
            if (containsClear) {
                setInputValue("");
                setCustomError("Please enter valid comp address.");
            } else {
                setInputValue(googleAddress);
                setCustomError("");
            }
        } else if (googleAddress) {
            setInputValue(googleAddress.formatted_address ? googleAddress.formatted_address : "");
        }
        //eslint-disable-next-line
    }, [googleAddress]);

    let handleGoogleAddressProcess = async (place) => {
        try {
            let googleAddressTemp = {
                place_id: place.place_id,
            };
            let shortAddress = {}
            let longAddress = {}
            let mixAddress = {};

            for (let i = 0; i < place.address_components.length; i++) {
                if (place.address_components[i].types.includes("street_number")) {
                    googleAddressTemp.address_1 = place.address_components[i].short_name;
                    shortAddress.address_1 = place.address_components[i].short_name;
                    longAddress.address_1 = place.address_components[i].long_name;
                    mixAddress.address_1 = place.address_components[i].long_name;
                }
                if (place.address_components[i].types.includes("route")) {
                    googleAddressTemp.address_1 = (googleAddressTemp.address_1 ? googleAddressTemp.address_1 + " " : "") + place.address_components[i].short_name;
                    shortAddress.address_1 = (shortAddress.address_1 ? shortAddress.address_1 + " " : "") + place.address_components[i].short_name;
                    longAddress.address_1 = (longAddress.address_1 ? longAddress.address_1 + " " : "") + place.address_components[i].long_name;
                    mixAddress.address_1 = (mixAddress.address_1 ? mixAddress.address_1 + " " : "") + place.address_components[i].short_name;

                    let subPremise = place.address_components.find(item => item.types.includes('subpremise'))?.long_name;
                    if (subPremise) {
                        googleAddressTemp.address_1 = (googleAddressTemp.address_1 ? googleAddressTemp.address_1 + " " : "") + subPremise;
                        shortAddress.address_1 = (shortAddress.address_1 ? shortAddress.address_1 + " " : "") + subPremise;
                        longAddress.address_1 = (longAddress.address_1 ? longAddress.address_1 + " " : "") + subPremise;
                        mixAddress.address_1 = (mixAddress.address_1 ? mixAddress.address_1 + " " : "") + subPremise;
                    }
                }

                if (place.address_components[i].types.includes("sublocality_level_2")) {
                    googleAddressTemp.address_1 = (googleAddressTemp.address_1 ? googleAddressTemp.address_1 + ", " : "") + place.address_components[i].long_name;
                    shortAddress.address_1 = (shortAddress.address_1 ? shortAddress.address_1 + " " : "") + place.address_components[i].short_name;
                    longAddress.address_1 = (longAddress.address_1 ? longAddress.address_1 + " " : "") + place.address_components[i].long_name;
                    mixAddress.address_1 = (mixAddress.address_1 ? mixAddress.address_1 + " " : "") + place.address_components[i].long_name;
                }
                if (place.address_components[i].types.includes("sublocality_level_1")) {
                    googleAddressTemp.address_2 = place.address_components[i].long_name;
                    shortAddress.address_2 = place.address_components[i].short_name;
                    longAddress.address_2 = place.address_components[i].long_name;
                    mixAddress.address_2 = place.address_components[i].long_name;
                }

                if (place.address_components[i].types.includes("locality") || place.address_components[i].types.includes("administrative_area_level_3")) {
                    googleAddressTemp.city = place.address_components[i].long_name;
                    shortAddress.city = place.address_components[i].short_name;
                    longAddress.city = place.address_components[i].long_name;
                    mixAddress.city = place.address_components[i].long_name;
                }
                if (place.address_components[i].types.includes("administrative_area_level_1")) {
                    googleAddressTemp.state = place.address_components[i].short_name;
                    shortAddress.state = place.address_components[i].short_name;
                    longAddress.state = place.address_components[i].long_name;
                    mixAddress.state = place.address_components[i].long_name;
                }
                if (place.address_components[i].types.includes("country")) {
                    googleAddressTemp.country = place.address_components[i].long_name;
                    shortAddress.country = place.address_components[i].short_name;
                    longAddress.country = place.address_components[i].long_name;
                    mixAddress.country = place.address_components[i].long_name;
                }
                if (place.address_components[i].types.includes("postal_code")) {
                    googleAddressTemp.postal_code = place.address_components[i].short_name;
                    shortAddress.postal_code = place.address_components[i].short_name;
                    longAddress.postal_code = place.address_components[i].long_name;
                    mixAddress.postal_code = place.address_components[i].long_name;
                }
                if (!googleAddressTemp.city && googleAddressTemp.address_2) {
                    googleAddressTemp.city = googleAddressTemp.address_2;
                    shortAddress.city = googleAddressTemp.address_2;
                    longAddress.city = googleAddressTemp.address_2;
                    mixAddress.city = googleAddressTemp.address_2;
                }
            }

            let response = await Geocode.fromAddress(place.formatted_address);
            if (response) {
                const { lat, lng } = response.results[0].geometry.location;
                const { formatted_address, address_components } = response.results[0];
                googleAddressTemp.address_components = address_components;
                googleAddressTemp.street_view = `${GOOGLE_MAP_API_URL}/streetview?size=600x400&location=${lat},${lng}&fov=90&pitch=10&key=${process.env.REACT_APP_GOOGLE_ADDRESS_KEY}`;
                googleAddressTemp.formatted_address = formatted_address;
                googleAddressTemp.google_latitude = lat;
                googleAddressTemp.google_longitude = lng;
                let zillow_address = formatted_address.replace(/[, ]+/g, "-").trim();
                let zillow_url = `https://www.zillow.com/homes/${zillow_address}_rb`;
                let google_address = formatted_address.replace(/[, ]+/g, "+").trim();
                let google_map_place = `https://www.google.com/maps/place/${google_address}`;
                googleAddressTemp.zillow_url = zillow_url;
                googleAddressTemp.google_map_place = google_map_place;
            }
            return { address: googleAddressTemp, shortAddress, longAddress, mixAddress };
        } catch (error) {
            throw error;
        }
    }

    let handleGoogleAddress = async (place) => {
        let googleAddress = await handleGoogleAddressProcess(place);
        let formatted_address = googleAddress.address.formatted_address ? googleAddress.address.formatted_address.trim() : "";
        setInputValue(formatted_address);
        propAddressObject(googleAddress);
    };

    let handleBlur = async (event) => {
        const inputValue = event.target.value;
        const service = new google.maps.places.AutocompleteService();
        const result = await new Promise((resolve) => {
            service.getPlacePredictions({ input: inputValue }, (predictions, status) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && predictions && predictions.length > 0) {
                    const placeId = predictions[0].place_id;
                    const placesService = new google.maps.places.PlacesService(document.createElement('div'));
                    placesService.getDetails({ placeId }, (place, status) => {
                        if (status === google.maps.places.PlacesServiceStatus.OK) {
                            resolve(place);
                        } else {
                            resolve(null);
                        }
                    });
                } else {
                    resolve(null);
                }
            });
        });

        if (result) {
            handleGoogleAddress(result);
        } else {
            console.log('No place details found');
        }
    }

    let handleChange = async (event) => {
        setInputValue(event.target.value);
        if (!event.target.value) {
            setCustomError("Please enter valid comp address.");
        } else {
            setCustomError("");
        }
    }

    return (
        <>
            <ReactGoogleAutocomplete size="sm" className="form-control"
                apiKey={process.env.REACT_APP_GOOGLE_ADDRESS_KEY}
                onPlaceSelected={(place) => handleGoogleAddress(place)}
                options={{
                    types: ["geocode", "establishment"],
                }}
                value={inputValue}
                placeholder="Enter comp address"
                onChange={(event) => handleChange(event)}
                onBlur={(event) => handleBlur(event)}
                required
            />
            {customError && <div className="form-error">{customError}</div>}
        </>
    )
}
export default GoogleApiWrapper({
    apiKey: userData.google_geocoding_api_key
})(GoogleAutoSearchTextInput);