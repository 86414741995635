/* ---------------------------------------COMPONENT INSTRUCTION---------------------------------------
--Import Component--
Import : import DateSelectPicker from "components/Common/DateSelectPicker.js";

--------------------
--Include Component--
<DateSelectPicker onHandleDateSelect={}  defaultValues= {} minDate={""} maxDate={""} />  

------------------------------------------------------------------------------------------------------*/

import React, { useState, useEffect } from "react";
import { InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { AvGroup } from "availity-reactstrap-validation";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";

const DateSelectPicker = ({ onHandleDateSelect, defaultValues, minDate, maxDate, isClear = false, showIcon = true }) => {


    let date = new Date(defaultValues);
    let dateTime = date.getTime();
    let [startDate, setStartDate] = useState(dateTime);

    useEffect(() => {
        if (defaultValues) {
            let date = new Date(defaultValues);
            let dateTime = date.getTime();

            setStartDate(dateTime);
        }
    }, [defaultValues]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isClear.current) {
            setStartDate(null);
        }

    }, [onHandleDateSelect, isClear]);// eslint-disable-line react-hooks/exhaustive-deps

    let handleInputDate = (date) => {
        setStartDate(date);
        isClear.current = false;
        onHandleDateSelect(date);
    }

    return (
        <>
            <AvGroup>

                <InputGroup className="input-group-alternative">
                    {showIcon &&
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="ni ni-calendar-grid-58"></i>
                            </InputGroupText>
                        </InputGroupAddon>
                    }
                    <DatePicker
                        autoComplete="off"
                        name="select_date"
                        className="form-control"
                        dateFormat="yyyy/MM/dd"
                        minDate={minDate ? minDate : ""}
                        maxDate={maxDate ? maxDate : ""}
                        placeholderText="Click or tap to select a date"
                        selected={startDate}
                        onChange={(date) => handleInputDate(date)}
                    />
                </InputGroup>
            </AvGroup>

        </>
    );
};

export default DateSelectPicker;
