
import React, { useState, useEffect } from "react";
import Spinner from "components/Common/Spinner.js";
import { PostData } from "../../../services/Api.service";
import * as moment from "moment";
import { AvForm, AvGroup, AvField } from 'availity-reactstrap-validation';
import { toast } from 'react-toastify';
import { Row, Button, Modal, Col, UncontrolledTooltip } from "reactstrap";
import { loggedInUser, sendExceptionEmail, checkRoles, checkRolesWithLevel } from "components/Common/Helpers.js"
import Dropzone from 'react-dropzone';
import FilesList from "../Common/File-Upload/FilesList";
import AddEditTopic from "./AddEditTopic.js";
import { ROLE_ANALYST, ROLE_PROCESSOR, ROLE_ACCOUNT_REP, ROLE_REVIEWER } from "../Common/Constants";
import { MESSAGE_TYPES } from "../Common/Constants/Conversation.constant";
import AddEditSubTopic from "./AddEditSubTopic.js";
import UserList from "../Common/UserList.js";

const userData = loggedInUser();
const MessageWithConversationModal = ({ propHandleModal, reportInspectionId, standaloneType, currentRow, onUpdateConversation }) => { // cTab
    let [isProcess, setProcess] = useState(false);
    let [update, setUpdate] = useState(moment());
    let [message, setMessage] = useState([]);
    let [topics, setTopics] = useState([]);
    let [subtopics, setSubTopics] = useState([]);
    let [fields, setFields] = useState({});
    let [selectTopicError, setSelectTopicError] = useState("");
    let [selectSubTopicError, setSelectSubTopicError] = useState("");
    let [selectedThreadFiles, setSelectedThreadFiles] = useState([]);
    let [threadFileUploadError, setThreadFileUploadError] = useState("");
    let [selectUserError, setSelectUserError] = useState("");
    let [recipients, setRecipients] = useState([]);
    let [selectRecipientError, setSelectRecipientError] = useState("");
    let [toRole, setToRole] = useState([]);
    let [toUser, setToUser] = useState(false);
    let [placeInQuestion, setPlaceInQuestion] = useState(false);
    let [user, setUser] = useState(false);
    let [selectUser, setSelectUser] = useState(false);
    let [isOpenAddEditConversationModal, setIsOpenAddEditConversationModal] = useState(false);
    let [messageTypeValue, setMessageTypeValue] = useState(MESSAGE_TYPES.INTERNAL);
    let isEdit = React.useRef(false);
    let isOpenEditTopicModal = React.useRef(false);
    let isOpenEditSubTopicModal = React.useRef(false);
    let [selectTopicConfirmation, setSelectTopicConfirmation] = useState('no');
    let [params] = useState({});

    useEffect(() => {
        if (messageTypeValue === MESSAGE_TYPES.CLIENT || messageTypeValue === MESSAGE_TYPES.INSPECTOR) {
            setSelectRecipientError("")
            setSelectUserError("")
        }
    }, [messageTypeValue, update]);
    useEffect(() => {
        recipients.push({ id: 'analyst', val: ROLE_ANALYST, text: 'Analyst' });
        recipients.push({ id: 'processor', val: ROLE_PROCESSOR, text: 'Processor' });
        recipients.push({ id: 'acct_rep', val: ROLE_ACCOUNT_REP, text: 'Account Rep' });
        recipients.push({ id: 'reviewer', val: ROLE_REVIEWER, text: 'Reviewer', });
        setRecipients(recipients)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (propHandleModal.current) {
            handleNewConversation();
            handleUserModal(); //eslint-disable-next-line 
        }
        if (Object.keys(currentRow).length > 0) {
            onHandleTopicChange(currentRow.topic_id, isEdit = true); // eslint-disable-line react-hooks/exhaustive-deps
            fields['select_topic'] = currentRow.topic_id;
            fields['select_sub_topic'] = currentRow.subtopic_id;
            fields['issue_detail'] = currentRow.issue_detail;
        }
    }, [propHandleModal.current]); // eslint-disable-line react-hooks/exhaustive-deps

    let handleUserModal = () => {
        setIsOpenAddEditConversationModal(propHandleModal.current);
    };

    let handleUser = (response) => {
        if (response && response.ids.length) {
            selectUser = response.ids.join(',')
            setSelectUser(selectUser);
            setUser(true);
            setSelectUserError("")
        } else {
            setToUser(false);
            setUser(false);
        }
    };

    let closeModal = (state) => {
        state = !state;
        setIsOpenAddEditConversationModal(state);
        propHandleModal.current = false;
    }

    let handleNewConversation = async () => {
        try {
            let topicData = await PostData("list/get-topics", { limit: "all" });
            let responseTopicJson = topicData.data.topics.rows;
            setTopics(responseTopicJson);
            if (isOpenEditTopicModal.current === true) {
                isOpenEditTopicModal.current = false
            }
        } catch (error) {
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error, { memberCode: 500, componentName: 'MessageWithConversationModal', functionName: 'handleNewConversation', data: {} });
        }
    }

    let onHandleTopicChange = async (topic_id, editMode) => {
        try {
            let subTopicData = await PostData("list/get-sub-topics", { topic_id: topic_id })
            let responseSubTopicJson = subTopicData.data.subtopics;
            setSubTopics(responseSubTopicJson);
            setToRole([]);
            if (editMode && editMode.current === false)
                delete fields['select_sub_topic']
            setSelectTopicError("")
        } catch (error) {
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message)
            await sendExceptionEmail(error, { memberCode: 500, componentName: 'MessageWithConversationModal', functionName: 'onHandleTopicChange', data: {} });
        }
    }

    let onDropFiles = (files) => {
        let tempArr = [];
        tempArr = files;
        setSelectedThreadFiles(tempArr);
        setThreadFileUploadError("");
        setUpdate(moment());
    };

    let onDropRejectedFiles = (rejectedFiles) => {
        if (rejectedFiles[0].errors[0].code === 'file-too-large') {
            rejectedFiles[0].errors[0].message = "File is larger than 100MB.";
        }
        if (rejectedFiles[0].errors[0].code === 'file-invalid-type') {
            rejectedFiles[0].errors[0].message = "Please upload a PDF, JPG, JPEG or PNG file only.";
        }
        const fileUploadError = rejectedFiles && rejectedFiles.length > 0 && rejectedFiles[0].errors[0].message;
        setThreadFileUploadError(fileUploadError);
        setUpdate(moment());
    };

    let deleteFile = (e, index) => {
        e.preventDefault();
        let selectedThreadFilesTemp = selectedThreadFiles;
        selectedThreadFilesTemp.splice(index, 1);
        setSelectedThreadFiles(selectedThreadFilesTemp);
        setUpdate(moment());
    };

    let threadFiles = selectedThreadFiles && selectedThreadFiles.map((file, i) => (
        <li key={file.name}>
            <i className="fa fa-trash text-danger" onClick={e => deleteFile(e, i)}></i> {file.name}
        </li>
    ));

    let handleRecipients = (e) => {
        if (e.target.name === "msg") {
            message.senderId = userData.user_id;
            message.text = e.target.value;
        } else if (e.target.name === "role" || e.target.name === "place_in_question") {
            if (e.target.id === "user") {
                setToUser(e.target.checked ? true : false);
                setUser((e.target.checked) ? true : false);
                setSelectUserError(e.target.checked ? selectUserError : '')
            } else if (e.target.id === "place_file") {
                setPlaceInQuestion(e.target.checked ? true : false);
            } else {
                if (e.target.checked) {
                    toRole.push(parseInt(e.target.value));
                } else {
                    let index = toRole.indexOf(parseInt(e.target.value));
                    if (index !== -1) {
                        toRole.splice(index, 1);
                    }
                }
                if (toUser === false) {
                    setUser(false);
                }
            }
            setToRole(toRole);
            setSelectRecipientError("")
        }
        setMessage(message);
        setUpdate(moment());
    }
    let handleEditConversationSubmit = async () => {
        try {
            if ((userData.is_RV_user && !fields['select_topic']) || (!userData.is_RV_user && !fields['select_topic'] && selectTopicConfirmation === 'yes')) {
                setSelectTopicError("Please select topic")
                return false
            }
            if ((userData.is_RV_user && !fields['select_sub_topic']) || (!userData.is_RV_user && !fields['select_sub_topic'] && subtopics.length > 0 && selectTopicConfirmation === 'yes')) {
                setSelectSubTopicError("Please select sub topic")
                return false
            }
            if (!userData.is_RV_user)
                toRole.push(ROLE_PROCESSOR, ROLE_ACCOUNT_REP);
            let filterRole = [...new Set(toRole)];
            params.role_id = filterRole.join(',');
            params.to = (user === false) ? 0 : selectUser;
            if (userData.is_RV_user && messageTypeValue === MESSAGE_TYPES.INTERNAL) {
                if ((!params.role_id) && (!params.to)) {
                    setSelectRecipientError("Please select recipient")
                    return false
                }

                if (toUser && user === false) {
                    setSelectUserError("Please select user")
                    return false
                }
            }
            setProcess(true);
            let routeVar = "conversation/add-conversation";
            if (Object.keys(currentRow).length > 0) {
                routeVar = "conversation/edit-conversation/" + currentRow.id;
            } else {
                if (standaloneType === "1") { // cTab === 'awaiting_inspection'
                    fields['inspection_order_id'] = reportInspectionId;
                } else {
                    fields['report_order_id'] = reportInspectionId;
                }
            }
            fields['place_in_questions'] = placeInQuestion;
            fields['message'] = message.text;
            let addEditDataResult = await PostData(routeVar, fields);
            let conversation = addEditDataResult.data.conversationData;
            await sendMessage(conversation);
            propHandleModal.current = false;
            setIsOpenAddEditConversationModal(false);
            onUpdateConversation(conversation, fields);
            setProcess(false);
        } catch (error) {
            setProcess(false);
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message)
            await sendExceptionEmail(error, { memberCode: 500, componentName: 'MessageWithConversationModal', functionName: 'handleEditConversationSubmit', data: {} });
        }
    }

    let sendMessage = async (conversation) => {
        try {
            const data = new FormData();
            if (selectedThreadFiles.length) {
                data.append(`uploaded_files`, selectedThreadFiles[0]);
            }
            if (standaloneType === "1") { // cTab === 'awaiting_inspection'
                params.inspection_order_id = conversation.inspection_order_id;
            } else {
                params.report_order_id = conversation.report_order_id;
            }
            params.conversation_id = conversation.id;
            params.from = message.senderId;
            params.message = message.text;
            params.messageType = !userData.is_RV_user ? MESSAGE_TYPES.CLIENT : messageTypeValue;
            for (let currentField in params) {
                data.append(currentField, params[currentField]);
            }
            setProcess(true);
            let conversationData = await PostData("conversation/send-message", data);
            toast["success"](conversationData.message);
            setProcess(false);
        } catch (error) {
            setProcess(false);
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error, { memberCode: 500, componentName: 'MessageWithConversationModal', functionName: 'sendMessage', data: {} });
        }
        setMessage([])
        setSelectedThreadFiles([]);
    }

    let handleInput = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'select_topic') {
            let selected_topic = e.target.id;
            fields['selected_topic'] = selected_topic;
        }
        if (name === 'select_sub_topic') {
            setSelectSubTopicError("")
            let selected_subtopic = e.target.id;
            fields['selected_subtopic'] = selected_subtopic;
        }
        fields[name] = value;
        setFields(fields);
    }

    // Handle Edit Topic popup
    let handleEditTopicModal = (e) => {
        e.preventDefault();
        isOpenEditTopicModal.current = true;
        setUpdate(moment());
    };

    // Handle Edit Topic popup
    let handleEditSubTopicModal = (e) => {
        e.preventDefault();
        isOpenEditSubTopicModal.current = true;
        setUpdate(moment());
    };

    return (
        <>
            <Spinner isShow={isProcess} />
            <Modal className="modal-dialog-centered edit-user-modal" style={{ maxWidth: "50%" }} isOpen={isOpenAddEditConversationModal} toggle={() => closeModal(isOpenAddEditConversationModal)}>
                <div className="modal-header modal-header-colored">
                    <h4 className="modal-title text-center w-100">
                        New Message
                    </h4>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => closeModal(isOpenAddEditConversationModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <AvForm onValidSubmit={(e) => handleEditConversationSubmit(e)}>
                        {checkRoles(["ANALYST"]) &&
                            <>
                                <Row>
                                    <Col>
                                        <li className="custom-control custom-checkbox mb-3">
                                            <input className="custom-control-input" type="checkbox" value=""
                                                name={"place_in_question"}
                                                id="place_file"
                                                onChange={(e) => handleRecipients(e)}
                                                checked={(placeInQuestion) ? true : false}
                                            />
                                            <label className="custom-control-label" htmlFor="place_file">Place file in questions status</label>
                                        </li>
                                    </Col>
                                </Row>
                            </>
                        }
                        <Row>
                            <Col className="col-sm-11 text-right">
                                <span className="accept-file-message">Accepted File PDF, PNG and JPG. Max File size 10MB.</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-sm-11">
                                <AvField
                                    name="msg"
                                    value={message.text}
                                    rows="5"
                                    onChange={(e) => handleRecipients(e)}
                                    placeholder="Type your message"
                                    type="textarea"
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: "This field is required."
                                        }
                                    }}
                                />
                            </Col>
                            <Col className="col-sm-1">
                                <Dropzone
                                    onDrop={onDropFiles}
                                    onDropRejected={onDropRejectedFiles}
                                    accept="application/pdf,image/jpg,image/jpeg,image/png"
                                    minSize={0}
                                    maxSize={1048576 * 100} //1048576 equal to 1 MB
                                    maxFiles={1}
                                >
                                    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                                        return (
                                            <div {...getRootProps({})}>
                                                <input {...getInputProps()} />
                                                <i id={"file_attached"} className="fa fa-paperclip fa-2x"></i>
                                                <UncontrolledTooltip delay={0} placement="auto" target={"file_attached"} >Attach File</UncontrolledTooltip>
                                            </div>
                                        )
                                    }}
                                </Dropzone>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="col-sm-11">
                                <FilesList filesList={threadFiles} />
                                {threadFileUploadError && (
                                    <div className="text-danger mt-2">
                                        {threadFileUploadError}
                                    </div>
                                )}
                            </Col>
                        </Row>
                        {userData.is_RV_user &&
                            <Row>
                                <Col className="col-sm-9 mb-3">
                                    <div className="custom-radio-btn inline">
                                        {(checkRoles(['ADMINISTRATOR', 'ACCOUNT_REP', 'SALES_REP']) || checkRolesWithLevel(['REVIEWER'], ">=", 3) || userData.allowClientMessaging) &&
                                            <>
                                                <input type="radio" id="msg_type_1" checked={MESSAGE_TYPES.CLIENT === messageTypeValue} name="message_type_value" value={MESSAGE_TYPES.CLIENT} onChange={e => setMessageTypeValue(MESSAGE_TYPES.CLIENT)} />
                                                <label htmlFor="msg_type_1">Client</label>
                                            </>
                                        }
                                        <input type="radio" checked={MESSAGE_TYPES.INTERNAL === messageTypeValue} id="msg_type_2" name="message_type_value" value={MESSAGE_TYPES.INTERNAL} onChange={e => setMessageTypeValue(MESSAGE_TYPES.INTERNAL)} />
                                        <label htmlFor="msg_type_2">Internal</label>
                                        {(checkRoles(['ADMINISTRATOR', 'ACCOUNT_REP', 'SALES_REP', 'PROCESSOR', 'DRV_ADMIN']) || checkRolesWithLevel(['REVIEWER'], ">=", 3)) &&
                                            <>
                                                <input type="radio" checked={MESSAGE_TYPES.INSPECTOR === messageTypeValue} id="msg_type_3" name="message_type_value" value={MESSAGE_TYPES.INSPECTOR} onChange={e => setMessageTypeValue(MESSAGE_TYPES.INSPECTOR)} />
                                                <label htmlFor="msg_type_3">Inspector</label>
                                            </>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        }
                        {!userData.is_RV_user &&
                            <Row>
                                <Col className="mb-2 col-sm-5">
                                    <label>Would you like to select a topic from our list?</label>
                                    <div className="custom-radio-btn inline">
                                        <input type="radio" id='select_topic_confirmation_yes' name="select_topic_confirmation" value='yes'
                                            onChange={(event) => {
                                                setSelectTopicConfirmation(event.target.value)
                                            }
                                            }
                                            checked={'yes' === selectTopicConfirmation}
                                        />
                                        <label htmlFor='select_topic_confirmation_yes'>Yes </label>

                                        <input type="radio" id='select_topic_confirmation_no' name="select_topic_confirmation" value='no'
                                            onChange={(event) => {
                                                setSelectTopicConfirmation(event.target.value)
                                            }
                                            }
                                            checked={'no' === selectTopicConfirmation}
                                        />
                                        <label htmlFor='select_topic_confirmation_no'>No</label>
                                    </div>
                                </Col>
                                {selectTopicConfirmation === 'no' &&
                                    <Col className="col-sm-7 mt-4">
                                        <label>Or would you like to type your own subject label?</label>
                                    </Col>
                                }
                            </Row>
                        }
                        {((userData.is_RV_user) || (!userData.is_RV_user && selectTopicConfirmation === 'yes')) &&
                            <Row>
                                <Col className="mb-2">
                                    <label>Topic</label>
                                    {(checkRoles(['ADMINISTRATOR', 'ACCOUNT_REP']) || checkRolesWithLevel(['REVIEWER'], ">=", 3) || checkRolesWithLevel(['ANALYST'], ">=", 3)) &&
                                        <Button id={"add_new_topic"} className="float-right mb-3" color="success" size="sm" type="button" onClick={e => handleEditTopicModal(e)}>
                                            Add New Topic
                                        </Button>
                                    }
                                    <div className="custom-radio-btn inline">
                                        {topics.map(function (topic) {
                                            return (
                                                <div key={topic.topic_name}>
                                                    <input type="radio" defaultChecked={currentRow.topic_id === topic.id ? "true" : ""} id={topic.topic_name} name="select_topic" value={topic.id} onChange={(e) => handleInput(e)} onClick={(e) => onHandleTopicChange(parseInt(topic.id), isEdit)} />
                                                    <label htmlFor={topic.topic_name}>{topic.topic_name}</label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    {selectTopicError && (
                                        <label className="text-danger">
                                            {selectTopicError}
                                        </label>
                                    )}
                                </Col>
                            </Row>
                        }
                        {((userData.is_RV_user) || ((subtopics.length > 0 || Object.keys(currentRow).length > 0) && (!userData.is_RV_user && selectTopicConfirmation === 'yes'))) &&
                            <>
                                <Row>
                                    <Col className="mb-2">
                                        <label>Sub Topic</label>
                                        {(checkRoles(['ADMINISTRATOR', 'ACCOUNT_REP']) || checkRolesWithLevel(['REVIEWER'], ">=", 3) || checkRolesWithLevel(['ANALYST'], ">=", 3)) &&
                                            <Button id={"add_new_sub_topic"} className="float-right mb-3" color="success" size="sm" type="button" onClick={e => handleEditSubTopicModal(e)}>
                                                Add New Sub Topic
                                            </Button>
                                        }
                                        <div className="custom-radio-btn inline">
                                            {subtopics.map(function (subtopic) {
                                                return (
                                                    <div key={subtopic.id}>
                                                        <input type="radio" defaultChecked={currentRow.subtopic_id === subtopic.id ? "true" : ""} id={subtopic.subtopic_name} name="select_sub_topic" value={subtopic.id} onChange={(e) => handleInput(e)} /* onClick={(e) => onHandleSubTopicChange(parseInt(subtopic.id))} */ />
                                                        <label htmlFor={subtopic.subtopic_name}>{subtopic.subtopic_name}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        {selectSubTopicError && (
                                            <label className="text-danger">
                                                {selectSubTopicError}
                                            </label>
                                        )}
                                    </Col>
                                </Row>
                            </>
                        }
                        {userData.is_RV_user &&
                            <>
                                <Row>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-auto">
                                                <label><b>To</b></label>
                                            </div>
                                            <div className="col-sm-9">
                                                {selectRecipientError && (
                                                    <div className="text-danger mt-2">
                                                        {selectRecipientError}
                                                    </div>
                                                )}
                                                <li className="custom-control custom-checkbox" sm="2">
                                                    {recipients && recipients.map((recipient, index) => {
                                                        return (
                                                            <label key={index}>
                                                                <input className="custom-control-input" type="checkbox" value={recipient.val}
                                                                    name={"role"}
                                                                    key={index}
                                                                    id={recipient.id}
                                                                    onChange={(e) => handleRecipients(e)}
                                                                />
                                                                <label className="custom-control-label mr-5" htmlFor={recipient.id}>{recipient.text}</label>
                                                            </label>
                                                        )
                                                    })}
                                                </li>
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-sm-auto ml-3">
                                                <label><b></b></label>
                                            </div>
                                            <div className="col-sm-7">
                                                <Row>
                                                    <Col sm="2">
                                                        <li className="custom-control custom-checkbox mb-4">
                                                            <input className="custom-control-input" type="checkbox" value=""
                                                                name={"role"}
                                                                id={"user"}
                                                                onChange={(e) => handleRecipients(e)}
                                                                checked={(toUser) ? true : false}
                                                            />
                                                            <label className="custom-control-label" htmlFor={"user"}>User</label>
                                                        </li>
                                                    </Col>
                                                    {user &&
                                                        <Col sm="10">
                                                            <UserList isRender={true} isMultiSelect={true} onHandleUserSelect={handleUser} userQuery={{ company_id: userData.company_id }} />
                                                        </Col>
                                                    }
                                                    {selectUserError && (
                                                        <div className="text-danger mt-2">
                                                            {selectUserError}
                                                        </div>
                                                    )}
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </Row>
                            </>
                        }
                        {selectTopicConfirmation === 'no' && !userData.is_RV_user &&
                            <Row>
                                <Col className="col-sm-6">
                                    <AvGroup>
                                        <AvField
                                            name="issue_detail"
                                            value={currentRow.issue_detail}
                                            onChange={(e) => handleInput(e)}
                                            className="form-control"
                                            placeholder="Subject"
                                            type="text"
                                        />
                                    </AvGroup>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col className="text-right">
                                <Button size="sm" color="danger" outline type="button" onClick={() => closeModal(isOpenAddEditConversationModal)}>
                                    Cancel
                                </Button>
                                <Button size="sm" color="info">
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </AvForm>
                </div>
            </Modal>
            {isOpenEditTopicModal.current &&
                <AddEditTopic propHandleModal={isOpenEditTopicModal} onUpdateTopic={handleNewConversation} selectedRow={[]} />
            }
            {isOpenEditSubTopicModal.current &&
                <AddEditSubTopic propHandleModal={isOpenEditSubTopicModal} onUpdateSubTopic={onHandleTopicChange} selectedRow={[]} />
            }
        </>
    )
}
export default MessageWithConversationModal;