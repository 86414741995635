import React, { useState, useEffect } from "react";
import { Button, Col, Modal, Row } from "reactstrap";
import { toast } from "react-toastify";

import { sendExceptionEmail, formatDate, number_format, formatPropertyAddress } from "components/Common/Helpers";
import RenderBootstrapTable from "components/Common/RenderBootstrapTable";
import TableSkeleton from "components/SkeletonUI/TableSkeleton";
import CompListingDetailModal from "./CompListingDetailModal";
import { PostData } from "services/Api.service";
import MarketPlaceUrls from "components/MarketPlaceUrls/MarketPlaceUrls";

const PropertyCompDetailModal = ({ isCompModal, compData, propCompModal }) => {
    const [isCompDetailModal, setCompDetailModal] = useState(false);
    const [isFetchingEditCompData, setIsFetchingEditCompData] = useState(false);
    const [isDiscrepancyCompModal, setIsDiscrepancyCompModal] = useState({ current: false });
    const [propertyData, setPropertyData] = useState({});
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        if (isCompModal.current) {
            getPropertyCompData();
            handleCompModal(isCompModal.current);
        }
        //eslint-disable-next-line
    }, [isCompModal.current]);

    const handleCompModal = async (state, reload = false) => {
        isCompModal.current = false;
        setCompDetailModal(state);
        if (!state) {
            propCompModal({ state });
        }
    };

    const formatNumber = (num) => (num ? number_format(num, 2).replace('.00', '') : 0);

    const formatCompRow = async (data = {}, label = '-') => {
        try {
            const formattedRow = {
                label,
                id: data.id || 0,
                username: data.username || '-',
                datetime: data.datetime || '-',
                above: data.above ? formatNumber(data.above) : 0,
                below: data.below ? formatNumber(data.below) : 0,
                beds: data.beds || 0,
                baths: data.baths || 0,
                year_built: data.year_built || 0,
                lot_size: data.lot_size ? formatNumber(data.lot_size) : 0,
                stories: data.stories || 0,
                garage: data.garage || 0,
                pool: data.pool || 0,
                key_flags_water_front: data.key_flags_water_front || 0,
                key_flags_golf_course: data.key_flags_golf_course || 0,
                performance_grade: data.grade || 0,
                property_type: data.property_type
            };
            return formattedRow;
        } catch (error) {
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error, { memberCode: 401, componentName: 'PropertyCompDetailModal', functionName: 'formatCompRow', data: {} });
        }
    };

    const getPropertyCompData = async () => {
        try {
            setIsFetchingEditCompData(true);
            const postData = {
                comp_id: compData.id,
                task_status: compData.task_status,
                action_type: compData.action_type,
                drv_id: compData.drv_id,
            }
            const compResult = await PostData(`search/property-comp`, postData);

            if (compResult.success) {
                const { comps_data: compsData, drv_summary: drvSummary, drv_records: drvRecords, my_values: myValues } = compResult.data;
                const drvSummaryData = drvSummary ? drvSummary : {};

                const updatedPropertyData = {
                    ...propertyData,
                    address: formatPropertyAddress(compsData),
                    property_id: compsData.property_id,
                    city: compsData.city,
                    state: compsData.state,
                    postal_code: compsData.postal_code,
                    property_comp_id: compsData.comp_id,
                    grade: myValues && myValues.drv_grade > 0 ? myValues.drv_grade : "0.00",
                    color_code: myValues ? myValues.color_code : ""
                };

                // Prepare DRV summary
                const drvSummaryRow = await formatCompRow({
                    above: drvSummaryData.above_grade_sqft || 0,
                    below: drvSummaryData.below_grade_sqft || 0,
                    beds: drvSummaryData.beds || 0,
                    baths: drvSummaryData.baths || 0,
                    year_built: drvSummaryData.year_built || 0,
                    lot_size: drvSummaryData.lot_size || 0,
                    stories: drvSummaryData.stories || 0,
                    garage: drvSummaryData.garage || 0,
                    pool: drvSummaryData.pool || 0,
                    key_flags_water_front: '-',
                    key_flags_golf_course: '-',
                    property_type: drvSummaryData.property_type || '-'
                }, 'Reconciled');

                // Prepare DRV records
                const drvData = await Promise.all(
                    drvRecords.map(async (record) => await formatCompRow({
                        id: record.id,
                        above: formatNumber(record.above_grade_sqft) || 0,
                        below: formatNumber(record.below_grade_sqft) || 0,
                        beds: record.beds || 0,
                        baths: record.baths || 0,
                        year_built: record.year_built || 0,
                        lot_size: formatNumber(record.lot_size) || 0,
                        stories: record.stories || 0,
                        garage: record.garage || 0,
                        pool: record.pool || 0,
                        key_flags_water_front: record.key_flags_water_front || 0,
                        key_flags_golf_course: record.key_flags_golf_course || 0,
                        username: record.user?.full_name || '-',  // Adding optional chaining to prevent errors if user is undefined
                        datetime: formatDate(null, record.datetime, 'MMM D YYYY, h:mm a'),
                        grade: formatNumber(record.drv_grade),
                        property_type: record.property_type || '-'
                    }))
                );

                // Prepare MLS and County data
                const mlsData = await formatCompRow({
                    above: compsData.original_living_area_square_feet || 0,
                    below: compsData.original_basement_square_feet || 0,
                    beds: compsData.original_bedrooms_total || 0,
                    baths: compsData.original_bathrooms_total || 0,
                    year_built: compsData.original_year_built || 0,
                    lot_size: compsData.original_lot_size_square_feet || 0,
                    stories: compsData.original_stories_total || 0,
                    garage: 0,
                    pool: 0,
                    key_flags_water_front: '-',
                    key_flags_golf_course: '-',
                    property_type: compsData.original_property_type_normalized || '-'
                }, 'MLS', compsData.comp_id);

                const countyData = await formatCompRow({
                    above: compsData.county_pr_LivingArea || 0,
                    below: compsData.county_pr_BelowGradeFinishedArea || 0,
                    beds: compsData.pr_BedroomsTotal || 0,
                    baths: compsData.pr_BathroomsTotalInteger || 0,
                    year_built: compsData.pr_YearBuilt || 0,
                    lot_size: compsData.pr_LotSizeSquareFeet || 0,
                    stories: 0,
                    garage: 0,
                    pool: 0,
                    key_flags_water_front: '-',
                    key_flags_golf_course: '-',
                    property_type: compsData.property_type_normalized || '-'
                }, 'County', compsData.comp_id);

                const rowsData = [drvSummaryRow, ...drvData, mlsData, countyData];
                const topRow = (1 + drvData.length);

                // Prepare my values
                if (compData.showMyValues) {
                    const myValuesObj = {
                        label: 'My value',
                        above: myValues.above_grade_sqft > 0 ? myValues.above_grade_sqft : 0,
                        below: myValues.below_grade_sqft > 0 ? myValues.below_grade_sqft : 0,
                        beds: myValues.beds > 0 ? myValues.beds : 0,
                        baths: myValues.baths > 0 ? myValues.baths : 0,
                        year_built: myValues.year_built > 0 ? myValues.year_built : 0,
                        lot_size: myValues.lot_size > 0 ? myValues.lot_size : 0,
                        property_type: myValues.property_type || "-",
                        stories: myValues.stories || 0,
                        key_flags_water_front: myValues.key_flags_water_front || 0,
                        key_flags_golf_course: myValues.key_flags_golf_course || 0,
                        pool: myValues.pool || 0,
                        garage: myValues.garage || 0,
                        grade: formatNumber(myValues.drv_grade),
                        username: myValues?.user?.user_name || '-',
                        datetime: formatDate(null, myValues.datetime, 'MMM D YYYY, h:mm a') || '-',
                    };
                    const myValuesData = await formatCompRow(myValuesObj, 'My Values');

                    let gradeData = {};
                    if (myValues && Object.keys(myValues).length) {
                        rowsData.splice(topRow, 0, myValuesData);
                        gradeData = myValues.drv_grade_details ? JSON.parse(myValues.drv_grade_details) : {};
                    }

                    const gradeObj = {
                        above: gradeData.above_sqft ? formatNumber(gradeData.above_sqft) : 0,
                        below: gradeData.below_sqft ? formatNumber(gradeData.below_sqft) : 0,
                        beds: gradeData.beds ? formatNumber(gradeData.beds) : 0,
                        baths: gradeData.baths ? formatNumber(gradeData.baths) : 0,
                        year_built: gradeData.year_built ? formatNumber(gradeData.year_built) : 0,
                        lot_size: gradeData.lot_size ? formatNumber(gradeData.lot_size) : 0,
                        stories: gradeData.stories ? formatNumber(gradeData.stories) : 0,
                        garage: gradeData.garage ? formatNumber(gradeData.garage) : 0,
                        pool: gradeData.pool ? formatNumber(gradeData.pool) : 0,
                        key_flags_water_front: gradeData.key_flags_water_front ? formatNumber(gradeData.key_flags_water_front) : 0,
                        key_flags_golf_course: gradeData.key_flags_golf_course ? formatNumber(gradeData.key_flags_golf_course) : 0,
                        property_type: gradeData.property_type_normalized ? formatNumber(gradeData.property_type_normalized) : 0,
                        user_name: '-',
                        datetime: '-',
                    };
                    const gradesData = await formatCompRow(gradeObj, 'Grade');
                    rowsData.push(gradesData);
                }
                setTableData({ ...tableData, mls_data: rowsData });
                setPropertyData(updatedPropertyData);
            }
            setIsFetchingEditCompData(false);
        } catch (error) {
            setIsFetchingEditCompData(false);
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error, { memberCode: 401, componentName: 'PropertyCompDetailModal', functionName: 'getPropertyCompData', data: {} });
        }
    };

    const renderCheckbox = (row, type) => {
        try {
            let checked = false;
            let value = null;
            if (type === "water_front") {
                checked = row.key_flags_water_front && parseInt(row.key_flags_water_front) === 1 ? true : false
                value = row.key_flags_water_front;
            } else if (type === "golf_course") {
                checked = row.key_flags_golf_course && parseInt(row.key_flags_golf_course) === 1 ? true : false
                value = row.key_flags_golf_course;
            } else if (type === "pool") {
                checked = row.pool && parseInt(row.pool) === 1 ? true : false
                value = row.pool;
            }
            if (row.label === "Grade") {
                return (
                    <span>{value}</span>
                );
            } else {
                return (
                    <div color="info" className="custom-checkbox-container inline" sm="2">
                        <label className="checkbox-div">
                            <input type="checkbox" name="key_flags_water_front" checked={checked} onChange={(event) => { }} />
                            <span className="checkmark"></span>
                        </label>
                    </div>
                );
            }

        } catch (error) {
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message);
            sendExceptionEmail(error).catch(emailError => console.error('Error sending exception email:', emailError));
        }
    };

    const mlsColumns = [
        { width: "0%", isKey: true, hidden: true, title: `Id`, dataField: 'id', align: 'center' },
        { width: "8%", title: ``, dataField: 'label', align: 'center', },
        { width: "8%", title: `User`, dataField: 'username', align: 'center', },
        { width: "9%", title: `Datetime`, dataField: 'datetime', align: 'center' },
        { width: "5%", title: `Above`, dataField: 'above', align: 'center' },
        { width: "5%", title: `Below`, dataField: 'below', align: 'center' },
        { width: "5%", title: `Beds`, dataField: 'beds', align: 'center' },
        { width: "5%", title: `Baths`, dataField: 'baths', align: 'center' },
        { width: "6%", title: `Year`, dataField: 'year_built', align: 'center' },
        { width: "6%", title: `Lot`, dataField: 'lot_size', align: 'center' },
        { width: "6%", title: `Stories`, dataField: 'stories', align: 'center' },
        { width: "6%", title: `Garage`, dataField: 'garage', align: 'center' },
        { width: "6%", title: `Pool`, dataFormat: (cell, row) => renderCheckbox(row, "pool"), align: 'center' },
        { width: "6%", title: `Waterfront`, dataFormat: (cell, row) => renderCheckbox(row, "water_front"), align: 'center' },
        { width: "6%", title: `Golf`, dataFormat: (cell, row) => renderCheckbox(row, "golf_course"), align: 'center' },
        { width: "7%", title: `Property type`, dataField: 'property_type', align: 'center' },
        { width: "6%", title: `Grade`, dataField: 'performance_grade', align: 'center' },
    ];

    const mlsTable = {
        tableData: Object.keys(tableData).length && 'mls_data' in tableData ? tableData.mls_data : [],
        columns: mlsColumns,
    };

    const editCompDataTable = (
        <>
            <div className={`table-responsive drv-summary-table ${compData.showMyValues ? 'my-values-rows' : 'drv-rows'}`}>
                <RenderBootstrapTable tableParams={mlsTable} pagination={false} />
            </div>
        </>
    );

    let handleViewDiscrepancy = () => {
        setIsDiscrepancyCompModal({ current: true });
    }

    return (
        <>
            <Modal className="modal-dialog-centered property-comp-modal-summary" style={{ maxWidth: "82%" }} isOpen={isCompDetailModal} toggle={() => handleCompModal(false)}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title w-100 text-center">
                        <Row>
                            <Col sm="12" md="12">
                                {propertyData.address}
                                {compData.showMyValues && <span className="float-right circular-span mt-1" style={{ "backgroundColor": `${propertyData.color_code}` }} > {propertyData.grade}</span>}
                                {propertyData?.address &&
                                    <span className="float-right mr-4">
                                        <MarketPlaceUrls address={{
                                            address: propertyData.address,
                                            city: propertyData.city,
                                            state: propertyData.state,
                                            postal_code: propertyData.postal_code
                                        }} size={20} />
                                    </span>
                                }
                            </Col>
                        </Row>
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleCompModal(false)}            >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    {isFetchingEditCompData ? <TableSkeleton rows={10} cols={15} /> : editCompDataTable}
                </div>
                <div className="modal-footer">
                    {!isFetchingEditCompData ? <Button color="info" outline onClick={() => handleViewDiscrepancy()} >View Listing Details</Button> : null}
                </div>
            </Modal >
            {isDiscrepancyCompModal.current && <CompListingDetailModal isCompModal={isDiscrepancyCompModal} compData={{ property_comp_id: propertyData.property_comp_id, showMyValues: compData.showMyValues, drv_id: compData.drv_id }} />}
        </>
    )
};

export default PropertyCompDetailModal;