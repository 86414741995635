import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Table, Button, ModalFooter } from 'reactstrap';
import { PostData } from "services/Api.service";
import { sendExceptionEmail, formatDateComponent } from "components/Common/Helpers.js"
import { toast } from 'react-toastify';
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from 'react-redux';
import { TASK_SLUG } from "../Constants/TimeTracking.constant";
import { periodObservance } from "components/Common/TimeTrackingHelpers";

const FlagsModal = ({ isOpen, toggleModal, propData, updateLatestIcon }) => {
    const [locationReviewerFlagData, setLocationReviewerFlagData] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const taskData = useSelector((state) => state.taskIdsList.data);
    const [orderData, setOrderData] = useState({});

    useEffect(() => {
        async function fetchInitialData() {
            try {
                if (propData && Object.keys(propData).length) {
                    let reviewerFlags = await PostData("reviewer-flags/get-reviewer-flags", { locationId: propData?.locationId, propertyId: propData?.propertyId, reportId: propData?.reportId, userId: propData?.userId });
                    const flagsData = reviewerFlags.data.data;
                    setLocationReviewerFlagData(flagsData);
                    const initiallySelectedRows = Array.isArray(flagsData)
                        ? flagsData
                            .filter(flag => flag?.show_in_report !== undefined && parseInt(flag.show_in_report) === 1)
                            .map(flag => flag?.id)
                            .filter(id => id !== undefined) // Ensure no undefined IDs
                        : [];
                    setSelectedRows(initiallySelectedRows);
                    setOrderData({
                        property_id: propData.propertyId,
                        id: propData.reportId,
                        order_status: propData.orderStatus
                    })
                }
            } catch (errors) {
                toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message)
                await sendExceptionEmail(errors, { memberCode: 403, componentName: 'flagModal', functionName: 'fetchInitialData', data: {} });
            }
        }
        if (isOpen) {
            fetchInitialData();
        }
    }, [isOpen, propData]); // eslint-disable-line react-hooks/exhaustive-deps

    const deleteFlag = async (recordId) => {
        await periodObservance({ taskData, slug: TASK_SLUG.OMS_REVIEWER_FLAG_DELETE_FLAG, orderData, closingTask: 0, newTabCheck: 0, flagLocation: propData.flagLocation });
        confirmAlert({
            title: 'Are you sure?',
            message: 'Do you want to delete this flag?',
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Delete',
                    onClick: async () => {
                        try {
                            setIsSaving(true);
                            const response = await PostData(`reviewer-flags/delete-reviewer-flag`, { recordId });
                            if (response); {
                                const updatedFlags = locationReviewerFlagData.filter(flag => flag.id !== recordId);
                                setLocationReviewerFlagData(updatedFlags);
                                toast['success'](response.message);
                                if (updatedFlags.length === 0) {
                                    toggleModal();
                                }
                            }
                        } catch (errors) {
                            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message);
                            await sendExceptionEmail(errors, { memberCode: 403, componentName: 'flagModal', functionName: 'deleteFlag', data: {} });
                        }
                        setIsSaving(false);
                    },
                    className: "btn btn-primary btn-sm"
                }, {
                    label: 'Cancel',
                    onClick: () => { },
                    className: "btn btn-secondary btn-sm"
                }
            ]
        });
    };

    // Function to handle click events for rating and severity
    const handleFieldClick = async (recordId, fieldType, currentValue) => {
        try {
            setIsSaving(true);
            let newValue;

            // Determine new value based on current value and field type
            if (fieldType === 'rating_type') {
                await periodObservance({ taskData, slug: TASK_SLUG.OMS_REVIEWER_FLAG_TOGGLE_RATING, orderData, closingTask: 0, newTabCheck: 0, flagLocation: propData.flagLocation });
                newValue = currentValue === 'none' ? 'down' : currentValue === 'down' ? 'up' : currentValue === 'up' ? 'waived' : 'none';
            } else if (fieldType === 'rating_severity') {
                await periodObservance({ taskData, slug: TASK_SLUG.OMS_REVIEWER_FLAG_TOGGLE_SEVERITY, orderData, closingTask: 0, newTabCheck: 0, flagLocation: propData.flagLocation });
                const severityOrder = ['low', 'med', 'high', 'major'];
                const currentIndex = severityOrder.indexOf(currentValue);
                newValue = severityOrder[(currentIndex + 1) % severityOrder.length];
            }

            const response = await PostData(`reviewer-flags/update-reviewer-flag`, { recordId, fieldType, value: newValue });

            if (response.success) {
                const updatedData = locationReviewerFlagData.map(item =>
                    item.id === recordId
                        ? { ...item, [fieldType]: newValue, show_in_report: '1' }
                        : item
                );
                setLocationReviewerFlagData(updatedData);
                if (!selectedRows.includes(recordId)) {               // Auto-select the checkbox
                    setSelectedRows([...selectedRows, recordId]);
                }
                if (fieldType === 'rating_type') {
                    updateLatestIcon(newValue, recordId)
                }
                toast['success'](response.message);
            }
        } catch (errors) {
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message);
            await sendExceptionEmail(errors, { memberCode: 403, componentName: 'flagModal', functionName: 'handleFieldClick', data: {} });
        }
        setIsSaving(false);
    };

    const handleCheckboxChange = (rowId) => {
        setSelectedRows((prev) =>
            prev.includes(rowId) ? prev.filter((id) => id !== rowId) : [...prev, rowId]
        );
        setLocationReviewerFlagData((prevData) =>  // Update show_in_report when checkbox is toggled
            prevData.map((item) => item.id === rowId ? { ...item, show_in_report: selectedRows.includes(rowId) ? '0' : '1' } : item)
        );
    };

    const handleCommentChange = (e, recordId) => {
        const updatedData = locationReviewerFlagData.map((dataItem) =>
            dataItem.id === recordId
                ? { ...dataItem, reviewer_comments: e.target.value, show_in_report: '1' } // Select checkbox automatically
                : dataItem
        );
        setLocationReviewerFlagData(updatedData);
        if (!selectedRows.includes(recordId)) {                       // Auto-select the checkbox
            setSelectedRows([...selectedRows, recordId]);
        }
    }

    const handleSubmit = async () => {
        try {
            await periodObservance({ taskData, slug: TASK_SLUG.OMS_REVIEWER_FLAG_SAVE, orderData, closingTask: 0, newTabCheck: 0, flagLocation: propData.flagLocation });
            const updatedComments = locationReviewerFlagData
                .filter((item) => selectedRows.includes(item.id)) // Only save for selected rows
                .map((item) => ({
                    recordId: item.id,
                    fieldType: 'reviewer_comments',
                    value: item.reviewer_comments || '',
                }));

            if (updatedComments.length === 0) {
                toast.error('Please select at least one record to save.');
                return;
            }

            const updatedSaveInReportValue = locationReviewerFlagData
                .filter(item => item.id)
                .map(item => ({
                    recordId: item.id,
                    fieldType: 'show_in_report',
                    value: selectedRows.includes(item.id) ? '1' : '0',
                }));

            if (updatedSaveInReportValue.length > 0) {
                updatedComments.push(...updatedSaveInReportValue);
            }
            setIsSaving(true);
            const response = await PostData(`reviewer-flags/update-reviewer-flag`, { updateData: updatedComments });
            if (response.success) {
                toggleModal();
                toast['success'](response.message);
            }
        } catch (errors) {
            toast.error(errors.errors && errors.errors.length ? errors.errors[0].message : errors.message);
            await sendExceptionEmail(errors, {
                memberCode: 403,
                componentName: 'flagModal',
                functionName: 'handleSubmit',
                data: {},
            });
        }
        setIsSaving(false);
    };

    return (
        <>
            <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={toggleModal} size="xl">
                <div className="modal-header modal-header-colored">
                    <h4 className="modal-title text-center w-100">Reviewer's Flags</h4>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() =>
                        toggleModal()}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <ModalBody className='flag-container'>
                    <Table bordered className="text-center">
                        <thead>
                            <tr>
                                <th>Select</th>
                                <th>Recipient Name</th>
                                <th>Rating Type </th>
                                <th>Severity Level</th>
                                <th>Comments</th>
                                <th>Date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody className="vertical-align">
                            {locationReviewerFlagData.length > 0 ? (locationReviewerFlagData.map((item, index) => (
                                <tr key={index}>
                                    <td width="5%">
                                        <div color="info" className="custom-control custom-checkbox">
                                            <input
                                                className="custom-control-input"
                                                name={`checkBox${index}`}
                                                id={`checkBox${index}`}
                                                type="checkbox"
                                                value='1'
                                                onChange={() => handleCheckboxChange(item.id)}
                                                checked={selectedRows.includes(item.id)}
                                            />
                                            <label className="custom-control-label" htmlFor={`checkBox${index}`}></label>
                                        </div >
                                    </td>
                                    <td>{item.recipient_user.recipient_user_name || '-'}</td>
                                    <td width="10%" disabled={isSaving}>
                                        <div className={`flag-icon ${item.rating_type === 'up' ? 'green-icon' : item.rating_type === 'down' ? 'red-icon' : item.rating_type === 'none' ? 'grey-icon' : 'blue-icon'}`}
                                            onClick={() => handleFieldClick(item.id, 'rating_type', item.rating_type)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            <i className={`fa ${item.rating_type === 'up' ? 'fa-thumbs-up' : item.rating_type === 'down' ? 'fa-thumbs-down' : item.rating_type === 'waived' ? 'fa-ban' : 'fa-thumbs-up'}`}></i>
                                        </div>
                                    </td>
                                    <td disabled={isSaving} width="10%" onClick={() => handleFieldClick(item.id, 'rating_severity', item.rating_severity)} style={{ cursor: 'pointer' }}>
                                        {item.rating_severity || 'Med'}</td>
                                    <td>
                                        <textarea
                                            disabled={isSaving}
                                            className="form-control"
                                            rows="1"
                                            defaultValue={item.reviewer_comments || ''}
                                            placeholder="Enter comments"
                                            onChange={(e) => handleCommentChange(e, item.id)}
                                        ></textarea>
                                    </td>
                                    <td>
                                        {item.datetime ? formatDateComponent("datetime", item, "MMM D h:mm a") : '-'}
                                    </td>
                                    <td>
                                        <i
                                            title="Delete Flag"
                                            className="fa fa-trash text-danger"
                                            onClick={() => deleteFlag(item.id)}
                                        ></i>
                                    </td>
                                </tr>
                            ))
                            ) : (
                                <tr>
                                    <td colSpan="7">No data available.</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" outline className="w-30" onClick={toggleModal} disabled={isSaving}>
                        Close
                    </Button>
                    <Button color="info" outline className="w-30" onClick={handleSubmit} disabled={isSaving}>
                        {isSaving ? "Saving..." : "Save"}
                    </Button>
                </ModalFooter>
            </Modal >
        </>
    );
};

export default FlagsModal;

