import React, { useState, useEffect } from "react";
import { Button, Modal, Row, Col, Label } from "reactstrap";
import Spinner from "components/Common/Spinner.js";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import { loggedInUser } from "components/Common/Helpers.js"
import "./style.scss";
import * as moment from "moment";
import { GetData, PostData as ApiPostData } from "services/Api.service";
import { Typeahead } from 'react-bootstrap-typeahead';
import { toast } from "react-toastify";
import { sendExceptionEmail } from "components/Common/Helpers";
const userData = loggedInUser();
let myFormRef;

const BudgetLineItemEditModal = ({ propData, handleEditLineItem, isOpenEditLineItemModal }) => {

    let [editModal, setEditModal] = useState(true);
    const [process, setProcess] = useState(false);
    let [update, setUpdate] = useState(moment());
    let [category, setCategory] = useState([]);
    let [selectedCategory, setselectedCategory] = useState([]);
    let [newCategoryInput, setNewCategoryInput] = useState(" ");
    let [customError, setCustomError] = useState(" ");
    const [isDropdownOpen, setIsDropdownOpen] = useState([]);
    const [key, setKey] = useState(0);

    useEffect(() => {
        setEditModal(isOpenEditLineItemModal);
        setProcess(true);

        GetData("orders/get-budget-categories").then(result => {
            setCategory(result.data.budgetCategoriesData.map((res) => res.name));
            setProcess(false);
        }).catch(errors => {
            setProcess(false);
            console.log("[Error] neightborhoodMetrics->BudgetLineItemEditModal.js->PostData(orders/get_budget_categories)", errors);
        });
        if (propData.category.length !== 0) {
            setselectedCategory(propData.category);
        }
    }, [propData, isOpenEditLineItemModal]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
    }, [update]);

    const toggleEdit = () => setEditModal(!editModal);

    let handleEditLineItemFinalData = async (e) => {
        try {
            e.preventDefault();
            const values = myFormRef.getValues();
            const { isValid } = await myFormRef.validateAll(values, false);
            myFormRef.setTouched(Object.keys(myFormRef._inputs), true, false);
            myFormRef.updateInputs();

            if (!selectedCategory.length) {
                setCustomError("*Some fields are missing or contain errors. Please review and correct them.");
                return false
            }

            if (!isValid) {
                return false
            }


            propData.user_id = userData.user_id;
            propData.category = selectedCategory;
            if (propData.line_item_name.length > 3 && propData.category.length > 0 && propData.cost_amount > 0) {
                setEditModal(!editModal);
                setProcess(true);
                let result = await ApiPostData("orders/update-budget-line-item", propData);
                toast["success"](result.message);
                setProcess(false);
                handleEditLineItem(propData);
                setCustomError(" ");
            } else {
                setCustomError("*Some fields are missing or contain errors. Please review and correct them.");
            }
        } catch (errors) {
            setProcess(false);
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message)
            await sendExceptionEmail(errors, { memberCode: 401, componentName: 'BudgetLineItemEditModal', functionName: 'handleEditLineItemFinalData', data: {} });
        }
    };

    let onDropDownChangeHandle = (e) => {
        if (e.length <= 3) {
            setselectedCategory(e);
            setUpdate(moment());
        }
    };

    let onChangeHandle = (event) => {
        if (event.target.name === "line_item_name_edit") {
            propData.line_item_name = event.target.value;
        }
        if (event.target.name === "description_edit") {
            propData.description = event.target.value;
        }
        if (event.target.name === "category_edit") {
            setselectedCategory([event.target.value]);
        }
        if (event.target.name === "quantity_edit") {
            propData.quantity = event.target.value;
        }
        if (event.target.name === "price_edit") {
            propData.price = event.target.value;
        }
        if (event.target.name === "cost_edit") {
            propData.cost_amount = event.target.value;
        }
        setUpdate(moment());
    };
    let onKeyDownAddNewCategory = (e, row) => {
        if ((e.key).toLowerCase() === "enter") {
            if ((selectedCategory).length < 3) {
                let categoryFlag = category.includes(x => x !== newCategoryInput);
                if (categoryFlag === false) {
                    let tmpCategory = [...selectedCategory, (newCategoryInput).trim()]
                    selectedCategory = tmpCategory;
                    let categoryTemp = [...category, newCategoryInput.trim()]
                    setCategory(categoryTemp);
                    onDropDownChangeHandle(tmpCategory, row);
                    setKey(key + 1);
                    setUpdate(moment());
                }
            }
        }
    };
    let onInputChangeAddNewCategory = (e) => {
        let index = category.findIndex(x => x === e);
        if (index === -1) {
            setNewCategoryInput(e);
        }
    };
    const handleFocus = (e, row) => {
        let openDropDownRow = [row.id];
        setIsDropdownOpen(openDropDownRow);
        setUpdate(moment());
    };

    const handleFocusOut = () => {
        setIsDropdownOpen([]);
        setUpdate(moment());
    };
    const subjectPropertyTable = (
        <>
            <div className="p-12">
                <AvForm ref={(el) => myFormRef = el} >
                    <Row>
                        <Col md="12" className="form-control-table-narrow">
                            <Row>
                                <Col md="4">
                                    <Label>Budget line Item Name</Label>
                                </Col>
                                <Col md="8">
                                    <AvGroup>
                                        <AvField
                                            name="line_item_name_edit"
                                            maxLength="250"
                                            onChange={(e) => { onChangeHandle(e) }}
                                            className="form-control-alternative form-control"
                                            placeholder="Enter Line Item Name"
                                            value={propData.line_item_name}
                                            type="text"
                                            required
                                            errorMessage="Minimum 3 character required."
                                            minLength="3"
                                        />
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <Label>Description</Label>
                                </Col>
                                <Col md="8">
                                    <AvGroup>
                                        <AvField
                                            name="description_edit"
                                            maxLength="250"
                                            className="form-control-alternative form-control"
                                            placeholder="Enter Description"
                                            type="text"
                                            value={propData.description}
                                            onChange={(e) => { onChangeHandle(e) }}
                                            errorMessage=" "
                                        />
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <Label>Category</Label>
                                </Col>
                                <Col md="8">
                                    <AvGroup>
                                        <Typeahead
                                            multiple={true}
                                            id={propData.id}
                                            key={key}
                                            options={category}
                                            value={newCategoryInput}
                                            inputText={newCategoryInput}
                                            selected={selectedCategory}
                                            placeholder="Categories"
                                            onChange={(e) => onDropDownChangeHandle(e)}
                                            onKeyDown={(e) => onKeyDownAddNewCategory(e)}
                                            onInputChange={(e) => onInputChangeAddNewCategory(e)}
                                            onFocus={(e) => handleFocus(e, propData)}
                                            onBlur={handleFocusOut}
                                            open={isDropdownOpen.length > 0 && isDropdownOpen[0] === propData.id ? true : false}
                                            emptyLabel={< div style={{ fontSize: 14, whiteSpace: "normal" }}>Type new category and hit enter to add new category.</div>}
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "This field is required."
                                                }
                                            }}
                                        />
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <Label>Quantity</Label>
                                </Col>
                                <Col md="8">
                                    <AvGroup>
                                        <AvField
                                            name="quantity_edit"
                                            maxLength="250"
                                            className="form-control-alternative form-control"
                                            placeholder="Enter Quantity"
                                            value={propData.quantity}
                                            onChange={(e) => { onChangeHandle(e) }}
                                            type="number"
                                            errorMessage=" "
                                            validate={{
                                                pattern: {
                                                    value: /^[0-9]+(\.[0-9]+)?$/,
                                                    errorMessage: "Invalid format. Only numbers and decimal values are allowed."
                                                },
                                                min: {
                                                    value: 0,
                                                    errorMessage: "Please enter value between 0 and 100."
                                                }
                                            }}
                                        />
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <Label>Price</Label>
                                </Col>
                                <Col md="8">
                                    <AvGroup>
                                        <AvField
                                            name="price_edit"
                                            maxLength="250"
                                            className="form-control-alternative form-control"
                                            placeholder="Enter Price"
                                            value={propData.price}
                                            onChange={(e) => { onChangeHandle(e) }}
                                            type="number"
                                            errorMessage=" "
                                            validate={{
                                                pattern: {
                                                    value: /^[0-9]+(\.[0-9]+)?$/,
                                                    errorMessage: "Invalid format. Only numbers and decimal values are allowed."
                                                },
                                                min: {
                                                    value: 0,
                                                    errorMessage: "Please enter value between 0 and 100."
                                                }
                                            }}
                                        />
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="4">
                                    <Label>Cost</Label>
                                </Col>
                                <Col md="8">
                                    <AvGroup>
                                        <AvField
                                            name="cost_edit"
                                            maxLength="250"
                                            className="form-control-alternative form-control"
                                            placeholder="Enter Cost"
                                            value={propData.cost_amount}
                                            onChange={(e) => { onChangeHandle(e) }}
                                            type="number"
                                            errorMessage=" "
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: "This field is required."
                                                },
                                                pattern: {
                                                    value: /^[0-9]+(\.[0-9]+)?$/,
                                                    errorMessage: "Invalid format. Only numbers and decimal values are allowed."
                                                },
                                                min: {
                                                    value: 0,
                                                    errorMessage: "Please enter value between 0 and 100."
                                                }
                                            }}
                                        />
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="12" sm="12" md="12" lg="12">
                                    <div className="text-right mt-5">
                                        <AvGroup>
                                            <div className="form-error text-right">{customError}</div>
                                            <Button size="sm" color="primary" type="button" onClick={(e) => handleEditLineItemFinalData(e)}>
                                                Submit
                                            </Button>
                                        </AvGroup>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </AvForm>
            </div>
        </>
    );

    return (
        <>
            <Spinner isShow={process} />
            <Modal className="modal-dialog-centered budget-line-item-edit-modal" style={{ maxWidth: "600px" }} isOpen={editModal} toggle={() => toggleEdit()}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-center w-100" id="budgetAllocateModalLabelEdit">
                        Edit Budget Line Item
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => toggleEdit()}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    {subjectPropertyTable}
                </div>
            </Modal>
        </>
    );
};
export default BudgetLineItemEditModal;