import { Table, Col } from "reactstrap";

const recipientFlagsTable = ({ flagsData }) => {
    return (
        <Col xl="12">
            <br></br>
            <Table striped className="flags-table">
                <thead>
                    <tr>
                        <th>Location</th>
                        <th>Flag Type</th>
                        <th>Rating</th>
                        <th>Severity</th>
                        <th>Comments</th>
                        <th>Flag Set By</th>
                    </tr>
                </thead>
                <tbody>
                    {flagsData.length > 0 ? (
                        flagsData.map((flag, index) => (
                            <tr key={index}>
                                <td>{flag.location?.flag_location || " "}</td>
                                <td>{flag.type?.type || " "}</td>
                                <td>{flag.rating_type || " "}</td>
                                <td>{flag.rating_severity || " "}</td>
                                <td>{flag.reviewer_comments || " "}</td>
                                <td>{flag.reviewer_user?.full_name || " "}</td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="6" className="text-center">
                                No flags available
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </Col>
    );
}
export default recipientFlagsTable;