import React, { useState, useEffect } from "react";
import { checkRoles, sendExceptionEmail } from "components/Common/Helpers.js"
import { PostData } from "services/Api.service";
import "./ReviewerFlags.scss";
import { UncontrolledTooltip } from "reactstrap";
import { toast } from 'react-toastify';
import FlagsModal from './FlagsModal';
import { useSelector } from 'react-redux';
import { TASK_SLUG } from "../Constants/TimeTracking.constant";
import { periodObservance } from "components/Common/TimeTrackingHelpers";

const FlagIcons = ({ propData }) => {
    const [reviewerFlagData, setReviewerFlagData] = useState([]);
    const [flagLocation, setFlagLocation] = useState('');
    const [flagLocationData, setFlagLocationData] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const [ratingType, setRatingType] = useState("none");
    const [recordId, setRecordId] = useState("");
    const [comments, setComments] = useState("");
    const [analystEligibleToEditFlag, setAnalystEligibleToEditFlag] = useState(0);
    const [editable, setEditable] = useState(false);
    const [flagTypeId, setFlagTypeId] = useState("");
    const [locationId, setLocationId] = useState("");
    const [iconToShow, setIconToShow] = useState("");
    const [dotsHtml, setDotsHtml] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const taskData = useSelector((state) => state.taskIdsList.data);
    const [orderData, setOrderData] = useState({});

    useEffect(() => {
        async function setInitialData() {
            if (propData && Object.keys(propData).length) {
                setReviewerFlagData(propData.reviewerFlagData);
                setFlagLocation(propData.flagLocation);
                setFlagLocationData(propData.reviewerFlagAllLocationData);
                setOrderData({
                    property_id: propData.propertyId,
                    id: propData.reportId,
                    order_status: propData.orderStatus
                })
            }
        }
        setInitialData();
    }, [propData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        async function generateIcon() {
            await renderFlagIcon();
        }
        generateIcon();
    }, [reviewerFlagData, flagLocationData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        async function generateIcon() {
            await getIcon(ratingType);
        }
        generateIcon();
    }, [ratingType]); // eslint-disable-line react-hooks/exhaustive-deps

    const getIcon = async (ratingType) => {
        try {
            const icons = {
                none: <div className="flag-icon grey-icon"><i className="fa fa-thumbs-up"></i></div>,
                up: <div className="flag-icon green-icon"><i className="fa fa-thumbs-up"></i></div>,
                down: <div className="flag-icon red-icon"><i className="fa fa-thumbs-down"></i></div>,
                waived: <div className="flag-icon blue-icon"><i className="fa fa-ban"></i></div>
            };
            const iconToShow = icons[ratingType] || icons.none;

            const dotsHtml = (ratingType !== "none" && editable) ? (
                <>
                    <div id={`threeDots-${recordId}`} className="three-dots" onClick={(e) => { e.stopPropagation(); openThreeDotsPopup(); }}>⋮⋮</div>
                    {recordId && (
                        <UncontrolledTooltip placement="auto" target={`threeDots-${recordId}`}>
                            {comments}
                        </UncontrolledTooltip>
                    )}
                </>
            ) : (
                <div className="three-dots-hidden"></div>
            );

            setIconToShow(iconToShow);
            setDotsHtml(dotsHtml);
            setIsDisabled(false);
        } catch (errors) {
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message)
            await sendExceptionEmail(errors, { memberCode: 403, componentName: 'FlagIcons', functionName: 'createFlags', data: {} });
            setIsDisabled(false);
        }
    };

    const createUpdateFlags = async () => {
        try {
            setIsDisabled(true);
            await periodObservance({ taskData, slug: TASK_SLUG.OMS_REVIEWER_FLAG_TOGGLE_RATING, orderData, closingTask: 0, newTabCheck: 0, flagLocation });
            const ratingMap = { 'none': 'down', 'down': 'up', 'up': 'waived', 'waived': 'none' };
            let newRating = ratingMap[ratingType] || 'none';
            const reviewerFlags = await PostData("reviewer-flags/create-update-reviewer-flags-toggle", {
                recordId,
                ratingType: newRating,
                locationId,
                flagTypeId,
                locationSlug: propData.flagLocation,
                userId: propData.userId,
                analystEligibleToEditFlag,
                propertyId: propData.propertyId,
                listingIdForSelectedComp: null,
                analysisTrackingId: null,
                reportId: propData.reportId,
                compId: null,
            });

            if (reviewerFlags?.data?.data) {
                setReviewerFlagData(reviewerFlags?.data?.data);
            } else {
                toast['info']('No recipient found for this report order. Unable to create flags.');
                setIsDisabled(false);
            }
        } catch (errors) {
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message)
            await sendExceptionEmail(errors, { memberCode: 403, componentName: 'FlagIcons', functionName: 'createFlags', data: {} });
            setIsDisabled(false);
        }
    };

    const openThreeDotsPopup = async () => {
        await periodObservance({ taskData, slug: TASK_SLUG.OMS_REVIEWER_FLAG_OPEN_POPUP, orderData, closingTask: 0, newTabCheck: 1, flagLocation });
        setModalOpen(true);
    };

    const closeThreeDotsPopup = async () => {
        setModalOpen(!modalOpen)
        await periodObservance({ taskData, slug: TASK_SLUG.OMS_REVIEWER_FLAG_OPEN_POPUP, orderData, closingTask: 1, newTabCheck: 0, flagLocation });
    };

    const updateLatestIcon = async (newValue, updatedRecordId) => {
        if (newValue && recordId === updatedRecordId) {
            await getIcon(newValue);
        }
    };

    const renderFlagIcon = async () => {
        try {
            let flag = {};
            let location = flagLocationData.find(location => location.slug === flagLocation) || {};

            if (location) {
                const data = Array.isArray(reviewerFlagData) && reviewerFlagData.length > 0 ? reviewerFlagData : [reviewerFlagData];
                flag = data.reduce((mostRecent, flag) => {
                    if (flag.flag_location_id === location.id) {
                        return (!mostRecent || flag.id > mostRecent.id) ? flag : mostRecent;
                    }
                    return mostRecent;
                }, null);
            }

            setRatingType(flag?.rating_type || "none");
            setRecordId(flag?.id || "");
            setComments(flag?.reviewer_comments || "");
            setAnalystEligibleToEditFlag(parseInt(location?.analyst_eligible || 0));
            setEditable(checkRoles(["REVIEWER"]) || (checkRoles(["ANALYST"]) && (parseInt(location?.analyst_eligible) === 1)));
            setFlagTypeId(location?.flag_type || "");
            setLocationId(location?.id || "");
        } catch (errors) {
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message)
            await sendExceptionEmail(errors, { memberCode: 403, componentName: 'FlagIcons', functionName: 'renderFlagIcon', data: {} });
            setIsDisabled(false);
        }
    };

    const handleFlagClick = () => {
        if (isDisabled) return;  // Prevent multiple rapid clicks
        setIsDisabled(true);     // Disable immediately
        createUpdateFlags();     // Proceed with the async operation
    };

    return (
        <div className="flag-container">
            {editable ? (
                <div
                    type="button"
                    //className="flag-toggle editable"
                    disabled={isDisabled}
                    className={`flag-toggle editable ${isDisabled ? "disabled" : ""}`}
                    onClick={() => handleFlagClick()}
                //onClick={() => createUpdateFlags()}
                >
                    {iconToShow}
                </div>
            ) : (
                <div className="flag-toggle static">{iconToShow}</div>
            )
            }
            {dotsHtml}
            {modalOpen &&
                <FlagsModal
                    isOpen={modalOpen}
                    toggleModal={() => closeThreeDotsPopup()}
                    propData={{ flagLocation, locationId, propertyId: propData?.propertyId, reportId: propData?.reportId, userId: propData?.userId, orderStatus: propData?.orderStatus }}
                    updateLatestIcon={updateLatestIcon}
                />
            }
        </div>
    );
};

export default FlagIcons;