import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { loggedInUser } from "components/Common/Helpers";
import * as moment from "moment";
import BudgetReview from "views/modules/NeighborhoodMetrics/BudgetReview";
import { periodObservance } from "components/Common/TimeTrackingHelpers";
import { connect } from "react-redux";
import { TASK_SLUG } from "../../Constants/TimeTracking.constant";
import AutoLoginDecrypt from "components/Common/AutoLoginDecrypt";
import RenderActionButton from "../RenderActionButton";

const phpUrl = process.env.REACT_APP_PHP_ENDPOINT;

const BudgetReviewButton = ({ propRowData, propButtonOptions, taskData }) => {

    const userData = loggedInUser();
    const [isOpenCancelReportOrderModal, setIsOpenCancelReportOrderModal] = useState(false);
    const [update, setUpdate] = useState(moment());
    const [isProcessing] = useState(false);

    useEffect(() => { }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleActionClick = async (e, row) => {
        // e.preventDefault();
        await periodObservance({ taskData: taskData, slug: TASK_SLUG.BUDGET_LISTING, orderData: propRowData, closingTask: 0, newTabCheck: 1 }); //Tracking time while opening the budget popup from clicking the hammer icon
        setIsOpenCancelReportOrderModal(true);
    };

    const handleToggleModal = (state) => {
        if (!isProcessing) {
            state = !state;
            setIsOpenCancelReportOrderModal(state);
            setUpdate(moment());
        }
    };

    const handleBudgetReview = async (response) => {
        handleToggleModal(isOpenCancelReportOrderModal)
        await periodObservance({ taskData: taskData, slug: TASK_SLUG.BUDGET_LISTING, orderData: propRowData, closingTask: 1, newTabCheck: 0 }); //Tracking time while cancel button or analyze button is clicked from the budget popup 
    }

    return (
        <>
            <RenderActionButton propButtonTitle="Budget Review" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />

            <Modal backdrop="static" className="modal-dialog-centered modal-xxl neighborhood-land-user" isOpen={isOpenCancelReportOrderModal} toggle={() => handleToggleModal(isOpenCancelReportOrderModal)}>
                <div className="modal-header modal-header-colored">
                    <h4 className="modal-title text-center w-100">
                        Budget Review - <AutoLoginDecrypt key={propRowData.id} data={{ user_id: userData.user_id, label: propRowData.address, url: `${phpUrl}valuation/deals/details/${propRowData.property_id}` }} style={{ color: 'white' }} />
                    </h4>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenCancelReportOrderModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body p-0">
                    <BudgetReview propData={propRowData} onHandleBudgetReviewResponse={e => handleBudgetReview(e)} />
                </div>
            </Modal>
        </>
    );
};

const mapStateToProps = state => ({   //maps the Redux state to the component's props, allowing us to access the state inside our class component.
    taskData: state.taskIdsList.data
});

//export default Revise;
export default connect(mapStateToProps)(BudgetReviewButton);