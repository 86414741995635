import { sendExceptionEmail } from "components/Common/Helpers";
import { loggedInUser } from "components/Common/Helpers";
import { PostData as OMSPostData } from "../../../services/PostData";
import * as moment from "moment";
import { toast } from "react-toastify";
import { PostData } from "services/Api.service";
import "./style.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { Button, Modal, Col, Row, Table, CardBody, CardFooter, UncontrolledTooltip } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";

import React, { useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Typeahead } from 'react-bootstrap-typeahead';
import Spinner from "components/Common/Spinner";
import { GetData } from "services/Api.service";
import RenderBootstrapTable from "components/Common/RenderBootstrapTable";
import { useSelector } from "react-redux";


const DrawLineItem = ({ onHandleData, reportData, otherData = {} }) => {
    const userData = loggedInUser();
    const [isProcess, setProcess] = useState(false);
    let [category, setCategory] = useState([]);
    let [activeInspectionDrawItems, setActiveInspectionDrawItems] = useState([]);
    let [newCategoryInput, setNewCategoryInput] = useState(" ");
    let [unassignedInspectionPhotos, setUnassignedInspectionPhotos] = useState([]);
    let [currentInspectionDrawItem, setCurrentInspectionDrawItem] = useState([]);
    const [key, setKey] = useState(0);
    let [update, setUpdate] = useState(moment());
    let [isOpenAddNewLineItemModal, setIsOpenAddNewLineItemModal] = useState(false);
    const [defaultValue, setDefaultValue] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState([]);
    let [selectedCheckboxIds, setSelectedCheckboxIds] = useState([]);
    let [checkBoxStateByID, setCheckBoxStateByID] = useState([]);
    let [customErrorMasterMessage, setCustomErrorMasterMessage] = useState(" ");
    let [selectedCategory, setselectedCategory] = useState([]);
    let [isOpenUnassignedInspectionPhotos, setIsOpenUnassignedInspectionPhotos] = useState(false);
    let [isOpenInspectionPhotosModel, setIsOpenInspectionPhotosModel] = useState(false);
    let [openReassignModel, setOpenReassignModel] = useState(false);
    let [selectedImagesForReassignOrUnassigned, setSelectedImagesForReassignOrUnassigned] = useState([]);
    let [selectedLineItemForReassignPhotoId, setSelectedLineItemForReassignPhotoId] = useState([]);
    let [rowSelectedForAssignment, setRowSelectedForAssignment] = useState([]);
    let [selectedPhotosForReassign, setSelectedPhotosForReassign] = useState([]);
    let [isOpenExcludedPhotos, setIsOpenExcludedPhotos] = useState(true);
    let [excludedPhotos, setExcludedPhotos] = useState([]);
    let [errorIndexes, setErrorIndexes] = useState([]);
    let [inspection_order_id, setInspectionOrderId] = useState(null);
    const additionalClass = otherData.class === 'full-height' ? 'full-height' : 'draw-height-table';
    const errorIndexesData = useSelector((state) => state.inspectionOrderIntake.errorIndexes)
    let onReportData = {};

    useEffect(() => {
        let inspection_order_id = reportData.type === "1" ? reportData.inspection_order_id : null;
        let report_order_id = reportData.type === "2" ? reportData.inspection_order_id : null;

        setInspectionOrderId(inspection_order_id);
        handleOrderInspectionStatus({ inspection_order_id, report_order_id });
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (errorIndexesData && errorIndexesData.length) {
            setErrorIndexes(errorIndexesData);
        }
    }, [errorIndexesData]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

    }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

    let handleOrderInspectionStatus = async (data) => {
        let { inspection_order_id, report_order_id } = data;
        if (inspection_order_id || report_order_id) {
            setErrorIndexes([]);

            try {
                setProcess(false);
                let getCategoryData = await GetData("orders/get-budget-categories");
                setCategory(getCategoryData.data.budgetCategoriesData.map((res) => res.name));

                let result = await PostData("inspection/inspection-history", {
                    inspection_order_id,
                    report_order_id
                });
                setProcess(false);

                let responseJson = result.data;
                setUnassignedInspectionPhotos(responseJson.unassignedInspectionPhotos)
                setExcludedPhotos(responseJson.excludeInspectionPhotos)
                setInspectionOrderId(result.data?.rows[0]?.inspection_order?.id);

                let drawInspection = responseJson.inspectionOrderData?.inspection_type?.draw_inspection ?? 0;
                if (parseInt(drawInspection) === 0) {
                    toast["error"]("Invalid inspection order id");
                }

                let drawItems = result.data.drawItemsData;
                if (drawItems.length > 0) {
                    let activeDrawItems = [];
                    let inactiveDrawItems = [];
                    for (const eachDraw of drawItems) {
                        if (eachDraw.active) {
                            activeDrawItems.push(eachDraw);
                        } else {
                            inactiveDrawItems.push(eachDraw)
                        }
                    }
                    let selectItemCounts = 0;
                    activeDrawItems?.forEach(element => {
                        if (element.active) {
                            selectItemCounts++;
                        }
                    });

                    if (drawItems.length === selectItemCounts) {

                        drawItems.selectAll = true;
                    } else {
                        drawItems.selectAll = false;
                    }
                    setActiveInspectionDrawItems(drawItems);
                    onHandleData({ activeInspectionDrawItems: drawItems });
                }

                setUpdate(moment());
            } catch (error) {
                toast['error'](error.message);
                setUpdate(moment());
                await sendExceptionEmail(error, { memberCode: 401, componentName: 'DrawLineItem', functionName: 'handleOrderInspectionStatus', data: {} });
                setProcess(false);
            }
        }
        setDefaultValue([{ id: 1, line_item_name: "", description: "", category: [], active: true, cost: 0 }]);
    }

    let addNewLineItem = () => {
        setIsOpenAddNewLineItemModal(!isOpenAddNewLineItemModal);
    };

    let unassignedInspectionPhoto = (isForExcludedPhotos = false) => {
        setIsOpenExcludedPhotos(isForExcludedPhotos);
        setIsOpenUnassignedInspectionPhotos(!isOpenUnassignedInspectionPhotos);
    };

    let callRetrieveProxyPicsAPI = async () => {
        try {
            setProcess(true);
            let params = {
                inspection_order_id
            }
            let result = await PostData("inspection/retrieve-inspection-photos", params);
            if (result?.data?.drawItemsData) {
                setActiveInspectionDrawItems(result?.data?.drawItemsData);
                setUnassignedInspectionPhotos(result?.data?.unassignedInspectionPhotos);
                toast["success"](result.message);
                onHandleData({ activeInspectionDrawItems: result?.data?.drawItemsData });
            }
            setUpdate(moment());
            setProcess(false);
        } catch (error) {
            toast["error"](error.message);
            setUpdate(moment());
            await sendExceptionEmail(error, { memberCode: 401, componentName: 'DrawLineItem', functionName: 'callRetrieveProxyPicsAPI', data: {} });
            setProcess(false);
        };
    }

    let openImageSlider = (item, allImages = false) => {
        allImages ? setCurrentInspectionDrawItem(item) : setCurrentInspectionDrawItem([item]);
        setIsOpenInspectionPhotosModel(!isOpenInspectionPhotosModel);
    }

    let selectUnselectAll = (e) => {
        let { checked } = e.target;
        let drawItemTemp = [...activeInspectionDrawItems]
        drawItemTemp?.forEach(i => {
            if (checked) {
                i.active = true;
            } else {
                i.active = false;
            }
        });
        if (checked) {
            drawItemTemp.selectAll = true;
        } else {
            drawItemTemp.selectAll = false;
        }
        setActiveInspectionDrawItems(drawItemTemp);
        onHandleData({ activeInspectionDrawItems: drawItemTemp });
        setUpdate(moment())
    };

    let onDataChange = async (e, i) => {
        if (e && e.target) {
            let { name, value, checked } = e.target;
            value = value ? value.trim() : null;
            name = name.split("___")[0];
            let instructionData = { ...activeInspectionDrawItems };
            errorIndexes = [...errorIndexes];

            if (['line_item_name', 'description', 'cost', 'line_item_check', 'new_our_completion_percent'].includes(name)) {

                let drawItemTemp = [...activeInspectionDrawItems]
                drawItemTemp[i] = { ...drawItemTemp[i] }
                if (name === 'line_item_check') {
                    if (checked) {
                        drawItemTemp[i]['active'] = true;
                    } else {
                        drawItemTemp[i]['active'] = false;

                        if (errorIndexes.indexOf(i) !== -1) {
                            errorIndexes.splice(errorIndexes.indexOf(i), 1);
                            errorIndexes = [...new Set(errorIndexes)];
                            setErrorIndexes(errorIndexes);
                        }
                    }
                } else {
                    if (['cost', 'new_our_completion_percent'].includes(name)) {
                        drawItemTemp[i][name] = value ? value : "0";
                    } else {
                        drawItemTemp[i][name] = value;
                    }
                }
                let selectItemCounts = 0;
                drawItemTemp?.forEach(element => {
                    if (element.active) {
                        selectItemCounts++;
                    }
                });
                if (drawItemTemp.length === selectItemCounts) {
                    drawItemTemp.selectAll = true;
                } else {
                    drawItemTemp.selectAll = false;
                }
                instructionData = drawItemTemp;

                if (name === 'line_item_check') {
                    if (checked) {
                        checkCompletedDrawItems(drawItemTemp, i);
                    }
                }

                if (name === 'new_our_completion_percent') {
                    let our_completion_percent = drawItemTemp[i].new_our_completion_percent ? drawItemTemp[i].new_our_completion_percent : drawItemTemp[i].our_qc_completion_percent;
                    if (parseFloat(drawItemTemp[i].previous_completion_percentage) > parseFloat(our_completion_percent)) {
                        errorIndexes.push(i);
                    } else {
                        if (errorIndexes.indexOf(i) !== -1) {
                            errorIndexes.splice(errorIndexes.indexOf(i), 1);
                        }
                    }
                    errorIndexes = [...new Set(errorIndexes)];
                    setErrorIndexes(errorIndexes);
                }
            }
            setActiveInspectionDrawItems(instructionData);
            onHandleData({ activeInspectionDrawItems: instructionData });
        } else {
            if (e.length <= 3) {
                let drawItemTemp = [...activeInspectionDrawItems]
                drawItemTemp[i] = { ...drawItemTemp[i] }
                drawItemTemp[i]['categories'] = e;
                if (drawItemTemp[i].id && drawItemTemp[i].id) {
                    let data = {};
                    data.category = drawItemTemp[i].category;
                    data.user_id = userData.user_id;
                    data.budget_extraction_id = drawItemTemp[i].id;
                    setKey(key + 1);
                }
                setActiveInspectionDrawItems(drawItemTemp);
                onHandleData({ activeInspectionDrawItems: drawItemTemp });
            }
        }
    };

    let checkCompletedDrawItems = (drawItems, itemIndex) => {
        if (parseFloat(drawItems[itemIndex].our_qc_completion_percent) === 100) {
            confirmAlert({
                title: '',
                message: "These line items have been previously marked as completed. Are you sure that you want to select them as active line items for this new draw inspection?",
                closeOnClickOutside: false,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => updateDrawItemStatus(drawItems, itemIndex, true),
                        className: "btn btn-primary btn-sm"
                    }, {
                        label: 'Close',
                        onClick: () => updateDrawItemStatus(drawItems, itemIndex, false),
                        className: "btn btn-outline-danger btn-sm"
                    }
                ]
            });
        }
    }

    let updateDrawItemStatus = (drawItems, itemIndex, value) => {
        drawItems[itemIndex] = { ...drawItems[itemIndex], active: value };
        setActiveInspectionDrawItems(drawItems);
        onHandleData({ activeInspectionDrawItems: drawItems });
    }

    let onKeyDownAddNewCategory = (e, row) => {
        if ((e.key).toLowerCase() === "tab") {
            if ((row.categories).length < 3) {
                let categoryFlag = category.includes(x => x !== newCategoryInput);
                if (categoryFlag === false) {
                    let tmpCategory = [...row.categories, (newCategoryInput).trim()]
                    row.categories = tmpCategory;
                    let categoryTemp = [...category, newCategoryInput.trim()]
                    setCategory(categoryTemp);

                    onDropDownChangeHandle(tmpCategory, row);
                    setKey(key + 1);
                    setUpdate(moment());
                }
            }
        }
    };

    let onDropDownChangeHandle = (e, row) => {
        if (e.length <= 3) {
            row.categories = e;
            activeInspectionDrawItems.forEach(drawitem => {
                if (drawitem.id === row.id) {
                    drawitem.categories = e;
                }
            });
        }
        setActiveInspectionDrawItems(activeInspectionDrawItems);
        onHandleData({ activeInspectionDrawItems: activeInspectionDrawItems });
        setUpdate(moment);
    };

    let onInputChangeAddNewCategory = (e, row) => {
        let index = category.findIndex(x => x === e);
        if (index === -1) {
            setNewCategoryInput(e);
        }
    };

    let onChangeHandle = (event, row, field) => {
        let values = defaultValue;
        let index = values.findIndex(x => x.id === row.id);
        if (index >= 0) {
            values[index][field] = event.target.value;
            setDefaultValue(values);
            setUpdate(moment());
        }
    };

    let textBox = (cell, row, field, type, required = false) => {
        let name = field;
        let placeholder = name
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
        let validate = {};
        if (type === "number") {
            validate = {
                required: {
                    value: required,
                    errorMessage: "This field is required."
                },
                pattern: { value: /^[0-9\b]+$/, errorMessage: "Invalid format." },
            }
        } else {
            validate = {
                required: {
                    value: required,
                    errorMessage: "This field is required."
                },
            }
        }


        return (<AvField
            name={name + "_" + row.id}
            maxLength="250"
            autoComplete="off"
            onChange={(e) => { onChangeHandle(e, row, field) }}
            className="form-control-alternative form-control"
            placeholder={placeholder}
            type={type}
            value={row[field] + ""}
            minLength="3"
            validate={validate}
        />)
    };

    let dropDown = (cell, row, isForUnassignedImages = false) => {
        let dropDownSelectedCategory = isForUnassignedImages ? row.categories : row.category;
        return (
            <><Row>
                <Col className={`text-${isForUnassignedImages ? 'left' : 'center'}`}>
                    {isForUnassignedImages ?
                        (<div>{row.categories.join(', ')}</div>) : (
                            <div className="my-typeahead">
                                <Typeahead
                                    multiple={true}
                                    id="typeahead"
                                    key={key}
                                    options={category}
                                    value={newCategoryInput}
                                    inputText={newCategoryInput}
                                    selected={dropDownSelectedCategory}
                                    placeholder="Select Categories"
                                    onChange={(e) => onDropDownChangeHandleNewLine(e, row)}
                                    onKeyDown={(e) => onKeyDownAddNewCategoryNewLine(e, row)}
                                    onInputChange={(e) => onInputChangeAddNewCategoryNewLine(e, row)}
                                    onFocus={(e) => handleFocus(e, row)}
                                    onBlur={handleFocusOut}
                                    open={isDropdownOpen.length > 0 && isDropdownOpen[0] === row.id ? true : false}
                                    emptyLabel={<div style={{ fontSize: 14, whiteSpace: "normal" }}>Type a new category and hit tab to add a new category.</div>}
                                />
                            </div>
                        )}
                </Col>
            </Row>
            </>
        );
    };

    let onDropDownChangeHandleNewLine = (e, row) => {
        if (e.length <= 3) {
            let tempDefault = defaultValue;
            let index = tempDefault.findIndex(x => x.id === row.id);
            if (index >= 0) {
                tempDefault[index].category = e;
                setDefaultValue(tempDefault);
                setUpdate(moment());
            }
            handleFocusOut();
        }
    };

    let onKeyDownAddNewCategoryNewLine = (e, row) => {
        if ((e.key).toLowerCase() === "tab") {
            if ((selectedCategory).length < 3) {
                let categoryFlag = category.includes(x => x !== newCategoryInput);
                if (categoryFlag === false) {
                    let tmpCategory = [...selectedCategory, (newCategoryInput).trim()]
                    selectedCategory = tmpCategory;
                    setselectedCategory(selectedCategory);
                    let categoryTemp = [...category, newCategoryInput.trim()]
                    setCategory(categoryTemp);
                    onDropDownChangeHandle(tmpCategory, row);
                    setKey(key + 1);
                    setUpdate(moment());
                }
            }
        }
    };

    let onInputChangeAddNewCategoryNewLine = (e) => {
        let index = category.findIndex(x => x === e);
        if (index === -1) {
            setNewCategoryInput(e);
        }
    };

    const handleFocus = (e, row) => {
        let openDropDownRow = [row.id];
        setIsDropdownOpen(openDropDownRow);
        setUpdate(moment());
    };

    const handleFocusOut = () => {
        setIsDropdownOpen([]);
        setUpdate(moment());
    };

    let onCheckBoxChange = (e, row) => {
        let values = defaultValue;
        values[row.id - 1].active = !values[row.id - 1].active
        setDefaultValue(values);
        setUpdate(moment());
    }

    let checkbox = function (cell, row, field) {
        const budgetId = `${field}_${row.id}`;
        return (
            <>
                <div name={budgetId}>
                    <input type="checkbox" id={budgetId} onChange={(e) => onCheckBoxChange(e, row)} checked={row && row.active ? true : false} className="custom-checkbox" align="center" />
                    <UncontrolledTooltip delay={0} placement="auto" target={budgetId} >{row && row.active ? 'Active Line Item' : 'Inactive Line Item'}</UncontrolledTooltip>
                </div>
            </>
        );
    };

    let inspectionPhotoLableChange = async (e, item, index) => {
        if (e && e.target) {
            let { value } = e.target;
            let allLineItems = activeInspectionDrawItems;
            let indexOfLineItem = allLineItems.findIndex(x => x.id === item.id);
            allLineItems[indexOfLineItem].assignedImages[index].label = value;
            setActiveInspectionDrawItems(allLineItems);
            onHandleData({ activeInspectionDrawItems: allLineItems });
        }
    }

    let unassignPhotoLabelChange = async (e, item, type = "unassignedPhoto") => {
        if (e && e.target) {
            let { value } = e.target;
            let allLineItems = [];
            if (type === "unassignedPhoto") {
                allLineItems = [...unassignedInspectionPhotos];
            } else if (type === "excludedPhoto") {
                allLineItems = [...excludedPhotos];
            }
            let indexOfLineItem = allLineItems.findIndex(x => x.id === item.id);
            allLineItems[indexOfLineItem].label = value;
            if (type === "unassignedPhoto") {
                setUnassignedInspectionPhotos(allLineItems);
            } else if (type === "excludedPhoto") {
                setExcludedPhotos(allLineItems);
            }
        }
    }

    let addNewRow = () => {
        let data1 = defaultValue;
        let data = { id: data1[defaultValue.length - 1].id + 1, line_item_name: "", description: "", category: [], active: true, cost: 0 }
        let values = [...defaultValue, data]
        setDefaultValue(values);
    }

    let removeRow = (e, row) => {
        let values = defaultValue;
        if (values.length > 1) {
            let selectedIds = [...selectedCheckboxIds];
            let indexSelectedIds = selectedIds.findIndex(x => x === row.id);
            let index = values.findIndex(x => x.id === row.id);
            values.splice(index, 1);
            let checkBoxStateByIDTemp = [...checkBoxStateByID];
            let checkBoxStateIDIndex = checkBoxStateByIDTemp.findIndex(x => x.endsWith("_" + row.id));
            if (checkBoxStateIDIndex >= 0) {
                checkBoxStateByIDTemp.splice(checkBoxStateIDIndex, 1);
                setCheckBoxStateByID(checkBoxStateByIDTemp);
                setDefaultValue(values);
                selectedIds.splice(indexSelectedIds, 1);
                setSelectedCheckboxIds(selectedIds);
            }
            setUpdate(moment());
        }
    }

    let actionButton = (cell, row, field) => {
        return (
            <div className="lender-entity-section">
                <Button color="danger" size="sm" name="addNewLineItem" type="button" onClick={(e) => removeRow(e, row)}>
                    <span id={'action_remove_' + row.id} data-placement="auto" className="fa fa-minus-circle" aria-hidden="true" /><UncontrolledTooltip delay={0} placement="auto" target={'action_remove_' + row.id} >Remove Line Item</UncontrolledTooltip>
                </Button>
            </div>
        );
    };

    let handleAddNewLineItemFinalData = async (e) => {
        try {
            let tempResponseData = defaultValue;
            let counter = 1;
            let errorRow = [];
            tempResponseData.forEach((data) => {
                if (!(data.line_item_name.length > 3 && data.category.length > 0 && parseFloat(data.cost) > 0)) {
                    errorRow.push(counter);
                }
                counter += 1;
            })

            if (errorRow.length === 0) {
                setIsOpenAddNewLineItemModal(false)
                let requestBody = {
                    draw_items: tempResponseData,
                    inspection_order_id
                }
                setProcess(true);
                const actionTypesResp = await OMSPostData("inspection/create-new-draw-items", requestBody);
                if (actionTypesResp?.data?.data?.drawItemsData?.length) {
                    setActiveInspectionDrawItems(actionTypesResp?.data?.data?.drawItemsData);
                    onHandleData({ activeInspectionDrawItems: actionTypesResp?.data?.data?.drawItemsData });
                }
                toast['success'](actionTypesResp.data.message);
                setProcess(false);
                setCustomErrorMasterMessage(" ");
                setUpdate(moment())
                setDefaultValue([{ id: 1, line_item_name: "", description: "", category: [], active: true, cost: 0 }]);
            } else {
                setCustomErrorMasterMessage(`*Please fill all necessary data in row(s) number ${errorRow}`);
            }
        } catch (error) {
            toast['error'](error.message);
            setUpdate(moment());
            await sendExceptionEmail(error, { memberCode: 401, componentName: 'DrawLineItem', functionName: 'handleAddNewLineItemFinalData', data: {} });
            setProcess(false);
        }
    };

    let columns = [
        { title: "No", width: "3%", isKey: true, dataField: "id", align: "center" },
        { title: "Line Item", width: "20%", dataFormat: (cell, row) => textBox(cell, row, "line_item_name", "text", true), align: "center" },
        { title: "Description", width: "20%", dataFormat: (cell, row) => textBox(cell, row, "description", "text", false), align: "center" },
        { title: "Category", width: "21%", dataFormat: (cell, row) => dropDown(cell, row), align: "center" },
        { title: "Cost", width: "5%", dataFormat: (cell, row) => textBox(cell, row, "cost", "number", true), align: "center" },
        { title: `Active`, width: "5%", dataFormat: (cell, row) => checkbox(cell, row), align: "center", hasTooltip: true, tooltipText: "Active" },
        { title: `Action`, width: "10%", dataFormat: (cell, row) => actionButton(cell, row), align: "center" }
    ];

    const tableParams = {
        tableData: defaultValue,
        columns,
    };

    const subjectPropertyTable = (
        <>
            <div className="p-12">
                <AvForm>
                    <CardBody className="budget-section" style={{ height: "500px", overflow: "auto" }}>
                        <Row>
                            <Col>
                                <RenderBootstrapTable tableParams={tableParams} pagination={false} />
                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col className="text-left">
                                <Button color="info" size="sm" outline type="button" onClick={(e) => addNewRow()}>
                                    <i className="p-2 fa fa-plus-circle" aria-hidden="true"></i> Add New Line Item
                                </Button>
                            </Col>
                            <Col className="text-right">
                                <div className="form-error text-right">{customErrorMasterMessage}</div>
                                <Button color="primary" outline onClick={async (e) => await handleAddNewLineItemFinalData(e)}>
                                    Save And Close
                                </Button>
                            </Col>
                        </Row>
                    </CardFooter>
                </AvForm>
            </div>
        </>
    );

    let unassignedPhoto = async (isFromExclude = false, isFromUnassignedPhoto = false, isForIncludePhoto = false) => {
        try {
            let dataForPayload = [];
            let tempActivedata = activeInspectionDrawItems;
            let tempCurrentInspectionDrawItems = currentInspectionDrawItem;
            let tempUnassignedInspectionPhotos = isForIncludePhoto ? excludedPhotos : unassignedInspectionPhotos;
            let tempExcludedPhotos = excludedPhotos;
            for (let selectedImages of selectedImagesForReassignOrUnassigned) {
                if (selectedImages.image_ids.length) {
                    dataForPayload.push(selectedImages)
                    let indexOfCurrent = tempCurrentInspectionDrawItems.findIndex(x => x.id === selectedImages.draw_item_id)
                    let index = tempActivedata.findIndex(x => x.id === selectedImages.draw_item_id)
                    for (let imageId of selectedImages.image_ids) {
                        let imageIdx = tempActivedata[index]?.assignedImages.findIndex(x => x.id === imageId)
                        if (imageIdx !== -1) {
                            if (isFromUnassignedPhoto) {
                                let indexAssigned = tempUnassignedInspectionPhotos.findIndex(x => x.id === imageId)
                                if (indexAssigned !== -1) {
                                    if (isFromExclude) {
                                        tempExcludedPhotos.push(tempUnassignedInspectionPhotos[indexAssigned]);
                                    }
                                    if (isForIncludePhoto) {
                                        let unassignedInspectionPhotosTemp = unassignedInspectionPhotos;
                                        unassignedInspectionPhotosTemp.push(tempUnassignedInspectionPhotos[indexAssigned]);
                                        setUnassignedInspectionPhotos(unassignedInspectionPhotosTemp);
                                    }
                                    tempUnassignedInspectionPhotos.splice(indexAssigned, 1);
                                }
                            } else {
                                if (isFromExclude) {
                                    tempExcludedPhotos.push(tempActivedata[index]?.assignedImages[imageIdx]);
                                } else if (!isForIncludePhoto) {
                                    tempUnassignedInspectionPhotos.push(tempActivedata[index]?.assignedImages[imageIdx]);
                                }
                            }
                            tempActivedata[index]?.assignedImages.splice(imageIdx, 1);
                            imageIdx = -1;
                        }
                        imageIdx = tempCurrentInspectionDrawItems[indexOfCurrent]?.assignedImages.findIndex(x => x.id === imageId)
                        if (imageIdx !== -1) {
                            tempCurrentInspectionDrawItems[indexOfCurrent]?.assignedImages.splice(imageIdx, 1);
                            imageIdx = -1;
                        }
                    }
                }
            }

            if (dataForPayload.length) {
                setProcess(true);
                let result = await PostData("inspection/unassigned-draw-inspection-photos", { unassigned_data: dataForPayload, exclude: isFromExclude });
                if (result.success) {
                    toast['success'](result.message);
                }
                if (isFromExclude) {
                    setExcludedPhotos(tempExcludedPhotos);
                }
                if (isForIncludePhoto) {
                    setExcludedPhotos(tempUnassignedInspectionPhotos);
                } else {
                    setUnassignedInspectionPhotos(tempUnassignedInspectionPhotos);
                }
                setCurrentInspectionDrawItem(tempCurrentInspectionDrawItems)
                setActiveInspectionDrawItems(tempActivedata);
                onHandleData({ activeInspectionDrawItems: tempActivedata });
                setSelectedImagesForReassignOrUnassigned([]);
                setProcess(false);
            }
        } catch (error) {
            toast['error'](error.message);
            setUpdate(moment());
            setProcess(false);
            await sendExceptionEmail(error, { memberCode: 401, componentName: 'DrawLineItem', functionName: 'unassignedPhoto', data: {} });
        }

    };
    let reassignedPhoto = async (callReassignedAPI = false) => {
        try {
            if (selectedImagesForReassignOrUnassigned.length) {
                let tempSelectedPhotosForReassign = selectedPhotosForReassign;
                if (callReassignedAPI) {
                    let tempIds = selectedImagesForReassignOrUnassigned.flatMap(item => item.image_ids);
                    if (tempIds.length) {
                        let tempActivedata = activeInspectionDrawItems;
                        let tempCurrentInspectionDrawItems = currentInspectionDrawItem;

                        for (let selectedImages of selectedImagesForReassignOrUnassigned) {
                            if (selectedImages.image_ids.length) {
                                let indexOfCurrent = tempCurrentInspectionDrawItems.findIndex(x => x.id === selectedImages.draw_item_id)
                                let index = tempActivedata.findIndex(x => x.id === selectedImages.draw_item_id)
                                for (let imageId of selectedImages.image_ids) {
                                    let selectedLineItemIndexOfActiveDraw = tempActivedata.findIndex(x => x.id === selectedLineItemForReassignPhotoId)
                                    let selectedLineItemIndexOfCurrentDraw = tempCurrentInspectionDrawItems.findIndex(x => x.id === selectedLineItemForReassignPhotoId)

                                    let imageIdx = tempActivedata[index].assignedImages.findIndex(x => x.id === imageId)
                                    if (imageIdx !== -1) {
                                        tempActivedata[selectedLineItemIndexOfActiveDraw].assignedImages.push(tempActivedata[index].assignedImages[imageIdx])
                                        tempActivedata[index].assignedImages.splice(imageIdx, 1);
                                        imageIdx = -1;
                                    }

                                    imageIdx = tempCurrentInspectionDrawItems[indexOfCurrent].assignedImages.findIndex(x => x.id === imageId)
                                    if (imageIdx !== -1) {
                                        tempCurrentInspectionDrawItems[selectedLineItemIndexOfCurrentDraw].assignedImages.push(tempCurrentInspectionDrawItems[indexOfCurrent].assignedImages[imageIdx]);
                                        tempCurrentInspectionDrawItems[indexOfCurrent].assignedImages.splice(imageIdx, 1);
                                        imageIdx = -1;
                                    }
                                }
                            }
                        }
                        setProcess(true);
                        let payload = { reassignLineItemId: selectedLineItemForReassignPhotoId, imagesIds: tempIds }
                        let result = await PostData("inspection/reassign-draw-inspection-photos", payload);
                        if (result.success) {
                            toast['success'](result.message);
                        }
                        setCurrentInspectionDrawItem(tempCurrentInspectionDrawItems)
                        setActiveInspectionDrawItems(tempActivedata);
                        onHandleData({ activeInspectionDrawItems: tempActivedata });
                        setSelectedImagesForReassignOrUnassigned([]);
                        setSelectedLineItemForReassignPhotoId("");
                        setRowSelectedForAssignment([]);
                        setProcess(false);
                    }
                } else {
                    setSelectedPhotosForReassign(tempSelectedPhotosForReassign)
                    setOpenReassignModel(!openReassignModel);
                    setIsOpenUnassignedInspectionPhotos(!isOpenUnassignedInspectionPhotos)
                    setUpdate(moment());
                }
            }
        } catch (error) {
            setProcess(false);
            toast['error'](error.message);
            setUpdate(moment());
            await sendExceptionEmail(error, { memberCode: 401, componentName: 'DrawLineItem', functionName: 'reassignedPhoto', data: {} });
        }
    };

    let selectPhotosForReassignOrUnassign = (e, image, idx, item) => {
        let temp = selectedImagesForReassignOrUnassigned;
        if (temp.length) {
            let index = temp.findIndex((x) => x.draw_item_id === item.id)
            if (index !== -1) {
                let imageIndex = temp[index].image_ids.findIndex((x) => x === image.id)
                if (imageIndex !== -1) {
                    temp[index].image_ids.splice(imageIndex, 1);
                    if (temp[index].image_ids.length === 0) {
                        temp.splice(index, 1);
                    }
                } else {
                    if (temp[index].image_ids.length) {
                        temp[index].image_ids.push(image.id)
                    } else {
                        temp[index].image_ids = [image.id]
                    }
                }
            } else {
                temp.push({ "draw_item_id": item.id, "image_ids": [image.id] });
            }
        } else {
            temp.push({ "draw_item_id": item.id, "image_ids": [image.id] });
        }
        setSelectedImagesForReassignOrUnassigned(temp);
        setUpdate(moment());
    }
    function giveSelectedCss(item, image) {
        let index = selectedImagesForReassignOrUnassigned.findIndex((x) => x.draw_item_id === item.id)
        if (index !== -1) {
            let imageIndex = selectedImagesForReassignOrUnassigned[index]?.image_ids.findIndex((x) => x === image?.id)
            if (imageIndex !== -1) {
                return " #1cd31c";
            } else {
                return " black";
            }
        } else {
            return " black";
        }
    }

    let onCheckboxSelect = (e, row) => {
        setRowSelectedForAssignment([row]);
        if (openReassignModel && currentInspectionDrawItem.length) {
            setSelectedLineItemForReassignPhotoId(row.id)
        }
    }

    let excludeCheckbox = function (cell, row, field) {
        return (<>
            <Col className="custom-radio-container inline mt-2">
                <label className="radio-div">
                    <input type="radio"
                        name={"line_item_check___" + row?.id}
                        onChange={(e) => onCheckboxSelect(e, row)}
                        checked={rowSelectedForAssignment.length && row && row?.id === rowSelectedForAssignment[0]?.id}
                    />
                    <span className="radio"></span>
                </label>
            </Col>
        </>)
    };

    let getOurCompletionData = (cell, row) => {
        return parseInt(row.our_qc_completion_percent) || 0;
    }

    let unassignedInspectionPhotosColumns = [
        { title: "Select", width: "5%", dataFormat: (cell, row) => excludeCheckbox(cell, row, "select") },
        { title: "   Line Item", width: "10%", dataField: "line_item_name", align: "left" },
        { title: "      Category", width: "10%", dataFormat: (cell, row) => dropDown(cell, row, true), align: "left" },
        { title: "      Cost", width: "10%", isKey: true, dataField: "cost", align: "left" },
        { title: "Inspector Completion%", width: "10%", dataField: "inspector_completion_percent", align: "left" },
        { title: "Our Prior Completion%", width: "10%", dataFormat: (cell, row) => getOurCompletionData(cell, row), align: "left" },
        { title: "New Completion%", width: "10%", dataFormat: (cell, row) => getOurCompletionData(cell, row), align: "left" },
    ];

    const unassignedPhotosTableParams = {
        tableData: activeInspectionDrawItems,
        columns: unassignedInspectionPhotosColumns
    };

    const assignImageToLineItem = async () => {
        try {
            let tempUnassignedInspectionPhotos = isOpenExcludedPhotos ? excludedPhotos : unassignedInspectionPhotos;
            let tempActivedata = activeInspectionDrawItems;
            if (selectedImagesForReassignOrUnassigned[0].image_ids.length) {
                let rowIndex = tempActivedata.findIndex(x => x.id === rowSelectedForAssignment[0].id)
                selectedImagesForReassignOrUnassigned[0].image_ids.map((imageId) => {
                    let imageIndex = tempUnassignedInspectionPhotos.findIndex(x => x.id === imageId);
                    tempActivedata[rowIndex].assignedImages.push(tempUnassignedInspectionPhotos[imageIndex])
                    tempUnassignedInspectionPhotos.splice(imageIndex, 1);
                    return "";
                })
                setProcess(true);
                let payload = { reassignLineItemId: rowSelectedForAssignment[0].id, imagesIds: selectedImagesForReassignOrUnassigned[0].image_ids }
                let result = await PostData("inspection/reassign-draw-inspection-photos", payload);
                if (result.success) {
                    toast['success'](result.message);
                }
                setActiveInspectionDrawItems(tempActivedata);
                onHandleData({ activeInspectionDrawItems: tempActivedata });
                if (isOpenExcludedPhotos) {
                    setExcludedPhotos(tempUnassignedInspectionPhotos)
                } else {
                    setUnassignedInspectionPhotos(tempUnassignedInspectionPhotos)
                }
                setRowSelectedForAssignment([]);
                setSelectedImagesForReassignOrUnassigned([]);
                setProcess(false);
            }
        } catch (error) {
            setProcess(false);
            toast['error'](error.message);
            setUpdate(moment());
            await sendExceptionEmail(error, { memberCode: 401, componentName: 'DrawLineItem', functionName: 'assignImageToLineItem', data: {} });
        }

    };

    const modelDataForUnassignedPhotos = (
        <>
            <div className="p-12">
                <AvForm>
                    <CardBody className="budget-section" >
                        <Row>
                            <Col xs="12" sm="12" md="6" lg="6" style={{ height: "450px", overflow: "auto" }}>
                                <RenderBootstrapTable tableParams={unassignedPhotosTableParams} pagination={false} />
                            </Col>
                            <Col xs="12" sm="12" md="6" lg="6" style={{ height: "450px", overflow: "auto" }}>
                                {openReassignModel && currentInspectionDrawItem.length ?
                                    <>
                                        {currentInspectionDrawItem.length && currentInspectionDrawItem.map(function (item, i) {
                                            return (
                                                <div key={i}>
                                                    {item.assignedImages.length ?
                                                        <>
                                                            <h2 style={{ margin: "11px 3px 3px 3px" }}>{item.line_item_name} </h2>
                                                            <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "left" }}>
                                                                {item.assignedImages.map((image, idx) => (
                                                                    <div key={idx} style={{
                                                                        margin: "2px 3px 3px 9px", textAlign: "left", width: "250px", border: `3px solid${giveSelectedCss(item, image)}`, padding: "2px"
                                                                    }}>
                                                                        <img
                                                                            height={"250px"}
                                                                            width={"242px"}
                                                                            style={{ border: "2px solid", cursor: "pointer" }}
                                                                            src={image.location}
                                                                            alt={"img_" + idx}
                                                                            onClick={(e) => selectPhotosForReassignOrUnassign(e, image, idx, item)}
                                                                            loading="lazy"
                                                                        />
                                                                        <AvField bsSize="sm"
                                                                            value={image.label}
                                                                            maxLength={150}
                                                                            style={{ margin: "4px 0px", border: "1px solid" }}
                                                                            onChange={e => inspectionPhotoLableChange(e, item, idx)}
                                                                            name={"line_item_name___" + idx}
                                                                            placeholder="Enter Line Item" validate={{
                                                                                required: {
                                                                                    value: false,
                                                                                    errorMessage: "This field is required."
                                                                                }
                                                                            }} />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </> : null}
                                                </div>
                                            );
                                        })}
                                    </> :
                                    <>
                                        {isOpenExcludedPhotos ?
                                            <>
                                                {!excludedPhotos.length ?
                                                    <>
                                                        <h2>There are no photos for assignment.</h2>
                                                    </> :
                                                    <>
                                                        <h2>Excluded Photos</h2> <p>Please choose a line item and select the photos you would like to assign to the selected line item.</p>
                                                        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "left" }}>
                                                            {excludedPhotos.map((image, idx) => (
                                                                <div key={idx} style={{
                                                                    margin: "2px 3px 3px 9px", textAlign: "left", width: "250px", border: `3px solid${giveSelectedCss(selectedLineItemForReassignPhotoId, image)}`, padding: "2px"
                                                                }}>
                                                                    {image?.location &&
                                                                        <>
                                                                            <img
                                                                                height={"250px"}
                                                                                width={"242px"}
                                                                                style={{ border: "2px solid", cursor: "pointer" }}
                                                                                src={image?.location || ""}
                                                                                alt={"img_" + idx}
                                                                                onClick={(e) => selectPhotosForReassignOrUnassign(e, image, idx, selectedLineItemForReassignPhotoId)}
                                                                                loading="lazy"
                                                                            />
                                                                            <AvField bsSize="sm"
                                                                                id={"line_item_name___" + idx}
                                                                                value={image.label}
                                                                                maxLength={150}
                                                                                style={{ margin: "4px 0px", border: "1px solid" }}
                                                                                name={"line_item_name___" + idx}
                                                                                onChange={e => unassignPhotoLabelChange(e, image, "excludedPhoto")}
                                                                            />
                                                                            <UncontrolledTooltip delay={0} placement="auto" target={"line_item_name___" + idx} >{image.label}</UncontrolledTooltip>
                                                                        </>
                                                                    }
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                }
                                            </> :
                                            <>
                                                {!unassignedInspectionPhotos.length ?
                                                    <>
                                                        <h2>There are no photos for assignment.</h2>
                                                    </> :
                                                    <>
                                                        <h2>Unassigned Photos</h2> <p>Please choose a line item and select the photos you would like to assign to the selected line item.</p>
                                                        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "left" }}>
                                                            {unassignedInspectionPhotos.map((image, idx) => (
                                                                <div key={idx} style={{
                                                                    margin: "2px 3px 3px 9px", textAlign: "left", width: "250px", border: `3px solid${giveSelectedCss(selectedLineItemForReassignPhotoId, image)}`, padding: "2px"
                                                                }}>
                                                                    {image?.location &&
                                                                        <>
                                                                            <img
                                                                                height={"250px"}
                                                                                width={"242px"}
                                                                                style={{ border: "2px solid", cursor: "pointer" }}
                                                                                src={image?.location || ""}
                                                                                alt={"img_" + idx}
                                                                                onClick={(e) => selectPhotosForReassignOrUnassign(e, image, idx, selectedLineItemForReassignPhotoId)}
                                                                                loading="lazy"
                                                                            />
                                                                            <AvField bsSize="sm"
                                                                                id={"line_item_name___" + idx}
                                                                                value={image.label || ""}
                                                                                maxLength={150}
                                                                                style={{ margin: "4px 0px", border: "1px solid" }}
                                                                                name={"line_item_name___" + idx}
                                                                                onChange={e => unassignPhotoLabelChange(e, image, "unassignedPhoto")}
                                                                            />
                                                                            <UncontrolledTooltip delay={0} placement="auto" target={"line_item_name___" + idx} >{image.label}</UncontrolledTooltip>
                                                                        </>
                                                                    }
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        }
                                    </>}

                            </Col>
                        </Row>
                    </CardBody>
                    <CardFooter>
                        <Row>
                            <Col className="text-right">
                                {isOpenExcludedPhotos ? <>
                                    <Button outline size="sm" color="primary" onClick={async () => { await unassignedPhoto(false, true, true) }} disabled={selectedImagesForReassignOrUnassigned.length ? false : true}>
                                        Include Photo(s) / Move To Unassigned Photo(s)
                                    </Button>
                                    <Button color="primary" size="sm" outline type="button" onClick={async () => { openReassignModel && currentInspectionDrawItem.length ? await reassignedPhoto(true) : await assignImageToLineItem() }} disabled={selectedImagesForReassignOrUnassigned.length && rowSelectedForAssignment.length ? false : true} >
                                        Assigned Photo(s)
                                    </Button>
                                </> :
                                    <>
                                        <Button outline size="sm" color="primary" onClick={async () => { await unassignedPhoto(true, true) }} disabled={selectedImagesForReassignOrUnassigned.length ? false : true}>
                                            Exclude Photo(s)
                                        </Button>
                                        <Button color="primary" size="sm" outline type="button" onClick={async () => { openReassignModel && currentInspectionDrawItem.length ? await reassignedPhoto(true) : await assignImageToLineItem() }} disabled={selectedImagesForReassignOrUnassigned.length && rowSelectedForAssignment.length ? false : true} >
                                            Assigned Photo(s)
                                        </Button>
                                    </>
                                }
                            </Col>
                        </Row>
                    </CardFooter>
                </AvForm>
            </div>
        </>
    );

    const openInspectionPhotos = (
        <>
            <div className="p-12">
                <AvForm>
                    <CardBody className="budget-section" style={{ height: "80%", overflow: "auto" }}>
                        <Row>
                            <Col xs="12" sm="12" md="12" lg="12">
                                <div className="text-right mb-2">
                                    <Button className="mt-2" outline size="sm" color="primary" onClick={async () => { await unassignedPhoto(true, false) }} disabled={selectedImagesForReassignOrUnassigned.length ? false : true}>
                                        Exclude Photo(s)
                                    </Button>
                                    <Button className="mt-2" outline size="sm" color="primary" onClick={async () => { await unassignedPhoto(false, false) }} disabled={selectedImagesForReassignOrUnassigned.length ? false : true}>
                                        Unassign Photo(s)
                                    </Button>
                                    <Button className="mt-2" outline size="sm" color="primary" onClick={async () => { await reassignedPhoto() }} disabled={selectedImagesForReassignOrUnassigned.length ? false : true}>
                                        Re-assign Photo(s)
                                    </Button>
                                </div>
                            </Col>
                            <Col xs="12" sm="12" md="12" lg="12" style={{ height: "500px", overflow: "auto" }}>
                                <>
                                    {currentInspectionDrawItem.length && currentInspectionDrawItem.map(function (item, i) {
                                        return (
                                            <div key={i}>
                                                {item.assignedImages.length ?
                                                    <>
                                                        <h2 style={{ margin: "3px" }}>{item.line_item_name} </h2>
                                                        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "left" }}>
                                                            {item.assignedImages.map((image, idx) => (
                                                                <div key={idx} style={{
                                                                    margin: "2px 3px 3px 9px", textAlign: "left", width: "400px", border: `3px solid${giveSelectedCss(item, image)}`, padding: "2px"
                                                                }}>
                                                                    {image && image.location ?
                                                                        <>
                                                                            <img
                                                                                height={"370px"}
                                                                                width={"390px"}
                                                                                style={{ border: "2px solid", cursor: "pointer" }}
                                                                                src={image?.location || ""}
                                                                                alt={"img_" + idx}
                                                                                onClick={(e) => selectPhotosForReassignOrUnassign(e, image, idx, item)}
                                                                                loading="lazy"
                                                                            />
                                                                            <AvField bsSize="sm"
                                                                                value={image?.label}
                                                                                maxLength={150}
                                                                                style={{ margin: "4px 0px", border: "1px solid" }}
                                                                                onChange={e => inspectionPhotoLableChange(e, item, idx)}
                                                                                name={"line_item_name___" + idx}
                                                                                placeholder="Enter Line Item" validate={{
                                                                                    required: {
                                                                                        value: false,
                                                                                        errorMessage: "This field is required."
                                                                                    }
                                                                                }} />
                                                                        </> : null}
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </> : null}
                                            </div>
                                        );
                                    })}
                                </>

                            </Col>
                        </Row>
                    </CardBody>
                </AvForm>
            </div>
        </>
    );

    return (
        <>
            <Spinner isShow={isProcess} />
            <Col xs="12" sm="12" md="12" lg="12" >
                <>
                    <Row>
                        <Col style={{ align: "left" }} md="2" lg="2">
                            <h2 >Draw Items</h2>
                        </Col>
                        <Col className="text-right" md="10" lg="10">
                            <Button className="mt-2" outline size="sm" color="primary" onClick={() => { addNewLineItem() }}>
                                + Add Line Item
                            </Button>
                            <Button className="mt-2 text-right" size="sm" outline color="primary" onClick={() => { unassignedInspectionPhoto() }} disabled={unassignedInspectionPhotos.length ? false : true}>
                                Unassigned Inspection Photos ({unassignedInspectionPhotos.length})
                            </Button>
                            <Button className="mt-2 text-right" size="sm" outline color="primary" onClick={() => { unassignedInspectionPhoto(true) }} disabled={unassignedInspectionPhotos.length ? false : true}>
                                Excluded Inspection Photos ({excludedPhotos.length})
                            </Button>
                            <Button className="mt-2" outline size="sm" color="primary" onClick={async () => { await callRetrieveProxyPicsAPI() }}>
                                Retrieve Inspection Photos
                            </Button>
                            <Button className="mt-2" outline size="sm" color="primary" onClick={() => { openImageSlider(activeInspectionDrawItems, true) }}>
                                Edit All Inspection Photos
                            </Button>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col lg="12" className={`inspection-intake-draw-item-lists inspection-draw-items-modal ${additionalClass}`} >
                            <Table bordered striped>
                                <thead>
                                    <tr>
                                        <th className="text-center" width="3%">
                                            <Col className="custom-checkbox-container inline m-1">
                                                <label className="checkbox-div" name="select_all" id="select_all">
                                                    <input type="checkbox"
                                                        name={"select_all"}
                                                        value='true'
                                                        onChange={(e) => selectUnselectAll(e)}
                                                        checked={activeInspectionDrawItems?.selectAll}
                                                    />
                                                    <UncontrolledTooltip delay={0} placement="auto" target={"select_all"} >Select/Unselect All</UncontrolledTooltip>
                                                    <span className="checkmark"></span>
                                                </label>
                                            </Col>
                                        </th>
                                        <th className="text-center" width="14%">Draw Item</th>
                                        <th className="text-center" width="14%">Description</th>
                                        <th className="text-center" width="18%">Category</th>
                                        <th className="text-center" width="10%">Cost</th>
                                        <th className="text-center" width="4%">Previous Completion %</th>
                                        <th className="text-center" width="4%">Inspector Completion %</th>
                                        <th className="text-center" width="4%">Our Completion %</th>
                                        <th className="text-center" width="5%">Status</th>
                                        <th className="text-center" width="24%">Assigned Photos</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {activeInspectionDrawItems.map(function (item, i) {
                                        return (
                                            <tr key={i} className={errorIndexes.length && errorIndexes.includes(i) ? "color-red" : ""}>
                                                <td className="text-center">
                                                    <Col className="custom-checkbox-container inline">
                                                        <label className="checkbox-div draw-line-checkbox">
                                                            <input type="checkbox"
                                                                name={"line_item_check___" + i}
                                                                value={item.line_item_name}
                                                                onChange={(e) => { onDataChange(e, i); }}
                                                                checked={item.active === true}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </Col>
                                                </td>
                                                <td style={{ verticalAlign: "middle" }}>
                                                    <AvField bsSize="sm"
                                                        value={item.line_item_name}
                                                        onChange={e => { onDataChange(e, i); }}
                                                        name={"line_item_name___" + i}
                                                        placeholder="Enter Line Item" validate={{
                                                            required: {
                                                                value: false,
                                                                errorMessage: "This field is required."
                                                            }
                                                        }} />
                                                </td >
                                                <td style={{ verticalAlign: "middle" }} className="line-items-description">
                                                    <AvField bsSize="sm"
                                                        value={item.description}
                                                        onChange={e => { onDataChange(e, i); }}
                                                        name={"description___" + i}
                                                        placeholder="Enter Description" validate={{
                                                            required: {
                                                                value: false,
                                                                errorMessage: "This field is required."
                                                            }
                                                        }} />
                                                </td>
                                                <td style={{ verticalAlign: "middle" }} className="line-item-categories">
                                                    <div className="my-typeahead">
                                                        <Typeahead
                                                            multiple={true}
                                                            id="typeahead"
                                                            key={i}
                                                            options={category}
                                                            selected={item.categories}
                                                            name={"category___" + i}
                                                            placeholder="Select Categories"
                                                            onChange={e => onDataChange(e, i)}
                                                            onKeyDown={(e) => onKeyDownAddNewCategory(e, item)}
                                                            onInputChange={(e) => onInputChangeAddNewCategory(e, item)}
                                                            emptyLabel={< div style={{ fontSize: 14, whiteSpace: "normal" }}>Type new category and hit tab to add new category.</div>}
                                                        />
                                                    </div>
                                                </td>
                                                <td style={{ verticalAlign: "middle" }} className="line-items-cost">
                                                    <AvField bsSize="sm"
                                                        value={item.cost ? item.cost : 0}
                                                        name={"cost___" + i}
                                                        onChange={e => { onDataChange(e, i); }}
                                                        placeholder="Enter Cost"
                                                        validate={{
                                                            required: {
                                                                value: false,
                                                                errorMessage: "This field is required."
                                                            }
                                                        }} />
                                                </td>
                                                <td className="line-items-prior-complition-percent text-center">
                                                    <div>
                                                        {item.previous_completion_percentage ? item.previous_completion_percentage : 0}%
                                                    </div>
                                                </td>
                                                <td className="line-items-prior-complition-percent text-center">
                                                    <div>
                                                        {item.inspector_completion_percent ? item.inspector_completion_percent : 0}%
                                                    </div>
                                                </td>
                                                <td className="line-items-new-complition-percent" style={{ verticalAlign: "middle" }}>
                                                    <div className="form-goup">
                                                        <AvField bsSize="sm"
                                                            value={item.our_qc_completion_percent || "0"}
                                                            onChange={e => onDataChange(e, i)}
                                                            name={"new_our_completion_percent___" + i}
                                                            placeholder="Enter %" validate={{
                                                                required: {
                                                                    value: false,
                                                                    errorMessage: "This field is required."
                                                                },
                                                                pattern: {
                                                                    value: /^\d{1,3}(\.\d{1,2})?$/,
                                                                    errorMessage: "Please enter a valid integer.",
                                                                },
                                                                max: {
                                                                    value: 100,
                                                                    errorMessage: "Invalid input. Must be less than or equal to 100.",
                                                                },
                                                                min: {
                                                                    value: 0,
                                                                    errorMessage: "Invalid input. Must be greater than or equal to 0.",
                                                                }
                                                            }} />
                                                    </div>
                                                </td>
                                                <td style={{ verticalAlign: "middle" }} className="active-inactive text-center">
                                                    <Button className="text-center" size="sm" outline color={item.active ? "primary" : "danger"}>
                                                        {item.active ? 'Active' : 'Inactive'}
                                                    </Button>
                                                </td>
                                                <td className="image-assigned text-center">
                                                    {item.assignedImages.length > 0 ?
                                                        <>
                                                            {item.assignedImages.map(function (image, index) {
                                                                if (image && image.location) {
                                                                    return (<img
                                                                        key={index}
                                                                        height={"80px"}
                                                                        width={"80px"}
                                                                        style={{ margin: "2px", border: "1px solid", cursor: "pointer" }}
                                                                        src={image.location}
                                                                        alt={"img_" + index}
                                                                        loading="lazy"
                                                                        onClick={(e) => openImageSlider(item)}
                                                                    />)

                                                                }
                                                                return "";
                                                            })}
                                                        </>
                                                        :
                                                        <h2 className="mt-2" style={{ fontSize: '18px' }}>Photos not assigned.</h2>
                                                    }
                                                </td>

                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </>
            </Col>

            <Modal className={`${onReportData.isStandalone === '1' ? 'modal-dialog' : 'modal-dialog-centered'} modal-lg`} style={{ maxWidth: "85%", maxHeight: "85%", marginTop: "3%" }} isOpen={isOpenAddNewLineItemModal} toggle={() => setIsOpenAddNewLineItemModal(false)}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-center w-100">
                        Add New Line Items
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => setIsOpenAddNewLineItemModal(false)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                {subjectPropertyTable}
            </Modal>

            <Modal className={`${onReportData.isStandalone === '1' ? 'modal-dialog' : 'modal-dialog-centered'} modal-lg`} style={{ maxWidth: "82%", maxHeight: "82%", marginTop: "3%" }} isOpen={isOpenInspectionPhotosModel} toggle={() => setIsOpenInspectionPhotosModel(false)}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-center w-100">
                        Inspection Photos
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => setIsOpenInspectionPhotosModel(false)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                {openInspectionPhotos}
            </Modal>

            <Modal className={`${onReportData.isStandalone === '1' ? 'modal-dialog' : 'modal-dialog-centered'} modal-lg`} style={{ maxWidth: "80%", maxHeight: "80%", marginTop: "3%" }} isOpen={isOpenUnassignedInspectionPhotos} toggle={() => { setIsOpenUnassignedInspectionPhotos(false); setOpenReassignModel(false); }}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-center w-100">
                        Inspection Photos {openReassignModel && currentInspectionDrawItem.length ? "Re-Assignment" : "Assignment"}
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => { setIsOpenUnassignedInspectionPhotos(false); setOpenReassignModel(false); }}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                {modelDataForUnassignedPhotos}
            </Modal>
        </>
    );
}

export default DrawLineItem;