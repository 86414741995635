import React, { useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Row, UncontrolledTooltip } from 'reactstrap'
import { loggedInUser, checkRoles, checkRolesWithLevel, formatDateComponent, sendExceptionEmail, formatDate } from 'components/Common/Helpers';
import RenderBootstrapTable from 'components/Common/RenderBootstrapTable';
import CustomToggleButton from 'components/CustomToggleButton/CustomToggleButton';
import Header from 'components/Headers/Header'
import TableSkeleton from 'components/SkeletonUI/TableSkeleton';
import { toast } from 'react-toastify';
import { PostData } from 'services/Api.service';
import ThreadColumn from './ThreadColumn';
import ActionButtons from '../Common/ActionsButtons/ActionButtons';
import {
    IO_DISMISSED,
    IO_COMPLETED,
    IO_RECEIVED,
    IO_CANCELLED,
    IO_ON_HOLD,
    IT_DRAW,
    IO_QC,
    IO_REVISED,
    RO_PROPERTY_ANALYSIS_REVIEWED,
    RO_REPORT_DELIVERED,
    RO_CLIENT_INQUIRY_RESOLVED,
    RO_REVISION_REQUESTED,
    RO_BUDGET_UPDATED,
    RO_DISPUTE_INITIATED,
    RO_ORDERED,
    RO_BEGIN_PROPERTY_ANALYSIS,
    RO_ANALYSIS_UNASSIGNED,
    RT_EVAL,
    RO_REPORT_FINALIZED,
    RO_REPORT_CANCELLED,
    RO_ON_HOLD,
    RO_PROPERTY_ANALYSIS_QUESTIONS,
    RO_ANALYSIS_REVIEW_QUESTIONS,
    RO_MARKET_UPDATE,
    RO_PROPERTY_ANALYSIS_QUESTIONS_RESOLVED,
    RO_PROPERTY_ANALYSIS_EDITS_REQUESTED,
    RO_PROPERTY_ANALYSIS_COMPLETED,
    RO_ANALYSIS_REVIEW_INITIATED,
    RO_ANALYSIS_REVIEW_UNASSIGNED,
    RO_ANALYSIS_REVIEW_QUESTIONS_RESOLVED,
    RO_ANALYSIS_REVIEW_EDITS_REQUESTED,
    RO_NEW_SPECS,
} from '../Common/Constants';
import { MESSAGE_TYPES } from '../Common/Constants/Conversation.constant';
import eventBus from "../Common/EventBus";

const userData = loggedInUser();

const Messaging = () => {
    const [isProcess, setProcess] = useState(true);
    let [tableData, setTableData] = useState();

    const [sortFieldName, setSortFieldName] = useState('created_at');
    const [sortOrder, setSortOrder] = useState('desc');
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(10);
    const [totalRecordSize, setTotalRecordSize] = useState(0);
    const [isTab, setIsTab] = useState("toMe");

    let message_type;
    const isEligible = !userData.is_client && checkRoles(['ADMINISTRATOR', 'ACCOUNT_REP', 'SALES_REP', 'REVIEWER', 'ANALYST']);

    if (isEligible) {
        message_type = [MESSAGE_TYPES.INSPECTOR, MESSAGE_TYPES.CLIENT, MESSAGE_TYPES.INTERNAL];
    } else if (checkRoles(['PROCESSOR', 'DRV_ADMIN']) && !userData.is_client) {
        message_type = [MESSAGE_TYPES.INSPECTOR];
    } else if (userData.is_client) {
        message_type = [MESSAGE_TYPES.CLIENT];
    }

    /*
   | Message Action
   */
    const messageData = (cell, row) => {
        const { property_id, notifications, report_order_id, inspection_order_id, standalone } = row;

        const rowData = {
            propertyId: property_id,
            notifications,
            id: report_order_id || inspection_order_id,
            ...(standalone && { standalone })
        };

        return <ThreadColumn rowData={rowData} onUpdateData={(e) => { }} onUpdateMessage={retrieveMessaging} />;
    };

    const actions = (cell, row) => {
        try {
            const buttonsToDisplay = [];
            const { type } = row;

            if (type === "inspection") {
                if ([IO_DISMISSED].includes(row.inspection_status_id) === false) {
                    if ([IO_COMPLETED, IO_RECEIVED, IO_CANCELLED, IO_ON_HOLD].includes(row.inspection_status_id) === false) {
                        buttonsToDisplay.push('INSPECTION_LOG_ACTION')
                        buttonsToDisplay.push('INSPECTION_ORDER_CANCEL_OR_REPLACE')
                    }

                    if (row.standalone === '1' && [IO_COMPLETED, IO_RECEIVED, IO_CANCELLED, IO_ON_HOLD].includes(row.inspection_status_id) === false) {
                        buttonsToDisplay.push('INSPECTION_ORDER_PLACE_HOLD')
                    }

                    if (row.standalone === '1' && [IO_ON_HOLD].includes(row.inspection_status_id)) {
                        buttonsToDisplay.push('INSPECTION_ORDER_RELEASE_HOLD')
                    }

                    if ((row.standalone === '1' || row.inspection_type_id === IT_DRAW) && ([IO_QC].includes(row.inspection_status_id))) {
                        buttonsToDisplay.push('INSPECTION_ORDER_DISMISS')
                    }

                    if (row.standalone === '1' && [IO_COMPLETED, IO_RECEIVED].includes(row.inspection_status_id) === true && row.download_url) {
                        buttonsToDisplay.push('INSPECTION_ORDER_DOWNLOAD_PDF')
                        buttonsToDisplay.push('INSPECTION_ORDER_SEND_COMPLETED_PDF')
                    }
                }

                if (row.standalone === '1' && [IO_COMPLETED, IO_RECEIVED, IO_DISMISSED, IO_CANCELLED].includes(row.inspection_status_id) === true) {
                    buttonsToDisplay.push('INSPECTION_ORDER_REOPEN')
                }

                if (row.standalone === '1' && [IO_REVISED].includes(row.inspection_status_id) === true) {
                    buttonsToDisplay.push('INSPECTION_ORDER_DISMISS_REVISION')
                }
            }

            if (type === "eval") {
                if (!row.isSnags && [RO_PROPERTY_ANALYSIS_REVIEWED].includes(row.order_status)) {
                    row.pdfButtons = 'preview_pdf,generate_pdf_and_send,download_report'
                }

                if (!row.isSnags && [RO_REPORT_DELIVERED, RO_CLIENT_INQUIRY_RESOLVED].includes(row.order_status)) {
                    row.pdfButtons = 'preview_pdf,resend_pdf_url,download_report'
                }

                if (!row.isSnags && [RO_REVISION_REQUESTED, RO_BUDGET_UPDATED, RO_DISPUTE_INITIATED].includes(row.order_status)) {
                    row.pdfButtons = 'preview_pdf,download_report'
                }

                if (row.order_status === RO_DISPUTE_INITIATED) {
                    buttonsToDisplay.push('DISPUTE_ORDER')

                    if (!row.isSnags && row.budget_file && row.renovation_budget_needed && row.renovation_budget_needed === 1 && row.order_status !== RO_REPORT_DELIVERED) {
                        buttonsToDisplay.push('BUDGET_REVIEW')
                    }
                } else {

                    if (([RO_ORDERED, RO_BEGIN_PROPERTY_ANALYSIS, RO_ANALYSIS_UNASSIGNED].includes(row.order_status)) && !row.isSnags && checkRoles(['ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP']) === true) {
                        buttonsToDisplay.push('ADD_REMOVE_RUSH')
                    }

                    if ((row.order_status === RO_REVISION_REQUESTED || row.order_status === RO_BUDGET_UPDATED || row.order_status === RO_DISPUTE_INITIATED) && !row.isSnags && checkRoles(['REVIEWER']) && (row.report_type_id !== RT_EVAL || (row.report_type_id === RT_EVAL && checkRoles(['REVIEWER'])))) {
                        if (row.reviewer === "-") {
                            buttonsToDisplay.push('REPORT_ORDER_REVISION_ACCEPT')
                        }
                    }

                    if ((userData.user_id === row.reviewer_user_id || checkRoles(['ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP']) === true) && !row.isSnags && row.reviewer !== "-" && (row.order_status === RO_REVISION_REQUESTED || row.order_status === RO_BUDGET_UPDATED || row.order_status === RO_DISPUTE_INITIATED)) {
                        buttonsToDisplay.push('REPORT_ORDER_REVISION_REASSIGN')
                    }

                    if (!row.isSnags && row.budget_file && row.renovation_budget_needed && row.renovation_budget_needed === 1 && row.order_status !== RO_REPORT_DELIVERED) {
                        buttonsToDisplay.push('BUDGET_REVIEW')
                    }

                    if (checkRoles(['ADMINISTRATOR']) || checkRolesWithLevel(['REVIEWER'], ">=", 3) || (!row.isSnags && userData.user_id === row.reviewer_user_id)) {
                        if (parseInt(row.order_status) === RO_DISPUTE_INITIATED) {
                            buttonsToDisplay.push('CLIENT_RESPONSE')
                        }
                    }

                    if (!row.isSnags && [RO_PROPERTY_ANALYSIS_REVIEWED, RO_REPORT_DELIVERED, RO_CLIENT_INQUIRY_RESOLVED, RO_REVISION_REQUESTED, RO_BUDGET_UPDATED, RO_DISPUTE_INITIATED].includes(row.order_status)) {
                        buttonsToDisplay.push('PDF_BUTTONS')
                    }

                    if (!row.isSnags && [RO_REPORT_DELIVERED, RO_REPORT_CANCELLED, RO_CLIENT_INQUIRY_RESOLVED].includes(row.order_status)) {
                        buttonsToDisplay.push('REPORT_ORDER_REOPEN')
                    }

                    if ((checkRoles(['ADMINISTRATOR', 'PROCESSOR', 'SALES_REP', 'ACCOUNT_REP', 'TECH', 'TECH_SUPPORT']) === true || checkRolesWithLevel(['REVIEWER'], ">=", 2)) && !row.isSnags && [RO_REPORT_FINALIZED, RO_REPORT_DELIVERED, RO_REPORT_CANCELLED, RO_CLIENT_INQUIRY_RESOLVED, RO_ON_HOLD].includes(row.order_status) === false) {
                        buttonsToDisplay.push('REPORT_ORDER_PLACE_HOLD')
                    }

                    if ((checkRoles(['ADMINISTRATOR', 'PROCESSOR', 'LOAN_OFFICER', 'MODERATOR', 'LOAN_PROCESSOR']) === true && !row.isSnags && [RO_REPORT_FINALIZED, RO_REPORT_DELIVERED, RO_REPORT_CANCELLED, RO_CLIENT_INQUIRY_RESOLVED, RO_REVISION_REQUESTED, RO_BUDGET_UPDATED, RO_DISPUTE_INITIATED].includes(row.order_status) === false)) {
                        buttonsToDisplay.push('REPORT_ORDER_CANCEL')
                    }

                    if (!row.isSnags && [RO_REVISION_REQUESTED, RO_BUDGET_UPDATED, RO_DISPUTE_INITIATED].includes(row.order_status) === true) {
                        buttonsToDisplay.push('REPORT_ORDER_DISMISS_REVISION')
                    }

                    if (!row.isSnags && ((checkRoles(['ADMINISTRATOR', 'PROCESSOR', 'SALES_REP', 'LOAN_OFFICER']) || checkRolesWithLevel(['REVIEWER'], ">=", 2)) && [RO_REPORT_FINALIZED, RO_REPORT_DELIVERED, RO_REPORT_CANCELLED, RO_CLIENT_INQUIRY_RESOLVED].includes(row.order_status) === false)) {
                        buttonsToDisplay.push('CHANGE_REPORT_TYPE')
                    }

                    if (!row.isSnags && [RO_ON_HOLD].includes(row.order_status)) {
                        buttonsToDisplay.push('REPORT_ORDER_RELEASE_HOLD')
                    }

                    if (!row.isSnags && [RO_PROPERTY_ANALYSIS_QUESTIONS, RO_ANALYSIS_REVIEW_QUESTIONS].includes(row.order_status) === true) {
                        buttonsToDisplay.push('REPORT_ORDER_QUESTION_ANSWER')
                    }

                    if (!row.isSnags && (row.is_new_order === false) && (row.order_status < RO_BEGIN_PROPERTY_ANALYSIS || row.order_status === RO_ANALYSIS_UNASSIGNED || row.order_status === RO_MARKET_UPDATE) && (checkRoles(['ANALYST']) || (checkRoles(['REVIEWER'])))) {
                        buttonsToDisplay.push('PROPERTY_ANALYSIS')
                    }

                    if ((row.analyst_user_id === userData.user_id || checkRoles(['REVIEWER', 'ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP', 'TRAINING_ADMINISTRATOR']) === true) && !row.isSnags && [RO_BEGIN_PROPERTY_ANALYSIS, RO_PROPERTY_ANALYSIS_QUESTIONS, RO_PROPERTY_ANALYSIS_QUESTIONS_RESOLVED, RO_PROPERTY_ANALYSIS_EDITS_REQUESTED].includes(row.order_status) === true) {
                        buttonsToDisplay.push('PROPERTY_ANALYSIS_RELINQUISH')
                    }

                    if ((!row.isSnags && [RO_PROPERTY_ANALYSIS_COMPLETED, RO_ANALYSIS_REVIEW_INITIATED, RO_ANALYSIS_REVIEW_UNASSIGNED, RO_ANALYSIS_REVIEW_QUESTIONS_RESOLVED, RO_ANALYSIS_REVIEW_EDITS_REQUESTED, RO_BUDGET_UPDATED, RO_NEW_SPECS].includes(row.order_status) && (row.order_status < RO_ANALYSIS_REVIEW_INITIATED || row.order_status === RO_ANALYSIS_REVIEW_UNASSIGNED || row.order_status === RO_NEW_SPECS) && checkRoles(['REVIEWER']) && (row.report_type_id !== RT_EVAL || (row.report_type_id === RT_EVAL && checkRoles(['REVIEWER']))))) {
                        buttonsToDisplay.push('ANALYSIS_REVIEW')
                    }

                    if ((!row.isSnags && (userData.user_id === row.reviewer_user_id || checkRoles(['ADMINISTRATOR', 'TRAINING_ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP']) === true) && [RO_ANALYSIS_REVIEW_INITIATED, RO_ANALYSIS_REVIEW_QUESTIONS, RO_ANALYSIS_REVIEW_QUESTIONS_RESOLVED, RO_ANALYSIS_REVIEW_EDITS_REQUESTED].includes(row.order_status) === true)) {
                        buttonsToDisplay.push('ANALYSIS_REVIEW_RELINQUISH')
                    }

                    if (row.isSnags && row.property_id) {
                        buttonsToDisplay.push('REPORT_ORDER_RELEASE_SNAG')
                    }

                    if (row.isSnags && row.property_id === null) {
                        buttonsToDisplay.push('REPORT_ORDER_RETRY_PROPERTY_UPLOAD')
                    }

                    if (((!row.isSnags && [RO_REPORT_DELIVERED, RO_REPORT_CANCELLED, RO_CLIENT_INQUIRY_RESOLVED].includes(row.order_status) === false && (([IO_CANCELLED, IO_COMPLETED].includes(row.inspection_order && row.inspection_order.inspection_status) === true || Object.keys(row.inspection_order).length === 0))) && checkRoles(['ADMINISTRATOR', 'PROCESSOR', 'SALES_REP', 'ACCOUNT_REP']))) {
                        buttonsToDisplay.push('ORDER_NEW_INSPECTION')
                    }
                }

            }

            if (type === "epo") {
                if ([RO_REPORT_DELIVERED, RO_REPORT_CANCELLED, RO_CLIENT_INQUIRY_RESOLVED].includes(row.order_status) === false &&
                    (([IO_CANCELLED, IO_COMPLETED].includes(row.inspection_status) || Object.keys(row.inspection_order).length === 0)) &&
                    checkRoles(['ADMINISTRATOR', 'PROCESSOR', 'SALES_REP', 'ACCOUNT_REP'])) {
                    buttonsToDisplay.push('ORDER_NEW_INSPECTION');

                }

                if ([IO_DISMISSED, IO_COMPLETED, IO_RECEIVED, IO_CANCELLED, IO_ON_HOLD].includes(row.inspection_status_id) === false) {
                    buttonsToDisplay.push('INSPECTION_ORDER_CANCEL_OR_REPLACE');
                }

                if ([RO_REPORT_DELIVERED, RO_CLIENT_INQUIRY_RESOLVED].includes(row.order_status) && row.report_pdf_url) {
                    buttonsToDisplay.push('EPO_DOWNLOAD_REPORT');
                }

                if ([RO_REPORT_DELIVERED, RO_CLIENT_INQUIRY_RESOLVED].includes(row.order_status)) {
                    buttonsToDisplay.push('EPO_REGENERATE_REPORT');
                }

            }

            if (!buttonsToDisplay?.length) {
                return <div> No Action </div>
            }

            return (<ActionButtons propButtonsToDisplay={buttonsToDisplay} propRowData={row} />);
        }
        catch (errors) {
            sendExceptionEmail(errors, { memberCode: 504, componentName: 'Messaging', functionName: 'actions', data: {} });
        }
    };

    const recentMessage = (cell, row) => {
        const { message = "", date = "", author = "" } = row.last_update || {};
        if (!message) {
            return "-";
        }
        const viewMessage = message.length > 15 ? message.slice(0, 15) + "..." : message;

        const formattedDate = formatDate(date, date, "MMM D, h:mm a")
        return <div>
            <span id={"tooltip" + row.id}>{`${formattedDate} ${author || ""} | ${viewMessage}`}</span>
            <UncontrolledTooltip delay={0} placement="auto" target={"tooltip" + row.id} > {message}</UncontrolledTooltip>
        </div>
    }

    const retrieveMessaging = async () => {
        try {
            let tableData = [];
            let offset = (currentPage - 1) * recordsPerPage;
            const primaryRecipient = isTab === 'toMe';

            let data = {
                offset, limit: recordsPerPage, sort_field: sortFieldName, sort_order: sortOrder, message_type, primaryRecipient, isEligible
            };

            setProcess(true);
            const response = await PostData(`conversation/get-messaging`, data);
            const messaging = response?.data?.rows;
            for (const item of messaging) {
                let finalOrderObj = {};

                finalOrderObj.report_order_id = item?.report_order_id;
                finalOrderObj.inspection_order_id = item?.inspection_order_id;
                finalOrderObj.inspection_order = item?.inspection_order;
                finalOrderObj.report_order = item?.report_order;

                const inspection_order = item?.inspection_order;
                const report_order = item?.report_order;
                const report_fulfillment_action = item?.report_fulfillment_action;
                finalOrderObj.fulfillment_action_id = report_fulfillment_action ? report_fulfillment_action.id : null;

                finalOrderObj.type = item?.type;

                finalOrderObj.id = item?.report_order_id;
                finalOrderObj.report_pdf_url = report_order?.report_pdf_url ?? null;
                finalOrderObj.report_status = report_order?.order_status ?? "-";
                finalOrderObj.order_status = report_order?.order_status_id ?? 0;
                finalOrderObj.report_type_name = report_order?.report_type?.nickname ?? "-";
                finalOrderObj.report_type_slug = report_order?.report_type?.slug;
                finalOrderObj.slug = finalOrderObj.report_type_slug;
                finalOrderObj.report_type_id = report_order?.report_type?.id ?? null;
                finalOrderObj.renovation_budget_needed = report_order?.report_type?.renovation_budget_needed;
                finalOrderObj.neighborhood_metrics = report_order?.report_type?.neighborhood_metrics;
                finalOrderObj.property_access_contacts = report_order?.property_access_contacts
                finalOrderObj.rush_fee = report_order?.rush_fee ?? null;
                finalOrderObj.order_token = report_order?.order_token;
                finalOrderObj.isSnags = report_order?.isSnags;
                finalOrderObj.reviewer_user_id = report_order?.reviewer_user_id;
                finalOrderObj.analyst_user_id = report_order?.analyst_user_id;
                finalOrderObj.reviewer = report_order?.reviewer_user_id ? true : "-";
                finalOrderObj.is_new_order = report_order?.is_new_order;
                finalOrderObj.base_fee = report_order?.base_fee ?? null;
                finalOrderObj.report_intake_batch_id = report_order?.order_intake?.report_intake_batch_id ?? null;
                finalOrderObj.intake_token = report_order?.order_intake?.token ?? null;

                finalOrderObj.propertyUploadType = report_order?.order_intake?.report_intake_batch?.property_upload_type;

                finalOrderObj.report_hold = report_order?.report_hold;
                finalOrderObj.missing_docs_arr = report_order?.missing_docs ? report_order?.missing_docs?.split(',') : [];

                finalOrderObj.inspection_status_name = inspection_order?.inspection_status_name ?? "-";
                finalOrderObj.inspection_status_id = inspection_order?.inspection_status ?? null;
                finalOrderObj.inspection_type_id = inspection_order?.inspection_type?.id ?? null;
                finalOrderObj.inspection_type_name = inspection_order?.inspection_type?.name ?? "-";
                finalOrderObj.inspection_type_slug = inspection_order?.inspection_type?.slug ?? null;
                finalOrderObj.trip_charge = inspection_order?.inspection_type?.trip_charge ?? null;
                finalOrderObj.inspection_order_token = inspection_order?.inspection_order_token ?? null;
                finalOrderObj.standalone = inspection_order?.standalone ?? '0';
                finalOrderObj.isStandalone = finalOrderObj.standalone;

                finalOrderObj.property_type_normalized = item?.property_type_normalized ?? null;

                finalOrderObj.property_id = item?.property_id;
                finalOrderObj.company_id = item?.companyId;
                finalOrderObj.client = item?.company_name ?? "-";
                finalOrderObj.address = item?.address ?? "-";
                finalOrderObj.due_date = item?.due_date ?? "-";
                finalOrderObj.postal_code = item?.postal_code ?? null;
                finalOrderObj.state = item?.state ?? null;

                finalOrderObj.last_update = item?.latestMessageData ?? null;
                finalOrderObj.notifications = item?.notifications;
                finalOrderObj.budget_files = item?.budget_files ? item.budget_files.location : null;
                finalOrderObj.full_action_id = report_fulfillment_action?.id
                let urlDownload;

                if (report_fulfillment_action && report_fulfillment_action.other_data) {
                    urlDownload = report_fulfillment_action.other_data.inspection_files
                        ? report_fulfillment_action.other_data.inspection_files[0]
                        : null;
                    urlDownload = report_fulfillment_action.other_data.report_files
                        ? report_fulfillment_action.other_data.report_files
                        : urlDownload;
                }
                finalOrderObj.download_url = urlDownload;

                tableData.push(finalOrderObj);
            }

            setTableData(tableData);
            setTotalRecordSize(response?.data?.count);
            setProcess(false);

        } catch (errors) {
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message);
            await sendExceptionEmail(errors, { memberCode: 504, componentName: 'Messaging', functionName: 'retrieveMessaging', data: {} });
            setProcess(false);
        }
    };

    // TABLE EVENT'S
    // Handle  page change show record in table
    let onPageChangeEvent = (currentPageTemp, recordsPerPageTemp) => {
        setCurrentPage(currentPageTemp > 0 ? currentPageTemp : 1);
        setRecordsPerPage(recordsPerPageTemp);
    };

    // On sorting filter
    let onSortChangeEvent = (fieldName, order) => {
        setSortFieldName(fieldName);
        setSortOrder(order);
    };

    // Show current page number and total record
    let paginationTotalText = (start, to, total) => {
        return (
            <p>From {start} to {to}, totals is {total}</p>
        );
    };

    let columns = [
        { width: "15%", title: 'Address', dataField: 'address' },
        { width: "10%", title: 'Client', dataField: 'client', isKey: true },
        { width: "10%", title: 'Report Type', dataField: 'report_type_name' },
        { width: "10%", title: 'Report Status', dataField: 'report_status' },
        { width: "10%", title: 'Inspection Type', dataField: 'inspection_type_name' },
        { width: "10%", title: 'Inspection Status', dataField: 'inspection_status_name' },
        { width: "10%", title: 'Due Date', dataFormat: (cell, row) => formatDateComponent("due_date", row, "MMM D", "", "MMM D, YY") },
        { width: "5%", title: 'Chat', dataFormat: messageData },
        { width: "15%", title: 'Recent Message', dataFormat: recentMessage },
        { width: "5%", title: 'Action', dataFormat: actions },
    ];

    const tableParams = {
        currentPage,
        recordsPerPage,
        onPageChangeEvent,
        onSortChangeEvent,
        paginationTotalText,
        tableData,
        totalRecordSize,
        columns,
    };

    useEffect(() => {
        eventBus.on("on_report_order_status_updated", retrieveMessaging);
        eventBus.on("on_inspection_order_status_updated", retrieveMessaging);

        // returned function will be called on component unmount 
        return () => {
            eventBus.remove("on_report_order_status_updated", retrieveMessaging);
            eventBus.remove("on_inspection_order_status_updated", retrieveMessaging);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        retrieveMessaging();
        setProcess(!isProcess)
        //eslint-disable-next-line
    }, [currentPage, recordsPerPage, isTab])


    return (
        <>
            <Header />
            <Container className="mt--9 container-vh-100" fluid>
                <Row>
                    <Col>
                        <Card className="bg-secondary shadow">
                            <CardBody>

                                {isEligible && <CustomToggleButton
                                    options={[
                                        { key: 'toMe', label: 'To Me' },
                                        { key: 'toOthers', label: 'To Others' },
                                    ]}
                                    defaultSelectionKey={isTab}
                                    onToggleChange={(value) => setIsTab(value)}
                                />
                                }

                                {isProcess ?
                                    <TableSkeleton rows={10} cols={10} />
                                    :
                                    <RenderBootstrapTable tableParams={tableParams}></RenderBootstrapTable>
                                }

                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>

        </>
    )
}

export default Messaging