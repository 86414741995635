import * as moment from "moment";
import React, { useState, useEffect } from "react";
import { PostData } from "../../../services/Api.service";
import { toast } from 'react-toastify';
import { MESSAGE_TYPES } from "../Common/Constants/Conversation.constant";
import { Modal, Row, Col, Button } from "reactstrap";
import { loggedInUser, sendExceptionEmail } from "components/Common/Helpers.js"
import { AvForm } from "availity-reactstrap-validation";
import Spinner from "components/Common/Spinner";

const PropertyFileType = ({ propHandleModal, messageRow, callBack }) => {
    const userData = loggedInUser();
    let [update, setUpdate] = useState(moment());
    let [isOpenPropertyFileModal, setIsOpenPropertyFileModal] = useState(false);
    let [fileTypeData, setFileTypeData] = useState([]);
    let [fileType, setFileType] = useState();
    let [customError, setCustomError] = useState("");
    let [isProcess, setProcess] = useState(false);

    useEffect(() => {
        if (propHandleModal.current) {
            setIsOpenPropertyFileModal(true);
            getPropertyFileType();
        }
    }, [propHandleModal.current])// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

    }, [update]);

    let handleToggleModal = (state) => {
        state = !state;
        setIsOpenPropertyFileModal(state);
        propHandleModal.current = false;
        setUpdate(moment());
    }

    let getPropertyFileType = async () => {
        try {
            const result = await PostData("list/property-file-type");
            let responseData = result.data.fileType;
            setFileTypeData(responseData);
        } catch (error) {
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error, { memberCode: 502, componentName: 'PropertyFileTypeModal', functionName: 'getPropertyFileType', data: {} });
        }
    }

    let handleInput = (e) => {
        setFileType(e.target.value);
        setCustomError("");
    }

    let handleFileType = async () => {
        try {
            if (!fileType) {
                setCustomError("This field is required.");
                return false;
            }
            let data = {
                file_name: messageRow.file_name,
                file_url: messageRow.file_url,
                file_type: fileType,
                report_order_messages_id: messageRow.id,
                conversation_id: messageRow.conversation_id,
            }
            setProcess(true);
            const response = await PostData("property/attaches_property_file", data);
            toast['success'](response.message);
            handleToggleModal(isOpenPropertyFileModal);
            callBack((userData.is_RV_user) ? [MESSAGE_TYPES.CLIENT, MESSAGE_TYPES.INTERNAL, MESSAGE_TYPES.INSPECTOR] : [MESSAGE_TYPES.CLIENT]);
            setProcess(false);
        } catch (error) {
            setProcess(false);
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error, { memberCode: 502, componentName: 'PropertyFileTypeModal', functionName: 'handleFileType', data: {} });
        }
    }

    return (
        <>
            <Modal className="modal-dialog-centered modal-sm" isOpen={isOpenPropertyFileModal} toggle={() => handleToggleModal(isOpenPropertyFileModal)}>
                <div className="modal-header modal-header-colored">
                    <h3 className="text-center modal-title w-100">File Type</h3>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenPropertyFileModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>

                <div className="modal-body">
                    <Spinner isShow={isProcess} />
                    <AvForm onValidSubmit={(e) => handleFileType(e)}>
                        <Row>
                            <Col>
                                <div className="custom-radio-btn">
                                    {fileTypeData.map((type, index) => {
                                        return (
                                            <>
                                                <div key={index}>
                                                    <input
                                                        required={true}
                                                        type="radio"
                                                        id={type.file_type_name}
                                                        name="property_file_type"
                                                        value={type.id}
                                                        onChange={(e) => handleInput(e)}
                                                    />
                                                    <label htmlFor={type.file_type_name}>{type.file_type_name}</label>
                                                </div>
                                            </>
                                        )
                                    })}
                                    {customError && (
                                        <div className="text-danger">
                                            {customError}
                                        </div>
                                    )}
                                </div>
                                <Button className="float-right" size="sm" color="info">Save</Button>
                            </Col>
                        </Row>
                    </AvForm>
                </div>
            </Modal>
        </>
    )
}

export default PropertyFileType;