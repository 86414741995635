import React, { useState, useEffect } from "react";
import { Row, Button, UncontrolledTooltip, Modal, FormGroup } from "reactstrap";
import * as moment from "moment";
import { PostData, PostDataFullUrl } from "../../../../../services/PostData";
import { checkRoles, loggedInUser, checkRolesWithLevel } from "components/Common/Helpers.js"
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import eventBus from "../../EventBus";
import { sendExceptionEmail } from "components/Common/Helpers";
import { useDispatch } from "react-redux";
import { showSpinner } from "ShowHideSpinner.reducer";

const phpUrl = process.env.REACT_APP_PHP_ENDPOINT;

const HandlePdfReport = ({ onHandlePDF, propButtons, propReportRow }) => {

    const dispatch = useDispatch();
    const userData = loggedInUser();
    const [update, setUpdate] = useState(moment());
    const [downloadUrl, setDownloadUrl] = useState("");
    const [isOpenDownLoadPDFModal, setIsOpenDownLoadPDFModal] = useState(false);

    const [selectedRow, setSelectedRow] = useState([]);
    const [reportButtons, setReportButtons] = useState([]);

    useEffect(() => {
        setReportButtons(propButtons ? propButtons.split(',') : []);
        setSelectedRow(propReportRow);
    }, [propButtons, propReportRow]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => { }, [update]);

    const handleModal = (state) => {
        if (state === "isOpenDownLoadPDFModal") {
            state = !state;
            setIsOpenDownLoadPDFModal(state);
        }
    };

    const getReportOrderDataForPDF = async () => {
        try {
            dispatch(showSpinner(true));
            let result = await PostData("list/settings", { keys: ['json_payload_token'] })
            let responseJson = result.data;

            let orderDetailsResp = await PostData("orders/details", { report_order_id: selectedRow.id, full_action_id: selectedRow.full_action_id ? selectedRow.full_action_id : null });
            let orderDetailsRespJson = orderDetailsResp.data;
            orderDetailsRespJson.data[0].report_token = responseJson.data[0].value;
            dispatch(showSpinner(false));
            return orderDetailsRespJson.data[0];

        } catch (errors) {
            dispatch(showSpinner(false));
            await sendExceptionEmail(errors, { memberCode: 500, componentName: 'HandlePdfReport', functionName: 'getReportOrderDataForPDF', data: {} });
            toast["error"]("Something went wrong.");
        }
    };

    // Handle report order status
    const handleOrderStatus = async (e, status, row) => {
        if ((checkRoles(['ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP']) === true || checkRolesWithLevel(['REVIEWER'], ">=", 3)) && status === "preview_pdf") {
            row.isDisabled = false;
        }
        if (row.disabled && row.isDisabled) {
            return false;
        }
        if (status === 'preview_pdf') {
            await handlePdfReportProcess(status, row);
        } else if (status === 'generate_pdf_and_send') {
            confirmAlert({
                title: '',
                message: 'Are you sure you want to send PDF to client and mark report as completed?',
                closeOnClickOutside: false,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            await handlePdfReportProcess(status, row);
                        },
                        className: "btn btn-primary btn-sm"
                    }, {
                        label: 'Close',
                        onClick: () => { },
                        className: "btn btn-secondary btn-sm"
                    }
                ]
            });

        } else if (status === 'resend_pdf_url') {
            confirmAlert({
                title: '',
                message: 'Are you sure you want to resend PDF URL to client?',
                closeOnClickOutside: false,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            await handlePdfReportProcess(status, row);
                        },
                        className: "btn btn-primary btn-sm"
                    }, {
                        label: 'Close',
                        onClick: () => { },
                        className: "btn btn-secondary btn-sm"
                    }
                ]
            });

        } else if (status === 'regenerate_pdf_and_send') {
            confirmAlert({
                title: '',
                message: 'Are you sure you want to regenerate PDF and send to client ?',
                closeOnClickOutside: false,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: async () => {
                            await handlePdfReportProcess(status, row);
                        },
                        className: "btn btn-primary btn-sm"
                    }, {
                        label: 'Close',
                        onClick: () => { },
                        className: "btn btn-secondary btn-sm"
                    }
                ]
            });
        } else if (status === 'download_report') {
            await handlePdfReportProcess(status, row);
        }

        e.preventDefault();
    };

    const handlePdfReportProcess = async (status, row) => {
        dispatch(showSpinner(true));
        try {
            let selectedOrderDetails = await getReportOrderDataForPDF();
            dispatch(showSpinner(false));
            selectedOrderDetails.custom_analysis_ids = selectedOrderDetails.analysis_trackings && selectedOrderDetails.analysis_trackings[0] ? JSON.parse(selectedOrderDetails.analysis_trackings[0].custom_analysis_ids) : [];
            selectedOrderDetails.payload_json = selectedOrderDetails.analysis_trackings && selectedOrderDetails.analysis_trackings[0] && selectedOrderDetails.analysis_trackings[0].payload_json ? JSON.parse(selectedOrderDetails.analysis_trackings[0].payload_json) : {};
            selectedOrderDetails.merged_comp_array = selectedOrderDetails.analysis_trackings && selectedOrderDetails.analysis_trackings[0] ? JSON.parse(selectedOrderDetails.analysis_trackings[0].merged_comp_array) : [];
            selectedOrderDetails.analysis_tracking_id = selectedOrderDetails.analysis_trackings && selectedOrderDetails.analysis_trackings[0] ? selectedOrderDetails.analysis_trackings[0].id : '';
            selectedOrderDetails.action_type = status;

            let pdfUrl = selectedOrderDetails?.report_fulfillment_actions && selectedOrderDetails?.report_fulfillment_actions[0]?.report_pdf_url ? selectedOrderDetails?.report_fulfillment_actions[0]?.report_pdf_url : selectedOrderDetails.report_pdf_url;

            if (status === 'preview_pdf') {
                dispatch(showSpinner(true));

                if (selectedRow.full_action_id) {
                    selectedOrderDetails.payload_json = selectedOrderDetails.report_fulfillment_actions[0].payload_json ? JSON.parse(selectedOrderDetails.report_fulfillment_actions[0].payload_json) : {};
                    if (selectedOrderDetails.report_fulfillment_actions[0].report_type) {
                        selectedOrderDetails.report_type = selectedOrderDetails.report_fulfillment_actions[0].report_type;
                    }
                }

                selectedOrderDetails.payload_json.rvServer = "0";
                generatePDF(selectedOrderDetails, status, function (err, generatePDFResponse) {
                    dispatch(showSpinner(false));
                    if (err) {
                        toast["error"](err);
                    } else {
                        let downloadUrl = generatePDFResponse.file;
                        let preview_url = downloadUrl.replace('download/', 'files/');
                        window.open(preview_url, "_blank");
                    }
                });
            } else if (status === 'generate_pdf_and_send') {

                dispatch(showSpinner(true));
                generatePDF(selectedOrderDetails, status, function (err, generatePDFResponse) {
                    if (err) {
                        dispatch(showSpinner(false));
                        toast["error"](err);
                    } else {
                        let downloadUrl = generatePDFResponse.file;
                        sendPdfUrlToClient(selectedOrderDetails, { pdf_downloaded_url: downloadUrl }, function (sendPDFResponse) { dispatch(showSpinner(false)); });
                    }
                    onHandlePDF({ message: "success" });
                });

            } else if (status === 'resend_pdf_url') {

                dispatch(showSpinner(true));

                if (pdfUrl) {
                    dispatch(showSpinner(true));
                    sendPdfUrlToClient(selectedOrderDetails, { pdf_downloaded_url: pdfUrl }, function (sendPDFResponse) { dispatch(showSpinner(false)); });
                } else {
                    dispatch(showSpinner(false));
                    toast["error"]("PDF url not exists!");
                }

            } else if (status === 'regenerate_pdf_and_send') {

                dispatch(showSpinner(true));
                generatePayload(selectedOrderDetails, status, function (err, generatePayloadResp) {
                    if (err) {
                        dispatch(showSpinner(false));
                        toast["error"]("Something went wrong.");
                    } else {
                        selectedOrderDetails.payload_json = generatePayloadResp;
                        generatePDF(selectedOrderDetails, status, function (err, generatePDFResponse) {
                            if (err) {
                                dispatch(showSpinner(false));
                                toast["error"](err);
                            } else {
                                let downloadUrl = generatePDFResponse.file;
                                selectedOrderDetails.report_pdf_url = downloadUrl;
                                sendPdfUrlToClient(selectedOrderDetails, { pdf_downloaded_url: downloadUrl }, function (sendPDFResponse) { dispatch(showSpinner(false)); });
                            }
                            onHandlePDF({ message: "success" });
                        });
                    }
                })

                return false;
            } else if (status === 'download_report') {

                if (pdfUrl) {
                    let downloadUrl = pdfUrl;
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    link.setAttribute('download', pdfUrl);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    dispatch(showSpinner(false));
                    toast["error"]("PDF url not exists!");
                }
            };

            setUpdate(moment());
        } catch (errors) {
            await sendExceptionEmail(errors, { memberCode: 500, componentName: 'HandlePdfReport', functionName: 'handlePdfReportProcess', data: {} });
        }
    }

    const generatePayload = async (row, status, cb) => {
        try {
            let report_type_val = row.report_type.report_type_val;
            let report_data_val = "repor-custuser";

            if (row.analysis_trackings[0].custom_analysis_ids && row.custom_analysis_ids.includes('main_curv')) {
                report_data_val = "repor-prim";
            }

            let data = {
                report_token: row.report_token,
                company_id: userData.company_id,
                prop_id: row.property_id,
                report_type_val: report_type_val,
                report_data_val: report_data_val,
                include_sca: row.analysis_trackings && row.analysis_trackings[0] && row.analysis_trackings[0].include_sca ? row.analysis_trackings[0].include_sca : 0,
                include_photo: row.analysis_trackings && row.analysis_trackings[0] && row.analysis_trackings[0].include_photo ? row.analysis_trackings[0].include_photo : 0,
                include_report_order_subject_photos: row.analysis_trackings && row.analysis_trackings[0] && row.analysis_trackings[0].include_subject_photos ? row.analysis_trackings[0].include_subject_photos : 0,
                include_zoning: row.require_zoning,
                user_values_graphs: '1',
                p_company_id: row.company.id,
                loadCoverPage: '1',
                rv_server: "1",
                as_is_value: row.analysis_trackings && row.analysis_trackings[0] && row.analysis_trackings[0].ending_value_as_is ? row.analysis_trackings[0].ending_value_as_is : 0,
                min_arv: row.analysis_trackings && row.analysis_trackings[0] && row.analysis_trackings[0].ending_value_min ? row.analysis_trackings[0].ending_value_min : 0,
                partial_arv: row.analysis_trackings && row.analysis_trackings[0] && row.analysis_trackings[0].ending_value_partial ? row.analysis_trackings[0].ending_value_partial : 0,
                full_arv: row.analysis_trackings && row.analysis_trackings[0] && row.analysis_trackings[0].ending_value_full ? row.analysis_trackings[0].ending_value_full : 0,
                value_add_arv: row.analysis_trackings && row.analysis_trackings[0] && row.analysis_trackings[0].ending_value_value_add ? row.analysis_trackings[0].ending_value_value_add : 0,
                user_id: row.analysis_trackings && row.analysis_trackings[0] && row.analysis_trackings[0].user_id ? row.analysis_trackings[0].user_id : 0
            };

            var formData = new FormData();

            for (var key in data) {
                formData.append(key, data[key]);
            }

            row.report_order_property_files.forEach(element => {
                formData.append('property_files[]', element.property_file.location);
            });

            row.merged_comp_array.forEach(element => {
                formData.append('comp_id_arr[]', element);
            });


            let result = await PostDataFullUrl(phpUrl + "valuation/report_generator/json_for_reports", formData)
            let responseJson = result.data;
            cb(null, responseJson);
        } catch (errors) {
            await sendExceptionEmail(errors, { memberCode: 500, componentName: 'HandlePdfReport', functionName: 'generatePayload', data: {} });
            cb(errors, null);
        };
    };

    const generatePDF = async (row, status, cb) => {
        try {

            let report_type_val = row.report_type.report_type_val;
            let report_data_val = "repor-custuser";

            if (row.analysis_trackings[0].custom_analysis_ids && row.custom_analysis_ids.includes('main_curv')) {
                report_data_val = "repor-prim";
            }


            let json_type = '';
            if (report_type_val === 'repor-raev' && report_data_val === 'repor-prim') {
                json_type = 'renovation_analysis';
            } else if (report_type_val === 'repor-stan' && report_data_val === 'repor-prim') {
                json_type = 'standard_arv';
            } else if (report_type_val === 'repor-rst' && report_data_val === 'repor-prim') {
                json_type = 'reno_strat';
            } else if (report_type_val === 'repor-sca' && report_data_val === 'repor-prim') {
                json_type = 'sca_report';
            } else if (report_type_val === 'repor-stan' && report_data_val === 'repor-custuser') {
                json_type = 'user_json_standard_arv';
            } else if (report_type_val === 'repor-raev' && report_data_val === 'repor-custuser') {
                json_type = 'user_json_repor2';
            } else if (report_type_val === 'repor-rst' && report_data_val === 'repor-custuser') {
                json_type = 'user_json_reno_strat';
            } else if (report_type_val === 'repor-sca' && report_data_val === 'repor-custuser') {
                json_type = 'sca_report';
            } else if (report_type_val === 'repor-eval' && (report_data_val === 'repor-prim' || report_data_val === 'repor-custuser')) {
                json_type = 'eval';
            } else if (report_type_val === 'repor-land' && (report_data_val === 'repor-prim' || report_data_val === 'repor-custuser')) {
                json_type = 'land';
            } else if (report_type_val === 'repor-construction' && (report_data_val === 'repor-prim' || report_data_val === 'repor-custuser')) {
                json_type = 'new_construction';
            } else {
                toast["error"]("Invalid Report Type!");
                dispatch(showSpinner(false));
                return false;
            }

            dispatch(showSpinner(true));

            var reportFormData = new FormData();

            if (status === 'generate_pdf_and_send' || status === 'regenerate_pdf_and_send') {
                row.payload_json.rvServer = '1';
                row.payload_json.preparedFor = {
                    "companyName": row.company.name,
                    "companyAddress": row.company.address,
                    "companyCity": row.company.city,
                    "companyState": row.company.state,
                    "companyZip": row.company.postal_code
                };

                if (row.company.logo) {
                    row.payload_json.preparedFor.companyLogo = `${phpUrl}uploads/logos/${row.company.logo}`;
                }
            };
            reportFormData.append('payload', JSON.stringify(row.payload_json));
            reportFormData.append('report_type', json_type);

            const result = await PostDataFullUrl(phpUrl + "valuation/report_generator/getPDFresponce", reportFormData)
            let responseJson = result.data;
            cb(null, responseJson);
        } catch (error) {
            await sendExceptionEmail(error, { memberCode: 200, componentName: 'HandlePdfReport', functionName: 'generatePDF', data: {} });
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            cb(error?.errors?.length ? error.errors[0].message : error.message, null);
        }
    };

    const sendPdfUrlToClient = async (row, data, cb) => {
        try {
            let params = { pdf_url: data.pdf_downloaded_url, report_order_id: row.id, user_id: userData.user_id };
            if (row.action_type === 'regenerate_pdf_and_send') {
                row.payload_json.rvServer = "0";
                params.payload_json = JSON.stringify(row.payload_json);
                params.action_type = row.action_type;
                params.analysis_tracking_id = row.analysis_tracking_id;
            }

            const result = await PostData("orders/send_finalized_email_with_pdf_url", params)
            if (result.data.status !== 200) {
                toast["error"](result.data.message);
                dispatch(showSpinner(false));
            } else {
                if (row.action_type === 'regenerate_pdf_and_send' || row.action_type === 'generate_pdf_and_send') {
                    var performanceRatingData = new FormData();
                    performanceRatingData.append('report_order_id', row.id);
                    performanceRatingData.append('user_id_completed', userData.user_id);

                    const performanceRatingResult = await PostDataFullUrl(phpUrl + "valuation/apis/api_calculate_performance_ratings", performanceRatingData)

                    if (performanceRatingResult.data.status !== 200) {
                        toast["error"](performanceRatingResult.data.message);
                    } else {
                        toast["success"]("An email with report PDF URL has been sent to the client.");
                        setDownloadUrl(data.pdf_downloaded_url);
                        setIsOpenDownLoadPDFModal(true);
                    }
                    dispatch(showSpinner(false));
                    setUpdate(moment());
                    eventBus.dispatch("report_order_status_updated", { client_id: row.company_id });
                } else {
                    toast["success"]("An email with report PDF URL has been sent to the client.");
                    setDownloadUrl(data.pdf_downloaded_url);
                    setIsOpenDownLoadPDFModal(true);

                    dispatch(showSpinner(false));
                    setUpdate(moment());
                    eventBus.dispatch("report_order_status_updated", { client_id: row.company_id });
                }
            }
        } catch (error) {
            dispatch(showSpinner(false));
            await sendExceptionEmail(error, { memberCode: 200, componentName: 'HandlePdfReport', functionName: 'sendPdfUrlToClient', data: {} });
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            cb(error?.errors?.length ? error.errors[0].message : error.message, null);
        }
    };

    let row_id = selectedRow.full_action_id ? selectedRow.full_action_id : selectedRow.id;

    return (
        <>

            {((checkRoles(['ADMINISTRATOR', 'PROCESSOR', 'SALES_REP', 'ACCOUNT_REP']) === true || checkRolesWithLevel(['REVIEWER'], ">=", 3))) && reportButtons.includes('preview_pdf') === true &&
                <>
                    {(checkRoles(['ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP']) === true || checkRolesWithLevel(['REVIEWER'], ">=", 3)) ?
                        <>
                            <Button color="primary" disabled={false} size="sm" type="button" outline id={"preview_pdf" + row_id} onClick={e => handleOrderStatus(e, 'preview_pdf', selectedRow)}>
                                <i className="fas fa-file-pdf" aria-hidden="true"></i>
                            </Button>
                            <UncontrolledTooltip delay={0} placement="auto" target={"preview_pdf" + row_id} >{null} Preview PDF </UncontrolledTooltip>
                        </> :
                        <>
                            <Button color="primary" disabled={selectedRow.disabled ? true : false} size="sm" type="button" outline id={"preview_pdf" + row_id} onClick={e => handleOrderStatus(e, 'preview_pdf', selectedRow)}>
                                <i className="fas fa-file-pdf" aria-hidden="true"></i>
                            </Button>
                            <UncontrolledTooltip delay={0} placement="auto" target={"preview_pdf" + row_id} >{selectedRow.tooltip_prefix} Preview PDF </UncontrolledTooltip>
                        </>
                    }
                </>
            }
            {reportButtons.includes('generate_pdf_and_send') === true &&
                <>
                    <Button color="warning" size="sm" type="button" outline id={"generate_pdf_and_send_" + row_id} onClick={e => handleOrderStatus(e, 'generate_pdf_and_send', selectedRow)}>
                        <i className="fa fa-paper-plane" aria-hidden="true"></i>
                    </Button>
                    <UncontrolledTooltip delay={0} placement="auto" target={"generate_pdf_and_send_" + row_id} > Send PDF to Client </UncontrolledTooltip>
                </>
            }
            {((checkRoles(['ADMINISTRATOR', 'PROCESSOR', 'SALES_REP', 'ACCOUNT_REP']) === true || checkRolesWithLevel(['REVIEWER'], ">=", 3))) && reportButtons.includes('resend_pdf_url') === true &&
                <>
                    <Button color="warning" disabled={selectedRow.disabled ? true : false} size="sm" type="button" outline id={"resend_pdf_url" + row_id} onClick={e => handleOrderStatus(e, 'resend_pdf_url', selectedRow)}>
                        <i className="fa fa-paper-plane" aria-hidden="true"></i>
                    </Button>
                    <UncontrolledTooltip delay={0} placement="auto" target={"resend_pdf_url" + row_id} >{selectedRow.tooltip_prefix} Resend PDF to Client</UncontrolledTooltip>
                </>
            }
            {reportButtons.includes('regenerate_pdf_and_send') === true &&
                <>
                    <Button color="danger" size="sm" type="button" outline id={"regenerate_pdf_and_send" + row_id} onClick={e => handleOrderStatus(e, 'regenerate_pdf_and_send', selectedRow)}>
                        <i className="fas fa-sync-alt" aria-hidden="true"></i>
                    </Button>

                    <UncontrolledTooltip delay={0} placement="auto" target={"regenerate_pdf_and_send" + row_id} > Regenerate PDF and Send to Client </UncontrolledTooltip>
                </>
            }
            {(checkRoles(['ADMINISTRATOR', 'SALES_REP', 'ACCOUNT_REP', 'TRAINING_ADMINISTRATOR'])) && reportButtons.includes('download_report') === true &&
                <>
                    <Button color="warning" disabled={selectedRow.disabled ? true : false} size="sm" type="button" outline id={"download_report" + row_id} onClick={e => handleOrderStatus(e, 'download_report', selectedRow)}>
                        <i className="fa fa-download" aria-hidden="true"></i>
                    </Button>
                    <UncontrolledTooltip delay={0} placement="auto" target={"download_report" + row_id} >{selectedRow.tooltip_prefix} Download Report</UncontrolledTooltip>
                </>
            }


            <Modal className="modal-dialog-centered" style={{ maxWidth: "600px" }} isOpen={isOpenDownLoadPDFModal} toggle={() => handleModal("isOpenDownLoadPDFModal")}>
                <div className="modal-header">
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleModal("isOpenDownLoadPDFModal")}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <Row>
                        <h2 className="text-center col-lg-12 pt-2"> Email has been sent.</h2>
                        <div className="col-lg-12 pt-2">
                            <FormGroup>
                                {checkRoles(['ADMINISTRATOR', 'TRAINING_ADMINISTRATOR']) ?
                                    <>
                                        <a href={downloadUrl} id="download_file" className="btn btn-md btn-outline-warning" target="_blank" rel="noreferrer"><i className="fa fa-download"></i>  Download </a>
                                        <UncontrolledTooltip delay={0} placement="right" target="download_file" > Click here to download file. </UncontrolledTooltip>
                                    </>
                                    :
                                    <button aria-label="Close" className="btn btn-md btn-outline-success" data-dismiss="modal" type="button" onClick={() => handleModal("isOpenDownLoadPDFModal")}> OK </button>
                                }
                            </FormGroup>
                        </div>
                    </Row>
                </div>
            </Modal>
        </>
    );
};

export default HandlePdfReport;
