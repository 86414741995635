import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import * as moment from "moment";
import {
    GetData as OrderIntakeGet,
    PostData as OrderIntakePost
} from "services/OrderIntakeData";
import { sendExceptionEmail } from "components/Common/Helpers";
import PaymentForm from "../PaymentForm";
import RenderActionButton from "./RenderActionButton";
import { useDispatch } from "react-redux";
import { showSpinner } from './../../../../ShowHideSpinner.reducer'

const ReportOrderPaymentButton = ({ propRowData, propButtonOptions, paymentCb = null }) => {

    const [update, setUpdate] = useState(moment());
    const isOpenPaymentFormModal = React.useRef(false);
    const [pricingDetails, setPricingDetails] = useState({})
    const dispatch = useDispatch();

    useEffect(() => { }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleActionClick = async (e) => {
        e.preventDefault();

        try {
            dispatch(showSpinner(true))
            if (propRowData?.sourcePage === 'borrowerPublicPaymentPage') {
                const isValidBorrower = await validateBorrower();
                if (!isValidBorrower) {
                    dispatch(showSpinner(false))
                    return false
                };
            }

            let response = await OrderIntakeGet(`public/${propRowData.intake_token}/pricing-details`, {});
            let pricingDataTemp = response.data.pricing_data;

            setPricingDetails(pricingDataTemp)
            dispatch(showSpinner(false))
            isOpenPaymentFormModal.current = true;
            setUpdate(moment())

        } catch (error) {
            toast['error'](error?.errors?.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error, { memberCode: 504, componentName: 'ReportOrderPaymentButton', functionName: 'handleActionClick', data: {} });
            setUpdate(moment());
        };
    };

    const validateBorrower = async (e) => {
        try {
            if (!propRowData?.policyAgreementUserData?.name || !propRowData?.policyAgreementUserData?.email || !propRowData?.policyAgreementUserData?.phone_number) {
                toast['error']("Please enter all fields value!")
                return false;
            }

            let response = await OrderIntakeGet(`public/${propRowData.intake_token}/borrower-sent-email-row`, {})

            if (response.data.emailData) {
                let emailData = response.data.emailData;

                let toEmails = emailData.to_emails ? JSON.parse(emailData.to_emails) : [];
                let ccEmails = emailData.cc_emails ? JSON.parse(emailData.cc_emails) : [];
                let order_intake_id = emailData.report_intake_id ? JSON.parse(emailData.report_intake_id) : [];

                let email_matched = false;
                let valid_form_data = false;

                propRowData.policyAgreementUserData.toEmails = JSON.stringify(toEmails)
                propRowData.policyAgreementUserData.ccEmails = JSON.stringify(ccEmails)
                propRowData.policyAgreementUserData.order_intake_id = order_intake_id
                if (toEmails.length > 0 || ccEmails.length > 0) {
                    if (toEmails.length > 0) {
                        for (var i = 0; i < toEmails.length; i++) {
                            if (propRowData?.policyAgreementUserData?.email?.toLowerCase() === toEmails[i].toLowerCase()) {
                                email_matched = true;
                                break;
                            }
                        }
                    }

                    if (!email_matched) {
                        if (ccEmails.length > 0 && !email_matched) {
                            for (var j = 0; j < ccEmails.length; j++) {
                                if (propRowData?.policyAgreementUserData?.email?.toLowerCase() === ccEmails[j].toLowerCase()) {
                                    email_matched = true;
                                    break;
                                }
                            }
                        }
                    }
                } else {
                    toast["error"]('To and CC emails both can not be empty.');
                    return false;
                }

                if (email_matched) {
                    valid_form_data = true;
                } else {
                    valid_form_data = false;
                    toast["error"]('Please use the same email address on which you get the payment link.');
                    return false;
                }

                return valid_form_data;
            } else {
                toast["error"]("System doesn't found any link sent to borrower for this order.");
                return false;
            }
        } catch (errors) {
            confirmAlert({
                title: 'Error',
                message: (errors.errors.length && errors.errors[0].message) ? errors.errors[0].message : errors.message,
                closeOnClickOutside: false,
                buttons: [
                    {
                        label: 'Ok',
                        onClick: () => { },
                        className: "btn btn-danger btn-sm"
                    }
                ]
            });
            dispatch(showSpinner(false))
            await sendExceptionEmail(errors, { memberCode: 200, componentName: 'ReportOrderPaymentButton', functionName: 'validateBorrower', data: {} });
            setUpdate(moment());
        };
    };

    const handlePaymentSourceCb = async (response) => {
        try {
            const paymentSource = response.payment_source;

            let requestData = {};
            requestData.source_page = propRowData?.sourcePage;
            if (paymentSource.payment_method === "USE_EXISTING_SOURCE") {
                requestData.payment_method = paymentSource.payment_method;
                requestData.payment_source_id = paymentSource.payment_source_id;

                if (propRowData?.policyAgreementUserData !== null) {
                    requestData.policy_agreement_user_data = propRowData?.policyAgreementUserData;
                }
            } else if (paymentSource.payment_method === "ADD_NEW_SOURCE") {
                requestData.payment_method = paymentSource.payment_method;
                requestData.payment_source_type = paymentSource.payment_source_type;
                requestData.payment_transaction_type = paymentSource.payment_transaction_type;
                requestData.source_token = paymentSource.source_token;

                if (propRowData?.policyAgreementUserData !== null) {
                    requestData.policy_agreement_user_data = propRowData?.policyAgreementUserData;
                }
            } else if (paymentSource.payment_method === "ADD_TO_INVOICE") {
                requestData.payment_method = paymentSource.payment_method;
            }

            if (Object.keys(requestData).length > 0) {
                dispatch(showSpinner(true));
                let response = await OrderIntakePost(`public/${propRowData.intake_token}/payment`, requestData, propRowData.company_id)
                toast["success"](response.message);
                dispatch(showSpinner(false));
                if (paymentCb) {
                    paymentCb({
                        success: true
                    })
                }
            }
        } catch (errors) {
            await sendExceptionEmail(errors, { memberCode: 200, componentName: 'ReportOrderPaymentButton', functionName: 'handlePaymentSourceCb', data: {} });
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message);
            dispatch(showSpinner(false));
        }
    };

    return (
        <>
            <RenderActionButton propButtonTitle="Pay" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />

            {isOpenPaymentFormModal.current && <PaymentForm onPay={isOpenPaymentFormModal} onPaymentSourceCb={handlePaymentSourceCb}
                paymentDetails={{
                    total_amount: pricingDetails?.total_amounts?.total_price - (pricingDetails?.discount || 0),
                    cc_surcharge: pricingDetails?.total_amounts?.cc_surcharge,
                    paymentFor: 'New Report Order'
                }}
                clientId={propRowData.company_id}
                sourcePage={propRowData.sourcePage}
            />}
        </>
    );
};

export default ReportOrderPaymentButton;