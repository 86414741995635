import React, { useState, useEffect } from "react";
import { Modal, Col, Row } from "reactstrap";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./imageSlider.scss";
import { setImageViewed } from "../../Training/Training.reducer";
import { useDispatch } from "react-redux";


const ImageSlider = ({ propHandleModal, imageArray, selectedItem }) => {

    let [isOpenAddBankAccountModal, setOpenAddBankAccountModal] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (propHandleModal.current) {
            setOpenAddBankAccountModal(propHandleModal.current);
        };
    }, [propHandleModal.current]);// eslint-disable-line react-hooks/exhaustive-deps

    let handleToggleModal = (state) => {
        state = !state;
        setOpenAddBankAccountModal(state);
        propHandleModal.current = false;
    };

    const handleImageChange = (index) => {
        dispatch(setImageViewed(index));
    };

    return (
        <>
            <Modal className="modal-dialog-centered modal-lg" isOpen={isOpenAddBankAccountModal} toggle={() => handleToggleModal(isOpenAddBankAccountModal)}>
                <div className="modal-header modal-header-colored">
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenAddBankAccountModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body others-column-body">
                    <div className="text-left">
                        <Row>
                            <Col xs="12" sm="12" md="12" lg="12">
                                <Carousel selectedItem={selectedItem} onChange={handleImageChange}>
                                    {imageArray.map(function (fileRow, i) {
                                        if (fileRow.hasOwnProperty("title") === false) {
                                            return (<div key={i} className="image-slider">
                                                <img src={fileRow} alt={"img" + i} loading="lazy" />
                                            </div>
                                            );
                                        } else {
                                            return (<div key={i} className="image-slider">
                                                <p >{fileRow.title}</p>
                                                <img src={fileRow.url} alt={"img" + i} loading="lazy" />
                                            </div>
                                            );
                                        }
                                    })}
                                </Carousel>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ImageSlider;