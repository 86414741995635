import React, { useEffect, useState, useRef } from "react";
import "./style.scss";
import { Row, Col, Label, Button, UncontrolledTooltip } from "reactstrap";
import { loggedInUser, sendExceptionEmail } from "components/Common/Helpers.js"
import { confirmAlert } from 'react-confirm-alert';
import { PostData, DeleteData } from "../../../services/Api.service";
import { MESSAGE_TYPES } from "../Common/Constants/Conversation.constant";
import Spinner from "components/Common/Spinner.js";
import { toast } from 'react-toastify';
import * as moment from "moment";
import PropertyFileType from "./PropertyFileTypeModal";

const MessageList = ({ messages, onEditDeleteMessage, onEditMsg }) => {
    const messagesEndRef = useRef(null)
    useEffect(() => {
        scrollToBottom()
    }, [messages]);

    const userData = loggedInUser();
    let [isProcess, setProcess] = useState(false);
    let isOpenFileTypeModal = React.useRef(false);
    let [messageRow, setMessageRow] = useState();
    let [update, setUpdate] = useState(moment());

    let scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    useEffect(() => {

    }, [update])

    let handlePdfDownload = (e, fileUrl) => {
        if (fileUrl) {
            const link = document.createElement('a');
            link.href = fileUrl;
            link.target = '_blank';
            link.setAttribute('download', fileUrl);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            toast["error"]("PDF url not exists!");
        }
    }

    let handleDeleteMessage = async (e, message_id, from_id) => {
        confirmAlert({
            message: 'Are you sure you want to delete this message?',
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => {
                        setProcess(true);
                        try {
                            let result = await DeleteData("conversation/delete-message/" + message_id, { fromId: from_id });
                            setProcess(false);
                            onEditDeleteMessage((userData.is_RV_user) ? [MESSAGE_TYPES.CLIENT, MESSAGE_TYPES.INTERNAL, MESSAGE_TYPES.INSPECTOR] : [MESSAGE_TYPES.CLIENT]);
                            toast["success"](result.message);
                        } catch (error) {
                            setProcess(false);
                            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message);
                            await sendExceptionEmail(error, { memberCode: 500, componentName: 'MessageList', functionName: 'handleDeleteMessage', data: {} });
                        }
                    },
                    className: "btn btn-primary btn-sm"
                }, {
                    label: 'No',
                    onClick: () => { },
                    className: "btn btn-outline-danger btn-sm"
                }
            ]
        });
    };

    let handleEditMessage = async (e, message_id) => {
        try {
            let result = await PostData("conversation/edit-message/" + message_id);
            onEditMsg(result.data.editMsgData);
        } catch (error) {
            setProcess(false);
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error, { memberCode: 500, componentName: 'MessageList', functionName: 'handleEditMessage', data: {} });
        }
    };

    let handleFileType = (e, message) => {
        isOpenFileTypeModal.current = true;
        if (message) {
            setMessageRow(message);
        }
        setUpdate(moment());
    }

    let handleFileDeAttached = (e, message) => {
        confirmAlert({
            message: 'Are you sure you want to detach this file from the property?',
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        fileDetach(e, message);
                    },
                    className: "btn btn-primary btn-sm"
                }, {
                    label: 'No',
                    onClick: () => { },
                    className: "btn btn-outline-danger btn-sm"
                }
            ]
        });
    }

    let fileDetach = async (e, message) => {
        try {
            let data = {
                message_id: message.id,
                property_file_id: message.property_file_id
            };
            setProcess(true);
            const response = await PostData("property/de_attaches_property_file", data);
            setProcess(false);
            onEditDeleteMessage((userData.is_RV_user) ? [MESSAGE_TYPES.CLIENT, MESSAGE_TYPES.INTERNAL, MESSAGE_TYPES.INSPECTOR] : [MESSAGE_TYPES.CLIENT]);
            toast["success"](response.message);
        } catch (error) {
            setProcess(false);
            toast['error'](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error, { memberCode: 500, componentName: 'MessageList', functionName: 'fileDetach', data: {} });
        }
    }

    return (
        <>
            <Spinner isShow={isProcess} />
            <div className="message-list">
                {messages.length > 0 ?
                    <>
                        <span>
                            <Row>
                                <Col xs="12">
                                    <div className="right-chat-message-detail">
                                        <Row>
                                            <Col xs="12">
                                                {messages[0].message_detail}
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </span>
                        {messages.map((message, index) => {
                            return (
                                <span key={message.id}>
                                    {message.from_id !== userData.user_id ?
                                        <Row className="mt-2">
                                            <Col xs="12">
                                                <Row>
                                                    <Col xs="12" className="text-left" >
                                                        <Label className="mb-1">
                                                            <b>{message.to} </b>
                                                            {message.messageType === MESSAGE_TYPES.INTERNAL && message.primary_email_users &&
                                                                <>
                                                                    <span>TO</span>
                                                                    <b className="text-primary"> {message.primary_email_users}</b>
                                                                </>
                                                            }
                                                        </Label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12">
                                                        <label className={(message.messageType === MESSAGE_TYPES.CLIENT) ? "left-chat-box-arrow_client" : ((message.messageType === MESSAGE_TYPES.INTERNAL) ? "left-chat-box-arrow_internal" : (message.messageType === MESSAGE_TYPES.INSPECTOR) ? "left-chat-box-arrow_inspector" : "left-chat-box-arrow_internal")}>
                                                            <Row>
                                                                <Col xs="12">
                                                                    {message.text &&
                                                                        <span style={{ whiteSpace: 'pre-wrap' }}>{message.text}</span>
                                                                    }
                                                                    {message.file_url !== '' &&
                                                                        <>
                                                                            <div className={(message.text) ? "mt-3" : ""}>
                                                                                <Button color="default" size="sm" type="button" onClick={e => handlePdfDownload(e, message.file_url)}>
                                                                                    <i className="fa fa-download" aria-hidden="true"></i>
                                                                                </Button>
                                                                                <span> {message.file_name}</span>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12" className="text-left">
                                                        <Label>{message.messageTime} </Label>
                                                        <span className="ml-3">
                                                            {message.file_url && userData.is_RV_user &&
                                                                <>
                                                                    {!message.property_file_id ?
                                                                        (
                                                                            <>
                                                                                <i className="fas fa-solid fa-paperclip cursor-pointer" id={`file_type${index}`} onClick={e => handleFileType(e, message)}></i>
                                                                                < UncontrolledTooltip delay={0} placement="auto" target={`file_type${index}`} >Attach File</UncontrolledTooltip>
                                                                            </>
                                                                        ) :
                                                                        (
                                                                            <>
                                                                                <i className="fas fa-solid fa-paperclip cursor-pointer" style={{ color: "green" }} id={`file_attached${index}`} onClick={e => handleFileDeAttached(e, message)}></i>
                                                                                < UncontrolledTooltip delay={0} placement="auto" target={`file_attached${index}`} >File already attached</UncontrolledTooltip>
                                                                            </>
                                                                        )
                                                                    }
                                                                </>
                                                            }
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        :
                                        <Row className="mt-2">
                                            <Col xs="12">
                                                <Row>
                                                    <Col xs="12" className="text-right" >
                                                        <Label className="mb-1"><b>Me</b></Label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12">
                                                        <label className={(message.messageType === MESSAGE_TYPES.CLIENT) ? "right-chat-box-arrow_client" : ((message.messageType === MESSAGE_TYPES.INTERNAL) ? "right-chat-box-arrow_internal" : (message.messageType === MESSAGE_TYPES.INSPECTOR) ? "right-chat-box-arrow_inspector" : "right-chat-box-arrow_internal")}>
                                                            <Row>
                                                                <Col xs="12">
                                                                    {message.text &&
                                                                        <span style={{ whiteSpace: 'pre-wrap' }}>{message.text}</span>
                                                                    }
                                                                    {message.file_url !== '' &&
                                                                        <>
                                                                            <div className={(message.text) ? "mt-3" : ""}>
                                                                                <Button className="mr-2" color="default" size="sm" type="button" onClick={e => handlePdfDownload(e, message.file_url)}>
                                                                                    <i className="fa fa-download" aria-hidden="true"></i>
                                                                                </Button>
                                                                                <span> {message.file_name}</span>
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                        </label>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="12" className="text-right">
                                                        <span className="mr-4">
                                                            {message.file_url && userData.is_RV_user &&
                                                                <>
                                                                    {!message.property_file_id ?
                                                                        (
                                                                            <>
                                                                                <i className="fas fa-solid fa-paperclip cursor-pointer" id={`file_type_${index}`} onClick={e => handleFileType(e, message)}></i>
                                                                                < UncontrolledTooltip delay={0} placement="auto" target={`file_type_${index}`} >Attach File</UncontrolledTooltip>
                                                                            </>
                                                                        ) :
                                                                        (
                                                                            <>
                                                                                <i className="fas fa-solid fa-paperclip cursor-pointer" style={{ color: "green" }} id={`file_attached_${index}`} onClick={e => handleFileDeAttached(e, message)}></i>
                                                                                < UncontrolledTooltip delay={0} placement="auto" target={`file_attached_${index}`} >File already attached</UncontrolledTooltip>
                                                                            </>
                                                                        )
                                                                    }
                                                                </>
                                                            }
                                                            {message.messageType !== MESSAGE_TYPES.INSPECTOR &&
                                                                <>
                                                                    <i className="fa fa-edit ml-2 mr-1 cursor-pointer" id="edit_message" onClick={e => handleEditMessage(e, message.id)}></i>
                                                                    < UncontrolledTooltip delay={0} placement="auto" target={"edit_message"} >Edit message</UncontrolledTooltip>
                                                                </>
                                                            }
                                                            <i className="fa fa-trash text-danger cursor-pointer" id="delete_message" onClick={e => handleDeleteMessage(e, message.id, message.from_id)}></i>
                                                            < UncontrolledTooltip delay={0} placement="auto" target={"delete_message"} >Delete message</UncontrolledTooltip>
                                                        </span>
                                                        <Label>
                                                            {message.messageTime}
                                                        </Label>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    }
                                </span>
                            )
                        })}
                        <div ref={messagesEndRef} />
                    </>
                    :
                    <label className="btn-outline-danger"><b>No messages yet</b></label>
                }
            </div >
            {
                isOpenFileTypeModal.current &&
                <PropertyFileType propHandleModal={isOpenFileTypeModal} messageRow={messageRow} callBack={onEditDeleteMessage} />
            }
        </>
    )
}
export default MessageList;