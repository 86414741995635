
import { sendExceptionEmail } from "components/Common/Helpers";
import Spinner from "components/Common/Spinner";
import React, { useState, useEffect } from "react";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { Button, UncontrolledTooltip } from "reactstrap";
import { PostData } from "services/Api.service";


const SendInspectionCompletedEmail = (props) => {

    let [isProcess, setProcess] = useState(false);
    let [rowData] = useState(props.rowData);
    // let [update, setUpdate] = useState(moment());

    useEffect(() => {

        let unmounted = false;

        if (!unmounted) { }

        return () => {
            unmounted = true;
        };
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => { }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

    let resendInspectionCompletedEmail = async (e) => {
        e.preventDefault();

        confirmAlert({
            title: '',
            message: 'Are you sure you want to resend inspection completed email ?',
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        handleInspectionCompleteEmailProcess();
                    },
                    className: "btn btn-primary btn-sm"
                }, {
                    label: 'Close',
                    onClick: () => { },
                    className: "btn btn-secondary btn-sm"
                }
            ]
        });

    };

    let handleInspectionCompleteEmailProcess = async () => {
        try {

            let data = {
                fulfillment_action_id: rowData.fulfillment_action_id
            };

            setProcess(true);
            const response = await PostData(`inspection/resend-inspection-completed-email`, data);
            toast['success'](response.message)
            setProcess(false);

        } catch (errors) {
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message);
            await sendExceptionEmail(errors, { memberCode: 200, componentName: 'SendInspectionCompletedEmail', functionName: 'handleInspectionCompleteEmailProcess', data: {} });
            setProcess(false);
        }
    }

    return (<>
        <Spinner isShow={isProcess} />
        {rowData.fulfillment_action_id &&
            <>
                <Button id={"resend_" + rowData.fulfillment_action_id} outline color="danger" size="sm" type="button" onClick={e => resendInspectionCompletedEmail(e)}>
                    <i className="fa fa-envelope" aria-hidden="true"></i>
                </Button>
                <UncontrolledTooltip delay={0} placement="auto" target={"resend_" + rowData.fulfillment_action_id} > Resend </UncontrolledTooltip>
            </>
        }
    </>);
};

export default SendInspectionCompletedEmail;