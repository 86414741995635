import React, { useEffect, useState } from "react";
import { Button, Modal, FormGroup, Row, Col, Label } from "reactstrap";

import { PostData as OMSPostData } from "../../../../../services/PostData";
import { GetData, PostData } from "../../../../../services/Api.service";
import { GetData as OrderIntakeGet } from "../../../../../services/OrderIntakeData";

import eventBus from "../../EventBus";
import Spinner from "components/Common/Spinner.js";
import { toast } from 'react-toastify';
import { AvForm, AvGroup, AvField, AvRadioGroup, AvRadio } from "availity-reactstrap-validation";
import { confirmAlert } from 'react-confirm-alert'; // Import
import * as moment from "moment";

import { sendExceptionEmail } from "components/Common/Helpers";
import { checkRoles } from "components/Common/Helpers";
import RenderActionButton from "../RenderActionButton";
import PaymentForm from "../../PaymentForm";
import ReportOrderOriginalSource from "../../ReportOrderOriginalSource";

const InspectionOrderCancelOrReplaceButton = ({ propRowData, propButtonOptions }) => {

    let [isProcess, setProcess] = useState(false);
    let [isOpenCancelReportOrderModal, setOpenCancelReportOrderModal] = useState(false);
    let [isOpenReplaceInspectionOrderModal, setOpenReplaceInspectionOrderModal] = useState(false);
    let [cancellationReason, setCancellationReason] = useState("");
    let [inspectionVendors, setInspectionVendors] = useState([]);
    let [inspectionType, setInspectionType] = useState(null);
    let [vendorId, setVendorId] = useState(null);
    let [eligibleInspectionTypes, setEligibleInspectionTypes] = useState([]);
    let [update, setUpdate] = useState(moment());
    let [isContactInfoRequired, setContactInfoRequired] = useState(false);
    let [doPaymentProcess, setDoPaymentProcess] = useState(null);

    let [fields, setFields] = useState({});

    let defaultValues = {
        vendor_id: null,
        inspection_type: null,
        contact_name: propRowData.property_access_contacts.length && propRowData.property_access_contacts[0].contact_name ? propRowData.property_access_contacts[0].contact_name : '',
        contact_email: propRowData.property_access_contacts.length && propRowData.property_access_contacts[0].contact_email ? propRowData.property_access_contacts[0].contact_email : '',
        contact_phone: propRowData.property_access_contacts.length && propRowData.property_access_contacts[0].contact_phone ? propRowData.property_access_contacts[0].contact_phone : '',
    };

    let [showStripeMsg, setShowStripeMsg] = useState(false);
    let [showRefundMsg, setShowRefundMsg] = useState(false);
    let [inspectionPriceData, setInspectionPriceData] = useState({});
    let [isOpenPaymentSourceModal, setIsOpenPaymentSourceModal] = useState({ current: false });
    let [selectedPaymentSource, setSelectedPaymentSource] = useState(null);
    let [paymentError, setPaymentError] = useState(null);
    let [defaultSourceChecked, setDefaultSourceChecked] = useState(false);
    let [glaAndLicensingSettings, setGlaAndLicensingSettings] = useState([]);

    useEffect(() => { }, [update]);

    useEffect(() => {
        let asyncFuncs = async () => {
            if (inspectionType) {
                await getReportOrderFees(inspectionType);
            }
        }
        asyncFuncs()
    }, [inspectionType, fields?.gla_include, fields?.licensing_required]); // eslint-disable-line react-hooks/exhaustive-deps

    let handleActionClick = (e) => {
        e.preventDefault();
        handleOrderStatus(null, propRowData, 'cancel')
    };

    let resetFields = (state) => {
        setCancellationReason("");
        setInspectionVendors([]);
        setInspectionType(null);
        setEligibleInspectionTypes([]);
        setDoPaymentProcess(null);
        setShowStripeMsg(false);
        setShowRefundMsg(false);
        setSelectedPaymentSource(null)
        setUpdate(moment());
    };

    /*
    | Handle report order status
    */
    let handleOrderStatus = (e, row, status) => {

        if (status === 'cancel') {

            const buttons = [
                {
                    label: 'Cancel Inspection Only',
                    onClick: () => { confirmCancelInspection(row); },
                    className: "btn btn-primary btn-sm"
                }, {
                    label: 'Replace Inspection',
                    onClick: async () => {
                        setOpenReplaceInspectionOrderModal(true);
                        setProcess(true)
                        let fieldsTemp = {
                            contact_name: propRowData.property_access_contacts.length && propRowData.property_access_contacts[0].contact_name ? propRowData.property_access_contacts[0].contact_name : '',
                            contact_email: propRowData.property_access_contacts.length && propRowData.property_access_contacts[0].contact_email ? propRowData.property_access_contacts[0].contact_email : '',
                            contact_phone: propRowData.property_access_contacts.length && propRowData.property_access_contacts[0].contact_phone ? propRowData.property_access_contacts[0].contact_phone : '',
                        }

                        fieldsTemp = { ...fieldsTemp, ...await retrieveGLAAndLicensingSettings() }
                        fieldsTemp = { ...fieldsTemp }

                        setFields(fieldsTemp);
                        await handlePreReplaceInspection();
                        setProcess(false)
                    },
                    className: "btn btn-warning btn-sm"
                }
            ];

            if (row.report_order_id) {
                buttons.push({
                    label: 'Cancel Entire Order',
                    onClick: async () => {
                        setOpenCancelReportOrderModal(true);
                    },
                    className: "btn btn-danger btn-sm"
                });
            }

            buttons.push({
                label: 'Close',
                onClick: () => { },
                className: "btn btn-secondary btn-sm"
            });

            confirmAlert({
                title: 'Select option',
                message: 'Please select what you would like to cancel...',
                closeOnClickOutside: false,
                buttons
            });
        }
    };

    let retrieveGLAAndLicensingSettings = async () => {
        try {
            let requestDataTemp = {};
            const glaAndLicensingSettingsResp = await OrderIntakeGet("company/gla-licensing-settings", { company_token: '{company_token}', }, propRowData.company_id);
            setGlaAndLicensingSettings(glaAndLicensingSettingsResp.data)

            if (!requestDataTemp['gla_include'] && (glaAndLicensingSettingsResp.data?.glaAndLicensingSettings?.gla_default === '1' || glaAndLicensingSettingsResp.data?.glaAndLicensingSettings?.gla_required === '1')) {
                requestDataTemp['gla_include'] = '1'
            }

            if (!requestDataTemp['licensing_required'] && (glaAndLicensingSettingsResp.data?.glaAndLicensingSettings?.licensed_default === '1' || glaAndLicensingSettingsResp.data?.glaAndLicensingSettings?.licensed_required === '1')) {
                requestDataTemp['licensing_required'] = '1'
            }

            return requestDataTemp;
        } catch (error) {
            toast["error"](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error, { memberCode: 200, componentName: 'InspectionOrderCancelOrReplaceButton', functionName: 'retrieveGLAAndLicensingSettings', data: {} });
        }
    };

    let onDataChange = async (e) => {
        try {
            let { name, value, checked } = e.target;

            if (['gla_include', 'licensing_required'].includes(name)) {
                value = checked ? '1' : '0';
            }

            let fieldsTemp = { ...fields };
            fieldsTemp[name] = value

            setFields(fieldsTemp);
            setUpdate(moment());
        } catch (error) {
            toast["error"](error.message);
            await sendExceptionEmail(error, { memberCode: 200, componentName: 'InspectionOrderCancelOrReplaceButton', functionName: 'onDataChange', data: {} });
        }
    };

    let confirmCancelInspection = (row) => {
        setTimeout(function () {
            confirmAlert({
                title: 'Are you sure ?',
                message: 'You are about to cancel the inspection order.',
                closeOnClickOutside: false,
                buttons: [
                    {
                        label: 'Yes',
                        onClick: () => InspectionOrderCancelOrReplaceButton(row.inspection_order_id, 'inspection_only'),
                        className: "btn btn-primary btn-sm"
                    }, {
                        label: 'No',
                        onClick: () => { },
                        className: "btn btn-secondary btn-sm"
                    }
                ]
            });
        }, 100);
    };

    let handlePreReplaceInspection = async () => {
        setProcess(true);
        try {
            const result = await PostData("list/inspection_vendors", {})
            setInspectionVendors(result.data.vendors);
        } catch (errors) {
            toast["error"]("Something went wrong!");
            await sendExceptionEmail(errors, { memberCode: 200, componentName: 'InspectionOrderCancelOrReplaceButton', functionName: 'handlePreReplaceInspection', data: {} });
            setProcess(false);
        };
    };

    let InspectionOrderCancelOrReplaceButton = async (inspection_order_id, cancellation_type) => {

        try {
            let params = {
                inspection_order_id: inspection_order_id,
                cancellation_type: cancellation_type,
                cancellation_reason: cancellationReason,
            };

            let fieldsTemp = { ...fields }

            fieldsTemp.gla_include = fieldsTemp.gla_applicable === '1' ? fieldsTemp.gla_include : '0'
            fieldsTemp.licensing_required = fieldsTemp.licensing_applicable === '1' ? fieldsTemp.licensing_required : '0'

            if (inspectionType) {
                params.inspection_type = inspectionType
                params.vendor_id = vendorId
                params.other_fields = fieldsTemp

                params.other_fields.contact_email = params.other_fields.contact_email ? params.other_fields.contact_email.trim() : null;
                params.other_fields.contact_phone = params.other_fields.contact_phone ? params.other_fields.contact_phone.trim() : null;
            }


            if (isContactInfoRequired && !params.other_fields.contact_email && !params.other_fields.contact_phone) {
                toast["error"]("Please enter any one from email OR phone.");
                return false;
            };

            if (!isContactInfoRequired && params.other_fields) {
                params.other_fields.contact_name = null;
                params.other_fields.contact_email = null;
                params.other_fields.contact_phone = null;
            };

            if (selectedPaymentSource && selectedPaymentSource.payment_source) {
                params.payment_source = selectedPaymentSource.payment_source;
            }

            params.do_payment_process = doPaymentProcess;

            setProcess(true);

            let result = await OMSPostData("inspection/cancel_inspection_order", params);

            let responseJson = result.data;
            setProcess(false);


            if (responseJson.status !== 200) {
                if (responseJson.err_code === 'PAYMENT_ERROR' && selectedPaymentSource?.isOriginalSource === true) {
                    setPaymentError(responseJson.message);
                    setSelectedPaymentSource(null);
                } else {
                    eventBus.dispatch("report_order_status_updated", { client_id: propRowData.company_id });
                    toast["error"](responseJson.message);
                }
                return false;

            } else {
                toast["success"](responseJson.message);
            }

            eventBus.dispatch("report_order_status_updated", { client_id: propRowData.company_id });
            resetFields();

            if (cancellation_type === 'entire_order') {
                setOpenCancelReportOrderModal(!isOpenCancelReportOrderModal);
            } else if (cancellation_type === 'replace_inspection_order') {
                setOpenReplaceInspectionOrderModal(!isOpenReplaceInspectionOrderModal);
            }

        } catch (error) {
            setProcess(false);
            toast["error"](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error, { memberCode: 200, componentName: 'InspectionOrderCancelOrReplaceButton', functionName: 'InspectionOrderCancelOrReplaceButton', data: {} });
        }
    };

    let onHandleVendorChange = async (vendor_id) => {
        setInspectionType(null);
        setVendorId(vendor_id);

        defaultValues.inspection_type = null;
        setContactInfoRequired(false);
        try {

            setProcess(true);

            let params = {
                vendor_id: vendor_id,
                company_id: propRowData.company_id
            };

            if (propRowData?.isStandalone === '1') {
                params.inspection_type = 'standalone-inspection'
            } else {
                params.inspection_type = 'report-inspection'
                params.report_type_id = propRowData.report_type_id
            }

            const result = await GetData("inspection/vendor-eligible-inspection-types", params)

            setEligibleInspectionTypes(result.data.inspectionTypes);
            setProcess(false);
        } catch (errors) {
            setProcess(false);
            toast["error"]("Something went wrong!");
        }
    };

    let onInspectionTypeChanged = async (val) => {

        let { slug, contact_info_required, gla_applicable, licensing_applicable } = val

        let fieldsTemp = fields

        fieldsTemp.gla_applicable = gla_applicable
        fieldsTemp.licensing_applicable = licensing_applicable

        setFields(fieldsTemp);
        setInspectionType(slug);
        setContactInfoRequired(contact_info_required === '1' ? true : false);
        setUpdate(moment());
    };

    let getReportOrderFees = async (inspection_type) => {
        setShowStripeMsg(false);
        setShowRefundMsg(false);
        setSelectedPaymentSource(null);
        setInspectionPriceData([]);
        setProcess(true);


        let params = {
            new_inspection_type: inspection_type,
            gla_include: fields?.gla_applicable === '1' ? fields.gla_include : '0',
            licensing_required: fields?.licensing_applicable === '1' ? fields.licensing_required : '0',
        };

        if (propRowData?.isStandalone === '1') {
            params.inspection_order_type = 'standalone-inspection'
            params.current_inspection_order_id = propRowData.inspection_order_id
        } else {
            params.inspection_order_type = 'report-inspection'
            params.report_order_id = propRowData.report_order_id
        }

        try {

            const result = await PostData("inspection/price-comparison", params)
            let inspectionFeesData = result.data

            let doPaymentProcessTemp = null;
            if (parseFloat(inspectionFeesData.totalVariance) > 0) {
                setShowStripeMsg(true);
                doPaymentProcessTemp = 'yes';
            } else if (parseFloat(inspectionFeesData.totalVariance) < 0) {
                setShowRefundMsg(true);
                doPaymentProcessTemp = 'no';
            }

            setDoPaymentProcess(doPaymentProcessTemp)
            setInspectionPriceData(inspectionFeesData);
            setProcess(false);
            setUpdate(moment())
        } catch (errors) {
            setProcess(false);
            toast["error"](errors?.errors?.length ? errors.errors[0].message : errors.message);
        };
    };

    let openPaymentMethodSelector = () => {
        setSelectedPaymentSource(null);
        setIsOpenPaymentSourceModal({ current: true });
    };

    let onReportOrderOriginalSourceCB = (result) => {
        setSelectedPaymentSource(result);
        setDefaultSourceChecked(true);
        setUpdate(moment());
    };

    let handlePaymentSourceCb = (result) => {
        if (result) {
            setSelectedPaymentSource(result);
            setUpdate(moment());
        }
    };

    return (
        <>
            {/* Cancel order Modal */}
            <RenderActionButton propButtonTitle="Cancel Report Order" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />

            <Modal className="modal-dialog-centered modal-md" isOpen={isOpenCancelReportOrderModal} toggle={() => { setOpenCancelReportOrderModal(!isOpenCancelReportOrderModal); resetFields(); }}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-center w-100">
                        Cancel Report Order
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => { setOpenCancelReportOrderModal(!isOpenCancelReportOrderModal); resetFields(); }}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body mt-3">
                    <AvForm onValidSubmit={(e) => InspectionOrderCancelOrReplaceButton(propRowData.inspection_order_id, 'entire_order')}>
                        <Spinner isShow={isProcess} />
                        <AvGroup className="text-left">
                            <AvField
                                name="cancellation_reason"
                                onChange={(e) => setCancellationReason(e.target.value)}
                                className="form-control"
                                placeholder="Enter reason to cancel report order"
                                type="textarea"
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: "This field is required."
                                    },
                                    maxLength: {
                                        value: 255,
                                        errorMessage: 'Reason must be less than 255 character'
                                    }
                                }}
                            />
                        </AvGroup>
                        <div className="text-right">
                            <FormGroup>
                                <Button size="sm" color="danger" outline onClick={() => { setOpenCancelReportOrderModal(!isOpenCancelReportOrderModal); resetFields(); }}>
                                    Close
                                </Button>
                                <Button size="sm" color="primary">
                                    Submit
                                </Button>
                            </FormGroup>
                        </div>
                    </AvForm>
                </div>
            </Modal>

            {/* Replace Inspection order Modal */}
            <Modal className="modal-dialog-centered modal-lg" isOpen={isOpenReplaceInspectionOrderModal} toggle={() => { setOpenReplaceInspectionOrderModal(!isOpenReplaceInspectionOrderModal); resetFields(); }}>
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-center w-100">
                        Replace Inspection Order
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => { setOpenReplaceInspectionOrderModal(!isOpenReplaceInspectionOrderModal); resetFields(); }}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body text-left">
                    <AvForm onValidSubmit={(e) => InspectionOrderCancelOrReplaceButton(propRowData.inspection_order_id, 'replace_inspection_order')} model={defaultValues}>
                        <Spinner isShow={isProcess} />
                        <Row>
                            <Col className="custom-av-radio-btn inline">
                                <AvRadioGroup name="vendor_id" label="Vendor" required >
                                    {inspectionVendors.map(function (vendor, i) {
                                        return (
                                            <AvRadio key={i} label={vendor.nickname} value={vendor.id} onClick={(e) => onHandleVendorChange(parseInt(vendor.id))} />
                                        );
                                    })}
                                </AvRadioGroup>
                            </Col>
                        </Row>
                        {eligibleInspectionTypes.length > 0 &&
                            <>
                                <Row>
                                    <Col className="custom-av-radio-btn inline">
                                        <AvRadioGroup name="inspection_type" label="Inspection Type" required >
                                            {eligibleInspectionTypes.map(function (inspectionEligibility, i) {
                                                return (
                                                    <AvRadio key={i} label={inspectionEligibility.name} value={inspectionEligibility.slug} onClick={(e) => onInspectionTypeChanged(inspectionEligibility)} />
                                                );
                                            })}
                                        </AvRadioGroup>
                                    </Col>
                                </Row>


                                {fields?.gla_applicable === '1' &&
                                    <Row>
                                        <Col className="my-2">
                                            <div color="info" className="custom-control custom-checkbox" sm="2">
                                                <input
                                                    className="custom-control-input"
                                                    name="gla_include"
                                                    id="gla_include"
                                                    type="checkbox"
                                                    value='1'
                                                    onChange={(e) => onDataChange(e)}
                                                    disabled={glaAndLicensingSettings?.glaAndLicensingSettings?.gla_required === '1'}
                                                    checked={(fields?.gla_include === '1')}
                                                />
                                                <label className="custom-control-label" htmlFor="gla_include">
                                                    Include GLA Measurement and Floorplan (${glaAndLicensingSettings?.glaAndLicensingCharges?.gla_surcharge})
                                                </label>
                                            </div>
                                        </Col>
                                    </Row>
                                }

                                {fields?.licensing_applicable === '1' &&
                                    <Row>
                                        <Col className="my-2">
                                            <div color="info" className="custom-control custom-checkbox" sm="2">
                                                <input
                                                    className="custom-control-input"
                                                    name="licensing_required"
                                                    id="licensing_required"
                                                    type="checkbox"
                                                    value='1'
                                                    onChange={(e) => onDataChange(e)}
                                                    disabled={glaAndLicensingSettings?.glaAndLicensingSettings?.licensed_required === '1'}
                                                    checked={fields?.licensing_required === '1'}
                                                />
                                                <label className="custom-control-label" htmlFor="licensing_required">
                                                    Require licensed agents only (${glaAndLicensingSettings?.glaAndLicensingCharges?.licensing_surcharge})
                                                </label>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                                {isContactInfoRequired &&
                                    <Row className="form-control-table-narrow">
                                        <Col lg="12">
                                            <h2>Property Access Contact Information</h2>
                                        </Col>
                                        <Col md="4" >
                                            <AvGroup>
                                                <AvField
                                                    name={"contact_name"}
                                                    onChange={(event) => setFields(prevState => ({
                                                        ...prevState,
                                                        contact_name: event.target.value,
                                                    }))}
                                                    className="form-control"
                                                    placeholder="Contact Name"
                                                    type="text"
                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: "This field is required."
                                                        },
                                                        maxLength: {
                                                            value: 50,
                                                            errorMessage: 'Max length is 50'
                                                        }
                                                    }}
                                                />
                                            </AvGroup>
                                        </Col>
                                        <Col md="4" >
                                            <AvGroup>
                                                <AvField
                                                    name={"contact_email"}
                                                    onChange={(event) => setFields(prevState => ({
                                                        ...prevState,
                                                        contact_email: event.target.value,
                                                    }))}
                                                    className="form-control"
                                                    placeholder="Contact Email"
                                                    type="email"
                                                    validate={{
                                                        pattern: {
                                                            value: '/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$/',
                                                            errorMessage: 'Invalid Email'
                                                        },
                                                        maxLength: {
                                                            value: 50,
                                                            errorMessage: 'Max length is 50'
                                                        }
                                                    }}
                                                />
                                            </AvGroup>
                                        </Col>
                                        <Col md="4" >
                                            <AvGroup>
                                                <AvField
                                                    name={"contact_phone"}
                                                    onChange={(event) => setFields(prevState => ({
                                                        ...prevState,
                                                        contact_phone: event.target.value,
                                                    }))}
                                                    className="form-control"
                                                    placeholder="Contact Phone"
                                                    type="text"
                                                    validate={{
                                                        pattern: {
                                                            value: '/^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})(?: *x(\\d+))?\\s*$/',
                                                            errorMessage: 'Invalid phone number'
                                                        }
                                                    }}
                                                />
                                            </AvGroup>
                                        </Col>

                                    </Row>
                                }
                            </>
                        }

                        {(showStripeMsg || showRefundMsg) &&
                            <>
                                {checkRoles(["ADMINISTRATOR", "SALES_REP", "ACCOUNT_REP"]) &&
                                    <Row>
                                        <Col xs="12" sm="12" md="12" lg="12" className="my-2">
                                            {showStripeMsg &&
                                                <Label className="required"><b> Fee Increase:</b> The new inspection has a fee of ${inspectionPriceData.totalNew} and the previous inspection was charged at ${inspectionPriceData.totalCurrent}. Would you like to charge the client in the amount of ${Math.abs(inspectionPriceData.totalVariance)} ? </Label>
                                            }
                                            {showRefundMsg &&
                                                <Label className="required"><b>Possible Refund:</b> The previous inspection was charged at ${inspectionPriceData.totalCurrent} and the new inspection has a fee of ${inspectionPriceData.totalNew}. Would you like to provide a refund to the client of ${Math.abs(inspectionPriceData.totalVariance)} ? </Label>
                                            }
                                            <div className="custom-radio-btn inline">
                                                <div >
                                                    <input type="radio" id='do_payment_process_yes' name="do_payment_process" value='yes'
                                                        onChange={(event) => {
                                                            setSelectedPaymentSource(null);
                                                            setDoPaymentProcess(event.target.value)
                                                        }
                                                        }
                                                        checked={'yes' === doPaymentProcess}
                                                    />
                                                    <label htmlFor='do_payment_process_yes'>Yes </label>
                                                </div>
                                                <div >
                                                    <input type="radio" id='do_payment_process_no' name="do_payment_process" value='no'
                                                        onChange={(event) => {
                                                            setSelectedPaymentSource(null);
                                                            setDoPaymentProcess(event.target.value)
                                                        }
                                                        }
                                                        checked={'no' === doPaymentProcess}
                                                    />
                                                    <label htmlFor='do_payment_process_no'>No</label>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </>
                        }

                        {(showStripeMsg && doPaymentProcess === 'yes') &&
                            <Row>
                                <Col className="mb-3">

                                    {!paymentError && <ReportOrderOriginalSource reportOrderId={propRowData.report_order_id} inspectionOrderId={propRowData.inspection_order_id} onReportOrderOriginalSourceCB={onReportOrderOriginalSourceCB} />}
                                    {paymentError &&
                                        <Row>
                                            <span className="col-md-12 text-danger font-weight-bold">
                                                The payment was failed because of following reason. <br />
                                                <ul><li>{paymentError}</li></ul>
                                            </span >
                                        </Row>
                                    }
                                    {(selectedPaymentSource && selectedPaymentSource?.isOriginalSource === false) &&
                                        <Label><span className="text-success mr-2"><i className="ni ni-check-bold"></i> {selectedPaymentSource.selectionText}</span><i className="fa fa-pencil-alt text-danger" onClick={() => openPaymentMethodSelector()} aria-hidden="true"></i></Label>
                                    }

                                    {(!selectedPaymentSource && defaultSourceChecked) &&
                                        <Button size="sm" color="warning" outline onClick={() => openPaymentMethodSelector()}> <i className="fas fa-credit-card"></i> Select Payment Method </Button>
                                    }
                                </Col>
                            </Row>
                        }


                        <div className="text-right">
                            <FormGroup>
                                <Button size="sm" color="danger" outline onClick={() => { setOpenReplaceInspectionOrderModal(!isOpenReplaceInspectionOrderModal); resetFields(); }}>
                                    Close
                                </Button>
                                <Button size="sm" color="primary"
                                    className={
                                        ((showStripeMsg && doPaymentProcess === 'yes' && !selectedPaymentSource) || inspectionType === null) ? "disabled" : ""
                                    }>
                                    Submit
                                </Button>
                            </FormGroup>
                        </div>
                    </AvForm>
                </div>
            </Modal >

            <PaymentForm onPay={isOpenPaymentSourceModal}
                paymentDetails={{
                    total_amount: inspectionPriceData?.totalVariance,
                    cc_surcharge: null,
                    paymentFor: 'Inspection Order'
                }}
                onPaymentSourceCb={handlePaymentSourceCb} clientId={propRowData?.company_id} />

        </>
    );
};

export default InspectionOrderCancelOrReplaceButton;