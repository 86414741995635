
import React, { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { PostData as OrderIntakePost } from "services/OrderIntakeData";
import { Button, Card, CardBody, Col, Label, Modal, Row } from "reactstrap";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import { sendExceptionEmail } from "components/Common/Helpers";
import RenderActionButton from "views/modules/Common/ActionsButtons/RenderActionButton";

const InspectionOrderPlaceHoldButton = ({ currentRow, propButtonOptions }) => {

    const [isProcess, setProcess] = useState(false);
    const [isOpenModal, setOpenModal] = useState(false);
    const [fields, setFields] = useState({});

    const handleActionClick = (e, row) => {
        handleToggleModal(isOpenModal)
    }

    const handleToggleModal = (state) => {
        if (!isProcess) {
            state = !state;
            setOpenModal(state);
            // setUpdate(moment());
        }
    };

    const handleSubmit = () => {
        confirmAlert({
            title: '',
            message: 'Are you sure you want to place this inspection order on Hold?',
            closeOnClickOutside: false,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        handleInspectionPlaceHoldProcess();
                    },
                    className: "btn btn-primary btn-sm"
                }, {
                    label: 'Close',
                    onClick: () => {
                        handleToggleModal(isOpenModal);
                    },
                    className: "btn btn-secondary btn-sm"
                }
            ]
        });
    };

    const handleInspectionPlaceHoldProcess = async () => {
        try {
            setProcess(true)
            const response = await OrderIntakePost("inspection/" + currentRow.inspection_order_token + "/place-hold", {
                hold_reason: fields.hold_reason
            }, currentRow.company_id);
            handleToggleModal(isOpenModal);
            toast["success"](response.message);
            setProcess(false)
        } catch (error) {
            toast["error"](error.errors && error.errors.length ? error.errors[0].message : error.message);
            await sendExceptionEmail(error, { memberCode: 200, componentName: 'InspectionOrderPaymentButton', functionName: 'handleInspectionPlaceHoldProcess', data: {} });
            setProcess(false)
        }
    };

    const handleInput = (e) => {
        let tempFields = fields;
        tempFields[e.target.name] = e.target.value

        setFields(tempFields);
    };

    return (
        <>
            <RenderActionButton propButtonTitle="Place Hold" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />

            <Modal
                className="modal-dialog-centered"
                size="md"
                isOpen={isOpenModal}
                toggle={() => handleToggleModal(isOpenModal)}
            >
                <div className="modal-header modal-header-colored">
                    <h2 className="modal-title text-center w-100">
                        Place Inspection On Hold
                    </h2>
                    <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenModal)}>
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body p-0">
                    <Card className="bg-secondary shadow border-0">
                        <CardBody className="text-left">
                            <AvForm onValidSubmit={(e) => handleSubmit()} >
                                <Row>
                                    <Col xs="12" sm="12" md="12" lg="12">
                                        <Label className="required">Place Hold Reason</Label>
                                        <AvField
                                            name="hold_reason"
                                            onChange={(e) => handleInput(e)}
                                            className="form-control"
                                            placeholder="Enter reason to place a hold"
                                            type="textarea"
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: 'This field is required'
                                                },
                                                minLength: {
                                                    value: 7,
                                                    errorMessage: 'Reason must be greater or equal to 7 character'
                                                },
                                                maxLength: {
                                                    value: 300,
                                                    errorMessage: 'Reason must be less or equal to 300 character'
                                                }
                                            }}
                                        />
                                    </Col>

                                    <Col xs="12" sm="12" md="12" lg="12">
                                        <div className="text-right">
                                            <AvGroup>
                                                {isProcess ?
                                                    <Button type="button" color="warning" size="sm">
                                                        <div className="fa fa-spinner fa-spin"></div> Processing...
                                                    </Button>
                                                    :
                                                    <>
                                                        <Button size="sm" color="danger" outline onClick={() => handleToggleModal(isOpenModal)}>
                                                            Cancel
                                                        </Button>
                                                        <Button size="sm" color="primary">
                                                            Submit
                                                        </Button>
                                                    </>
                                                }
                                            </AvGroup>
                                        </div>
                                    </Col>
                                </Row>
                            </AvForm>
                        </CardBody>
                    </Card>
                </div>
            </Modal>
        </>
    );
};

export default InspectionOrderPlaceHoldButton;