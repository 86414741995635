import React, { useState, useEffect } from "react";

import { PostData } from "../../../services/Api.service";
import { Button, UncontrolledTooltip, Card, CardBody, Container, Row, Col } from "reactstrap";
import { checkRoles, priceNumber, formatDateComponent } from "components/Common/Helpers.js"
import * as moment from "moment";
import { toast } from "react-toastify";
import { sendExceptionEmail } from "components/Common/Helpers";
import { useLocation } from 'react-router-dom'
import Header from "components/Headers/Header.js";
import MakeAdjustmentsModal from "../Common/MakeAdjustmentsModal";
import RenderBootstrapTable from "components/Common/RenderBootstrapTable";
import DueTotalInfo from "../Invoice/DueTotalInfo";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import TableSkeleton from "components/SkeletonUI/TableSkeleton";

const InvoiceAllSearch = (prop) => {
    let [isProcess, setProcess] = useState(false);
    let [update, setUpdate] = useState(moment());
    let [adjustmentData, setAdjustmentData] = useState([]);
    let [searchString, setSearchString] = useState("");
    let isOpenAdjustmentsModal = React.useRef(false);
    let location = useLocation();

    let [sortFieldName, setSortFieldName] = useState('id');
    let [sortOrder, setSortOrder] = useState('desc');
    let [currentPage, setCurrentPage] = useState(1);
    let [recordsPerPage, setRecordsPerPage] = useState(10);
    let [totalRecordSize, setTotalRecordSize] = useState(0);
    let [tableData, setTableData] = useState([]);
    let isOpenTotalDueInfoModal = React.useRef(false);
    let [dueTotalInfo, setDueTotalInfo] = useState([]);


    useEffect(() => {
        let searchString = new URLSearchParams(location.search);
        searchString = searchString.get('invoice-search');
        setSearchString(searchString);
    }, [location]);

    useEffect(() => {
        getProperties(searchString);
    }, [searchString, currentPage, recordsPerPage, sortFieldName, sortOrder]);// eslint-disable-line react-hooks/exhaustive-deps


    useEffect((e) => {

    }, [update]);

    let getProperties = async (searchStringParams, page = currentPage, currentSizePerPage = recordsPerPage, currentFieldName = sortFieldName, currentSortOrder = sortOrder) => {

        let currentOffset = (page - 1) * currentSizePerPage;
        let data = {
            offset: currentOffset,
            limit: currentSizePerPage,
            sort_field: currentFieldName,
            sort_order: currentSortOrder,
            search_text: searchStringParams,
        };

        try {
            if (searchStringParams) {
                setProcess(true)
                const getPropertiesResp = await PostData("accounting/get_search_invoices", data);

                let responseJson = getPropertiesResp.data.paymentData;
                let properties = [];

                for (let index = 0; index < responseJson.length; index++) {
                    let obj = responseJson[index];

                    let finalObj = {};

                    finalObj.id = obj.id;
                    finalObj.report_payment_id = obj.id;
                    finalObj.address = '';
                    finalObj.report_type = '-';
                    finalObj.inspection_type = '-';

                    if (obj.report_order && obj.report_order.property) {
                        finalObj.address = obj.report_order.property.address + ", " + obj.report_order.property.city + ", " + obj.report_order.property.state + ", " + obj.report_order.property.postal_code;
                        finalObj.report_type = obj.report_order.report_type.formal_name;
                        finalObj.asset_ref_id = obj.report_order.property && obj.report_order.property.asset_ref_id !== '' ? obj.report_order.property.asset_ref_id : '-';
                    } else if (obj.inspection_order && obj.inspection_order) {

                        if (obj.inspection_order.property) {
                            finalObj.address = obj.inspection_order.property.address + ", " + obj.inspection_order.property.city + ", " + obj.inspection_order.property.state + ", " + obj.inspection_order.property.postal_code;
                        } else {
                            finalObj.address = obj.inspection_order.inspection_property.property_address + ", " + obj.inspection_order.inspection_property.city + ", " + obj.inspection_order.inspection_property.state + ", " + obj.inspection_order.inspection_property.postal_code;
                        }
                        finalObj.inspection_type = obj.inspection_order.inspection_type.name;
                        finalObj.asset_ref_id = obj.inspection_order.inspection_property ? obj.inspection_order.inspection_property.asset_ref_id : '-';

                    }

                    finalObj.date_ordered = obj.datetime;
                    finalObj.adjustments = parseFloat(obj.total_adjustments ? obj.total_adjustments : 0);
                    finalObj.base_fee = parseFloat(obj.total_base_fee ? obj.total_base_fee : 0);
                    finalObj.conversion_fee = parseFloat(obj.total_conversion_fee ? obj.total_conversion_fee : 0);
                    finalObj.rush_fee = parseFloat(obj.total_rush_fee ? obj.total_rush_fee : 0);
                    finalObj.flood_certificate_fee = parseFloat(obj.total_flood_certificate_fee ? obj.total_flood_certificate_fee : 0);
                    finalObj.inspection_fee = parseFloat(obj.total_inspection_fee ? obj.total_inspection_fee : 0);
                    if (obj.invoice_number) {
                        finalObj.amount = parseFloat(obj.total_amount ? obj.total_amount : 0);
                    } else {
                        finalObj.amount = parseFloat(obj.total_amount_due ? obj.total_amount_due : 0);
                    }
                    finalObj.report_payment_ids = obj.report_payment_ids ? obj.report_payment_ids : null;
                    finalObj.report_payment_status = obj.payment_status.report_pymt_status;
                    finalObj.surcharge = parseFloat(obj.total_surcharge ? obj.total_surcharge : 0);
                    finalObj.discount = parseFloat(obj.total_discount ? obj.total_discount : 0);

                    properties.push(finalObj);
                }
                setTableData(properties);
                setTotalRecordSize(getPropertiesResp.data.count);
                setRecordsPerPage(currentSizePerPage);
                setCurrentPage(page);
                setProcess(false);
            }
        } catch (errors) {
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message)
            await sendExceptionEmail(errors, { memberCode: 200, componentName: 'InvoiceAllSearch', functionName: 'getProperties', data: {} });
            setProcess(false);
        }

    }

    let onPageChangeEvent = (currentPage, recordsPerPage) => {
        setProcess(true);
        getProperties(searchString, currentPage, recordsPerPage);
    };

    let paginationTotalText = (start, to, total) => {
        return (
            <p>From {start} to {to}, totals is {total}</p>
        );
    };

    let onSortChangeEvent = (fieldName, order) => {
        if (order === 'desc') {
            order = 'asc';
        } else {
            order = 'desc';
        }

        setSortFieldName(fieldName);
        setSortOrder(order);

        getProperties(searchString, currentPage, recordsPerPage, fieldName, order);
    };

    /*
|       Invoice Action
     */
    let invoiceActionData = (cell, row) => {
        return (
            <div>
                {
                    <>
                        <Button id="trip_charge" color="primary" size="sm" type="button" onClick={() => openAdjustmentsModal(row)}>
                            <i className="fa fa-pencil-alt" aria-hidden="true"></i>
                        </Button>
                        <UncontrolledTooltip delay={0} placement="auto" target="trip_charge" >Make Adjustments</UncontrolledTooltip>
                    </>
                }
            </div >
        );
    };

    let openAdjustmentsModal = (row) => {
        isOpenAdjustmentsModal.current = true;
        setAdjustmentData(row)
        setUpdate(moment());
    }

    let onAdjustmentMade = () => {
        getProperties(searchString);
    };

    let totalDueInfo = (cell, row) => {

        return (
            <>
                <Link to="#" className="custom-link" onClick={(e) => handelTotalDue(e, row)}>{priceNumber(row.amount, 2)}</Link>
            </>
        )
    }

    let handelTotalDue = (e, row) => {
        e.preventDefault();
        isOpenTotalDueInfoModal.current = true;
        let displayData = {};
        displayData.base_fee = row.base_fee;
        displayData.conversion_fee = row.conversion_fee;
        displayData.inspection_fee = row.inspection_fee;
        displayData.rush_fee = row.rush_fee;
        displayData.surcharge = row.surcharge;
        displayData.flood_certificate_fee = row.flood_certificate_fee;
        displayData.gla_surcharge = row.gla_surcharge;
        displayData.licensing_surcharge = row.licensing_surcharge;
        displayData.adjustments = row.adjustments;
        displayData.discount = row.discount;
        displayData.total_price = row.amount;
        setDueTotalInfo(displayData);
    }


    let columns = [
        { width: "8%", title: 'Client Ref Number', dataField: 'asset_ref_id', isKey: true },
        { width: "13%", title: 'Address', dataField: 'address', align: 'left' },
        { width: "9%", title: 'Report Type', dataField: 'report_type', align: 'left' },
        { width: "9%", title: 'Inspection Type', dataField: 'inspection_type', align: 'left' },
        { width: "9%", title: `Date Ordered`, dataFormat: (cell, row) => formatDateComponent("date_ordered", row, "MMM D h:mm a"), align: 'left' },
        { width: "7%", title: `Total`, dataFormat: totalDueInfo, align: 'left' }
    ];

    if (checkRoles(['ADMINISTRATOR', 'ACCOUNTING', 'SALES_REP', 'ACCOUNT_REP'])) {
        columns.push({ width: "10%", title: 'Actions', dataFormat: invoiceActionData });
    }

    const tableParams = {
        currentPage,
        recordsPerPage,
        onPageChangeEvent,
        onSortChangeEvent,
        paginationTotalText,
        tableData,
        totalRecordSize,
        columns,
    };

    return (
        <>
            <Header />
            <Container className="mt--9 container-vh-100 search-result" fluid>
                <Card className="shadow">
                    <CardBody >
                        <Row>
                            <Col>
                                <h2 className="text-left">Search Result</h2>
                            </Col>
                            {isProcess ?
                                <TableSkeleton rows={10} cols={7} />
                                :
                                <RenderBootstrapTable tableParams={tableParams} />
                            }
                        </Row>
                    </CardBody>
                </Card>
            </Container>

            {isOpenAdjustmentsModal.current &&
                <MakeAdjustmentsModal isOpenModal={isOpenAdjustmentsModal} adjustmentData={adjustmentData} onAdjustment={onAdjustmentMade} />
            }
            {isOpenTotalDueInfoModal.current &&
                <DueTotalInfo data={{ isModal: isOpenTotalDueInfoModal, dueInfo: dueTotalInfo }} />
            }
        </>
    )
}

export default InvoiceAllSearch;