import Spinner from "components/Common/Spinner";
import moment from "moment";
import { useEffect, useState } from "react";
import RenderActionButton from "./RenderActionButton";
import { Button, Col, FormGroup, Label, Modal } from "reactstrap";
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation";
import { PostData } from "services/Api.service";
import { toast } from "react-toastify";
import eventBus from "../EventBus";
import { sendExceptionEmail } from "components/Common/Helpers";

const InspectionOrderDismissRevisionButton = ({ propRowData, propButtonOptions }) => {
    let [isOpenModal, setIsOpenModal] = useState(false);
    let [update, setUpdate] = useState(moment());
    let [isProcess, setProcess] = useState(false);
    const [note, setNote] = useState('');
    useEffect(() => { }, [update]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleActionClick = () => {
        setIsOpenModal(true);
    };

    let dismissRevisionOrder = async (propRowData) => {
        try {
            setProcess(true);
            let params = {
                inspection_order_id: propRowData.inspection_order_id,
                notes: note,
            }
            let result = await PostData("inspection/dismiss-revision-inspection-order", params);
            toast["success"](result.message);
            eventBus.dispatch("inspection_order_status_updated", { client_id: propRowData.company_id });
            setIsOpenModal(false);
            setProcess(false);
        } catch (errors) {
            setProcess(false);
            toast['error'](errors.errors && errors.errors.length ? errors.errors[0].message : errors.message)
            await sendExceptionEmail(errors, { memberCode: 502, componentName: 'InspectionOrderDismissRevisionButton', functionName: 'dismissRevisionOrder', data: {} });
        }
        setUpdate(moment());
    };

    let handleToggleModal = (state) => {
        setIsOpenModal(!state);
        setUpdate(moment());
    };

    return (
        <>
            <RenderActionButton propButtonTitle="Cancel/Dismiss Revision" propButtonOptions={propButtonOptions} propOnClickEvent={handleActionClick} />

            {isOpenModal &&
                <Modal
                    className="modal-dialog-centered"
                    isOpen={isOpenModal}
                    toggle={() => handleToggleModal(isOpenModal)}
                >
                    <div className="modal-header modal-header-colored">
                        <h2 className="modal-title text-center w-100">
                            Are you sure you want to dismiss the revision order?
                        </h2>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => handleToggleModal(isOpenModal)}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body text-left">
                        <Spinner isShow={isProcess} />
                        <AvForm onValidSubmit={(e) => dismissRevisionOrder(propRowData)}>
                            <Col md="12" >
                                <AvGroup>
                                    <Label className="required">Reason : </Label>
                                    <AvField
                                        name={"Notes"}
                                        onChange={(e) => {
                                            const cleanedNote = e.target.value.replace(/ +/g, ' ').trim();
                                            setNote(cleanedNote);
                                        }}
                                        className="form-control"
                                        placeholder="Enter Notes"
                                        type="textarea"
                                        validate={{
                                            required: {
                                                value: true,
                                                errorMessage: 'This field is required.'
                                            },
                                            minLength: {
                                                value: 15,
                                                errorMessage: 'Please enter minimum 15 character.'
                                            }
                                        }}
                                    />

                                </AvGroup>
                            </Col>
                            <div className="text-right">
                                <FormGroup>
                                    <Button size="sm" color="danger" outline onClick={() => handleToggleModal(isOpenModal)}>
                                        Close
                                    </Button>
                                    <Button size="sm" color="info">
                                        Submit
                                    </Button>
                                </FormGroup>
                            </div>
                        </AvForm>
                    </div>
                </Modal>
            }
        </>
    );
}

export default InspectionOrderDismissRevisionButton;